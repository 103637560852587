import * as React from "react";
import { SFC } from "react";
import { ResourceCollection } from "client/resources/resourceCollection";
import { EventResource } from "client/resources/eventResource";
import DateFormatter from "utils/DateFormatter/DateFormatter";
import { EventFormatter } from "components/EventFormatter/EventFormatter";
import SimpleDataTable from "../../../../components/SimpleDataTable/SimpleDataTable";
const styles = require("./history.less");
import ToolTip from "../../../../components/ToolTip/ToolTip";

interface HistoryProps {
    history: ResourceCollection<EventResource>;
}

class HistoryTable extends SimpleDataTable<EventResource> {}
const History: SFC<HistoryProps> = props => {
    return (
        <div className={styles.container}>
            <h4>Task History</h4>
            <HistoryTable
                rowColumnClassName={styles.row}
                data={props.history.Items}
                headerColumns={["When", "Who", "What"]}
                headerColumnClassNames={[styles.row, styles.row, styles.row]}
                onRow={event => [
                    <ToolTip content={DateFormatter.dateToShortFormat(event.Occurred)}>
                        <div style={{ minWidth: "5rem" }}>{DateFormatter.momentAgo(event.Occurred)}</div>
                    </ToolTip>,
                    event.Username,
                    <EventFormatter event={event} />,
                ]}
            />
        </div>
    );
};

History.displayName = "History";

export default History;
