import { combineReducers } from "redux";

export const OnboardingActions = {
    ONBOARDING_UPDATED: "ONBOARDING_UPDATED",
};

export interface OnboardingAreaState {
    config: OnboardingState;
}

export interface OnboardingState {
    showFooterOnDashboard: boolean;
}

export function onboardingStateUpdated(state: OnboardingState) {
    return { type: OnboardingActions.ONBOARDING_UPDATED, state };
}

const updateConfig = (state: OnboardingState, patch: Partial<OnboardingState>) => (state ? { ...state, ...patch } : state);
const INITIAL_STATE: OnboardingState = { showFooterOnDashboard: false };

const onboardingConfig = (state: OnboardingState = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case OnboardingActions.ONBOARDING_UPDATED:
            const config = action.state as OnboardingState;
            return updateConfig(state, config);
        default:
            return state;
    }
};

const onboardingArea = combineReducers({ config: onboardingConfig });

export default onboardingArea;
