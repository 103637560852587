import * as React from "react";
const styles = require("./style.less");

interface VariableCellProps {
    className?: string;
    style?: React.CSSProperties;
    children: React.ReactNode;
}

const VariableCell: React.SFC<VariableCellProps> = (props: VariableCellProps) => {
    return (
        <div className={`${styles.cell} ${props.className}`} style={props.style}>
            {props.children}
        </div>
    );
};

export default VariableCell;
