import Resource from "./resource";
import Permission from "./permission";
import ProjectedTeamReferenceDataItem from "./projectedTeamReferenceDataItem";
import UserPermissionRestriction from "./userPermissionRestriction";

export type Permissions = Record<string, UserPermissionRestriction[]>;

export class UserPermissionSetResource extends Resource {
    SpacePermissions: Permissions;
    SystemPermissions: Permission[];
    Teams: ProjectedTeamReferenceDataItem[];
    IsTeamsComplete: boolean;
    IsPermissionsComplete: boolean;
}

export default UserPermissionSetResource;
