import { ProjectRouteParams } from "areas/projects/components/ProjectLayout";
import { repository } from "clientInstance";
import AreaTitle from "components/AreaTitle";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import PaperLayout from "components/PaperLayout";
import * as H from "history";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import * as URI from "urijs";

import routeLinks from "../../../../routeLinks";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import { calculateOverviewRedirect } from "./calculateOverviewRedirect";

interface OverviewFilterRedirectState extends DataBaseComponentState {
    redirectTo?: H.LocationDescriptor;
}

export class OverviewRedirect extends DataBaseComponent<RouteComponentProps<ProjectRouteParams>, OverviewFilterRedirectState> {
    constructor(props: RouteComponentProps<ProjectRouteParams>) {
        super(props);
        this.state = {
            redirectTo: null,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const projectSlug = this.props.match.params.projectSlug;
            const releaseVersion = URI(this.props.location.search).search(true).release;
            const releaseId = await this.getReleaseIdFromVersion(releaseVersion);

            const redirectTo = calculateOverviewRedirect(projectSlug, releaseId);

            this.setState({ redirectTo });
        });
    }

    render() {
        const to = this.state.redirectTo;
        if (!to) {
            return (
                <main id="maincontent">
                    <AreaTitle link={routeLinks.projects.root} title="Projects" />
                    <PaperLayout busy={this.state.busy} fullWidth={true} errors={this.state.errors} />
                </main>
            );
        }

        return <InternalRedirect push={false} to={to} />;
    }

    private async getReleaseIdFromVersion(releaseVersion: string) {
        if (!releaseVersion) {
            return null;
        }

        try {
            const project = await repository.Projects.get(this.props.match.params.projectSlug);
            const release = await repository.Projects.getReleaseByVersion(project, releaseVersion);
            if (!release) {
                return null;
            }

            return release.Id;
        } catch (err) {
            return null;
        }
    }
}
