import NamedResource from "./namedResource";
import { WorkerPoolType } from "./workerPoolsSupportedTypesResouce";

export abstract class WorkerPoolResource extends NamedResource {
    Description: string;
    SortOrder: number;
    IsDefault: boolean;
    CanAddWorkers: boolean;
    WorkerPoolType: WorkerPoolType;
}

export class StaticWorkerPoolResource extends WorkerPoolResource {
    constructor() {
        super();
        this.WorkerPoolType = WorkerPoolType.Static;
    }
}

export class DynamicWorkerPoolResource extends WorkerPoolResource {
    WorkerType: string;

    constructor() {
        super();
        this.WorkerPoolType = WorkerPoolType.Dynamic;
    }
}

export default WorkerPoolResource;
