import * as _ from "lodash";
import Logger from "client/logger";

export enum ParameterDataType {
    string = "string",
    secureString = "securestring",
    int = "int",
    bool = "bool",
    object = "object",
    secureObject = "secureobject",
    array = "array",
}

export enum Source {
    octopus = "octopus",
    azureKeyVault = "azureKeyVault",
}

export class ParameterValues {
    [paramName: string]: ParameterValue;
}

export interface ParameterValue {
    type: ParameterDataType;
    value: string;
    source: Source;
    defaultValue?: string;
    allowedValues?: string[];
    minValue?: string;
    maxValue?: string;
    minLength?: string;
    maxLength?: string;
    description?: string;
    keyVaultId?: string;
    keyVaultSecretName?: string;
}

export interface ParameterResult<T> {
    value: T;
    errors: string[];
}

export class AzureTemplateHelper {
    public static extractParameters = (templateFile: string, parametersFile: string = ""): ParameterResult<ParameterValues> => {
        const parameterValues = new ParameterValues();
        const errors = [];
        let template;
        let parameters: any;

        try {
            template = JSON.parse(templateFile);
            parameters = parametersFile ? JSON.parse(parametersFile) : {};

            if (template.parameters) {
                _.each(template.parameters, (parameter, parameterName) => {
                    const existingParameterValue = parameters[parameterName];
                    const parameterType = AzureTemplateHelper.normalizeType(parameter.type);
                    const parameterValue: ParameterValue = {
                        ...parameter,
                        type: parameterType,
                        description: parameter.metadata !== undefined ? parameter.metadata.description : undefined,
                        value: existingParameterValue ? AzureTemplateHelper.getParameterValue(parameters[parameterName].value) : AzureTemplateHelper.getParameterValueWithDefault(parameter, parameterType),
                        source: Source.octopus,
                    };

                    if (existingParameterValue && existingParameterValue.reference) {
                        parameterValue.keyVaultId = existingParameterValue.reference.keyVault.id;
                        parameterValue.keyVaultSecretName = existingParameterValue.reference.secretName;
                        parameterValue.source = Source.azureKeyVault;
                    }

                    parameterValues[parameterName] = parameterValue;
                });
            }
        } catch (e) {
            if (template) {
                // error with parametersFile
                errors.push("The parameter file is not valid JSON");
            } else {
                errors.push("The template file is not valid JSON");
            }
        }

        return { value: parameterValues, errors };
    };

    public static exportParameters = (parameters: ParameterValues): ParameterResult<string> => {
        const armParams: any = {};
        const invalidTemplateParameters: string[] = [];

        if (parameters) {
            _.each(parameters, (paramValue, paramName) => {
                const armParameterValue: any = {};
                if (paramValue.source !== Source.azureKeyVault) {
                    if (AzureTemplateHelper.shouldJsonParse(paramValue.type)) {
                        try {
                            armParameterValue.value = JSON.parse(paramValue.value);
                        } catch (e) {
                            //Logger.error("Failed to parse '" + paramValue.value + "' as valid JSON.");
                            invalidTemplateParameters.push(paramName);
                            armParameterValue.value = paramValue.value; // Leave this as what the user typed in ... give them the opportunity to fix it.
                        }
                    } else {
                        armParameterValue.value = paramValue.value;
                    }
                } else {
                    armParameterValue.reference = {
                        keyVault: {
                            id: paramValue.keyVaultId,
                        },
                        secretName: paramValue.keyVaultSecretName,
                    };
                }

                armParams[paramName] = armParameterValue;
            });
        }

        return { value: JSON.stringify(armParams), errors: invalidTemplateParameters };
    };

    static getParameterValue = (value: any): string => {
        if (_.isObject(value)) {
            return JSON.stringify(value, undefined, 4);
        } else {
            return value;
        }
    };

    static getParameterValueWithDefault(parameter: ParameterValue, parameterType: string): string {
        if (parameterType === ParameterDataType.bool) {
            if (parameter.defaultValue === undefined) {
                return "False";
            }
            return parameter.defaultValue.toString();
        }
        return parameter.defaultValue ? parameter.defaultValue : parameterType === ParameterDataType.array ? "[]" : parameterType === ParameterDataType.object ? "{}" : undefined;
    }

    //Technically speaking type names should follow camelCase naming convention as per https://azure.microsoft.com/en-us/documentation/articles/resource-group-authoring-templates/
    //but even Visual Studio project does not follow it.
    static normalizeType(type: string) {
        return type.toLowerCase();
    }

    //Parameters that are of type 'array', 'object' or 'secureObject' needs to be parsed as JSON,
    //otherwise the template will not be deemed valid as they will be used as a string and the deployment
    //will fail with an error message similar to the below:
    //'Hyak.Common.CloudException: InvalidTemplate: Deployment template validation failed:
    //'The provided value for the template parameter '<parameterName>' at line 'x' and column 'y' is not valid.'.
    static shouldJsonParse = (type: ParameterDataType): boolean => {
        return type === ParameterDataType.array || type === ParameterDataType.object || type === ParameterDataType.secureObject;
    };
}
