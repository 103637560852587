import BasicRepository, { AllArgs, ListArgs } from "./basicRepository";
import Client from "../client";
import { TenantVariableResource } from "client/resources/tenantVariableResource";

type TenantVariableListArgs = {
    projectId?: string;
} & ListArgs;

type TenantVariableAllArgs = {
    projectId?: string;
} & AllArgs;

class TenantVariableRepository extends BasicRepository<TenantVariableResource, TenantVariableResource, TenantVariableListArgs, TenantVariableAllArgs> {
    constructor(client: Client) {
        super("TenantVariables", client);
    }
}

export default TenantVariableRepository;
