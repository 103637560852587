import * as React from "react";
import DataBaseComponent from "components/DataBaseComponent";
import { repository } from "clientInstance";
import { Sensitive, SensitiveFileUpload, Note } from "components/form";
import ExternalLink from "components/Navigation/ExternalLink";
import Callout, { CalloutType } from "components/Callout/Callout";
import { SensitiveValue } from "client/resources/propertyValueResource";
import { CertificateResource } from "client/resources";
import { DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";

interface CertificateReplaceFormProps {
    certificate: CertificateResource;
    afterCertificateReplace(replacedCertificate: CertificateResource): void;
}

interface CertificateReplaceFormState extends DataBaseComponentState {
    certificateData?: SensitiveValue;
    certificatePassword?: SensitiveValue;
}

class ReplaceCertificate extends DataBaseComponent<CertificateReplaceFormProps, CertificateReplaceFormState> {
    constructor(props: CertificateReplaceFormProps) {
        super(props);

        this.state = {
            certificatePassword: { NewValue: null, HasValue: false },
        };
    }

    async save() {
        return this.doBusyTask(async () => {
            const result = (await repository.CertificateConfiguration.replace(
                this.props.certificate,
                this.state.certificateData && this.state.certificateData.NewValue,
                this.state.certificatePassword && this.state.certificatePassword.NewValue
            )) as CertificateResource;
            const cert = await repository.Certificates.get(result.Id);
            this.props.afterCertificateReplace(cert);
        });
    }

    render() {
        return (
            <SaveDialogLayout title="Replace Certificate" busy={this.state.busy} errors={this.state.errors} onSaveClick={() => this.save()}>
                <div>
                    <p>Replace this certificate with an uploaded certificate file.</p>
                    <Callout title="Note" type={CalloutType.Information}>
                        Referencing variables will refer to the new certificate. The existing certificate will be archived. See our <ExternalLink href="ReplaceCertificate">documentation</ExternalLink> for more information.
                    </Callout>
                    <SensitiveFileUpload label="Certificate File" value={this.state.certificateData} onChange={file => this.setState({ certificateData: file })} />
                    <Note>Supported formats: PFX (PKCS #12), DER, PEM</Note>
                    <Sensitive value={this.state.certificatePassword} onChange={pwd => this.setState({ certificatePassword: pwd })} label="Password" />
                    <Note>The password protecting the file (if required).</Note>
                </div>
            </SaveDialogLayout>
        );
    }
}

export default ReplaceCertificate;
