export interface TimeSpan {
    Days: number;
    Hours: number;
    Minutes: number;
    Seconds: number;
}

export class TimeSpanString extends String {
    public static Zero: string = "00:00:00";
    public static OneHour: string = "0.01:00:00";
    public static TenSeconds: string = "00:00:10";
}
export default TimeSpanString;
