import * as React from "react";
import { alert } from "theme/colors";
import ToolTip from "../../../components/ToolTip/index";

interface MissingVariablesIcon {
    show: boolean;
}

const MissingVariablesIcon: React.StatelessComponent<MissingVariablesIcon> = props => {
    if (!props.show) {
        return null;
    }

    return (
        <ToolTip content={`Some of the required variable values have not been set`}>
            <em className={"fa fa-warning"} style={{ color: alert }} />
        </ToolTip>
    );
};

export default MissingVariablesIcon;
