import Resource from "./resource";

export class FeaturesConfigurationResource extends Resource {
    IsCommunityActionTemplatesEnabled: boolean;
    IsServiceMessageResourceCreationEnabled: boolean;
    IsBuiltInWorkerEnabled: boolean;
    IsHelpSidebarEnabled: boolean;
    HelpSidebarSupportLink: string;
}

export default FeaturesConfigurationResource;
