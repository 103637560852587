import { PackagingRegistration } from "../../Registry/packagingRegistry";

import BuildServerPackagingInstruction from "./BuildServerPackagingInstruction";
import ManualPackagingInstruction from "./ManualPackagingInstruction";
import CommandLinePackagingInstruction from "./CommandLinePackagingInstruction";

export const AspNetPackagingRegistration: PackagingRegistration = {
    displayOrder: 100,
    instructions: [BuildServerPackagingInstruction, ManualPackagingInstruction, CommandLinePackagingInstruction],
    name: "ASP.NET",
    id: "AspNet",
};

export default AspNetPackagingRegistration;
