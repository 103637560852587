import * as React from "react";

interface LookupProps<T> {
    lookupCollection: T[];
    lookupId: string;
    getIdFromElement: (element: T) => string;
    render: (item: T) => JSX.Element;
    renderFallback: JSX.Element;
    allowMissingItem?: boolean;
}

const Lookup: React.StatelessComponent<LookupProps<any>> = props => {
    let item = props.lookupCollection.find(element => props.getIdFromElement(element) === props.lookupId);

    if (!!props.allowMissingItem === true && !item) {
        item = { Id: props.lookupId, Name: props.lookupId };
    }

    return item ? props.render(item) : props.renderFallback;
};

export default Lookup;
