import React = require("react");
import ExternalLink from "components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
import { TopicsContainer, LearnMoreResources } from "./CommonViews";

export const CommonInfrastructureMachinePolicyOverview: React.StatelessComponent<{}> = props => {
    return (
        <OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="OnboardingMachinePoliciesLearnMore">Machine policies</ExternalLink> are groups of settings that can be applied to Tentacle and SSH endpoints to modify their behavior.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>
    );
};

export const CommonInfrastructureMachinePolicyTopics: React.StatelessComponent<{}> = props => {
    return (
        <TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="OnboardingMachinePoliciesLearnMore">Machine Policies</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>
    );
};
