import { Reducer, combineReducers } from "redux";
import { ErrorsState } from "./ErrorsState";
import current, { UnhandledErrorState } from "./current";

export * from "./actions";
export * from "./selectors";
export { ErrorsState, UnhandledErrorState };

const unhandledErrorsReducer: Reducer<ErrorsState> = combineReducers({ current });

export default unhandledErrorsReducer;
