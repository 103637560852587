import * as React from "react";
import { Switch } from "react-router-dom";
import { Projects } from "../Projects";
import { ProjectLayout } from "../ProjectLayout";
import { ReleasesRoute } from "../Releases";
import { TriggersRoute } from "../Triggers";
import { ChannelsRoute } from "../Channels";
import { DeploymentProcessRoute } from "../DeploymentProcess/DeploymentProcessRoute";
import VariablesRoute from "../Variables/VariablesRoute";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import { RouteComponentProps } from "react-router";
import * as H from "history";
import AreaTitle from "components/AreaTitle/index";
import { repository } from "clientInstance";
import { ProjectRouteParams } from "../ProjectLayout/ProjectLayout";
import SlugSafeRedirect from "components/SlugSafeRedirect/SlugSafeRedirect";
import routeLinks from "routeLinks";
import PaperLayout from "components/PaperLayout";
import { RedirectAs404 } from "components/NotFound/NotFound";
import { OverviewRedirect } from "areas/projects/components/ProjectsRoutes/OverviewRedirect";
import InternalRedirect from "components/Navigation/InternalRedirect/InternalRedirect";
import pageIds from "pageIds";
import { withPage } from "components/Page/Page";
import { ProjectSettings } from "../ProjectSettings";
import { ProjectOverview } from "../ProjectOverview";

const ProjectSettingsPage = withPage({ page: pageIds.project().settings })(ProjectSettings);
const ProjectOverviewPage = withPage({ page: pageIds.project().overview })(ProjectOverview);
const ProjectsPage = withPage({ page: pageIds.projects.root })(Projects);

export class ProjectRoutes extends React.Component<RouteComponentProps<any>, any> {
    async loadSlugFromProjectId(projectId: string, location: H.Location) {
        if (location.state && location.state.project) {
            return location.state.project.Slug;
        }
        return (await repository.Projects.get(projectId)).Slug;
    }

    projectSlugLoading() {
        return (
            <main id="maincontent">
                <AreaTitle link={routeLinks.projects.root} title="Projects" />
                <PaperLayout busy={true} fullWidth={true} />
            </main>
        );
    }

    render() {
        const projectSlugParameter = "projectSlug";
        const specificProjectLink = routeLinks.project(`:${projectSlugParameter}`);
        return (
            <Switch>
                <ReloadableRoute path={routeLinks.v3projectRoutes.newProject}>
                    <InternalRedirect to={routeLinks.projects.root} />
                </ReloadableRoute>
                <ReloadableRoute path={specificProjectLink.root}>
                    <SlugSafeRedirect parameter={projectSlugParameter} regexp={/^Projects-[0-9]+$/} getRealParam={this.loadSlugFromProjectId} loadingComponent={this.projectSlugLoading}>
                        <ProjectLayout>
                            <Switch>
                                <ReloadableRoute path={specificProjectLink.releases} component={ReleasesRoute} />
                                <ReloadableRoute path={specificProjectLink.overview} component={ProjectOverviewPage} />
                                <ReloadableRoute path={specificProjectLink.settings} component={ProjectSettingsPage} />
                                <ReloadableRoute path={specificProjectLink.triggers} component={TriggersRoute} />
                                <ReloadableRoute path={specificProjectLink.channels} component={ChannelsRoute} />
                                <ReloadableRoute path={specificProjectLink.process.root} component={DeploymentProcessRoute} />
                                <ReloadableRoute path={specificProjectLink.variables.root} component={VariablesRoute} />
                                <ReloadableRoute
                                    path={specificProjectLink.steptemplates}
                                    render={(props: RouteComponentProps<ProjectRouteParams>) => <InternalRedirect to={routeLinks.project(props.match.params.projectSlug).process.stepTemplates} />}
                                />
                                <ReloadableRoute
                                    path={specificProjectLink.childStepTemplates(":parentStepId").root}
                                    render={(props: RouteComponentProps<ProjectRouteParams & { parentStepId: string }>) => (
                                        <InternalRedirect to={routeLinks.project(props.match.params.projectSlug).process.childStepTemplates(props.match.params.parentStepId).root} />
                                    )}
                                />
                                <ReloadableRoute exact={true} path={specificProjectLink.root} component={OverviewRedirect} />
                                <RedirectAs404 />
                            </Switch>
                        </ProjectLayout>
                    </SlugSafeRedirect>
                </ReloadableRoute>
                <ReloadableRoute path={routeLinks.projects.root} component={ProjectsPage} />
                <RedirectAs404 />
            </Switch>
        );
    }
}

export default ProjectRoutes;
