import * as React from "react";
import { OctopusPackageMetadataMappedResource } from "client/resources";
import DataBaseComponent, { DataBaseComponentState } from "components/DataBaseComponent";
import WorkItems from "components/WorkItems/WorkItems";
import ExternalLink from "components/Navigation/ExternalLink";
import { DataTable, DataTableBody, DataTableRow, DataTableRowHeaderColumn, DataTableRowColumn } from "components/DataTable";
import SimpleExpander from "components/SimpleExpander/SimpleExpander";
import CommitDetails from "components/Commits/CommitDetails";
const styles = require("./styles.less");

interface PackageMetadataProps {
    data: OctopusPackageMetadataMappedResource;
    heading?: string;
}

export default class PackageMetadataTable extends DataBaseComponent<PackageMetadataProps, DataBaseComponentState> {
    constructor(props: PackageMetadataProps) {
        super(props);
        this.state = {};
    }

    buildTable() {
        return (
            <DataTable>
                <DataTableBody>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Build Environment</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{this.props.data.BuildEnvironment}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Build</DataTableRowHeaderColumn>
                        <DataTableRowColumn>
                            <ExternalLink href={this.props.data.BuildUrl}>{this.props.data.BuildNumber}</ExternalLink>
                        </DataTableRowColumn>
                    </DataTableRow>
                    {this.props.data && this.props.data.Commits && this.props.data.Commits.length > 0 && (
                        <DataTableRow>
                            <DataTableRowHeaderColumn>Commits</DataTableRowHeaderColumn>
                            <DataTableRowColumn>
                                <CommitDetails data={this.props.data.Commits} />
                            </DataTableRowColumn>
                        </DataTableRow>
                    )}
                    {this.props.data && this.props.data.WorkItems && this.props.data.WorkItems.length > 0 && (
                        <DataTableRow>
                            <DataTableRowHeaderColumn>Work Items</DataTableRowHeaderColumn>
                            <DataTableRowColumn>
                                <WorkItems data={this.props.data.WorkItems} />
                            </DataTableRowColumn>
                        </DataTableRow>
                    )}
                </DataTableBody>
            </DataTable>
        );
    }

    render() {
        return this.props.heading ? (
            <SimpleExpander title={<div className={styles.metadataPackageTitle}>{this.props.heading}</div>} key={this.props.data.PackageId} errorKey={this.props.data.PackageId}>
                {this.buildTable()}
            </SimpleExpander>
        ) : (
            this.buildTable()
        );
    }
}
