import * as React from "react";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import OnboardingPage from "../../../../components/GettingStarted/OnboardingPage";

const Onboarding = () => {
    return (
        <OnboardingPage
            title="Add a project to automate your software's deployment"
            intro="A project is the collection of deployment steps, configuration variables, packages and assets that Octopus brings together and executes during the process of deploying your software."
            learnMore={
                <span>
                    <ExternalLink href="OnboardingProjectsLearnMore">Learn more</ExternalLink>.
                </span>
            }
        />
    );
};

export default Onboarding;
