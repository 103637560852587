import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonProjectTriggerOverview, CommonProjectTriggerTopics } from "../../CommonComponents/CommonProjectTriggerViews";

export const ProjectDeploymentTargetTriggerRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().deploymentTargetTrigger,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectTriggerOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectTriggerTopics />;
    },
};
