import * as React from "react";

interface MeasureProps<TMeasurement> {
    onMeasured(measurement: TMeasurement): void;
}

export default function Measure<TMeasurement>(getMeasurement: (measurementElement: HTMLElement) => TMeasurement) {
    return class MeasureInternal extends React.Component<MeasureProps<TMeasurement>> {
        private measurementCell: HTMLElement | null = null;

        componentDidMount() {
            if (this.measurementCell) {
                this.props.onMeasured(getMeasurement(this.measurementCell));
            }
        }

        render() {
            return <div ref={measurementCell => (this.measurementCell = measurementCell)}>{this.props.children}</div>;
        }
    };
}

export const MeasureWidth = Measure(e => e.getBoundingClientRect().width);
