import BasicRepository from "./basicRepository";
import Client from "../client";
import { NewSpaceResource, SpaceResource } from "../resources";
import * as logoUpload from "./logoUpload";

class SpaceRepository extends BasicRepository<SpaceResource, NewSpaceResource> {
    constructor(client: Client) {
        super("Spaces", client);
    }
}

export default SpaceRepository;
