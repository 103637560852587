import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonConfigurationUserOverview, CommonConfigurationUserTopics } from "../../CommonComponents/CommonConfigurationUserViews";

export const ConfigurationUsersNewRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.configuration.users.create,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationUserOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationUserTopics />;
    },
};
