import * as React from "react";
import { ProjectContextActions, ProjectContextState, useProjectContext, ProjectContextConsumer } from "./ProjectContext";

export type WithProjectContextInjectedProps = {
    projectContext: {
        state: ProjectContextState;
        actions: ProjectContextActions;
    };
};

export const withProjectContext = <T extends unknown>(Component: React.ComponentType<T & WithProjectContextInjectedProps>) => {
    const WithProjectContext: React.FC<T> = props => {
        const context = useProjectContext();
        return <Component projectContext={context} {...props} />;
    };
    return WithProjectContext;
};
