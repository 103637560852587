import * as React from "react";
import ActionTemplateSearchResource from "client/resources/actionTemplateSearchResource";
import Logo from "components/Logo/Logo";
import classnames = require("classnames");
import { ActionHandlerCategory } from "client/resources";
import { NavigationCancel } from "material-ui/svg-icons";
import SvgIcon from "material-ui/SvgIcon";
import ActionTemplateCategoryIconHelper from "./ActionTemplateCategoryIconHelper";
import { primary } from "theme/colors";

const styles = require("./ActionTemplateCategory.less");

interface ActionTemplateCategoryProps {
    category: ActionHandlerCategory;
    name: string;
    templates: ActionTemplateSearchResource[];
    active: boolean;
    isBlurred?: boolean;
    isSelectable?: boolean;
    onCategorySelected: () => void;
}

const ActionTemplateCategory: React.StatelessComponent<ActionTemplateCategoryProps> = (props: ActionTemplateCategoryProps) => {
    let iconUrl: string = null;
    let iconSvgPath: JSX.Element = null;
    try {
        if (props.category === ActionHandlerCategory.CommunitySubCategory) {
            iconUrl = props.templates && props.templates.length > 0 && props.templates[0].Links.Logo;
        } else {
            // We have custom SVG icons for our primary categories (that switch fill based on active state).
            iconSvgPath = ActionTemplateCategoryIconHelper.getCategorySvgElement(props.category, props.active);
        }
    } catch (err) {
        console.log(`Failed to find icon for ${props.category}. Falling back to first template's logo.`);
        iconUrl = props.templates && props.templates.length > 0 && props.templates[0].Links.Logo;
        iconSvgPath = null;
    }

    return (
        <li
            className={classnames(styles.actionCategory, props.active ? styles.active : styles.inactive, props.isSelectable ? styles.isFiltering : styles.isSelectable, props.isBlurred ? styles.blurred : null)}
            onClick={props.isSelectable ? null : props.onCategorySelected}
        >
            {iconUrl && <Logo url={iconUrl} size="3.125rem" />}
            {iconSvgPath && (
                <SvgIcon color={props.active ? "#FFFFFF" : "#0F2535"} viewBox="0 0 70 70" style={{ width: "3.125rem", height: "3.125rem" }}>
                    {iconSvgPath}
                </SvgIcon>
            )}
            <div className={styles.categoryName}>
                <small>{props.name}</small>
            </div>
            {props.active && (
                <div className={styles.activeIcon}>
                    <NavigationCancel color={primary} />
                </div>
            )}
        </li>
    );
};

ActionTemplateCategory.displayName = "ActionTemplateCategory";

export default ActionTemplateCategory;
