import * as React from "react";
import { func } from "prop-types";

type LabelStrategy = (fieldName: string) => string;

function generateLabel(fieldName: string, labelStrategy: LabelStrategy | undefined, fallbackLabelStrategy: LabelStrategy): string {
    if (labelStrategy) {
        return labelStrategy(fieldName);
    }
    return fallbackLabelStrategy(fieldName);
}

interface SetLabelStrategyProps {
    labelStrategy: LabelStrategy;
}

interface LabelStrategyContext {
    labelStrategy: LabelStrategy | undefined;
}

const LabelStrategyContextTypes = {
    labelStrategy: func,
};

/* This component is used to set a "labeling" strategy on the react context.
 * This strategy is used to determine how labels for input components are generated
 * This allows us to do things like say that all inputs within the filter panel
 * should have labels of the form "By [fieldName]", eg "By environments".
 * For components to use the label strategy to generate their label, they should use the `UseLabelStrategy` HOC*/
export class SetLabelStrategy extends React.Component<SetLabelStrategyProps> {
    static childContextTypes = LabelStrategyContextTypes;

    private readonly labelStrategy: LabelStrategy;
    constructor(props: SetLabelStrategyProps) {
        super(props);

        // Capture the label strategy so that it can't change (not a good idea to change things on context)
        this.labelStrategy = props.labelStrategy;
    }

    getChildContext() {
        const context: LabelStrategyContext = {
            labelStrategy: this.labelStrategy,
        };
        return context;
    }

    render() {
        return <div>{this.props.children}</div>;
    }
}

/* This clears any label strategy that might be on the context (see `SetLabelStrategy`),
 * so that any descendant components won't use that label strategy
 * This is useful for things like dialogs, which are usually placed at some arbitrary point
 * in the component hierarchy because they have to go somewhere, and therefore don't necessarily
 * want to inherit the provided label strategy.*/
export class ResetLabelStrategy extends React.Component {
    static childContextTypes = LabelStrategyContextTypes;

    getChildContext() {
        const context: LabelStrategyContext = {
            labelStrategy: undefined,
        };
        return context;
    }

    render() {
        return <div>{this.props.children}</div>;
    }
}

/* This HOC wraps a component and makes it use the label strategy on the react context in order to generate its label
 * It is mainly used by input components, like `Text` and `Select`
 * In order to provide a label strategy on the context, use `SetLabelStrategy`*/
export default function UseLabelStrategy<Props extends { label?: string | JSX.Element }>(Comp: React.ComponentClass<Props>, fallbackLabelStrategy: LabelStrategy) {
    type UseLabelStrategyInternalProps = Props & { fieldName?: string };
    return class UseLabelStrategyInternal extends React.Component<UseLabelStrategyInternalProps> {
        static contextTypes = LabelStrategyContextTypes;

        private readonly labelStrategy: LabelStrategy | undefined;

        constructor(props: UseLabelStrategyInternalProps, context: LabelStrategyContext) {
            super(props);
            this.labelStrategy = context.labelStrategy;
        }

        render() {
            const { fieldName, label, ...other } = this.props as any;

            const derivedLabel = label || (fieldName ? generateLabel(fieldName, this.labelStrategy, fallbackLabelStrategy) : undefined);

            return <Comp {...other} label={derivedLabel} />;
        }
    };
}
