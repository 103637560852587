import { createAction, getType, ActionType } from "typesafe-actions";
import { ThemePaletteType } from "theme";

export type ThemeAction = ActionType<typeof themeActions>;

export const themeActions = {
    changeTheme: createAction("THEME_CHANGE", resolve => (name: ThemePaletteType) => resolve({ name })),
};

export default themeActions;
