import ConfirmationDialog from "./ConfirmationDialog";
import { ActionButtonType } from "components/Button";
import * as React from "react";

interface PromiseConfirmationDialog {
    title: string;
    continueButtonLabel?: string;
    continueButtonBusyLabel?: string;
    continueButtonType?: ActionButtonType;
    deferred: Deferred<boolean>;
}

const PromiseConfirmationDialog: React.SFC<PromiseConfirmationDialog> = props => {
    const { deferred, children, ...otherProps } = props;

    return (
        <ConfirmationDialog open={deferred && !deferred.resolved} onClose={() => deferred.resolve(false)} onContinueClick={() => deferred.resolve(true)} {...otherProps}>
            {props.children}
        </ConfirmationDialog>
    );
};

export class Deferred<T> {
    resolve: (value?: T | PromiseLike<T>) => void;
    reject: (reason?: any) => void;
    resolved: boolean = false;
    promise: Promise<T>;

    constructor() {
        this.promise = new Promise<T>((resolve, reject) => {
            this.resolve = (value?) => {
                if (this.resolved) {
                    return;
                }
                this.resolved = true;
                resolve(value);
            };
            this.reject = (reason?) => {
                if (this.resolved) {
                    return;
                }
                this.resolved = true;
                reject(reason);
            };
        });
    }
}

export default PromiseConfirmationDialog;
