import * as React from "react";
import { EmptyPage } from "../EmptyPage/EmptyPage";

interface OnboardingPageProps {
    title: string;
    intro?: React.ReactNode | string;
    panels?: Array<{
        description: React.ReactNode;
        imageSrc: string;
        imageWidth: number;
        imageHeight: number; // Needed to ensure image dimensions are set on page load (to stop the content jumping around as images load).
        imageAlt: string;
    }>;
    learnMore: React.ReactNode;
}

const image = {
    src: require("./onboardingImage.svg"),
    alt: "It's deserted here",
};

class OnboardingPage extends React.Component<OnboardingPageProps, never> {
    render() {
        return <EmptyPage title={this.props.title} description={this.props.intro} image={image} panels={this.props.panels} learnMore={this.props.learnMore} />;
    }
}

export default OnboardingPage;
