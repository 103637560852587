import { repository } from "clientInstance";
import { TagResource, TagSetResource } from "client/resources";

const tagSeperator = "/";

const getAll = (): Promise<TagSetResource[]> => {
    return repository.TagSets.all();
};

interface TagSetGroup {
    name: string;
    tags: string[];
}

interface TagIndex {
    [canonicalTagName: string]: TagResource;
}

const getTagIndex = async (): Promise<TagIndex> => {
    const tagSets = await getAll();
    return getTagIndexForTagSets(tagSets);
};

const getTagIndexForTagSets = (tagSets: TagSetResource[]): TagIndex => {
    const idx: { [canonicalTagName: string]: TagResource } = {};
    tagSets.forEach(t => t.Tags.forEach(tag => (idx[tag.CanonicalTagName] = tag)));
    return idx;
};

const groupByTagSet = (canonicalTagNames: string[]): TagSetGroup[] => {
    const unorderedTagSets: TagSetGroup[] = [];
    canonicalTagNames.forEach((canonicalTagName: string) => {
        const [name] = canonicalTagName.split(tagSeperator);
        const tagSet = unorderedTagSets.find(x => x.name === name);
        if (tagSet) {
            tagSet.tags.push(canonicalTagName);
        } else {
            unorderedTagSets.push({ name, tags: [canonicalTagName] });
        }
    });
    return unorderedTagSets;
};

const groupAndOrderByTagSet = (canonicalTagNames: string[], tagSets: TagSetResource[]): TagSetGroup[] => {
    const unorderedTagSets = groupByTagSet(canonicalTagNames);
    return unorderedTagSets.sort((a, b) => tagSets.find(x => x.Name === a.name).SortOrder - tagSets.find(x => x.Name === b.name).SortOrder);
};

const groupAndOrderByTagSetAndTag = (canonicalTagNames: string[], tagSets: TagSetResource[]): TagSetGroup[] => {
    const orderedTagSets = groupAndOrderByTagSet(canonicalTagNames, tagSets);
    orderedTagSets.forEach((tagSet, i) => {
        orderedTagSets[i].tags = tagSet.tags.sort((a, b) => {
            const tags = tagSets.find(x => x.Name === tagSet.name).Tags;
            return tags.find(x => x.CanonicalTagName === a).SortOrder - tags.find(x => x.CanonicalTagName === b).SortOrder;
        });
    });
    return orderedTagSets;
};

const flattenGroup = (groups: TagSetGroup[]): string[] => groups.reduce((a, group) => a.concat(group.tags), []);

export { getAll, getTagIndex, getTagIndexForTagSets, groupByTagSet, groupAndOrderByTagSet, groupAndOrderByTagSetAndTag, flattenGroup, TagIndex };
