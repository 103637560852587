import * as React from "react";
import SimpleDataTable from "components/SimpleDataTable";
import { ProjectUsage, ReleaseUsageEntry } from "client/resources/libraryVariableSetUsageResource";
import InternalLink from "components/Navigation/InternalLink";
import routeLinks from "routeLinks";
import Chip from "components/Chips/Chip";
import { pluraliseSummary, pluraliseHelp } from "./VariableSetUsage";

export const VariableSetReleaseUsages: React.FC<{ usage: ProjectUsage[] }> = ({ usage }) => {
    return (
        <SimpleDataTable<ProjectUsage>
            data={usage}
            headerColumns={["Project Name", "Release Version"]}
            onRow={usageEntry => [
                usageEntry.ProjectName,
                usageEntry.Releases.map((release: ReleaseUsageEntry) => (
                    <InternalLink to={routeLinks.release(release.ReleaseId)} key={`${release.ReleaseId}-releaseSnapshotLink`}>
                        <Chip>{release.ReleaseVersion}</Chip>
                    </InternalLink>
                )),
            ]}
        />
    );
};

export function getReleaseUsageSummary(type: string, count: number, countOfReleasesUserCannotSee: number) {
    return pluraliseSummary(count, `This ${type}`, "has been", "has not been", "release snapshot", countOfReleasesUserCannotSee);
}

export function getReleaseUsageHelp(type: string, count: number, countOfReleasesUserCannotSee: number) {
    return pluraliseHelp(count, `This ${type}`, "has been", "has not been", "release", countOfReleasesUserCannotSee);
}

export function scriptModuleReleaseUsageSummary(usagesInReleaseSnapshots: ProjectUsage[], countOfReleasesUserCannotSee: number) {
    const usageCount = usagesInReleaseSnapshots.reduce((prevValue, currValue) => prevValue + currValue.Releases.length, 0);
    return getReleaseUsageSummary("script module", usageCount, countOfReleasesUserCannotSee);
}

export function variableSetReleaseUsageSummary(usagesInReleaseSnapshots: ProjectUsage[], countOfReleasesUserCannotSee: number) {
    const usageCount = usagesInReleaseSnapshots.reduce((prevValue, currValue) => prevValue + currValue.Releases.length, 0);
    return getReleaseUsageSummary("variable set", usageCount, countOfReleasesUserCannotSee);
}

export function scriptModuleReleaseUsageHelp(usagesInReleaseSnapshots: ProjectUsage[], countOfReleasesUserCannotSee: number) {
    const usageCount = usagesInReleaseSnapshots.reduce((prevValue, currValue) => prevValue + currValue.Releases.length, 0);
    return getReleaseUsageHelp("script module", usageCount, countOfReleasesUserCannotSee);
}

export function variableSetReleaseUsageHelp(usagesInReleaseSnapshots: ProjectUsage[], countOfReleasesUserCannotSee: number) {
    const usageCount = usagesInReleaseSnapshots.reduce((prevValue, currValue) => prevValue + currValue.Releases.length, 0);
    return getReleaseUsageHelp("variable set", usageCount, countOfReleasesUserCannotSee);
}
