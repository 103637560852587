import * as React from "react";
import { OpenDialogConnect, OpenDialogProps } from "components/Dialog/OpenDialogConnect";
import Dialog from "components/Dialog/Dialog";
import dialogIdGenerator from "components/Dialog/dialogIdGenerator";

const styles = require("./style.less");
import IconButton, { Icon } from "components/IconButton/IconButton";
import { ToolTipContent } from "components/ToolTip/ToolTip";

interface OpenDialogIconButtonProps {
    toolTipContent: ToolTipContent;
    icon: Icon | string;
    wideDialog?: boolean;
    disabled?: boolean;
    customIcon?: React.ReactElement<any, any>;
}

class OpenDialogIconButtonInternal extends React.Component<OpenDialogIconButtonProps & OpenDialogProps, never> {
    private uniqueId: string;

    constructor(props: OpenDialogIconButtonProps & OpenDialogProps) {
        super(props);

        this.uniqueId = dialogIdGenerator.next();
    }

    render() {
        const isOpen = this.props.openDialogs[this.uniqueId];

        return (
            <div className={styles.buttonDialogContainer}>
                <IconButton icon={this.props.icon} disabled={this.props.disabled} toolTipContent={this.props.toolTipContent} onClick={() => this.props.openDialog(this.uniqueId)} />
                <Dialog open={isOpen} wide={this.props.wideDialog}>
                    {this.props.children}
                </Dialog>
            </div>
        );
    }
}

const OpenDialogIconButton = OpenDialogConnect.to(OpenDialogIconButtonInternal);

export default OpenDialogIconButton;
