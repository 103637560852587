import * as React from "react";
import { SFC } from "react";
import { FeatureItem } from "./ConfigureFeaturesContent";
import SimpleDataTable from "components/SimpleDataTable/SimpleDataTable";
import MarkdownDescription from "components/MarkdownDescription";
import Checkbox from "components/form/Checkbox/Checkbox";
const styles = require("./style.less");

interface FeatureListProps {
    features: FeatureItem[];
    onIsEnabledChanged(value: boolean, featureName: string): void;
}

class FeatureTable extends SimpleDataTable<FeatureItem> {}

const FeatureList: SFC<FeatureListProps> = props => {
    return (
        <FeatureTable
            data={props.features}
            headerColumns={["Feature", "Description"]}
            headerColumnClassNames={[styles.headerColumn, styles.headerColumn]}
            rowColumnClassName={styles.rowColumn}
            onRow={res => buildResultRow(res, props.onIsEnabledChanged)}
        />
    );
};

const buildResultRow = (item: FeatureItem, onIsEnabledChanged: (value: boolean, featureName: string) => void) => {
    return [<Checkbox value={item.isEnabled} onChange={val => onIsEnabledChanged(val, item.featureName)} label={item.title} />, <MarkdownDescription markup={item.description} />];
};

FeatureList.displayName = "FeatureList";

export default FeatureList;
