import routeLinks from "../../../../routeLinks";
import { LocationDescriptorObject } from "history";

export function calculateOverviewRedirect(projectSlug: string, releaseId?: string): LocationDescriptorObject {
    const pathname = routeLinks.project(projectSlug).overview;
    return {
        pathname,
        search: releaseId ? `?release=${releaseId}` : undefined,
    };
}
