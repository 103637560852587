import { VariableType, ReferenceType } from "../../client/resources/variableResource";

export function isReferenceType(type: VariableType): type is ReferenceType {
    switch (type) {
        case VariableType.String:
            return false;
        case VariableType.Sensitive:
            return false;
        case VariableType.Certificate:
            return true;
        case VariableType.AmazonWebServicesAccount:
            return true;
        case VariableType.AzureAccount:
            return true;
    }

    return assertUnreachable(type);
}

function assertUnreachable(x: never): never {
    throw new Error("Didn't expect to get here");
}
