import * as React from "react";
import { range } from "lodash";
const styles = require("./style.less");
import { List as VirtualList, AutoSizer, WindowScroller } from "react-virtualized";
import Sticky, { StickyStatus } from "components/Sticky/Sticky";
import { headerId } from "components/PaperLayout/PaperLayout";
import Paper from "material-ui/Paper";
import TouchBackend from "react-dnd-touch-backend";
import { DragDropContext } from "react-dnd";
import ResizeColumnHandleDragLayer from "components/ScrollTable/ResizeColumnHandleDragLayer/ResizeColumnHandleDragLayer";
import AlignedScrollTableRow from "components/ScrollTable/AlignedScrollTableRow/AlignedScrollTableRow";
import { divider } from "theme/colors";
import { BorderCss } from "utils/BorderCss/BorderCss";

export interface CellAlignmentArgs {
    customColumnWidthsInPercent?: number[];
    showResizeHandles?: boolean;
}

export type CellAligner = (cells: JSX.Element[], optionalArgs?: CellAlignmentArgs) => JSX.Element;

export interface RenderArgs {
    columnWidthsInPercent: ReadonlyArray<number>;
    borderStyle: BorderCss;
    cellAligner: CellAligner;
}

export interface RowRenderArgs extends RenderArgs {
    index: number;
    isVisible: boolean;
}

interface ScrollTableProps {
    relativeColumnWidths: ReadonlyArray<number>;
    minimumColumnWidthsInPx: ReadonlyArray<number>;
    rowCount: number;
    overscanRowCount: number;
    shouldVirtualize: boolean;
    onColumnWidthsChanged(relativeColumnWidths: ReadonlyArray<number>): void;
    rowHeight(index: number): number;
    headers(renderArgs: RenderArgs): React.ReactNode[];
    rowRenderer(rowRenderArgs: RowRenderArgs): React.ReactNode;
}

interface ScrollTableState {
    headerStickyState: StickyStatus;
}

let scrollTableCount = 0;
const borderStyle = new BorderCss(0.0625, "solid", divider);

class ScrollTableInternal extends React.Component<ScrollTableProps, ScrollTableState> {
    private readonly scrollTableId: number;
    private readonly headerRenderArgs: RenderArgs;
    private rowCellAligner: CellAligner;
    private readonly rowRenderer: (args: any) => JSX.Element;
    private windowScroller: WindowScroller | null = null;
    private timeoutId?: number;
    private virtualList: VirtualList | null = null;

    constructor(props: ScrollTableProps) {
        super(props);
        this.state = {
            headerStickyState: StickyStatus.STATUS_ORIGINAL,
        };
        this.scrollTableId = scrollTableCount++;
        this.headerRenderArgs = new HeaderRenderArgsImpl(this);
        this.setRowCellAligner();
        this.rowRenderer = args => this.renderRow(args);
    }

    get relativeColumnWidthsInPercent() {
        return convertRelativeSizesToPercentages(this.props.relativeColumnWidths);
    }

    componentDidMount() {
        this.refreshWindowPosition();
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            window.clearTimeout(this.timeoutId);
        }
    }

    render() {
        return (
            <div id={`scrollTable-${this.scrollTableId}`} className={styles.table}>
                <ResizeColumnHandleDragLayer />
                <div className={styles.headerContainer}>
                    {/*The use of `headerId` here assumes that the scroll tables sticky anchor is always the paper layouts sticky header*/}
                    <Sticky top={`#${headerId}`} innerZ={9} bottomBoundary={`#scrollTable-${this.scrollTableId}`} onStateChange={state => this.setState({ headerStickyState: state.status })}>
                        <Paper style={this.state.headerStickyState === StickyStatus.STATUS_FIXED ? {} : { boxShadow: "none" }}>
                            {this.props.headers(this.headerRenderArgs).map((h, index) => {
                                return <div key={index}>{h}</div>;
                            })}
                        </Paper>
                    </Sticky>
                </div>
                <div className={styles.tableBody}>
                    {this.props.shouldVirtualize && (
                        <WindowScroller ref={windowScroller => (this.windowScroller = windowScroller)}>
                            {({ height, isScrolling, onChildScroll, scrollTop }) => (
                                <AutoSizer disableHeight={true}>
                                    {({ width }) => (
                                        <VirtualList
                                            autoHeight={true}
                                            tabIndex={-1}
                                            height={height}
                                            scrollTop={scrollTop}
                                            onScroll={onChildScroll}
                                            isScrolling={isScrolling}
                                            rowCount={this.props.rowCount}
                                            rowHeight={({ index }: { index: number }) => this.props.rowHeight(index)}
                                            width={width}
                                            className={styles.virtualList}
                                            overscanRowCount={this.props.overscanRowCount}
                                            rowRenderer={this.rowRenderer}
                                            ref={virtualList => (this.virtualList = virtualList)}
                                        />
                                    )}
                                </AutoSizer>
                            )}
                        </WindowScroller>
                    )}
                    {!this.props.shouldVirtualize &&
                        range(0, this.props.rowCount).map(ind => {
                            return this.rowRenderer({
                                key: ind,
                                index: ind,
                                isVisible: true,
                                style: {},
                            });
                        })}
                </div>
            </div>
        );
    }

    onColumnWidthsChanged = (newColumnWidths: ReadonlyArray<number>) => {
        // Run it through convertRelativeSizesToPercentages again to ensure that everything is rounded appropriately
        // and adds up to exactly 100%
        this.props.onColumnWidthsChanged(convertRelativeSizesToPercentages(newColumnWidths));
        // For performance reasons, we use `shouldComponentUpdate` in the variable editor.
        // The cell aligner is one of the properties that we watch to determine whether to re-render a variable row
        // By changing the cell aligner function instance, we can trigger the rows to re-render.
        this.setRowCellAligner();
        if (this.virtualList) {
            // Need to tell the virtual list that something has changed and it must re-render
            this.virtualList.forceUpdateGrid();
        }
    };

    private renderRow({ key, index, isVisible, style }: any) {
        const cells = this.props.rowRenderer({
            columnWidthsInPercent: this.relativeColumnWidthsInPercent,
            index,
            isVisible,
            cellAligner: this.rowCellAligner,
            borderStyle,
        });

        return (
            <div style={style} key={key}>
                {cells}
            </div>
        );
    }

    private setRowCellAligner() {
        this.rowCellAligner = (cells: JSX.Element[], optionalArgs?: CellAlignmentArgs) => cellAlignerInner(cells, optionalArgs, this);
    }

    private refreshWindowPosition() {
        this.timeoutId = window.setTimeout(() => {
            if (this.windowScroller) {
                // https://github.com/bvaughn/react-virtualized/blob/master/docs/WindowScroller.md#updateposition
                // This needs to be called if anything above the table in the dom moves, so that the table
                // can re-evaluate its position w.r.t. the window. It sucks to have to be so coupled to whatever
                // is displayed above the table, so instead lets just re-check the position every 500ms
                // so we don't have to worry about it
                this.windowScroller.updatePosition();
            }
            this.refreshWindowPosition();
        }, 500);
    }
}

class HeaderRenderArgsImpl implements RenderArgs {
    constructor(private readonly scrollTable: ScrollTableInternal) {}

    get columnWidthsInPercent() {
        return this.scrollTable.relativeColumnWidthsInPercent;
    }

    get borderStyle() {
        return borderStyle;
    }

    cellAligner: CellAligner = (cells, optionalArgs) => cellAlignerInner(cells, optionalArgs, this.scrollTable);
}

function cellAlignerInner(cells: JSX.Element[], optionalArgs: CellAlignmentArgs | undefined, scrollTable: ScrollTableInternal): JSX.Element {
    const emptyCellAlignmentArgs: CellAlignmentArgs = {};
    const { customColumnWidthsInPercent, showResizeHandles } = optionalArgs || emptyCellAlignmentArgs;
    return (
        <AlignedScrollTableRow
            cells={cells}
            showResizeHandles={showResizeHandles}
            relativeColumnWidthsInPercent={customColumnWidthsInPercent || scrollTable.relativeColumnWidthsInPercent}
            onColumnWidthsChanged={scrollTable.onColumnWidthsChanged}
            minimumColumnWidthsInPx={scrollTable.props.minimumColumnWidthsInPx}
        />
    );
}

function convertRelativeSizesToPercentages(relativeColumnSizes: ReadonlyArray<number>) {
    const totalSize = sum(relativeColumnSizes);
    const allColumnsExceptLast = relativeColumnSizes.slice(0, relativeColumnSizes.length - 1);
    const columnSizePercentageExceptLast = allColumnsExceptLast.map(relativeColumnSize => {
        return (relativeColumnSize / totalSize) * 100;
    });
    const lastColumnSize = 100 - sum(columnSizePercentageExceptLast);
    return [...columnSizePercentageExceptLast, lastColumnSize];

    function sum(numbers: ReadonlyArray<number>) {
        return numbers.reduce((p, c) => p + c, 0);
    }
}

const ScrollTable = DragDropContext(
    TouchBackend({
        enableMouseEvents: true,
    })
)(ScrollTableInternal);
export default ScrollTable;
