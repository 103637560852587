import * as React from "react";
import { buildServerPackagingInstruction } from "../../Instructions";
import { CommonBuildServerPackagingTextForOctopackJs, CommonBuildServerPackagingTextForIntegration, CommonBuildServerPackagingTextForOctoExe } from "../CommonRegistrationText";

export const BuildServerPackaging: React.SFC<{}> = () => (
    <div>
        <p>
            When packaging Node.js applications from build servers, we recommend using <i>Octopack.js</i>. Alternatively you can use <code>Octo.exe pack</code> on the output directory..
        </p>
        {CommonBuildServerPackagingTextForOctopackJs}
        {CommonBuildServerPackagingTextForOctoExe}
        {CommonBuildServerPackagingTextForIntegration}
    </div>
);

export default buildServerPackagingInstruction(() => <BuildServerPackaging />);
