import { pageId } from "utils/pageId";

export default () => {
    return {
        me: pageId("UserProfile", "User Profile"),
        logins: pageId("UserProfile.Logins", "User Profile Logins"),
        details: pageId("UserProfile.Details", "User Profile Details"),
        apiKeys: pageId("UserProfile.ApiKeys", "User Profile API Keys"),
        activity: pageId("UserProfile.Activity", "User Profile Activity"),
        register: pageId("UserRegister", "Register"),
        signIn: pageId("UserSignIn", "Sign In"),
        signOut: pageId("UserSignOut", "Sign Out"),
    };
};
