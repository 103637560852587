import StandardLayoutInternal from "./StandardLayoutInternal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { clearUnhandledErrors, currentUnhandledErrorSelector } from "components/UnhandledError/reducers";

interface GlobalDispatchProps {
    onErrorClose(): void;
}

const StandardLayout = connect(
    (state: GlobalState) => {
        return {
            unhandledError: currentUnhandledErrorSelector(state),
        };
    },
    (dispatch): GlobalDispatchProps => {
        return {
            onErrorClose: bindActionCreators(clearUnhandledErrors, dispatch),
        };
    }
)(StandardLayoutInternal);

export default StandardLayout;
