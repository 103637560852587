export default (loggedInRoot: string, authenticationRoot: string) => {
    const rootForRoutesWhenLoggedIn = `${loggedInRoot}/users`;

    // The loggedInRoot contains the spaceId, which we want to ignore for things like signing in and out
    const rootForAuthenticationRoutes = `${authenticationRoot}/users`;

    return {
        me: `${rootForRoutesWhenLoggedIn}/me`,
        logins: `${`${rootForRoutesWhenLoggedIn}/me`}/logins`,
        details: `${`${rootForRoutesWhenLoggedIn}/me`}/details`,
        apiKeys: `${`${rootForRoutesWhenLoggedIn}/me`}/apiKeys`,
        activity: `${`${rootForRoutesWhenLoggedIn}/me`}/activity`,
        register: (inviteCode: string) => `${rootForAuthenticationRoutes}/register/${inviteCode}`,
        signIn: `${rootForAuthenticationRoutes}/sign-in`,
        signOut: `${rootForAuthenticationRoutes}/sign-out`,
    };
};
