import * as React from "react";
import { EventAgentResource } from "client/resources";
import { MultiSelect } from "components/MultiSelect";
import { FormFieldProps } from "components/form";
import { EventAgentChip } from "components/Chips/index";
import LookupResourceChipComponent from "components/LookupResourceChip";
import { ChipIcon } from "components/Chips";

interface EventAgentMultiSelectProps extends FormFieldProps<string[]> {
    items: EventAgentResource[];
    label?: string | JSX.Element;
    error?: string;
}

const EventAgentTypedMultiSelect = MultiSelect<EventAgentResource>();
const LookupEventAgentChip = LookupResourceChipComponent<EventAgentResource>();

const EventAgentMultiSelect: React.StatelessComponent<EventAgentMultiSelectProps> = props => {
    const chipRenderer = (r: EventAgentResource, onRequestDelete: (event: object) => void) => {
        return <LookupEventAgentChip lookupCollection={props.items} lookupId={r.Id} type={ChipIcon.EventAgent} chipRender={item => <EventAgentChip onRequestDelete={onRequestDelete} eventAgent={item} />} />;
    };

    return <EventAgentTypedMultiSelect items={props.items} fieldName="event agents" renderChip={chipRenderer} {...props} />;
};

export default EventAgentMultiSelect;
