import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { LocationDescriptor } from "history";
import { resolvePathWithSpaceId } from "components/Navigation/resolvePathWithSpaceId";
import { SpaceRouteParams } from "./SpacesMenu";
import InternalLink from "components/Navigation/InternalLink";

interface LinkProps extends RouteComponentProps<SpaceRouteParams> {
    to: LocationDescriptor;
}

class SwitcherLink extends React.Component<LinkProps> {
    render() {
        const { staticContext, to, ...rest } = this.props;
        // tslint:disable-next-line:jsx-ban-elements
        return (
            <InternalLink {...rest} to={resolvePathWithSpaceId(to, this.props.match.params.spaceId)}>
                {this.props.children}
            </InternalLink>
        );
    }
}

export default withRouter(SwitcherLink);
