import {
    AccountResource,
    AccountType,
    CertificateResource,
    KubernetesAws,
    KubernetesAwsAuthentication,
    KubernetesAzure,
    KubernetesAzureAuthentication,
    KubernetesCertificate,
    KubernetesCertificateAuthentication,
    KubernetesStandard,
    KubernetesStandardAccountAuthentication,
    ProxyResource,
    WorkerPoolResource,
} from "../../../../../client/resources";
import * as React from "react";
import { DoBusyTask } from "../../../../../components/DataBaseComponent/DataBaseComponent";
import { KubernetesEndpointResource } from "../../../../../client/resources/endpointResource";
import CommonSummaryHelper from "../../../../../utils/CommonSummaryHelper";
import AccountSelect from "../../../../../components/form/AccountSelect/AccountSelect";
import ExpandableFormSection from "../../../../../components/form/Sections/ExpandableFormSection";
import Note from "../../../../../components/form/Note/Note";
import StringCheckbox from "../../../../../components/form/Checkbox/StringCheckbox";
import { Text } from "components/form";
import RadioButton from "components/form/RadioButton/RadioButton";
import Summary from "components/form/Sections/Summary";
import Select from "components/form/Select/Select";
import CertificateSelect from "components/form/CertificateSelect/CertificateSelect";
import RadioButtonGroup from "components/form/RadioButton/RadioButtonGroup";
import { repository } from "clientInstance";
import { CategorizedEndpointRegistration, CommunicationStyle, EndpointRegistrationKey } from "./endpointRegistry";
import { EndpointThumbnail } from "./EndpointThumbnail";
import EndpointCard from "./EndpointCard";
import ExternalLink from "components/Navigation/ExternalLink";

interface KubernetesEndpointProps {
    proxies: ProxyResource[];
    doBusyTask: DoBusyTask;
    busy: Promise<any> | boolean;
    endpoint: KubernetesEndpointResource;
    accounts: AccountResource[];
    workerPools: WorkerPoolResource[];
    refreshAccounts: () => Promise<any>;
    onChange(newValue: KubernetesEndpointResource): void;
    getFieldError(field: string): string;
}

interface KubernetesEndpointState {
    certificate: CertificateResource;
    account: AccountResource;
}

export default class KubernetesEndpoint extends React.Component<KubernetesEndpointProps, KubernetesEndpointState> {
    constructor(props: KubernetesEndpointProps) {
        super(props);
        this.state = {
            certificate: null,
            account: null,
        };
    }

    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            await this.getCertificateResource();
            await this.getAccountResource();
        });
    }

    render() {
        return (
            <div>
                <ExpandableFormSection errorKey="Account" title="Authentication" focusOnExpandAll summary={this.accountSummary()} help="Select the account or certificate that identifies the Kubernetes user.">
                    <Note>You can choose to authenticate with the Kubernetes cluster with a username and password, AWS account, Azure account, token or client certificate.</Note>
                    <RadioButtonGroup
                        value={this.getAccountType()}
                        onChange={(x: string) => {
                            const endpoint = this.props.endpoint;
                            // If switching types, clear any previous selection
                            if (endpoint.AccountType !== x) {
                                endpoint.Authentication = null;
                            }
                            endpoint.AccountType = x;
                            this.props.onChange(endpoint);
                        }}
                    >
                        <RadioButton value={AccountType.UsernamePassword} label="Username and password" />
                        <RadioButton value={AccountType.Token} label="Token" />
                        <RadioButton value={AccountType.AzureServicePrincipal} label="Azure Service Principal" />
                        <RadioButton value={AccountType.AmazonWebServicesAccount} label="AWS Account" />
                        <RadioButton value={KubernetesCertificate} label="Client Certificate" />
                    </RadioButtonGroup>
                    {this.getAccountType() === AccountType.UsernamePassword && (
                        <AccountSelect
                            onRequestRefresh={this.props.refreshAccounts}
                            value={this.getStandardAuth().AccountId}
                            type={[AccountType.UsernamePassword]}
                            allowClear={true}
                            onChange={async x => {
                                const endpoint = this.props.endpoint;
                                this.getStandardAuth().AccountId = x;
                                endpoint.AccountType = AccountType.UsernamePassword;
                                this.props.onChange(endpoint);
                                await this.getAccountResource();
                            }}
                            items={this.props.accounts}
                        />
                    )}
                    {this.getAccountType() === AccountType.Token && (
                        <AccountSelect
                            onRequestRefresh={this.props.refreshAccounts}
                            value={this.getStandardAuth().AccountId}
                            type={[AccountType.Token]}
                            allowClear={true}
                            onChange={async x => {
                                const endpoint = this.props.endpoint;
                                this.getStandardAuth().AccountId = x;
                                endpoint.AccountType = AccountType.Token;
                                this.props.onChange(endpoint);
                                await this.getAccountResource();
                            }}
                            items={this.props.accounts}
                        />
                    )}
                    {this.getAccountType() === AccountType.AzureServicePrincipal && (
                        <div>
                            <AccountSelect
                                onRequestRefresh={this.props.refreshAccounts}
                                value={this.getAzureAuth().AccountId}
                                type={[AccountType.AzureServicePrincipal]}
                                allowClear={true}
                                onChange={async x => {
                                    const endpoint = this.props.endpoint;
                                    this.getAzureAuth().AccountId = x;
                                    endpoint.AccountType = AccountType.AzureServicePrincipal;
                                    this.props.onChange(endpoint);
                                    await this.getAccountResource();
                                }}
                                items={this.props.accounts}
                            />
                            <Text
                                value={this.getAzureAuth().ClusterName}
                                onChange={x => {
                                    const endpoint = this.props.endpoint;
                                    this.getAzureAuth().ClusterName = x;
                                    this.props.onChange(endpoint);
                                }}
                                error={this.props.getFieldError("ClusterName")}
                                label="AKS cluster name"
                            />
                            <Text
                                value={this.getAzureAuth().ClusterResourceGroup}
                                onChange={x => {
                                    const endpoint = this.props.endpoint;
                                    this.getAzureAuth().ClusterResourceGroup = x;
                                    this.props.onChange(endpoint);
                                }}
                                error={this.props.getFieldError("ClusterResourceGroup")}
                                label="AKS resource group name"
                            />
                        </div>
                    )}
                    {this.getAccountType() === AccountType.AmazonWebServicesAccount && (
                        <div>
                            <AccountSelect
                                onRequestRefresh={this.props.refreshAccounts}
                                value={this.getAwsAuth().AccountId}
                                type={[AccountType.AmazonWebServicesAccount]}
                                allowClear={true}
                                onChange={async x => {
                                    const endpoint = this.props.endpoint;
                                    this.getAwsAuth().AccountId = x;
                                    endpoint.AccountType = AccountType.AmazonWebServicesAccount;
                                    this.props.onChange(endpoint);
                                    await this.getAccountResource();
                                }}
                                items={this.props.accounts}
                            />
                            <Text
                                value={this.getAwsAuth().ClusterName}
                                onChange={x => {
                                    const endpoint = this.props.endpoint;
                                    this.getAwsAuth().ClusterName = x;
                                    this.props.onChange(endpoint);
                                }}
                                error={this.props.getFieldError("ClusterName")}
                                label="EKS cluster name"
                            />
                        </div>
                    )}
                    {this.getAccountType() === KubernetesCertificate && (
                        <div>
                            <CertificateSelect
                                allowClear={true}
                                value={this.getCertificateAuth().ClientCertificate}
                                error={this.props.getFieldError("ClientCertificate")}
                                onChange={async x => {
                                    const endpoint = this.props.endpoint;
                                    this.getCertificateAuth().ClientCertificate = x;
                                    endpoint.AccountType = KubernetesCertificate;
                                    this.props.onChange(endpoint);
                                    await this.getCertificateResource();
                                }}
                                doBusyTask={this.props.doBusyTask}
                            />
                            <Note>The certificate that identifies the user.</Note>
                        </div>
                    )}
                </ExpandableFormSection>
                <ExpandableFormSection errorKey={"ClusterURL"} title="Kubernetes Details" summary={this.kubernetesSummary()} help={"Enter the Kubernetes cluster details."}>
                    {this.getAccountType() !== AccountType.AzureServicePrincipal && (
                        <div>
                            <Text
                                value={this.props.endpoint.ClusterUrl}
                                onChange={x => {
                                    const endpoint = this.props.endpoint;
                                    endpoint.ClusterUrl = x;
                                    this.props.onChange(endpoint);
                                }}
                                error={this.props.getFieldError("ClusterURL")}
                                label="Kubernetes cluster URL"
                            />
                            <Note>
                                Must be an absolute URL. e.g. <em>https://kubernetes.example.com</em>
                            </Note>
                            <CertificateSelect
                                allowClear={true}
                                value={this.props.endpoint.ClusterCertificate}
                                error={this.props.getFieldError("ClusterCertificate")}
                                onChange={x => {
                                    const endpoint = this.props.endpoint;
                                    endpoint.ClusterCertificate = x;
                                    this.props.onChange(endpoint);
                                }}
                                doBusyTask={this.props.doBusyTask}
                            />
                            <Note>The optional cluster certificate authority.</Note>
                            {!this.props.endpoint.ClusterCertificate && (
                                <div>
                                    <StringCheckbox
                                        value={this.props.endpoint.SkipTlsVerification}
                                        onChange={x => {
                                            const endpoint = this.props.endpoint;
                                            endpoint.SkipTlsVerification = x;
                                            this.props.onChange(endpoint);
                                        }}
                                        label="Skip TLS verification"
                                    />
                                    <Note>Enable this option to skip the verification of the cluster certificate. This can only be selected if no cluster certificate is specified.</Note>
                                </div>
                            )}
                        </div>
                    )}
                    <Text
                        value={this.props.endpoint.Namespace}
                        onChange={x => {
                            const endpoint = this.props.endpoint;
                            endpoint.Namespace = x;
                            this.props.onChange(endpoint);
                        }}
                        hintText="default"
                        error={this.props.getFieldError("Namespace")}
                        label="Kubernetes namespace"
                    />
                </ExpandableFormSection>
                {this.props.workerPools.length > 1 && (
                    <ExpandableFormSection
                        errorKey={"DefaultWorkerPool"}
                        title="Worker Pool"
                        summary={this.props.endpoint.DefaultWorkerPoolId ? CommonSummaryHelper.resourceSummary(this.props.endpoint.DefaultWorkerPoolId, this.props.workerPools, "worker pool") : Summary.placeholder("No pool selected - default pool")}
                        help="Select a default pool for this target (optional)."
                    >
                        <Select
                            label={"Select a default pool"}
                            items={this.props.workerPools.map(e => ({ value: e.Id, text: e.Name }))}
                            value={this.props.endpoint.DefaultWorkerPoolId}
                            allowFilter={true}
                            allowClear={true}
                            onChange={x => {
                                this.props.endpoint.DefaultWorkerPoolId = x;
                                this.props.onChange(this.props.endpoint);
                            }}
                        />
                    </ExpandableFormSection>
                )}
            </div>
        );
    }

    private getAccountType() {
        // First use any value saved in the AccountType field
        if (this.props.endpoint.AccountType) {
            return this.props.endpoint.AccountType;
        }

        // Inspect the account resource
        if (this.state.account) {
            return this.state.account.AccountType;
        }

        // Inspect the certificate resource
        if (this.state.certificate) {
            return KubernetesCertificate;
        }

        return null;
    }

    private accountSummary() {
        if (this.props.endpoint.Authentication) {
            if (this.props.endpoint.Authentication.AuthenticationType === KubernetesCertificate) {
                if (this.state.certificate) {
                    return Summary.summary(this.state.certificate.Name);
                }
                return Summary.summary(<span>Using certificate</span>);
            }

            const stdAccount = this.props.endpoint.Authentication as KubernetesStandardAccountAuthentication;
            if (stdAccount.AccountId) {
                return CommonSummaryHelper.resourceSummary(stdAccount.AccountId, this.props.accounts, "account");
            }
        }

        return Summary.placeholder("No account or certificate selected");
    }

    private kubernetesSummary() {
        if (this.props.endpoint.ClusterUrl) {
            const tlsNotes = this.props.endpoint.ClusterCertificate ? " with a custom certificate" : this.props.endpoint.SkipTlsVerification === "True" ? " without TLS verification" : " with TLS verification";

            const namespaceNotes = this.props.endpoint.Namespace ? (
                <span>
                    {" "}
                    and namespace <strong>{this.props.endpoint.Namespace}</strong>
                </span>
            ) : (
                <span />
            );

            return Summary.summary(
                <span>
                    Connecting to cluster at <strong>{this.props.endpoint.ClusterUrl}</strong>
                    {namespaceNotes}
                    {tlsNotes}
                </span>
            );
        }

        if (this.props.endpoint.Namespace) {
            return Summary.summary(
                <span>
                    Namespace is <strong>{this.props.endpoint.Namespace}</strong>
                </span>
            );
        }

        return Summary.placeholder("No cluster URL or namespace");
    }

    private async getCertificateResource() {
        if (this.props.endpoint.Authentication && this.props.endpoint.Authentication.AuthenticationType === KubernetesCertificate) {
            const auth = this.props.endpoint.Authentication as KubernetesCertificateAuthentication;
            if (auth.ClientCertificate) {
                const certificate = await repository.Certificates.get(auth.ClientCertificate);
                this.setState({
                    certificate,
                    // Clear the account if we have an account selected
                    account: !certificate ? this.state.account : null,
                });
            }
        }
    }

    private async getAccountResource() {
        if (this.props.endpoint.Authentication && this.props.endpoint.Authentication.AuthenticationType !== KubernetesCertificate) {
            const auth = this.props.endpoint.Authentication as KubernetesStandardAccountAuthentication;
            if (auth.AccountId) {
                const account = await repository.Accounts.get((this.props.endpoint.Authentication as KubernetesStandardAccountAuthentication).AccountId);
                this.setState({
                    account,
                    // Clear the certificate if we have an account selected
                    certificate: !account ? this.state.certificate : null,
                });
            }
        }
    }

    /**
     * Return the Aws authentication object, creating a new one if necessary
     */
    private getAwsAuth(): KubernetesAwsAuthentication {
        if (!this.props.endpoint.Authentication || this.props.endpoint.Authentication.AuthenticationType !== KubernetesAws) {
            this.props.endpoint.Authentication = new KubernetesAwsAuthentication();
        }

        return this.props.endpoint.Authentication as KubernetesAwsAuthentication;
    }

    /**
     * Return the Azure authentication object, creating a new one if necessary
     */
    private getAzureAuth(): KubernetesAzureAuthentication {
        if (!this.props.endpoint.Authentication || this.props.endpoint.Authentication.AuthenticationType !== KubernetesAzure) {
            this.props.endpoint.Authentication = new KubernetesAzureAuthentication();
            // Azure supplies these values for us
            this.props.endpoint.ClusterUrl = null;
            this.props.endpoint.ClusterCertificate = null;
            this.props.endpoint.SkipTlsVerification = "False";
        }

        return this.props.endpoint.Authentication as KubernetesAzureAuthentication;
    }

    /**
     * Return the standard authentication object, creating a new one if necessary
     */
    private getStandardAuth(): KubernetesStandardAccountAuthentication {
        if (!this.props.endpoint.Authentication || this.props.endpoint.Authentication.AuthenticationType !== KubernetesStandard) {
            this.props.endpoint.Authentication = new KubernetesStandardAccountAuthentication();
        }

        return this.props.endpoint.Authentication as KubernetesStandardAccountAuthentication;
    }

    /**
     * Return the Certificate authentication object, creating a new one if necessary
     */
    private getCertificateAuth(): KubernetesCertificateAuthentication {
        if (!this.props.endpoint.Authentication || this.props.endpoint.Authentication.AuthenticationType !== KubernetesCertificate) {
            this.props.endpoint.Authentication = new KubernetesCertificateAuthentication();
        }

        return this.props.endpoint.Authentication as KubernetesCertificateAuthentication;
    }
}

const kubernetesEndpointImage = require("./kubernetes-cluster.svg");
const kubernetesEndpointRegistration: CategorizedEndpointRegistration = {
    key: EndpointRegistrationKey.Kubernetes,
    displayOrder: 20,
    communicationStyle: CommunicationStyle.Kubernetes,
    name: "Kubernetes Cluster",
    categories: [
        {
            category: "Kubernetes Cluster",
            title: (
                <React.Fragment>
                    Let's setup a <strong>Kubernetes Cluster</strong> target.
                </React.Fragment>
            ),
            help: (
                <Note>
                    Learn more about <ExternalLink href="KubernetesTarget">Kubernetes Targets</ExternalLink>
                </Note>
            ),
            displayOrder: 30,
        },
    ],
    renderCard: ({ registration, category, getNavigationProps }) => (
        <EndpointCard
            logo={<EndpointThumbnail src={kubernetesEndpointImage} alt={registration.name} />}
            header={registration.name}
            description="Connect to an existing Kubernetes Cluster (K8S account and namespace boundary)."
            {...getNavigationProps()}
        />
    ),
};

export { kubernetesEndpointRegistration };
