import * as React from "react";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent";
import { connect } from "react-redux";
import { repository } from "clientInstance";
import { TenantResource, MultiTenancyStatusResource, ResourceCollection } from "client/resources";
import { tenantsActions } from "../tenantsArea";
import { ExpandableFormSection, Summary, SummaryNode, FormSectionHeading, MarkdownEditor } from "../../../components/form";
import FormPaperLayout from "../../../components/FormPaperLayout/FormPaperLayout";
import LogoEditor, { LogoEditorSettings } from "../../../components/form/LogoEditor/LogoEditor";
import Text from "../../../components/form/Text/Text";
import { required } from "../../../components/form/Validators";
import { RouteComponentProps } from "react-router";
import Logo from "../../../components/Logo/Logo";
import { saveLogo } from "client/repositories/logoUpload";
import { Permission } from "../../../client/resources/permission";
import { OverflowMenuItems } from "components/Menu/OverflowMenu";
import routeLinks from "../../../routeLinks";
import { bindActionCreators, Dispatch, Action } from "redux";
import InternalRedirect from "../../../components/Navigation/InternalRedirect/InternalRedirect";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import { configurationActions } from "../../configuration/reducers/configurationArea";
import PermissionCheck, { isAllowed } from "components/PermissionCheck/PermissionCheck";
import InternalLink from "components/Navigation/InternalLink";
import Section from "components/Section";
import { List } from "components/List/List";
import ListTitle from "components/ListTitle";
import { AddTenantDialog } from "../Tenants";

interface TenantModel {
    name: string;
    description: string;
    logo: LogoEditorSettings;
    tenantTags: string[];
    clonedFromTenantId: string;
}

interface GlobalDispatchProps {
    onSpaceMultiTenancyStatusFetched: (status: MultiTenancyStatusResource) => void;
    onTenantSaved: (tenant: TenantResource) => void;
}

interface TenantSettingsState extends OptionalFormBaseComponentState<TenantModel> {
    tenant: TenantResource;
    deleted: boolean;
    clonedFromTenantDetails: ClonedFromTenantDetails;
    clonedTenantsCollection: ResourceCollection<TenantResource>;
}

enum CloneVisibility {
    NotCloned = "NotCloned",
    Available = "Available",
    NotFound = "NotFound",
    AccessDenied = "AccessDenied",
}

interface ClonedFromTenantDetails {
    clonedFromTenant: TenantResource;
    cloneVisibility: CloneVisibility;
}

class ClonedTenantsList extends List<TenantResource> {}

class TenantSettingsInternal extends FormBaseComponent<GlobalDispatchProps & RouteComponentProps<any>, TenantSettingsState, TenantModel> {
    constructor(props: GlobalDispatchProps & RouteComponentProps<any>) {
        super(props);

        this.state = {
            tenant: null,
            deleted: false,
            clonedFromTenantDetails: {
                cloneVisibility: CloneVisibility.NotCloned,
                clonedFromTenant: null,
            },
            clonedTenantsCollection: null,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const tenantId = this.props.match.params.tenantId;
            const [tenant, clonedTenantsCollection] = await Promise.all([repository.Tenants.get(tenantId), repository.Tenants.list({ clonedFromTenantId: tenantId })]);

            let clonedFromTenant: TenantResource = null;
            let cloneVisibility = CloneVisibility.NotCloned;
            if (tenant.ClonedFromTenantId) {
                const canAccessClone = isAllowed({ permission: Permission.TenantView, tenant: tenant.ClonedFromTenantId, wildcard: true });
                cloneVisibility = canAccessClone ? CloneVisibility.Available : CloneVisibility.AccessDenied;

                if (canAccessClone) {
                    try {
                        clonedFromTenant = await repository.Tenants.get(tenant.ClonedFromTenantId);
                    } catch (error) {
                        // if it's any other error let it go, if we failed to load due to bad data, just move on with 'NotFound'
                        if (error.StatusCode !== 404) {
                            throw error;
                        }
                        cloneVisibility = CloneVisibility.NotFound;
                    }
                }
            }

            this.setState({
                tenant,
                model: this.buildModel(tenant),
                cleanModel: this.buildModel(tenant),
                clonedFromTenantDetails: {
                    clonedFromTenant,
                    cloneVisibility,
                },
                clonedTenantsCollection,
            });
        });
    }

    render() {
        const CloneDialog = () => (
            <PermissionCheck permission={Permission.TenantCreate}>
                <AddTenantDialog title="Clone Tenant" cloneId={this.state.tenant.Id} cloneTenantName={this.state.tenant.Name} />
            </PermissionCheck>
        );

        const overFlowActions = this.state.tenant
            ? [
                  OverflowMenuItems.dialogItem("Clone", <CloneDialog />, { permission: Permission.TenantCreate }),
                  OverflowMenuItems.deleteItemDefault("tenant", this.handleDeleteConfirm, { permission: Permission.TenantDelete, tenant: this.state.tenant.Id }),
                  OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsForTenant(this.state.tenant.Id), null, { permission: Permission.EventView, wildcard: true }),
              ]
            : [];

        if (this.state.deleted) {
            return <InternalRedirect to={routeLinks.tenants} />;
        }

        const isTenantCloned = this.state.clonedFromTenantDetails.cloneVisibility !== CloneVisibility.NotCloned;
        const includesCloneInformation = (this.state.model && isTenantCloned) || (this.state.clonedTenantsCollection && this.state.clonedTenantsCollection.TotalResults > 0);
        const clonedFromElement = this.state.model && isTenantCloned && this.renderCloneDetails();
        return (
            <FormPaperLayout
                title="Settings"
                busy={this.state.busy}
                errors={this.state.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                savePermission={{ permission: Permission.TenantEdit, tenant: "*" }}
                onSaveClick={this.handleSaveClick}
                overFlowActions={overFlowActions}
                saveText="Tenant details updated"
            >
                {this.state.model && (
                    <TransitionAnimation>
                        <ExpandableFormSection errorKey="logo" title="Logo" summary={this.logoSummary()} help="Choose an image to use as a tenant logo.">
                            <LogoEditor value={this.state.model.logo} onChange={logo => this.setModelState({ logo })} />
                        </ExpandableFormSection>

                        <ExpandableFormSection
                            errorKey="name"
                            title="Name"
                            focusOnExpandAll
                            summary={this.state.model.name ? Summary.summary(this.state.model.name) : Summary.placeholder("Please enter a name for your tenant")}
                            help="Enter a name for your tenant."
                        >
                            <Text value={this.state.model.name} onChange={name => this.setModelState({ name })} label="Tenant name" validate={required("Please enter a tenant name")} autoFocus={true} />
                        </ExpandableFormSection>

                        <ExpandableFormSection
                            errorKey="description"
                            title="Description"
                            summary={this.state.model.description ? Summary.summary(this.state.model.description) : Summary.placeholder("No description provided")}
                            help="Enter a description for your tenant."
                        >
                            <MarkdownEditor value={this.state.model.description} label="Tenant description" onChange={description => this.setModelState({ description })} />
                        </ExpandableFormSection>

                        {includesCloneInformation && (
                            <React.Fragment>
                                <FormSectionHeading title="Cloning History" />
                                {isTenantCloned && <ExpandableFormSection errorKey="ClonedFrom" title="Cloned From" summary={Summary.summary(clonedFromElement)} help={clonedFromElement} />}
                                {this.state.clonedTenantsCollection && this.state.clonedTenantsCollection.TotalResults > 0 && (
                                    <ExpandableFormSection errorKey="ClonedTenants" title="Cloned Tenants" summary={Summary.summary("This tenant was cloned to create other tenants.")} help="This tenant was cloned to create the following tenants.">
                                        <Section>
                                            <ClonedTenantsList
                                                initialData={this.state.clonedTenantsCollection}
                                                onRow={(tenant: TenantResource) => {
                                                    return <ListTitle>{tenant.Name}</ListTitle>;
                                                }}
                                                onRowRedirectUrl={(tenant: TenantResource) => routeLinks.tenant(tenant).root}
                                                filterSearchEnabled={false}
                                                autoFocusOnFilterSearch={false}
                                                apiSearchParams={["partialName"]}
                                                match={this.props.match}
                                                showPagingInNumberedStyle={true}
                                            />
                                        </Section>
                                    </ExpandableFormSection>
                                )}
                            </React.Fragment>
                        )}
                    </TransitionAnimation>
                )}
            </FormPaperLayout>
        );
    }

    renderCloneDetails() {
        const visibility = this.state.clonedFromTenantDetails.cloneVisibility;
        const clonedFromId = this.state.tenant.ClonedFromTenantId;
        if (visibility === CloneVisibility.NotFound) {
            return <div>This tenant was originally cloned from a tenant ({clonedFromId}) that cannot be found.</div>;
        } else if (visibility === CloneVisibility.AccessDenied) {
            return <div>This tenant was originally cloned from a tenant that you do not have {Permission.TenantView} for.</div>;
        } else if (visibility === CloneVisibility.Available) {
            return (
                <div>
                    This tenant was originally cloned from <InternalLink to={routeLinks.tenant(this.state.clonedFromTenantDetails.clonedFromTenant).root}>{this.state.clonedFromTenantDetails.clonedFromTenant.Name}</InternalLink>.
                </div>
            );
        }
        return null;
    }

    handleSaveClick = async () => {
        const model = this.state.model;
        const tenant: TenantResource = {
            ...this.state.tenant,
            Name: model.name,
            Description: model.description,
            TenantTags: model.tenantTags,
            ClonedFromTenantId: model.clonedFromTenantId,
        };

        await this.doBusyTask(async () => {
            await saveLogo(this.state.tenant, this.state.model.logo.file, this.state.model.logo.reset);

            const result = await repository.Tenants.save(tenant);
            this.props.onTenantSaved(result);
            this.setState(s => {
                return {
                    model: this.buildModel(result),
                    cleanModel: this.buildModel(result),
                    tenant: result,
                };
            });
        });
    };

    buildModel(tenant: TenantResource): TenantModel {
        const model: TenantModel = {
            name: tenant.Name,
            tenantTags: tenant.TenantTags,
            logo: { file: null, reset: false },
            clonedFromTenantId: tenant.ClonedFromTenantId,
            description: tenant.Description,
        };
        return model;
    }

    logoSummary(): SummaryNode {
        if (!this.state.tenant || this.state.model.logo.reset) {
            return Summary.placeholder("Default logo");
        }
        if (this.state.model.logo.file) {
            return Summary.summary(this.state.model.logo.file.name);
        }

        return Summary.summary(<Logo url={this.state.tenant.Links.Logo} size="2.5em" />);
    }

    private handleDeleteConfirm = async () => {
        const result = await repository.Tenants.del(this.state.tenant);
        const status = await repository.Tenants.status();
        this.props.onSpaceMultiTenancyStatusFetched(status);
        this.setState(state => {
            return {
                model: null,
                cleanModel: null,
                deleted: true,
            };
        });
        return true;
    };
}

const mapGlobalActionDispatchersToProps = (dispatch: Dispatch<Action<any>>): GlobalDispatchProps =>
    bindActionCreators(
        {
            onSpaceMultiTenancyStatusFetched: configurationActions.spaceMultiTenancyStatusFetched,
            onTenantSaved: tenantsActions.tenantSaved,
        },
        dispatch
    );

const TenantSettings = connect(
    null,
    mapGlobalActionDispatchersToProps
)(TenantSettingsInternal);

export default TenantSettings;
