import AuthenticationLayoutInternal from "./AuthenticationLayoutInternal";
import { connect } from "react-redux";
import { currentUnhandledErrorSelector } from "components/UnhandledError/reducers";

const AuthenticationLayout = connect((state: GlobalState) => {
    return {
        unhandledError: currentUnhandledErrorSelector(state),
    };
})(AuthenticationLayoutInternal);

export default AuthenticationLayout;
