import * as React from "react";
import { DeployLatestReleaseActionResource, ProjectResource, ChannelResource, EnvironmentResource, ResourceCollection, LifecycleResource, PhaseResource } from "client/resources";
import { repository } from "clientInstance";
import { Select, ExpandableFormSection, Summary } from "components/form";
import LookupResourceChipComponent from "components/LookupResourceChip";
import { EnvironmentChip, ChipIcon } from "components/Chips";

interface DeployLatestReleaseActionEditorProps {
    action: DeployLatestReleaseActionResource;
    allEnvironments: EnvironmentResource[];
    lifecycle: LifecycleResource;
    onActionChange(action: DeployLatestReleaseActionResource): void;
}

interface DeployLatestReleaseActionEditorState {
    sourceEnvironmentId: string;
    destinationEnvironmentId: string;
}

export class DeployLatestReleaseActionEditor extends React.Component<DeployLatestReleaseActionEditorProps, DeployLatestReleaseActionEditorState> {
    sourceEnvironments: EnvironmentResource[] = [];
    destinationEnvironments: EnvironmentResource[] = [];
    resetSourceEnvironments: boolean;
    resetDestinationEnvironments: boolean;

    constructor(props: DeployLatestReleaseActionEditorProps) {
        super(props);

        this.state = this.initState(this.props.action);
    }

    componentWillReceiveProps(newProps: DeployLatestReleaseActionEditorProps) {
        if (this.props.action.SourceEnvironmentId !== newProps.action.SourceEnvironmentId || this.props.action.DestinationEnvironmentId !== newProps.action.DestinationEnvironmentId) {
            this.resetSourceEnvironments = !newProps.action.DestinationEnvironmentId;
            this.resetDestinationEnvironments = !newProps.action.SourceEnvironmentId;
            this.setState(this.initState(newProps.action));
        }

        const lifecycleChanged = this.props.action.ChannelId !== newProps.action.ChannelId || this.props.lifecycle.Id !== newProps.lifecycle.Id;
        this.initEnvironments(newProps.lifecycle, newProps.allEnvironments, lifecycleChanged);
    }

    render() {
        return (
            <div>
                <ExpandableFormSection errorKey="SourceEnvironment" title="Source environment" focusOnExpandAll summary={this.buildSourceEnvironmentSummary()} help="The environment to use when selecting the release to deploy from.">
                    <Select
                        allowClear={true}
                        items={this.sourceEnvironments.map(e => {
                            return { text: e.Name, value: e.Id };
                        })}
                        value={this.state.sourceEnvironmentId}
                        onChange={this.onSourceEnvironmentChange}
                    />
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="DestinationEnvironment" title="Destination environment" focusOnExpandAll summary={this.buildDestinationEnvironmentSummary()} help="The environment to deploy the selected release to.">
                    <Select
                        allowClear={true}
                        items={this.destinationEnvironments.map(e => {
                            return { text: e.Name, value: e.Id };
                        })}
                        value={this.state.destinationEnvironmentId}
                        onChange={this.onDestinationEnvironmentChange}
                    />
                </ExpandableFormSection>
            </div>
        );
    }

    initState(value?: DeployLatestReleaseActionResource) {
        const action = value || new DeployLatestReleaseActionResource();
        return {
            sourceEnvironmentId: action.SourceEnvironmentId,
            destinationEnvironmentId: action.DestinationEnvironmentId,
        };
    }

    private environmentChip = (id: string) => {
        const LookupEnvironmentChip = LookupResourceChipComponent<EnvironmentResource>();

        return <LookupEnvironmentChip lookupCollection={this.props.allEnvironments} key={id} lookupId={id} type={ChipIcon.Environment} chipRender={item => <EnvironmentChip environmentName={item.Name} />} />;
    };

    private buildSourceEnvironmentSummary = () => {
        return this.state.sourceEnvironmentId ? Summary.summary(<span>Latest release in the {this.environmentChip(this.state.sourceEnvironmentId)} environment will be deployed</span>) : Summary.placeholder("No source environment selected");
    };

    private buildDestinationEnvironmentSummary = () => {
        return this.state.destinationEnvironmentId
            ? Summary.summary(<span>Latest release will be deployed to the {this.environmentChip(this.state.destinationEnvironmentId)} environment</span>)
            : Summary.placeholder("No destination environment selected");
    };

    private onSourceEnvironmentChange = (environmentId: string) => {
        this.setState(
            {
                sourceEnvironmentId: environmentId,
            },
            () => this.raiseChange()
        );
    };

    private onDestinationEnvironmentChange = (environmentId: string) => {
        this.setState(
            {
                destinationEnvironmentId: environmentId,
            },
            () => this.raiseChange()
        );
    };

    private raiseChange = () => {
        this.props.onActionChange({
            ...this.props.action,
            SourceEnvironmentId: this.state.sourceEnvironmentId,
            DestinationEnvironmentId: this.state.destinationEnvironmentId,
        });
    };

    private initEnvironments(lifecycle: LifecycleResource, allEnvironments: EnvironmentResource[], lifecycleChanged: boolean) {
        if (lifecycleChanged || (!this.state.sourceEnvironmentId && !this.state.destinationEnvironmentId)) {
            this.sourceEnvironments = this.destinationEnvironments = this.getAllEnvironments(lifecycle, allEnvironments);

            if (lifecycleChanged) {
                let sourceEnvironmentId = this.state.sourceEnvironmentId;
                let destinationEnvironmentId = this.state.destinationEnvironmentId;
                let shouldRaiseChange = false;
                if (!this.sourceEnvironments.some(e => e.Id === sourceEnvironmentId)) {
                    sourceEnvironmentId = null;
                    shouldRaiseChange = true;
                }
                if (!this.destinationEnvironments.some(e => e.Id === destinationEnvironmentId)) {
                    destinationEnvironmentId = null;
                    shouldRaiseChange = true;
                }
                if (shouldRaiseChange) {
                    this.setState(
                        {
                            sourceEnvironmentId,
                            destinationEnvironmentId,
                        },
                        () => this.raiseChange()
                    );
                    return;
                }
            }
        }

        if (this.state.sourceEnvironmentId) {
            if (this.resetSourceEnvironments) {
                this.sourceEnvironments = this.getAllEnvironments(lifecycle, allEnvironments);
            }

            const currentPhase = lifecycle.Phases.find(p => p.AutomaticDeploymentTargets.includes(this.state.sourceEnvironmentId) || p.OptionalDeploymentTargets.includes(this.state.sourceEnvironmentId));
            const phaseIndex = lifecycle.Phases.indexOf(currentPhase);

            if (!currentPhase) {
                // if there's no currentPhase it means the user cannot see an environment that matches the current `destinationEnvironmentId`
                this.destinationEnvironments = this.getAllEnvironments(lifecycle, allEnvironments);
                return;
            }

            const canGetNextPhase = (index: number) => index < lifecycle.Phases.length - 1;
            const getNextPhaseIndex = (index: number) => index + 1;
            const nextEnvironments = this.getEnvironments(lifecycle.Phases, phaseIndex, canGetNextPhase, getNextPhaseIndex);

            this.destinationEnvironments = this.filterToAccessibleEnvironments([].concat.apply([], [currentPhase.AutomaticDeploymentTargets, currentPhase.OptionalDeploymentTargets, nextEnvironments]), allEnvironments);
        }

        if (this.state.destinationEnvironmentId) {
            if (this.resetDestinationEnvironments) {
                this.sourceEnvironments = this.getAllEnvironments(lifecycle, allEnvironments);
            }

            const currentPhase = lifecycle.Phases.find(p => p.AutomaticDeploymentTargets.includes(this.state.destinationEnvironmentId) || p.OptionalDeploymentTargets.includes(this.state.destinationEnvironmentId));

            if (!currentPhase) {
                // if there's no currentPhase it means the user cannot see an environment that matches the current `destinationEnvironmentId`
                this.sourceEnvironments = this.getAllEnvironments(lifecycle, allEnvironments);
                return;
            }

            const phaseIndex = lifecycle.Phases.indexOf(currentPhase);

            const canGetPreviousPhase = (index: number) => index > 0;
            const getPreviousPhaseIndex = (index: number) => index - 1;
            const previousEnvironments = this.getEnvironments(lifecycle.Phases, phaseIndex, canGetPreviousPhase, getPreviousPhaseIndex);

            this.sourceEnvironments = this.filterToAccessibleEnvironments([].concat.apply([], [previousEnvironments, currentPhase.AutomaticDeploymentTargets, currentPhase.OptionalDeploymentTargets]), allEnvironments);
        }
    }

    private getAllEnvironments = (lifecycle: LifecycleResource, allEnvironments: EnvironmentResource[]) => {
        return this.filterToAccessibleEnvironments(lifecycle.Phases.map(p => p.AutomaticDeploymentTargets.concat(p.OptionalDeploymentTargets)).reduce((environmentIds, environmentId) => environmentIds.concat(environmentId)), allEnvironments);
    };

    private getEnvironments = (phases: PhaseResource[], index: number, canGetPhase: (index: number) => boolean, getPhaseIndex: (index: number) => number): string[] => {
        const phase = canGetPhase(index) ? phases[getPhaseIndex(index)] : null;
        let phaseEnvironments: string[] = [];
        if (phase) {
            if (phase.IsOptionalPhase) {
                const nextIndex = getPhaseIndex(index);
                phaseEnvironments = [].concat.apply([], [this.getEnvironments(phases, nextIndex, canGetPhase, getPhaseIndex)]);
            }
            phaseEnvironments = [].concat.apply(phaseEnvironments, [phase.AutomaticDeploymentTargets, phase.OptionalDeploymentTargets]);
        }
        return phaseEnvironments;
    };

    private filterToAccessibleEnvironments = (environmentIds: string[], allEnvironments: EnvironmentResource[]) => {
        return environmentIds.map((envId: string) => allEnvironments.find(e => e.Id === envId)).filter(e => e);
    };
}
