import * as React from "react";
import { RouteComponentProps } from "react-router";
import { client, repository, session } from "clientInstance";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import { required } from "components/form/Validators";
import TeamMultiSelect from "components/MultiSelect/TeamMultiSelect";
import { TeamChip, DefaultSpaceChip } from "components/Chips";
import buildValueList from "components/EventFilter/buildValueList";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent";
import { Checkbox, ExpandableFormSection, LogoEditor, LogoEditorSettings, Note, Summary, SummaryNode, Text } from "components/form";
import { UserResource, SpaceResource, TeamResource, TeamConstants } from "client/resources";
import Permission from "client/resources/permission";
import StringHelper from "utils/StringHelper";
import Logo from "components/Logo/Logo";
import { saveLogo } from "client/repositories/logoUpload";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import { UserChip } from "../../../../components/Chips/UserChip";
import ToggleDefaultSpaceDialog from "./ToggleDefaultSpaceDialog";
import DeleteSpace from "./DeleteSpace";
import { alert, success } from "theme/colors";
import routeLinks from "routeLinks";
import OverflowMenu, { MenuItem, OverflowMenuItems } from "components/Menu/OverflowMenu";
import UserMultiSelect from "../../../../components/MultiSelect/UserMultiSelect";
import Callout, { CalloutType } from "components/Callout";
import InternalLink from "components/Navigation/InternalLink";
import styles = require("./style.less");

interface SpaceEditModel {
    name: string;
    description: string;
    spaceManagersTeams: string[];
    spaceManagerUsers: string[];
    logo: LogoEditorSettings;
    taskQueueStopped: boolean;
}

interface SpaceEditState extends OptionalFormBaseComponentState<SpaceEditModel> {
    space: SpaceResource;
    allSpaces: SpaceResource[];
    usersSpaces: SpaceResource[];
    teams: TeamResource[];
    users: UserResource[];
    redirectTo?: string;
    canDelete: boolean;
}

export default class SpaceEdit extends FormBaseComponent<RouteComponentProps<{ currentSpaceId: string }>, SpaceEditState, SpaceEditModel> {
    constructor(props: RouteComponentProps<{ currentSpaceId: string }>) {
        super(props);
        this.state = {
            space: null,
            model: null,
            allSpaces: [],
            usersSpaces: [],
            cleanModel: null,
            teams: [],
            users: [],
            canDelete: false,
        };
    }

    spaceIdToEdit(): string {
        return this.props.match.params.currentSpaceId;
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const usersSpacesPromise = repository.Users.getSpaces(session.currentUser);
            const usersPromise = repository.Users.all();
            const teamsPromise = this.getTeams();
            const allSpacesPromise = repository.Spaces.all();
            const space = await repository.Spaces.get(this.spaceIdToEdit());

            this.setState({
                teams: (await teamsPromise).sort(this.sortTeams),
                users: await usersPromise,
                space,
                allSpaces: await allSpacesPromise,
                usersSpaces: await usersSpacesPromise,
                model: this.buildModel(space),
                cleanModel: this.buildModel(space),
            });
        });
    }

    buildModel(space: SpaceResource): SpaceEditModel {
        return {
            name: space.Name,
            description: space.Description,
            spaceManagersTeams: space.SpaceManagersTeams,
            spaceManagerUsers: space.SpaceManagersTeamMembers,
            logo: { file: null, reset: false },
            taskQueueStopped: space.TaskQueueStopped,
        };
    }

    handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const space: SpaceResource = {
                ...this.state.space,
                Name: this.state.model.name,
                Description: this.state.model.description,
                SpaceManagersTeams: this.state.model.spaceManagersTeams,
                SpaceManagersTeamMembers: this.state.model.spaceManagerUsers,
                TaskQueueStopped: this.state.model.taskQueueStopped,
            };

            await saveLogo(space, this.state.model.logo.file, this.state.model.logo.reset);
            await this.saveSpace(space);
        });
    };

    async saveSpace(space: SpaceResource) {
        space = await repository.Spaces.save(space);

        this.setState({
            space,
            model: this.buildModel(space),
            cleanModel: this.buildModel(space),
        });

        return space;
    }

    getTitle(isDefaultSpace: boolean): JSX.Element {
        const title = this.state.model ? this.state.model.name : StringHelper.ellipsis;
        return (
            <div>
                {title}
                {isDefaultSpace ? <DefaultSpaceChip /> : null}
            </div>
        );
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true} />;
        }

        return (
            <FormPaperLayout
                title={this.getTitle(this.state.space && this.state.space.IsDefault)}
                breadcrumbTitle={"Spaces"}
                breadcrumbPath={routeLinks.configuration.spaces.root}
                saveText="Space details changed"
                busy={this.state.busy}
                errors={this.state.errors}
                model={this.state.model}
                savePermission={session.currentPermissions.isSpaceManager(this.state.space) ? undefined : { permission: Permission.SpaceEdit }}
                cleanModel={this.state.cleanModel}
                expandAllOnMount={!this.spaceIdToEdit()}
                onSaveClick={this.handleSaveClick}
                overFlowActions={this.getOverflowItems()}
            >
                {this.state.model && (
                    <div>
                        {this.showSpaceSwitcherHint() && (
                            <Callout title="Switch to this space" type={CalloutType.Information}>
                                You are not currently in this space. You can <InternalLink to={routeLinks.forSpace(this.state.space.Id).configuration.space(this.state.space.Id)}>switch to this space</InternalLink> or use the space-switcher in the top
                                left.
                            </Callout>
                        )}

                        <ExpandableFormSection
                            errorKey="name"
                            title="Name"
                            focusOnExpandAll
                            summary={this.state.model.name ? Summary.summary(this.state.model.name) : Summary.placeholder("Please enter a name for your space")}
                            help={"Enter a name for your space."}
                        >
                            <Text value={this.state.model.name} onChange={name => this.setModelState({ name })} label="Name" validate={required("Please enter a space name")} error={this.getFieldError("name")} autoFocus={true} />
                            <Note>A short, memorable, unique name for this space.</Note>
                        </ExpandableFormSection>

                        {/* Only show this section if they have both TeamView and UserView
                    Otherwise they might only get a partial view of the teams/users which could be confusing */}
                        {this.canViewTeams() && this.canViewUsers() && (
                            <ExpandableFormSection errorKey="Managers" title="Space Managers" summary={this.spaceManagersSummary()} help="Select members and teams to be managers of this space.">
                                <div className={styles.spaceManagersCalloutContainer}>
                                    <Callout title="Space Managers are responsible for which users can access this space" type={CalloutType.Information}>
                                        <strong>How to give users access:</strong>
                                        <ol className={styles.addTeamInstructionsList}>
                                            <li>
                                                Create a new <InternalLink to={routeLinks.forSpace(this.state.space.Id).configuration.teams.root()}>team</InternalLink> or edit an{" "}
                                                <InternalLink to={routeLinks.forSpace(this.state.space.Id).configuration.teams.root()}>existing team</InternalLink>.
                                            </li>
                                            <li>Include user roles (eg. Project Deployer) and scope them to this space to grant permissions.</li>
                                            <li>Add or modify the members of the team.</li>
                                        </ol>
                                    </Callout>
                                </div>
                                <UserMultiSelect label={"Select space managers (members)"} items={this.state.users} onChange={spaceManagerUsers => this.setModelState({ spaceManagerUsers })} value={this.state.model.spaceManagerUsers} />
                                <TeamMultiSelect
                                    label={"Select space managers (teams)"}
                                    items={this.state.teams}
                                    onChange={ownerTeams => this.setModelState({ spaceManagersTeams: ownerTeams })}
                                    value={this.state.model.spaceManagersTeams}
                                    canBeDeleted={team => !this.isSpaceManagersTeam(team.Id)}
                                    descriptionPostfix={team => (this.isSpaceManagersTeam(team.Id) ? " (this team cannot be removed; each space must have a Space Manager team)" : null)}
                                />
                            </ExpandableFormSection>
                        )}

                        <ExpandableFormSection
                            errorKey="description"
                            title="Description"
                            focusOnExpandAll
                            summary={this.state.model.description ? Summary.summary(this.state.model.description) : Summary.placeholder("No space description provided")}
                            help={"Enter a short description for this space."}
                        >
                            <Text
                                value={this.state.model.description}
                                onChange={description => this.setModelState({ description })}
                                label="Space description"
                                validate={required("Please enter a space description")}
                                error={this.getFieldError("description")}
                            />
                        </ExpandableFormSection>

                        <ExpandableFormSection errorKey="logo" title="Logo" summary={this.logoSummary()} help="Choose an image to use as a space logo.">
                            <LogoEditor value={this.state.model.logo} onChange={logo => this.setModelState({ logo })} />
                        </ExpandableFormSection>

                        <ExpandableFormSection
                            errorKey="status"
                            title="Task Processing Status"
                            summary={
                                this.state.model.taskQueueStopped
                                    ? Summary.summary(
                                          <span>
                                              Task processing is <span style={{ color: alert }}>Stopped</span>
                                          </span>
                                      )
                                    : Summary.placeholder(
                                          <span>
                                              Task processing is <span style={{ color: success }}>Running</span>
                                          </span>
                                      )
                            }
                            help="Stop processing tasks for this space"
                        >
                            <Checkbox label="Stop processing tasks" value={this.state.model.taskQueueStopped} onChange={taskQueueStopped => this.setModelState({ taskQueueStopped })} />
                        </ExpandableFormSection>
                    </div>
                )}
            </FormPaperLayout>
        );
    }

    private showSpaceSwitcherHint(): boolean {
        return this.state.space && this.state.space.Id !== client.spaceId && this.state.usersSpaces && this.state.usersSpaces.filter(x => x.Id === this.state.space.Id).length > 0;
    }

    private isSpaceManagersTeam(teamId: string): boolean {
        return teamId === this.spaceManagersTeamId();
    }

    private spaceManagersTeamId = () => {
        return `${TeamConstants.SpaceManagersTeamIdPrefix}${this.spaceIdToEdit()}`;
    };

    private onDeleteSpaceChange = (canDelete: boolean) => {
        this.setState({ canDelete });
    };

    private deleteSpace = async () => {
        await repository.Spaces.del(this.state.space);

        const links = client.spaceId !== this.state.space.Id ? routeLinks : routeLinks.withoutSpace();
        this.setState({ redirectTo: links.configuration.spaces.root });
        return true;
    };

    /*
        When a system admin creates a new space they can select a list of users that will become Space Managers.
        These users will be assigned to Space Managers team created in the newly created space. This means that
        the system admin will not be able to view this new team on Space Edit page and will be faced with the red
        resource not accessible chip. To avoid that we add a fake team to the list of teams that we pass to Team selector.
    */
    private createFakeSpaceManagersTeam(): TeamResource {
        return {
            Name: "Space Managers",
            Description: "",
            Id: this.spaceManagersTeamId(),
            ExternalSecurityGroups: [],
            MemberUserIds: [],
            CanBeDeleted: false,
            CanBeRenamed: false,
            CanChangeMembers: true,
            CanChangeRoles: true,
            Links: {},
            SpaceId: this.spaceIdToEdit(),
        };
    }

    private logoSummary(): SummaryNode {
        if (!this.state.space || this.state.model.logo.reset) {
            return Summary.placeholder("Default logo");
        }
        if (this.state.model.logo.file) {
            return Summary.summary(this.state.model.logo.file.name);
        }
        return Summary.summary(<Logo url={this.state.space.Links.Logo} size="2.5em" />);
    }

    private sortTeams(team1: TeamResource, team2: TeamResource) {
        return team1.Name < team2.Name ? -1 : team1.Name > team2.Name ? 1 : 0;
    }

    private spaceManagersSummary() {
        return this.managersSummary(this.state.model.spaceManagersTeams, this.state.model.spaceManagerUsers);
    }

    private managersSummary(teamIds: string[], userIds: string[]) {
        const userChips = this.state.users.filter(u => userIds.includes(u.Id)).map(u => <UserChip key={u.Id} user={u} />);

        const teamChips = this.state.teams.filter(t => teamIds.includes(t.Id)).map(t => <TeamChip key={t.Id} team={t} />);

        const allChips = userChips.concat(teamChips);

        return Summary.summary(<div>{buildValueList(allChips)}</div>);
    }

    private getDisableDefaultSpaceDialog(): JSX.Element {
        return <ToggleDefaultSpaceDialog disableDefaultSpace={true} onDefaultSpaceChanged={this.setIsDefaultSpace} />;
    }

    private getEnableDefaultSpaceDialog(): JSX.Element {
        return <ToggleDefaultSpaceDialog disableDefaultSpace={false} onDefaultSpaceChanged={this.setIsDefaultSpace} />;
    }

    private setIsDefaultSpace = async (isEnabled: boolean) => {
        const updatedSpace = await repository.Spaces.modify({ ...this.state.space, IsDefault: isEnabled });
        this.setState({
            space: updatedSpace,
            model: this.buildModel(updatedSpace),
            cleanModel: this.buildModel(updatedSpace),
        });
    };

    private stopTaskQueue = async () => {
        const updatedSpace = await repository.Spaces.modify({ ...this.state.space, TaskQueueStopped: true });
        const model: SpaceEditModel = {
            ...this.buildModel(updatedSpace),
            ...this.state.model,
            taskQueueStopped: updatedSpace.TaskQueueStopped,
        };
        this.setState({
            space: updatedSpace,
            model,
            cleanModel: this.buildModel(updatedSpace),
        });
    };

    private getOverflowItems() {
        if (!this.state.space) {
            return [];
        }

        const overflowItems: MenuItem[] = [];

        const isDefaultSpaceEnabledForAnySpace = this.state.allSpaces.map(space => (space.Id === this.state.space.Id ? this.state.space : space)).some(space => space.IsDefault);

        if (!isDefaultSpaceEnabledForAnySpace) {
            overflowItems.push(
                OverflowMenuItems.dialogItem("Set as the default space", this.getEnableDefaultSpaceDialog(), {
                    permission: Permission.SpaceEdit,
                })
            );
        }

        if (this.state.space.IsDefault) {
            overflowItems.push(
                OverflowMenuItems.dialogItem("Disable the default space", this.getDisableDefaultSpaceDialog(), {
                    permission: Permission.SpaceEdit,
                })
            );
        }

        if (this.state.allSpaces.length > 1) {
            overflowItems.push(
                OverflowMenuItems.deleteItem(
                    "Delete",
                    "Are you sure you want to delete this space?",
                    this.deleteSpace,
                    dialogDoBusyTask => (
                        <DeleteSpace
                            doBusyTask={dialogDoBusyTask}
                            spaceName={this.state.space.Name}
                            spaceId={this.state.space.Id}
                            isDefaultSpace={this.state.space.IsDefault}
                            isTaskQueueStopped={this.state.space.TaskQueueStopped}
                            onChange={this.onDeleteSpaceChange}
                            stopTaskQueue={this.stopTaskQueue}
                        />
                    ),
                    { permission: Permission.SpaceDelete },
                    !this.state.canDelete
                )
            );
        }

        return overflowItems;
    }

    private async getTeams(): Promise<TeamResource[]> {
        const hasTeamView = this.canViewTeams();
        const spaceRepository = await repository.forSpace(this.spaceIdToEdit());
        const teams = hasTeamView ? await spaceRepository.Teams.all() : [];
        return teams.some(t => t.Id === this.spaceManagersTeamId()) ? teams : [this.createFakeSpaceManagersTeam(), ...teams];
    }

    private canViewTeams() {
        return session.currentPermissions.scopeToSpaceAndSystem(this.spaceIdToEdit()).isAuthorized({ permission: Permission.TeamView });
    }

    private canViewUsers() {
        return session.currentPermissions.scopeToSpaceAndSystem(this.spaceIdToEdit()).isAuthorized({ permission: Permission.UserView });
    }
}
