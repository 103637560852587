type EnvironmentResource = import("client/resources").EnvironmentResource;
type LinksCollection = import("client/resources").LinksCollection;
export type ResourcesById<TResource> = { [id: string]: TResource };

const generateLinks = (resource: Partial<EnvironmentResource>): LinksCollection => {
    const root = `/api/Spaces-1/environments/${resource.Id}`;
    return {
        Self: root,
        Machines: `${root}/machines{?skip,take,partialName,roles,isDisabled,healthStatuses,commStyles,tenantIds,tenantTags}`,
        Metadata: `${root}/metadata`,
        SinglyScopedVariableDetails: `${root}/singlyScopedVariableDetails`,
    };
};

function generateEnvironment(id: string, name: string, options: Partial<EnvironmentResource> = {}): EnvironmentResource {
    const template: Omit<EnvironmentResource, "Links"> = {
        Id: id,
        Name: name,
        Description: "",
        SortOrder: 0,
        UseGuidedFailure: false,
        AllowDynamicInfrastructure: true,
        SpaceId: "Spaces-1",
        ExtensionSettings: [],
    };
    const merged = { ...template, ...options };
    const result: EnvironmentResource = ({ ...merged, ...generateLinks(merged) } as unknown) as EnvironmentResource;
    return result;
}

export function getMockEnvironments(): EnvironmentResource[] {
    return [
        generateEnvironment("Environments-1", "Development", { SortOrder: 0 }),
        generateEnvironment("Environments-2", "Staging", { SortOrder: 1 }),
        generateEnvironment("Environments-3", "Production", { SortOrder: 2 }),
        generateEnvironment("Environments-61", "Something", { SortOrder: 3 }),
        generateEnvironment("Environments-101", "SomethingElse", { SortOrder: 4 }),
    ];
}

export function getMockEnvironmentsById(): ResourcesById<EnvironmentResource> {
    const seed: ResourcesById<EnvironmentResource> = {};
    return getMockEnvironments().reduce((prev, current) => {
        return { ...prev, [current.Id]: current };
    }, seed);
}
