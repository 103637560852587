import InsertVariableButton from "components/form/InsertVariableButton/InsertVariableButton";
import { ScriptingLanguage } from "components/scriptingLanguage";
import * as React from "react";

const styles = require("./style.less");

export interface VariableLookupProps {
    localNames?: string[];
    projectId?: string;
    syntax?: ScriptingLanguage;
}

export interface TextInputRef {
    focus: () => void;
    insertAtCursor: (val: string) => void;
}

export interface GlobalConnectedProps {
    textInputRef(input: TextInputRef | null): void;
}

export function withVariableLookup() {
    return <TProps extends {}>(Comp: React.ComponentClass<TProps & GlobalConnectedProps> | React.StatelessComponent<TProps & GlobalConnectedProps>) =>
        class extends React.Component<TProps & VariableLookupProps> {
            private textInputRef: TextInputRef | null;

            render() {
                const { localNames, projectId, syntax, textInputRef, wide, ...otherProps } = this.props as any;

                return (
                    <div className={styles.container}>
                        <div className={styles.wide}>
                            <Comp key="component" textInputRef={ref => (this.textInputRef = ref)} {...otherProps} />
                        </div>
                        <div key="buttons" className={styles.buttons}>
                            <InsertVariableButton syntax={this.props.syntax} projectId={this.props.projectId} localNames={this.props.localNames} onSelected={v => this.insert(v)} />
                        </div>
                    </div>
                );
            }

            private insert(val: string) {
                if (this.textInputRef) {
                    this.textInputRef.insertAtCursor(val);
                    this.textInputRef.focus();
                }
            }
        };
}
