import * as React from "react";
import { CertificateResource, TagSetResource, EnvironmentResource, TenantResource, CertificateDataFormat } from "client/resources";
import { CertificateExpiryChip, environmentChipList, tenantChipList } from "components/Chips/index";
import { secondaryText, primaryText } from "theme/colors";
import AccountBoxIcon from "material-ui/svg-icons/action/account-box";
import FingerprintIcon from "material-ui/svg-icons/action/fingerprint";
import InfoOutlineIcon from "material-ui/svg-icons/action/info-outline";
import Tag from "components/Tag/index";
import ListTitle from "components/ListTitle/ListTitle";
import RadioButtonGroup from "components/form/RadioButton/RadioButtonGroup";
import RadioButton from "components/form/RadioButton/RadioButton";
const styles = require("./style.less");
import ThumbprintText from "components/ThumbprintText";
import MarkdownDescription from "components/MarkdownDescription";
import { normal } from "fontWeights";
import { CalloutType } from "../../Callout";
import InternalLink from "../../Navigation/InternalLink";
import routeLinks from "../../../routeLinks";
import Callout from "../../Callout";

interface CertificateListItemProps {
    certificate: CertificateResource;
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    tenantTags: TagSetResource[];
    selected?: boolean;
    showSelection?: boolean;
    onClick(): void;
}

const CertificateSummary: React.StatelessComponent<CertificateListItemProps> = props => {
    const certificate = props.certificate;

    return (
        <div className={styles.container} onClick={props.onClick}>
            {props.showSelection && (
                <div className={styles.select}>
                    <RadioButtonGroup value={props.selected} onChange={null}>
                        <RadioButton value={true} />
                    </RadioButtonGroup>
                </div>
            )}
            <div className={styles.summary}>
                <div className={styles.titleContainer}>
                    <div className={styles.title}>
                        <ListTitle>{certificate.Name}</ListTitle>
                    </div>
                    {certificate.CertificateDataFormat !== CertificateDataFormat.Unknown && (
                        <div className={styles.details}>
                            <span className={styles.icon}>{certificate.CertificateChain.length > 0 && <em className="fa fa-chain" title="Contains a certificate chain" />}</span>
                            <span className={styles.icon}>{certificate.HasPrivateKey && <em className="fa fa-key" title="Contains a private-key" />}</span>
                            <CertificateExpiryChip certificate={certificate} />
                        </div>
                    )}
                </div>
                {certificate.Notes && (
                    <div className={styles.row}>
                        <MarkdownDescription markup={certificate.Notes} />
                    </div>
                )}

                {certificate.CertificateDataFormat === CertificateDataFormat.Unknown && (
                    <div className={styles.row}>
                        <Callout title="Invalid Certificate" type={CalloutType.Warning}>
                            This certificate was unable to be parsed and may be in an invalid format. This certificate will not be able to be used in Octopus deployments and you may need to upload a new certificate which can be correctly loaded.
                        </Callout>
                    </div>
                )}
                {certificate.CertificateDataFormat !== CertificateDataFormat.Unknown && (
                    <div className={styles.row} style={{ fontWeight: normal }}>
                        <div className={styles.propertyContainer}>
                            <span>
                                <InfoOutlineIcon color={primaryText} style={{ width: "16px", height: "16px" }} />
                            </span>
                            <span>{certificate.SubjectCommonName || certificate.SubjectOrganization || certificate.SubjectDistinguishedName}</span>
                        </div>
                        <div className={styles.propertyContainer}>
                            <span>
                                <AccountBoxIcon color={primaryText} style={{ width: "16px", height: "16px" }} />
                            </span>
                            <span>{certificate.SelfSigned ? "Self-Signed" : certificate.IssuerCommonName || certificate.IssuerOrganization || certificate.IssuerDistinguishedName}</span>
                        </div>
                        <div className={styles.propertyContainer}>
                            <span>
                                <FingerprintIcon color={primaryText} style={{ width: "16px", height: "16px" }} />
                            </span>
                            <ThumbprintText thumbprint={certificate.Thumbprint} />
                        </div>
                    </div>
                )}
                <div className={styles.row}>
                    <span>{environmentChipList(props.environments, certificate.EnvironmentIds)}</span>
                    {(!!certificate.TenantIds.length || !!certificate.TenantTags.length) && (
                        <span>
                            {tenantChipList(props.tenants, certificate.TenantIds)}
                            {certificate.TenantTags.map(tt => {
                                const result = props.tenantTags.find(t => !!t.Tags.find(tag => tag.CanonicalTagName === tt));
                                if (result) {
                                    const fu = result.Tags.find(tag => tag.CanonicalTagName === tt);
                                    return <Tag key={fu.Id} description={fu.Description} tagName={fu.Name} tagColor={fu.Color} />;
                                }
                            })}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CertificateSummary;
