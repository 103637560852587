import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonConfigurationLetsEncryptOverview, CommonConfigurationLetsEncryptTopics } from "../../CommonComponents/CommonConfigurationLetsEncryptViews";

export const ConfigurationLetsEncryptConfigureRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.configuration.letsEncrypt.configure,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationLetsEncryptOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationLetsEncryptTopics />;
    },
};
