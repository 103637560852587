import { getType } from "typesafe-actions";
import { ThemePaletteType } from "theme";
import { ThemeAction, themeActions } from "./actions";

export type CurrentThemeState = ThemePaletteType;
const INITIAL_STATE: CurrentThemeState = "light";

const currentThemeReducer = (state = INITIAL_STATE, action: ThemeAction) => {
    switch (action.type) {
        case getType(themeActions.changeTheme):
            return action.payload.name;
        default:
            return state;
    }
};

export default currentThemeReducer;
export { currentThemeReducer };
