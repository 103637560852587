import * as React from "react";
import { DataTable } from "components/DataTable/DataTable";
import { DataTableBody } from "components/DataTable/DataTableBody";
import { DataTableRow } from "components/DataTable/DataTableRow";
import { DataTableRowColumn } from "components/DataTable/DataTableRowColumn";
import { DataTableHeader } from "components/DataTable/DataTableHeader";
import { DataTableHeaderColumn } from "components/DataTable/DataTableHeaderColumn";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import routeLinks from "routeLinks";
import { ExpandableFormSection, Summary } from "components/form";
import { AccountResource, AccountUsageResource, StepUsage, StepUsageEntry, ReleaseUsage, ReleaseUsageEntry, LibraryVariableSetUsageEntry, ProjectVariableSetUsage, TargetUsageEntry } from "client/resources";
import Chip from "components/Chips/Chip";
import BaseComponent from "components/BaseComponent";
import { sum, sortBy } from "lodash";
import { SemVer, valid } from "semver";

class AccountUsageProps {
    account: AccountResource;
    accountUsages: AccountUsageResource;
}

enum UsageType {
    DeploymentProcess = "DeploymentProcess",
    Release = "Release",
    Target = "Target",
    LibraryVariableSet = "LibraryVariableSet",
    ProjectVariableSet = "ProjectVariableSet",
}

export default class AccountUsage extends BaseComponent<AccountUsageProps, {}> {
    private usageInProjects = new Array<ProjectVariableSetUsage>();
    private usageInReleases = new Array<ReleaseUsage>();

    constructor(props: AccountUsageProps) {
        super(props);
        this.state = {
            accountUsages: null,
            isAccountUsageLoaded: false,
        };
        this.usageInProjects = sortBy(this.props.accountUsages.ProjectVariableSets.filter(x => x.IsCurrentlyBeingUsedInProject === true), [x => x.ProjectName.toLowerCase()], ["asc"]);
        this.usageInReleases = this.combineReleaseSnapshotsIntoReleases(this.props.accountUsages);
    }

    render() {
        const accountUsages = this.props.accountUsages;

        return (
            <div>
                <ExpandableFormSection
                    key="usageInLibraryVariableSets"
                    errorKey="usageInLibraryVariableSets"
                    title="Library Variable Sets"
                    summary={this.accountUsageSummary(UsageType.LibraryVariableSet, accountUsages)}
                    help={this.accountUsageHelp(UsageType.LibraryVariableSet, accountUsages)}
                >
                    {this.accountUsageInLibraryVariableSets(accountUsages)}
                </ExpandableFormSection>
                <ExpandableFormSection
                    key="usageInProjects"
                    errorKey="usageInProjects"
                    title="Project Variables"
                    summary={this.accountUsageSummary(UsageType.ProjectVariableSet, accountUsages)}
                    help={this.accountUsageHelp(UsageType.ProjectVariableSet, accountUsages)}
                >
                    {this.accountUsageInProjects()}
                </ExpandableFormSection>
                <ExpandableFormSection
                    key="usageInDeploymentProcesss"
                    errorKey="usageInDeploymentProcesss"
                    title="Deployment Processes"
                    summary={this.accountUsageSummary(UsageType.DeploymentProcess, accountUsages)}
                    help={this.accountUsageHelp(UsageType.DeploymentProcess, accountUsages)}
                >
                    {this.accountUsageInDeploymentProcess(accountUsages)}
                </ExpandableFormSection>
                <ExpandableFormSection key="usageInReleases" errorKey="usageInReleases" title="Releases" summary={this.accountUsageSummary(UsageType.Release, accountUsages)} help={this.accountUsageHelp(UsageType.Release, accountUsages)}>
                    {this.accountUsageInReleases(this.usageInReleases)}
                </ExpandableFormSection>
                <ExpandableFormSection key="usageInTargets" errorKey="usageInTargets" title="Targets" summary={this.accountUsageSummary(UsageType.Target, accountUsages)} help={this.accountUsageHelp(UsageType.Target, accountUsages)}>
                    {this.accountUsageInTargets(accountUsages)}
                </ExpandableFormSection>
            </div>
        );
    }

    accountUsageSummary(usageType: UsageType, accountUsages: AccountUsageResource) {
        switch (usageType) {
            case UsageType.DeploymentProcess:
                let steps = 0;
                accountUsages.DeploymentProcesses.forEach((usageEntry: StepUsage) => {
                    steps = steps + usageEntry.Steps.length;
                });
                return steps > 0
                    ? steps > 1
                        ? Summary.summary(
                              <span>
                                  This account is currently being used in <b>{steps}</b> steps
                              </span>
                          )
                        : Summary.summary(
                              <span>
                                  This account is currently being used in <b>1</b> step
                              </span>
                          )
                    : Summary.placeholder("This account is not currently being used in any steps");
            case UsageType.Release:
                const count = sum(this.usageInReleases.map(x => x.Releases.length));
                return count > 0
                    ? count > 1
                        ? Summary.summary(
                              <span>
                                  This account is being used in <b>{count}</b> releases
                              </span>
                          )
                        : Summary.summary(
                              <span>
                                  This account is being used in <b>1</b> release
                              </span>
                          )
                    : Summary.placeholder("This account is not being used in any releases");
            case UsageType.Target:
                return accountUsages.Targets.length > 0
                    ? accountUsages.Targets.length > 1
                        ? Summary.summary(
                              <span>
                                  This account is being used in <b>{accountUsages.Targets.length}</b> targets
                              </span>
                          )
                        : Summary.summary(
                              <span>
                                  This account is being used in <b>1</b> target
                              </span>
                          )
                    : Summary.placeholder("This account is not being used in any targets");
            case UsageType.LibraryVariableSet:
                return accountUsages.LibraryVariableSets.length > 0
                    ? accountUsages.LibraryVariableSets.length > 1
                        ? Summary.summary(
                              <span>
                                  This account is being used in <b>{accountUsages.LibraryVariableSets.length}</b> library variable sets
                              </span>
                          )
                        : Summary.summary(
                              <span>
                                  This account is being used in <b>1</b> library variable set
                              </span>
                          )
                    : Summary.placeholder("This account is not being used in any library variable sets");
            case UsageType.ProjectVariableSet:
                return this.usageInProjects.length > 0
                    ? this.usageInProjects.length > 1
                        ? Summary.summary(
                              <span>
                                  This account is being used in variables in <b>{this.usageInProjects.length}</b> projects
                              </span>
                          )
                        : Summary.summary(
                              <span>
                                  This account is being used in variables in <b>1</b> project
                              </span>
                          )
                    : Summary.placeholder("This account is not being used in any variables in projects");
        }
    }

    accountUsageHelp(usageType: UsageType, accountUsages: AccountUsageResource) {
        switch (usageType) {
            case UsageType.DeploymentProcess:
                return accountUsages.DeploymentProcesses.length === 0
                    ? "This account is not currently being used in any steps"
                    : accountUsages.DeploymentProcesses.length === 1
                    ? "This account is currently being used in the following step"
                    : "This account is currently being used in the following steps";
            case UsageType.Release:
                const count = sum(this.usageInReleases.map(x => x.Releases.length));
                return count === 0 ? "This account is not being used in any releases" : count === 1 ? "This account is being used in the following release" : "This account is being used in the following releases";
            case UsageType.Target:
                return accountUsages.Targets.length === 0
                    ? "This account is not being used in any targets"
                    : accountUsages.Targets.length === 1
                    ? "This account is being used in the following target"
                    : "This account is being used in the following targets";
            case UsageType.LibraryVariableSet:
                return accountUsages.LibraryVariableSets.length === 0
                    ? "This account is not being used in any library variable sets"
                    : accountUsages.LibraryVariableSets.length === 1
                    ? "This account is being used in the following library variable set"
                    : "This account is being used in the following library variable sets";
            case UsageType.ProjectVariableSet:
                return this.usageInProjects.length === 0
                    ? "This account is not being used in any variables in projects"
                    : this.usageInProjects.length === 1
                    ? "This account is being used in variables in the following project"
                    : "This account is being used in variables in the following projects";
        }
    }

    private combineReleaseSnapshotsIntoReleases(accountUsages: AccountUsageResource) {
        const releases = accountUsages.Releases;

        accountUsages.ProjectVariableSets.filter(x => x.Releases.length > 0).forEach((usageEntry: ProjectVariableSetUsage) => {
            let project: ReleaseUsage = releases.find(x => x.ProjectId === usageEntry.ProjectId);
            if (!project) {
                project = { ProjectId: usageEntry.ProjectId, ProjectName: usageEntry.ProjectName, Releases: [] };
                releases.push(project);
            }
            usageEntry.Releases.forEach((releaseEntry: ReleaseUsageEntry) => {
                const existingEntry = project.Releases.find(x => x.ReleaseId === releaseEntry.ReleaseId);
                if (!existingEntry) {
                    project.Releases.push(releaseEntry);
                }
            });
        });

        //sort the projects list
        const sortedProjects = sortBy(releases, [x => x.ProjectName.toLowerCase()], ["asc"]);
        //sort the releases for each project
        sortedProjects.forEach(accountUsage => {
            accountUsage.Releases = accountUsage.Releases.sort(this.compareSemVer);
        });

        return sortedProjects;
    }

    private compareSemVer = (a: ReleaseUsageEntry, b: ReleaseUsageEntry) => {
        if (valid(a.ReleaseVersion) && valid(b.ReleaseVersion)) {
            return new SemVer(a.ReleaseVersion).compare(new SemVer(b.ReleaseVersion));
        }
        //it is usually valid semver, but sometimes it may not be (eg "1" is a valid version number)
        if (a.ReleaseVersion < b.ReleaseVersion) {
            return -1;
        }
        if (a.ReleaseVersion > b.ReleaseVersion) {
            return 1;
        }
        return 0;
    };

    private accountUsageInDeploymentProcess(accountUsages: AccountUsageResource) {
        const versionCount = sum(accountUsages.DeploymentProcesses.map(x => x.Steps.length));
        return (
            <div>
                {accountUsages.DeploymentProcesses.length > 0 && (
                    <DataTable>
                        <DataTableHeader>
                            <DataTableRow>
                                <DataTableHeaderColumn>Project Name</DataTableHeaderColumn>
                                <DataTableHeaderColumn>Step Name{versionCount === 1 ? "" : "s"}</DataTableHeaderColumn>
                            </DataTableRow>
                        </DataTableHeader>
                        <DataTableBody>
                            {sortBy(accountUsages.DeploymentProcesses, [x => x.ProjectName, "asc"]).map((usageEntry: StepUsage, idx) => {
                                const rowKey = `AUDP-${usageEntry.ProjectId}`;
                                return (
                                    <DataTableRow key={rowKey}>
                                        <DataTableRowColumn>{usageEntry.ProjectName}</DataTableRowColumn>
                                        <DataTableRowColumn>
                                            {usageEntry.Steps.map((step: StepUsageEntry, i) => (
                                                <InternalLink to={routeLinks.project(usageEntry.ProjectSlug).process.step(step.StepId)} key={`${step.StepId}-deploymentProcessLink`}>
                                                    <Chip>{step.StepName}</Chip>
                                                </InternalLink>
                                            ))}
                                        </DataTableRowColumn>
                                    </DataTableRow>
                                );
                            })}
                        </DataTableBody>
                    </DataTable>
                )}
            </div>
        );
    }

    private accountUsageInReleases(usages: ReleaseUsage[]) {
        const versionCount = sum(usages.map(x => x.Releases.length));
        return (
            <div>
                {usages.length > 0 && (
                    <DataTable>
                        <DataTableHeader>
                            <DataTableRow>
                                <DataTableHeaderColumn>Project Name</DataTableHeaderColumn>
                                <DataTableHeaderColumn>Release Version{versionCount === 1 ? "" : "s"}</DataTableHeaderColumn>
                            </DataTableRow>
                        </DataTableHeader>
                        <DataTableBody>
                            {usages.map((usageEntry: ReleaseUsage) => {
                                const rowKey = `AUR-${usageEntry.ProjectId}`;
                                return (
                                    <DataTableRow key={rowKey}>
                                        <DataTableRowColumn>{usageEntry.ProjectName}</DataTableRowColumn>
                                        <DataTableRowColumn>
                                            {usageEntry.Releases.map((release: ReleaseUsageEntry, i) => (
                                                <InternalLink to={routeLinks.release(release.ReleaseId)} key={`${release.ReleaseId}-releaseSnapshotLink`}>
                                                    <Chip>{release.ReleaseVersion}</Chip>
                                                </InternalLink>
                                            ))}
                                        </DataTableRowColumn>
                                    </DataTableRow>
                                );
                            })}
                        </DataTableBody>
                    </DataTable>
                )}
            </div>
        );
    }

    private accountUsageInProjects() {
        return (
            <div>
                {this.usageInProjects.length > 0 && (
                    <DataTable>
                        <DataTableHeader>
                            <DataTableRow>
                                <DataTableHeaderColumn>Project Name</DataTableHeaderColumn>
                            </DataTableRow>
                        </DataTableHeader>
                        <DataTableBody>
                            {this.usageInProjects.map((usageEntry: ProjectVariableSetUsage, idx) => {
                                const rowKey = `AUP-${usageEntry.ProjectId}`;
                                return (
                                    <DataTableRow key={rowKey}>
                                        <DataTableRowColumn>
                                            <InternalLink to={routeLinks.project(usageEntry.ProjectSlug).variables.root}>{usageEntry.ProjectName}</InternalLink>
                                        </DataTableRowColumn>
                                    </DataTableRow>
                                );
                            })}
                        </DataTableBody>
                    </DataTable>
                )}
            </div>
        );
    }

    private accountUsageInLibraryVariableSets(accountUsages: AccountUsageResource) {
        return (
            <div>
                {accountUsages.LibraryVariableSets.length > 0 && (
                    <DataTable>
                        <DataTableHeader>
                            <DataTableRow>
                                <DataTableHeaderColumn>Library Variable Set</DataTableHeaderColumn>
                            </DataTableRow>
                        </DataTableHeader>
                        <DataTableBody>
                            {sortBy(accountUsages.LibraryVariableSets, [x => x.LibraryVariableSetName, "asc"]).map((usageEntry: LibraryVariableSetUsageEntry, idx) => {
                                const rowKey = `AULVS-${usageEntry.LibraryVariableSetId}`;
                                return (
                                    <DataTableRow key={rowKey}>
                                        <DataTableRowColumn>
                                            <InternalLink to={routeLinks.library.variableSet(usageEntry.LibraryVariableSetId)}>{usageEntry.LibraryVariableSetName}</InternalLink>
                                        </DataTableRowColumn>
                                    </DataTableRow>
                                );
                            })}
                        </DataTableBody>
                    </DataTable>
                )}
            </div>
        );
    }

    private accountUsageInTargets(accountUsages: AccountUsageResource) {
        return (
            <div>
                {accountUsages.Targets.length > 0 && (
                    <DataTable>
                        <DataTableHeader>
                            <DataTableRow>
                                <DataTableHeaderColumn>Target Name</DataTableHeaderColumn>
                            </DataTableRow>
                        </DataTableHeader>
                        <DataTableBody>
                            {sortBy(accountUsages.Targets, [x => x.TargetName, "asc"]).map((usageEntry: TargetUsageEntry, idx) => {
                                const rowKey = `AUT-${usageEntry.TargetId}`;
                                return (
                                    <DataTableRow key={rowKey}>
                                        <DataTableRowColumn>
                                            <InternalLink to={routeLinks.infrastructure.machine(usageEntry.TargetId).root}>{usageEntry.TargetName}</InternalLink>
                                        </DataTableRowColumn>
                                    </DataTableRow>
                                );
                            })}
                        </DataTableBody>
                    </DataTable>
                )}
            </div>
        );
    }
}

export { UsageType };
