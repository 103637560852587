import Client from "../client";
import { ProgressionResource, ProjectResource } from "../resources";

class ProgressionRepository {
    private client: Client;
    constructor(client: Client) {
        this.client = client;
    }
    getProgression(project: ProjectResource, options?: any): Promise<ProgressionResource> {
        return this.client.get<ProgressionResource>(project.Links["Progression"], options);
    }
}

export default ProgressionRepository;
