import * as React from "react";
import ToolTip from "components/ToolTip/index";
import Chip from "../Chips/Chip";
import { white } from "theme/colors";
import { tagClass } from "uiTestClasses";
import { LocationDescriptor } from "history";
const styles = require("./styles.less");
import InternalLink from "components/Navigation/InternalLink/InternalLink";

interface TagProps {
    description: string;
    tagName: string;
    tagColor: string;
    small?: boolean;
    showTooltip?: boolean;
    tabIndex?: number;
    to?: LocationDescriptor;
    onClick?: () => void;
    onRequestDelete?: (event: object) => void;
}

const Tag: React.StatelessComponent<TagProps> = props => {
    const { tagName, tagColor, tabIndex, description, small = false } = props;
    const tagDescription = description ? tagName + ": " + description : "Tag: " + tagName;

    if (!small) {
        const theTag = (
            <Chip className={tagClass} tabIndex={tabIndex} onClick={props.onClick} onRequestDelete={props.onRequestDelete} backgroundColor={tagColor} description={tagDescription} labelColor={white}>
                {tagName}
            </Chip>
        );
        if (props.to) {
            return (
                <InternalLink to={props.to} className={styles.clickableTag}>
                    {theTag}
                </InternalLink>
            );
        }
        return theTag;
    }
    return (
        <ToolTip content={tagName}>
            <em className={`${"fa fa-square tag-swatch"} ${tagClass}`} style={{ color: tagColor, marginLeft: "0.0625rem", marginRight: "0.0625rem" }} />
        </ToolTip>
    );
};

export default Tag;
