import * as React from "react";
import { manualPackagingInstruction } from "../../Instructions";
import { CommonManualPackagingTextForNuGet, CommonManualPackagingTextForZip } from "../CommonRegistrationText";

export const ManualPackaging: React.SFC<{}> = () => (
    <div>
        <p>
            When packaging .NET Core applications manually, running a <code>dotnet publish</code> then creating <i>NuGet</i> or <i>Zip</i> packages yourself is an easy way to get started.
        </p>
        <h4>DotNet Publish</h4>
        <p>
            Firstly, you need to run <code>dotnet publish</code> on your .NET Core project to ensure all the necessary files are published, ready for packaging.
        </p>
        {CommonManualPackagingTextForNuGet}
        {CommonManualPackagingTextForZip}
    </div>
);

export default manualPackagingInstruction(() => <ManualPackaging />);
