import * as React from "react";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import OnboardingPage from "../../../../components/GettingStarted/OnboardingPage";

const Onboarding = () => {
    const panels = [
        {
            imageSrc: require("./tenant-tagsets.svg"),
            imageWidth: 690,
            imageHeight: 345,
            imageAlt: "Tag sets",
            description: (
                <div>
                    By tagging tenants you can:
                    <ul>
                        <li>more easily filter your dashboards,</li>
                        <li>modify your deployment processes,</li>
                        <li>scope variables,</li>
                        <li>implement different hosting strategies, and</li>
                        <li>deploy to groups of tenants.</li>
                    </ul>
                </div>
            ),
        },
    ];

    return (
        <OnboardingPage
            title="Create tags to easily classify and group tenants"
            learnMore={
                <p>
                    If you would like more information, or hints on how to get started with tags, take a look at our <ExternalLink href="MultiTenantTags">documentation</ExternalLink>.
                </p>
            }
            panels={panels}
        />
    );
};

export default Onboarding;
