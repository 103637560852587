import * as React from "react";
import { flatten } from "lodash";

function buildValueList(elements: JSX.Element[], separator?: string, finalElementSeparator?: string) {
    if (elements && elements.length > 0) {
        return (
            <span>
                {flatten(
                    elements.map((element, index) => {
                        const nodes = [element];
                        if (index === elements.length - 1) {
                            return nodes;
                        }
                        if (index === elements.length - 2 && finalElementSeparator) {
                            nodes.push(<span key={index}>{finalElementSeparator}</span>);
                        }
                        if (index !== elements.length - 2 && separator) {
                            nodes.push(<span key={index}>{separator}</span>);
                        }
                        return nodes;
                    })
                )}
            </span>
        );
    }
    return null;
}

export default buildValueList;
