import * as React from "react";
import { Summary } from "components/form";
import cronstrue from "cronstrue";

export default function getCronExpressionSummary(expression: string) {
    try {
        if (expression && expression.trim().split(" ").length >= 5) {
            return Summary.summary(
                <span>
                    <strong>{cronstrue.toString(expression)}</strong>
                </span>
            );
        }
        return Summary.placeholder("Enter the cron expression to configure when the trigger should execute");
    } catch {
        return Summary.summary(
            <span>
                <strong>Invalid cron expression</strong>
            </span>
        );
    }
}
