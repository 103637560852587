import Resource from "./resource";
import { ExternalIdentityResource } from "./identityResource";

export class InvitationResource extends Resource {
    AddToTeamIds: string[]; //TODO: ReferenceCollection
    InvitationCode: string;
    Expires: string[]; //TODO: datetimeoffset
}

export default InvitationResource;
