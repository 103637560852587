import * as React from "react";
import { ActivityLogElement } from "client/resources/taskDetailsResource";
import { chunk } from "lodash";
import TaskLogLineChunk from "./TaskLogLineChunk";
import { SFC } from "react";

const styles = require("./taskLogBlock.less");

interface TaskLogLinesProps {
    lines: ActivityLogElement[];

    showAdditional(): void;
}

const TaskLogLines: SFC<TaskLogLinesProps> = props => {
    const lines = props.lines;
    return (
        lines &&
        lines.length > 0 && (
            <div className={styles.lines}>
                {chunk(lines, 30).map((l, n) => (
                    <TaskLogLineChunk key={n.toString()} lines={l} showAdditional={props.showAdditional} />
                ))}
            </div>
        )
    );
};

TaskLogLines.displayName = "TaskLogLines";

export default TaskLogLines;
