import * as React from "react";
import { ExpandableFormSection, Summary, Select, Note, StringCheckbox, Checkbox, StringRadioButtonGroup, RadioButton } from "components/form";
import * as moment from "moment";
import DateFormatter from "utils/DateFormatter/DateFormatter";
import { Moment } from "moment";
import TimePicker from "areas/projects/components/Releases/Deployments/NowOrLater/TimePicker/timePicker";
import { ServerTimezoneResource, TriggerScheduleRunType } from "client/resources";
import { repository } from "clientInstance";

interface ScheduleStartProps {
    startTime?: Moment;
    runType?: TriggerScheduleRunType;
    showRunContinuously?: boolean;
    timezones: ServerTimezoneResource[];
    timezone: string;
    onStartTimeChanged?(startTime: Moment): void;
    onTimezoneChanged(timezone: string): void;
    onRunTypeChanged?(runType: TriggerScheduleRunType): void;
}

export default class ScheduleStart extends React.Component<ScheduleStartProps, any> {
    render() {
        return (
            <div>
                {this.props.startTime && (
                    <ExpandableFormSection
                        errorKey="ScheduleStartTime"
                        title="Start Time"
                        summary={
                            this.props.startTime
                                ? Summary.summary(
                                      this.props.runType === TriggerScheduleRunType.Continuously ? (
                                          <span>Runs all day</span>
                                      ) : (
                                          <span>
                                              Starting at <strong>{DateFormatter.dateToCustomFormat(this.props.startTime, "hh:mm A")}</strong>
                                          </span>
                                      )
                                  )
                                : Summary.placeholder("Please select at what time the schedule should execute.")
                        }
                        help="Select at what time the schedule should execute."
                    >
                        {this.props.showRunContinuously && (
                            <StringRadioButtonGroup value={this.props.runType} onChange={this.props.onRunTypeChanged}>
                                <RadioButton value={TriggerScheduleRunType.ScheduledTime} label="Start at scheduled time" key={TriggerScheduleRunType.ScheduledTime} />
                                {!this.props.showRunContinuously || (this.props.runType === TriggerScheduleRunType.ScheduledTime && <TimePicker time={this.props.startTime} onTimeChanged={this.props.onStartTimeChanged} />)}
                                <RadioButton value={TriggerScheduleRunType.Continuously} label="Run continuously" key={TriggerScheduleRunType.Continuously} />
                            </StringRadioButtonGroup>
                        )}
                        {!this.props.showRunContinuously && <TimePicker time={this.props.startTime} onTimeChanged={this.props.onStartTimeChanged} />}
                    </ExpandableFormSection>
                )}
                <ExpandableFormSection errorKey="ScheduleTimezones" title="Schedule Timezone" summary={this.scheduleTimezoneSummary()} help="Select the timezone used for the schedule.">
                    <Select value={this.props.timezone} allowClear={true} onChange={this.props.onTimezoneChanged} items={this.props.timezones.map(pg => ({ value: pg.Id, text: pg.Name }))} label="Select timezone" />
                    <Note>Schedule run times will be calculated using this timezone.</Note>
                </ExpandableFormSection>
            </div>
        );
    }

    private scheduleTimezoneSummary() {
        return this.props.timezone ? Summary.summary(this.props.timezone) : Summary.placeholder("No timezone selected");
    }
}
