import * as React from "react";
const styles = require("./style.less");

interface FormSectionHeadingProps {
    title: string | JSX.Element;
}

class FormSectionHeading extends React.Component<FormSectionHeadingProps, {}> {
    render() {
        return (
            <div className={styles.sectionHeading}>
                <div className={styles.sectionHeadingTitle}>{this.props.title}</div>
            </div>
        );
    }
}

export default FormSectionHeading;
