import * as React from "react";
import RetryAttemptsFormSection from "../RetryAttemptsFormSection";
import { FeedType } from "client/resources/feedResource";
import feedTypeRegistry, { FeedEditProps } from "./feedTypeRegistry";
import BasicFeedCredentialsFormSection from "../BasicFeedCredentialsFormSection";
import { MavenFeedResource } from "client/resources/feedResource";

class MavenFeed extends React.Component<FeedEditProps<MavenFeedResource>> {
    render() {
        return (
            <React.Fragment>
                <RetryAttemptsFormSection
                    backoffSeconds={this.props.feed.DownloadRetryBackoffSeconds}
                    onBackoffSecondsChanged={seconds => this.props.onChange({ ...this.props.feed, DownloadRetryBackoffSeconds: seconds })}
                    attemptCount={this.props.feed.DownloadAttempts}
                    onAttemptCountChanged={count => this.props.onChange({ ...this.props.feed, DownloadAttempts: count })}
                />
                <BasicFeedCredentialsFormSection
                    model={{ username: this.props.feed.Username, password: this.props.feed.Password }}
                    onChanged={model => this.props.onChange({ ...this.props.feed, Password: model.password, Username: model.username })}
                />
            </React.Fragment>
        );
    }
}

feedTypeRegistry.registerFeedType({
    type: FeedType.Maven,
    text: "Maven Feed",
    edit: MavenFeed,
});
