import * as React from "react";
import { ActionTemplateParameterResource } from "client/resources/actionTemplateParameterResource";
import ListTitle from "components/ListTitle/ListTitle";
import Markdown from "components/Markdown/index";
import Note from "components/form/Note/Note";
import convertPropertyValueResourceToString from "components/convertPropertyValueResourceToString";

interface ActionTemplateParameterResourceSummaryProps {
    parameter: ActionTemplateParameterResource;
}

const ActionTemplateParameterResourceSummary: React.SFC<ActionTemplateParameterResourceSummaryProps> = (props: ActionTemplateParameterResourceSummaryProps) => {
    return (
        <div key={props.parameter.Name}>
            <ListTitle>{props.parameter.Label || props.parameter.Name}</ListTitle>
            <code>
                #{`{${props.parameter.Name}}`} {props.parameter.DefaultValue ? " = " + convertPropertyValueResourceToString(props.parameter.DefaultValue) : ""}
            </code>
            <Note>
                <Markdown markup={props.parameter.HelpText} />
            </Note>
        </div>
    );
};

export default ActionTemplateParameterResourceSummary;
