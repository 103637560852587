import * as React from "react";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { WorkerPoolsSummaryResource, WorkerPoolSummaryResource } from "client/resources";
import { WorkerPoolIcon } from "components/Icon";
import { secondaryDark, white } from "theme/colors";
const styles = require("./style.less");
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import { secondaryText } from "theme/colors";
import routeLinks from "../../../../routeLinks";
import InternalLink from "components/Navigation/InternalLink";
import BusyIndicator from "components/BusyIndicator";
import { Section } from "components/Section/Section";

interface WorkersPoolsCardProps {
    workerSummary: WorkerPoolsSummaryResource;
}

interface WorkerPoolsCardState extends DataBaseComponentState {
    filterText?: string;
}

export default class WorkerPoolsCard extends DataBaseComponent<WorkersPoolsCardProps, WorkerPoolsCardState> {
    constructor(props: WorkersPoolsCardProps) {
        super(props);
        this.state = {};
    }

    render() {
        if (!this.props.workerSummary) {
            return this.props.workerSummary === undefined ? null : (
                <div className={styles.card}>
                    <div className={styles.cardTitleContainer}>
                        <div className={styles.cardTitle}>
                            <InternalLink to={routeLinks.infrastructure.workerPools.root} color={white}>
                                Worker Pools
                            </InternalLink>
                        </div>
                    </div>
                    <Section>
                        <BusyIndicator inline={true} show={true} />
                    </Section>
                </div>
            );
        }
        let workerSummaries = this.props.workerSummary && this.props.workerSummary.WorkerPoolSummaries;
        workerSummaries = workerSummaries.filter(item => this.onFilter(this.state.filterText, item));
        const workerpoolRows =
            workerSummaries &&
            workerSummaries.map((workerSummary, index) => {
                const totalMachines = workerSummary.TotalMachines || 0;
                return (
                    <div className={styles.workerpoolRowsContainer} key={workerSummary.WorkerPool.Id}>
                        <div className={styles.workerpoolIcon}>
                            <WorkerPoolIcon color={secondaryDark} />
                        </div>
                        <div className={styles.workerpoolName}>
                            <InternalLink to={routeLinks.infrastructure.workerPools.filtered({ workerPoolIds: workerSummary.WorkerPool.Id })}>{workerSummary.WorkerPool.Name}</InternalLink>
                        </div>
                        <div className={styles.workerpoolMachinesCount}>{totalMachines.toLocaleString()}</div>
                    </div>
                );
            });
        return (
            <div className={styles.card}>
                <div className={styles.cardTitleContainer}>
                    <div className={styles.cardTitle}>
                        <InternalLink to={routeLinks.infrastructure.workerPools.root} color={white}>
                            Worker Pools <span className={styles.cardCount}>({workerSummaries && workerSummaries.length.toLocaleString()})</span>
                        </InternalLink>
                    </div>
                </div>
                <div className={styles.cardContentContainer}>
                    {(this.props.workerSummary.WorkerPoolSummaries.length > 1 || this.state.filterText) && (
                        <div>
                            <FilterSearchBox
                                hintText="Filter..."
                                onChange={value => this.setState({ filterText: value.toLowerCase() })}
                                inputClassName={styles.dashboardFilterInputClassName}
                                iconColor={secondaryText}
                                iconStyle={{ width: "20px", height: "20px", top: "14px" }}
                            />
                        </div>
                    )}
                    {workerpoolRows}
                </div>
            </div>
        );
    }

    private onFilter(filter: string, resource: WorkerPoolSummaryResource) {
        return !filter || filter.length === 0 || !resource || resource.WorkerPool.Name.toLowerCase().includes(filter.toLowerCase());
    }
}
