import { repository } from "clientInstance";
import AreaTitle from "components/AreaTitle";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import PaperLayout from "components/PaperLayout";
import * as H from "history";
import * as React from "react";
import { RouteComponentProps } from "react-router";

import routeLinks from "../../../../routeLinks";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";

interface OverviewFilterRedirectState extends DataBaseComponentState {
    redirectTo?: H.LocationDescriptor;
}

interface ScopeRoleRouteParams {
    scopedRoleId: string;
}

export class RedirectFromScopedRole extends DataBaseComponent<RouteComponentProps<ScopeRoleRouteParams>, OverviewFilterRedirectState> {
    constructor(props: RouteComponentProps<ScopeRoleRouteParams>) {
        super(props);
        this.state = {
            redirectTo: null,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const id = this.props.match.params.scopedRoleId;
            const scopedUserRole = await repository.ScopedUserRoles.get(id);

            const redirectTo = routeLinks.forSpace(scopedUserRole.SpaceId).configuration.team(scopedUserRole.TeamId);

            this.setState({ redirectTo });
        });
    }

    render() {
        const to = this.state.redirectTo;
        if (!to) {
            return (
                <main id="maincontent">
                    <AreaTitle link={routeLinks.configuration.teams.root()} title="Configuration" />
                    <PaperLayout busy={this.state.busy} fullWidth={true} errors={this.state.errors} />
                </main>
            );
        }

        return <InternalRedirect push={false} to={to} />;
    }
}
