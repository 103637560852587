import Client from "../client";
import { UserResource, TeamNameResource } from "../resources";
import { convertToSpacePartitionParameters } from "./mixedScopeBaseRepository";

class UserTeamRepository {
    private client: Client;
    constructor(client: Client) {
        this.client = client;
    }

    getTeams(user: UserResource, includeSystem: boolean) {
        return this.client.get<TeamNameResource[]>(user.Links["Teams"], convertToSpacePartitionParameters(this.client.spaceId, includeSystem));
    }
}

export default UserTeamRepository;
