import BasicRepository, { AllArgs, ListArgs } from "./basicRepository";
import Client from "../client";
import { TenantMissingVariableResource, TenantVariableResource, TenantResource, TagTestResult, MultiTenancyStatusResource, ResourceCollection } from "../resources";
import { uploadLogo } from "./logoUpload";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { Permission } from "client/resources/permission";

type TenantListArgs = {
    projectId?: string;
} & ListArgs;

type TenantAllArgs = {
    projectId?: string;
} & AllArgs;

type ListTenantsArgs = {
    projectId?: string;
    tags?: string;
    clonedFromTenantId?: string;
    name?: string;
};

class TenantRepository extends BasicRepository<TenantResource, TenantResource, TenantListArgs, TenantAllArgs> {
    constructor(client: Client) {
        super("Tenants", client);
    }

    status(): Promise<MultiTenancyStatusResource> {
        return this.client.get(this.client.getLink("TenantsStatus"));
    }

    tagTest(tenantIds: string[], tags: string[]): Promise<TagTestResult> {
        return this.client.get(this.client.getLink("TenantTagTest"), { tenantIds, tags });
    }

    getVariables(tenant: TenantResource): Promise<TenantVariableResource> {
        return this.client.get(tenant.Links["Variables"]);
    }

    setVariables(tenant: TenantResource, variables: any): Promise<TenantVariableResource> {
        return this.client.put(tenant.Links["Variables"], variables);
    }

    missingVariables(filterOptions: FilterOptions = {}, includeDetails: boolean = false): Promise<TenantMissingVariableResource[]> {
        const payload = {
            environmentId: filterOptions.environmentId,
            includeDetails: !!includeDetails,
            projectId: filterOptions.projectId,
            tenantId: filterOptions.tenantId,
        };
        return this.client.get(this.client.getLink("TenantsMissingVariables"), payload);
    }

    all(args?: TenantAllArgs): Promise<TenantResource[]> {
        if (!isAllowed({ permission: Permission.TenantView, tenant: "*" })) {
            return Promise.resolve([]);
        }
        return super.all(args);
    }

    list(args?: ListTenantsArgs): Promise<ResourceCollection<TenantResource>> {
        return this.client.get(this.client.getLink("Tenants"), { ...args });
    }
}

type FilterOptions = {
    tenantId?: string;
    projectId?: string;
    environmentId?: string;
};

export default TenantRepository;
