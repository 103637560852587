import * as React from "react";
import { repository } from "clientInstance";
const styles = require("./style.less");
import { TaskResource } from "client/resources/taskResource";
import { TaskState } from "client/resources/taskState";
import OkDialogLayout from "components/DialogLayout/OkDialogLayout";
import { Callout, CalloutType } from "components/Callout/Callout";
import { DataBaseComponentState } from "components/DataBaseComponent";
import { DataBaseComponent } from "components/DataBaseComponent/DataBaseComponent";

type CreateTestAccountTaskPropsCallback = (accountId: string) => Promise<TaskResource<any>>;

interface TestAccountTaskProps {
    createTask: CreateTestAccountTaskPropsCallback;
}

interface SaveAndTestAccountProps {
    accountId: string;
    onOkClick: () => void;
}

interface SaveAndTestAccountState extends DataBaseComponentState {
    accountTestTask?: TaskResource<any>;
    testTask?: TaskResource<any>;
    testTaskFinished?: boolean;
}

export default class SaveAndTestAccountDialog extends DataBaseComponent<SaveAndTestAccountProps & TestAccountTaskProps, SaveAndTestAccountState> {
    constructor(props: SaveAndTestAccountProps & TestAccountTaskProps) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const task = await this.props.createTask(this.props.accountId);
            this.setState({ accountTestTask: task });
            await this.startRefreshLoop(() => this.refreshAccountTest(task.Id), 1500);
        });
    }

    async refreshAccountTest(id: string): Promise<any> {
        if (this.state.testTaskFinished) {
            // this will keep running because how beginRefreshLoop is, but will be cleaned up on leaving the page
            // unless there's a way to stop `beginRefreshLoop`
            return {};
        }

        await this.doBusyTask(async () => {
            const testTask = await repository.Tasks.get(id);
            const testTaskFinished = testTask.State === TaskState.Success || testTask.State === TaskState.Failed || testTask.State === TaskState.TimedOut;
            this.setState({
                testTask,
                testTaskFinished,
            });
            return { testTask };
        });
    }

    render() {
        return (
            <OkDialogLayout title={"Verifying account"} hideCancel={true} busy={!this.state.testTaskFinished || this.state.busy} errors={this.state.errors} onOkClick={this.props.onOkClick}>
                {this.state.testTask && this.state.testTask.ErrorMessage && (
                    <Callout title="Failed" type={CalloutType.Danger}>
                        {this.state.testTask.ErrorMessage}
                    </Callout>
                )}

                {this.state.testTask && this.state.testTask.State === TaskState.Success && (
                    <Callout title="Success" type={CalloutType.Success}>
                        <div className={styles.testingSuccess}>
                            <span>The account was verified successfully.</span>
                        </div>
                    </Callout>
                )}
            </OkDialogLayout>
        );
    }
}

const SaveAndTestAzureAccountDialog: React.SFC<SaveAndTestAccountProps> = ({ accountId, onOkClick }) => {
    return <SaveAndTestAccountDialog accountId={accountId} onOkClick={onOkClick} createTask={accId => repository.Tasks.createTestAzureAccountTask(accId)} />;
};

const SaveAndTestAmazonWebServicesAccountDialog: React.SFC<SaveAndTestAccountProps> = ({ accountId, onOkClick }) => {
    return <SaveAndTestAccountDialog accountId={accountId} onOkClick={onOkClick} createTask={accId => repository.Tasks.createTestAwsAccountTask(accId)} />;
};

export { SaveAndTestAzureAccountDialog, SaveAndTestAmazonWebServicesAccountDialog, SaveAndTestAccountProps };
