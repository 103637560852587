import * as React from "react";
import { Note } from "components/form";
const styles = require("./style.less");

interface FormSectionHeadingProps {
    title: string | JSX.Element;
}

// use as a section heading in dialogs where we want it to push to the edge despite
// dialogs having margin on the content
class DialogFormSectionHeading extends React.Component<FormSectionHeadingProps, {}> {
    render() {
        return (
            <div className={styles.dialogSectionHeading}>
                <div className={styles.sectionHeadingTitle}>{this.props.title}</div>
            </div>
        );
    }
}

export default DialogFormSectionHeading;
