import * as React from "react";
import Note from "../../../../../components/form/Note/Note";
import { ReleaseChanges } from "client/resources";
import DataBaseComponent, { DataBaseComponentState } from "components/DataBaseComponent";
import WorkItems from "components/WorkItems/WorkItems";
import Markdown from "components/Markdown";

interface ReleaseChangesDetailProps {
    releaseChanges: ReleaseChanges[];
}

export default class ReleaseChangesDetail extends DataBaseComponent<ReleaseChangesDetailProps, DataBaseComponentState> {
    constructor(props: ReleaseChangesDetailProps) {
        super(props);
        this.state = {};
    }

    render() {
        return this.props.releaseChanges.map(rn => (
            <div>
                <strong>Release {rn.Version}</strong>
                {(rn.ReleaseNotes && rn.ReleaseNotes.length > 0) || (rn.WorkItems && rn.WorkItems.length > 0) ? (
                    <>
                        {rn.ReleaseNotes && (
                            <div>
                                <Markdown markup={rn.ReleaseNotes} />
                            </div>
                        )}
                        {rn.WorkItems && rn.WorkItems.length > 0 && this.showWorkItems(rn) && (
                            <div>
                                <WorkItems data={rn.WorkItems} />
                            </div>
                        )}
                    </>
                ) : (
                    <Note>No release notes or work items</Note>
                )}
            </div>
        ));
    }

    showWorkItems = (releaseChanges: ReleaseChanges) => {
        return releaseChanges && releaseChanges.WorkItems && !releaseChanges.WorkItems.find(wi => releaseChanges && releaseChanges.ReleaseNotes && releaseChanges.ReleaseNotes.indexOf(wi.LinkUrl) > 0);
    };
}
