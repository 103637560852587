import * as React from "react";
import ActionButton, { ActionButtonType } from "../../Button/ActionButton";
import { LargeDialogFrame, DialogFrameProp } from "./CustomDialogFrames";
import { RenderProps } from "components/Dialog/CustomDialog";
import { CustomDialogTitleBar } from "./CustomDialogTitleBar";
import { CustomDialogContent } from "./CustomDialogContent";
import { CustomDialogActions } from "./CustomDialogActions";

export type CustomInfoDialogLayoutProps = RenderProps &
    DialogFrameProp & {
        title: string;
        closeButtonLabel?: string;
        additionalActions?: React.ReactElement<any>;
    };

export const CustomInfoDialogLayout: React.SFC<CustomInfoDialogLayoutProps> = ({ closeButtonLabel = "Close", children, title, close, frame: Frame = LargeDialogFrame, additionalActions }) => {
    return (
        <Frame>
            <CustomDialogTitleBar title={title} />
            <CustomDialogContent>{children}</CustomDialogContent>
            <CustomDialogActions
                actions={
                    <React.Fragment>
                        {additionalActions}
                        <ActionButton key="close" onClick={close} label={closeButtonLabel} type={ActionButtonType.Primary} />
                    </React.Fragment>
                }
            />
        </Frame>
    );
};

export default CustomInfoDialogLayout;
