import * as React from "react";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import OnboardingPage from "../../../../components/GettingStarted/OnboardingPage";

export const OnboardingTextForAccounts = "Accounts help you to centralise account details used during your deployments, including things like username/password, tokens, Azure and AWS credentials and SSH key pairs.";

const Onboarding = () => {
    return (
        <OnboardingPage
            title="Add accounts to use during your deployments"
            intro={<span>{OnboardingTextForAccounts}</span>}
            learnMore={
                <span>
                    <ExternalLink href="OnboardingAccountsLearnMore">Learn more</ExternalLink>
                </span>
            }
        />
    );
};

export default Onboarding;
