import BasicRepository from "./basicRepository";
import Client from "../client";
import DeploymentProcessResource from "../resources/deploymentProcessResource";
import { ReleaseTemplateResource } from "client/resources";

class DeploymentProcessRepository extends BasicRepository<DeploymentProcessResource, DeploymentProcessResource> {
    constructor(client: Client) {
        super("DeploymentProcesses", client);
    }
    getTemplate(deploymentProcess: DeploymentProcessResource, channelId: string, releaseId: string) {
        return this.client.get<ReleaseTemplateResource>(deploymentProcess.Links["Template"], { channel: channelId, releaseId });
    }
}

export default DeploymentProcessRepository;
