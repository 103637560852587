export class MarkdownProvider {
    private value: string;
    private selectionStart: number;
    private selectionEnd: number;
    private before: string;
    private after: string;
    private addNewLine: boolean;
    private afterNewline: string;
    private emptyText: string;

    public constructor(value: string, selectionStart: number, selectionEnd: number) {
        this.value = value;
        this.selectionStart = selectionStart;
        this.selectionEnd = selectionEnd;
        this.before = "";
        this.after = "";
        this.addNewLine = false;
        this.afterNewline = "";
        this.emptyText = "";
    }

    insertBefore(before: string) {
        this.before = before;
        return this;
    }

    insertAfter(after: string) {
        this.after = after;
        return this;
    }

    surroundWithNewlines() {
        this.addNewLine = true;
        return this;
    }

    insertAfterNewlineInSelection(afterNewline: string) {
        this.afterNewline = afterNewline;
        return this;
    }

    emptySelectionText(emptyText: string) {
        this.emptyText = emptyText;
        return this;
    }

    apply(callback: (value: string, selectionStart: number, selectionEnd: number) => void) {
        this.trimSelection();

        if (this.emptyText && this.selectionStart === this.selectionEnd) {
            this.addEmptyText();
        }

        if (this.addNewLine) {
            this.addNewLineImpl();
        }

        this.value = this.splice(this.value, this.selectionEnd, this.after);
        this.value = this.splice(this.value, this.selectionStart, this.before);

        this.selectionStart = this.selectionStart + this.before.length;
        this.selectionEnd = this.selectionEnd + this.before.length;

        if (this.afterNewline) {
            this.insertAfterNewlineInSelectionImpl();
        }

        callback(this.value, this.selectionStart, this.selectionEnd);
    }

    private splice(input: string, start: number, newSubStr: string) {
        return input.slice(0, start) + newSubStr + input.slice(start);
    }

    private addNewLineImpl() {
        // add a newline character for lists and comments,
        // but not if there is already one there
        const beforeSelection = this.value[this.selectionStart - 1];
        if (beforeSelection !== "\n" && beforeSelection !== undefined) {
            this.value = this.splice(this.value, this.selectionStart, "\n");
            this.selectionStart += 1;
            this.selectionEnd += 1;
        }

        const afterSelection = this.value[this.selectionEnd];
        if (afterSelection !== "\n" && afterSelection !== undefined) {
            this.value = this.splice(this.value, this.selectionEnd, "\n");
        }
    }

    private trimSelection() {
        // trim whitespace from the selection (this was implemented in
        // other online markdown editors when I checked how they handled selection)
        while (this.value[this.selectionStart] === " " && this.selectionStart < this.selectionEnd) {
            this.selectionStart += 1;
        }

        while (this.value[this.selectionEnd - 1] === " " && this.selectionStart < this.selectionEnd) {
            this.selectionEnd -= 1;
        }
    }

    private insertAfterNewlineInSelectionImpl() {
        // insert something after each newLine in the selection
        const selectedText = this.value.slice(this.selectionStart, this.selectionEnd);
        const updatedText = selectedText.replace(new RegExp("\n", "g"), "\n" + this.afterNewline);
        this.value = this.value.slice(0, this.selectionStart) + updatedText + this.value.slice(this.selectionEnd);
        this.selectionEnd = this.selectionEnd - selectedText.length + updatedText.length;
    }

    private addEmptyText() {
        this.value = this.splice(this.value, this.selectionStart, this.emptyText);
        this.selectionEnd = this.selectionEnd + this.emptyText.length;
    }
}
