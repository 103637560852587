import * as React from "react";
import Note from "components/form/Note/Note";
import { TenantResource } from "client/resources/tenantResource";
import Checkbox from "components/form/Checkbox/Checkbox";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import { EnvironmentResource } from "client/resources/environmentResource";
import { ReleaseResource } from "client/resources/releaseResource";
const styles = require("./style.less");
import { TenantChip } from "components/Chips/index";

interface SkipTenantsPanelProps {
    selectedEnvironmentIds: string[];
    showSkipPanel: boolean;
    tenantsWhoGotThisReleaseAsCurrent: TenantResource[];
    showTenantsWhoGotThisReleaseAsCurrent: boolean;
    skipTenantsWhoHaveTheReleaseAsCurrent: boolean;
    allEnvironments: EnvironmentResource[];
    release: ReleaseResource;
    onTenantFilterRuleChange(value: boolean): void;
    onTenantsToggled(): void;
}
const SkipTenantsPanel: React.StatelessComponent<SkipTenantsPanelProps> = props => {
    const numOfTenantsGotThisRelease = props.tenantsWhoGotThisReleaseAsCurrent.length;
    const environmentName = props.allEnvironments.find(x => x.Id === props.selectedEnvironmentIds[0]).Name;
    return (
        props.tenantsWhoGotThisReleaseAsCurrent.length > 0 &&
        props.showSkipPanel && (
            <div>
                <Checkbox
                    label={`Skip tenants where ${props.release.Version} is the current version deployed to ${environmentName} (${numOfTenantsGotThisRelease})`}
                    value={props.skipTenantsWhoHaveTheReleaseAsCurrent}
                    onChange={props.onTenantFilterRuleChange}
                />
                <div className={styles.tenantIntersection}>
                    <Note>
                        {`${numOfTenantsGotThisRelease} ${numOfTenantsGotThisRelease > 1 ? "tenants have" : "tenant has"} this version currently in ${environmentName}`}
                        <ActionButton type={ActionButtonType.Ternary} onClick={props.onTenantsToggled} label={props.showTenantsWhoGotThisReleaseAsCurrent ? "hide" : "show"} />
                        {props.showTenantsWhoGotThisReleaseAsCurrent && (
                            <div>
                                {props.tenantsWhoGotThisReleaseAsCurrent.map(tenant => (
                                    <TenantChip tenantName={tenant.Name} key={tenant.Id} />
                                ))}
                            </div>
                        )}
                    </Note>
                </div>
            </div>
        )
    );
};

export default SkipTenantsPanel;
