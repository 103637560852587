import * as React from "react";
import { AccountType } from "client/resources/index";
import { connect } from "react-redux";
import { getAccountById } from "../../reducers/selectors";

interface RenderProps {
    accountId: string;
    account: AccountProps;
}

export interface AccountProps {
    accountId: string;
    name: string;
    description: string;
    accountType: AccountType;
}

interface AccountDisplayProps {
    accountId: string;
    account: AccountProps;
    render: (props: RenderProps) => React.ReactElement<any>;
}

interface ConnectedAccountDisplayProps {
    accountId: string;
}

interface GlobalConnectedProps {
    account: AccountProps;
}

const AccountDisplay: React.SFC<AccountDisplayProps> = ({ accountId, account, render }) => {
    return render({ account, accountId });
};

const mapGlobalStateToProps = (state: GlobalState, props: ConnectedAccountDisplayProps): GlobalConnectedProps => {
    return {
        account: getAccountById(state, props),
    };
};

const ConnectedAccountDisplay = connect(mapGlobalStateToProps)(AccountDisplay);
export default ConnectedAccountDisplay;
