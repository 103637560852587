import { pageId } from "utils/pageId";

export default () => {
    function workerMachineLinks() {
        return {
            root: pageId("Infrastructure.Worker", "Worker"),
            settings: pageId("Infrastructure.WorkerSettings", "Worker Settings"),
            connection: pageId("Infrastructure.WorkerConnectivity", "Worker Connectivity"),
            events: pageId("Infrastructure.WorkerEvents", "Worker Events"),
        };
    }

    const deploymentTargetLinks = () => ({
        root: pageId("Infrastructure.DeploymentTarget", "Deployment Target"),
        settings: pageId("Infrastructure.DeploymentTargetSettings", "Deployment Target Settings"),
        deployments: pageId("Infrastructure.DeploymentTargetDeployments", "Deployment Target Deployments"),
        connection: pageId("Infrastructure.DeploymentTargetConnectivity", "Deployment Target Connectivity"),
        events: pageId("Infrastructure.DeploymentTargetEvents", "Deployment Target Events"),
    });

    return {
        root: pageId("Infrastructure"),
        overview: pageId("Infrastructure.Overview", "Overview"),
        environments: {
            root: pageId("Infrastructure.Environments", "Environments"),
            create: pageId("Infrastructure.EnvironmentsNew", "New Environment"),
            filtered: pageId("Infrastructure.EnvironmentsFiltered", "Filtered Environments"),
        },
        environment: pageId("Infrastructure.Environment", "Environment"),
        machines: {
            root: pageId("Infrastructure.DeploymentTargets", "Deployment Targets"),
            select: pageId("Infrastructure.DeploymentTargetsSelect", "New Deployment Target Selection"),
            create: pageId("Infrastructure.DeploymentTargetsNew", "New Deployment Target"),
            filtered: pageId("Infrastructure.DeploymentTargetsFiltered", "Filtered Deployment Targets"),
            discover: pageId("Infrastructure.DeploymentTargetsDiscover", "Discover Deployment Target"),
        },
        machine: {
            root: pageId("Infrastructure.DeploymentTarget", "Deployment Target"),
            settings: pageId("Infrastructure.DeploymentTargetSettings", "Deployment Target Settings"),
            deployments: pageId("Infrastructure.DeploymentTargetDeployments", "Deployment Target Deployments"),
            connection: pageId("Infrastructure.DeploymentTargetConnectivity", "Deployment Target Connectivity"),
            events: pageId("Infrastructure.DeploymentTargetEvents", "Deployment Target Events"),
        },
        deploymentTarget: {
            ...deploymentTargetLinks(),
        },
        proxies: {
            root: pageId("Infrastructure.Proxies", "Proxies"),
            create: pageId("Infrastructure.ProxiesNew", "New Proxy"),
        },
        proxy: pageId("Infrastructure.Proxy", "Proxy"),
        machinePolicies: {
            root: pageId("Infrastructure.MachinePolicies", "Machine Policies"),
            create: pageId("Infrastructure.MachinePoliciesNew", "New Machine Policy"),
        },
        machinePolicy: pageId("Infrastructure.MachinePolicy", "Machine Policy"),
        accounts: {
            root: pageId("Infrastructure.Accounts", "Accounts"),
            create: pageId("Infrastructure.AccountsNew", "New Account"),
        },
        account: pageId("Infrastructure.Account", "Account"),
        workerMachines: {
            root: pageId("Infrastructure.Workers", "Workers"),
            select: pageId("Infrastructure.WorkersSelect", "New Worker Selection"),
            create: pageId("Infrastructure.WorkersNew", "New Worker"),
            filtered: pageId("Infrastructure.WorkersFiltered", "Filtered Workers"),
            discover: pageId("Infrastructure.WorkersDiscover", "Discover Worker"),
        },
        workerMachine: {
            ...workerMachineLinks(),
        },
        workerPools: {
            root: pageId("Infrastructure.WorkerPools", "Worker Pools"),
            create: pageId("Infrastructure.WorkerPoolNew", "New Worker Pool"),
            filtered: pageId("Infrastructure.WorkerPoolsFiltered", "Filtered Worker Pools"),
        },
        workerPool: pageId("Infrastructure.WorkerPool", "Worker Pool"),
    };
};
