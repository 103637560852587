import BasicRepository, { AllArgs, ListArgs } from "./basicRepository";
import Client from "../client";
import { LibraryVariableSetResource, VariableSetContentType } from "../resources/libraryVariableSetResource";
import LibraryVariableSetUsageResource from "client/resources/libraryVariableSetUsageResource";

type LibraryVariableAllArgs = {
    contentType?: VariableSetContentType;
} & AllArgs;

type LibraryVariableListArgs = {
    contentType?: VariableSetContentType;
} & ListArgs;

class LibraryVariableRepository extends BasicRepository<LibraryVariableSetResource, LibraryVariableSetResource, LibraryVariableListArgs, LibraryVariableAllArgs> {
    constructor(client: Client) {
        super("LibraryVariables", client);
    }

    getUsages(libraryVariableSet: LibraryVariableSetResource): Promise<LibraryVariableSetUsageResource> {
        return this.client.get(libraryVariableSet.Links["Usages"]);
    }
}

export default LibraryVariableRepository;
