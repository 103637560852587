import React = require("react");
import ExternalLink from "components/Navigation/ExternalLink";
const image = require("../../Images/DeploymentProcess.svg");
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
import { TopicsContainer, LearnMoreResources } from "./CommonViews";
import ImageWithPlaceholder from "components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";

export const CommonProjectProcessOverview: React.StatelessComponent<{}> = props => {
    return (
        <OverviewContainer>
            <OverviewHighlightText>
                <p>
                    The <ExternalLink href="OnboardingDeploymentProcessLearnMore">deployment process</ExternalLink> is like a recipe for deploying your software. You define the recipe by adding steps and variables to a project. Each step contains a
                    specific action (or set of actions) that is executed as part of the deployment process each time your software is deployed.
                </p>
                <ImageWithPlaceholder src={image} alt={"Deployment process"} />
            </OverviewHighlightText>
        </OverviewContainer>
    );
};

export const CommonProjectProcessTopics: React.StatelessComponent<{}> = props => {
    return (
        <TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="OnboardingDeploymentProcessLearnMore">Deployment Process</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>
    );
};
