import { WorkerPoolResource } from "../../../../../client/resources/index";
import * as React from "react";
import { ExpandableFormSection, Summary, Select, Note } from "components/form";
import CommonSummaryHelper from "utils/CommonSummaryHelper";
import { CloudRegionEndpointResource } from "client/resources/endpointResource";
import { CategorizedEndpointRegistration, CommunicationStyle, CategoryDefinition, EndpointRegistrationKey } from "./endpointRegistry";
import { EndpointThumbnail } from "./EndpointThumbnail";
import EndpointCard from "./EndpointCard";
import ExternalLink from "components/Navigation/ExternalLink";

interface CloudRegionEndpointProps {
    endpoint: CloudRegionEndpointResource;
    workerPools: WorkerPoolResource[];
    onChange(newValue: CloudRegionEndpointResource): void;
}

class CloudRegionEndpoint extends React.Component<CloudRegionEndpointProps> {
    constructor(props: CloudRegionEndpointProps) {
        super(props);
    }

    render() {
        return (
            <div>
                {this.props.workerPools.length > 1 && (
                    <ExpandableFormSection
                        errorKey={"DefaultWorkerPool"}
                        title="Worker Pool"
                        summary={this.props.endpoint.DefaultWorkerPoolId ? CommonSummaryHelper.resourceSummary(this.props.endpoint.DefaultWorkerPoolId, this.props.workerPools, "worker pool") : Summary.placeholder("No pool selected - default pool")}
                        help="Select a default pool for this target (optional)."
                    >
                        <Select
                            label={"Select a default pool"}
                            items={this.props.workerPools.map(e => ({ value: e.Id, text: e.Name }))}
                            value={this.props.endpoint.DefaultWorkerPoolId}
                            allowFilter={true}
                            allowClear={true}
                            onChange={x => this.props.onChange({ ...this.props.endpoint, DefaultWorkerPoolId: x })}
                        />
                    </ExpandableFormSection>
                )}
            </div>
        );
    }
}

export default CloudRegionEndpoint;

const cloudRegionEndpointImage = require("./cloud-region.svg");
const cloudRegionEndpointRegistration: CategorizedEndpointRegistration = {
    key: EndpointRegistrationKey.CloudRegion,
    displayOrder: 100,
    communicationStyle: CommunicationStyle.None,
    name: "Cloud Region",
    categories: [
        {
            category: "Cloud Region",
            title: (
                <React.Fragment>
                    Let's setup a <strong>Cloud Region</strong> target.
                </React.Fragment>
            ),
            help: (
                <Note>
                    Learn more about <ExternalLink href="CloudRegionTargets">Cloud Regions</ExternalLink>
                </Note>
            ),
            displayOrder: 100,
        },
    ],
    renderCard: ({ registration, category, getNavigationProps }) => (
        <EndpointCard logo={<EndpointThumbnail src={cloudRegionEndpointImage} alt={registration.name} />} header={registration.name} description="Execute steps multiple times with variables scoped for each iteration." {...getNavigationProps()} />
    ),
};

export { cloudRegionEndpointRegistration };
