import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonProjectView, CommonProjectTopics } from "../../CommonComponents/CommonProjectViews";

export const ProjectRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().root,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectView />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectTopics />;
    },
};
