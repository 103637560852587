import * as React from "react";
import DataBaseComponent, { DataBaseComponentState } from "components/DataBaseComponent";
import OkDialogLayout from "components/DialogLayout/OkDialogLayout";
import Callout, { CalloutType } from "components/Callout";
import { client } from "clientInstance";
import { ConnectivityCheckResponse } from "client/resources/dynamicFormResources";
const styles = require("./style.less");

interface DynamicConnectivityCheckDialogProps {
    title: string;
    url: string;
    values: { [key: string]: any };
    onOkClick: () => void;
}

interface DynamicConnectivityCheckDialogState extends DataBaseComponentState {
    checkComplete?: boolean;
}

export default class DynamicConnectivityCheckDialog extends DataBaseComponent<DynamicConnectivityCheckDialogProps, DynamicConnectivityCheckDialogState> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            client
                .post<ConnectivityCheckResponse>(`${this.props.url}`, this.props.values)
                .then(resp => {
                    this.setState({
                        checkComplete: true,
                        errors: resp.WasSuccessful ? null : { message: `${this.props.title} verification failed`, details: resp.ErrorMessages, fieldErrors: {} },
                    });
                })
                .catch(err => {
                    this.setState({
                        checkComplete: true,
                        errors: { message: err.ErrorMessage, details: err.Errors, fieldErrors: {} },
                    });
                });
        });
    }

    render() {
        return (
            <OkDialogLayout title={`Verifying ${this.props.title}`} hideCancel={true} busy={!this.state.checkComplete || this.state.busy} errors={this.state.errors} onOkClick={this.props.onOkClick}>
                {this.state.checkComplete && !this.state.errors && (
                    <Callout title="Success" type={CalloutType.Success}>
                        <div className={styles.testingSuccess}>
                            <span>{this.props.title} was verified successfully.</span>
                        </div>
                    </Callout>
                )}
            </OkDialogLayout>
        );
    }
}
