import * as React from "react";
import { ExpandableFormSection, Summary, ExpansionButtons, required } from "components/form";
import Text from "components/form/Text/Text";
import { LibraryVariableSetResource } from "client/resources/libraryVariableSetResource";
import Markdown from "components/Markdown/index";
import MarkdownEditor from "components/form/MarkdownEditor/MarkdownEditor";
import { Errors } from "components/DataBaseComponent";

interface VariableSetSettingsProps {
    errors: Errors;
    libraryVariableSet: LibraryVariableSetResource;
    onLibraryVariableSetChanged(libraryVariableSet: LibraryVariableSetResource): void;
}

export default class VariableSetSettings extends React.Component<VariableSetSettingsProps> {
    render() {
        return (
            <div>
                <ExpandableFormSection
                    errorKey="name"
                    title="Name"
                    focusOnExpandAll
                    summary={this.props.libraryVariableSet.Name ? Summary.summary(this.props.libraryVariableSet.Name) : Summary.placeholder("Please enter a name for the variable set")}
                    help="Enter a name for the variable set"
                >
                    <Text label="Name" value={this.props.libraryVariableSet.Name} validate={required("Please enter a name")} onChange={Name => this.props.onLibraryVariableSetChanged({ ...this.props.libraryVariableSet, Name })} />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="description"
                    title="Description"
                    summary={this.props.libraryVariableSet.Description ? Summary.summary(<Markdown markup={this.props.libraryVariableSet.Description} />) : Summary.placeholder("No description provided")}
                    help="Enter a description for the variable set"
                >
                    <MarkdownEditor value={this.props.libraryVariableSet.Description} label="Variable Set Description" onChange={Description => this.props.onLibraryVariableSetChanged({ ...this.props.libraryVariableSet, Description })} />
                </ExpandableFormSection>
            </div>
        );
    }
}
