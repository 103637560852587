import * as React from "react";
const styles = require("./style.less");
import VariableCell from "areas/variables/VariableCell/VariableCell";

interface VariableNameAndDescriptionCellProps {
    name: React.ReactNode;
    description: React.ReactNode | undefined;
}

const VariableNameAndDescriptionCell: React.SFC<VariableNameAndDescriptionCellProps> = props => {
    return (
        <div className={styles.container}>
            <div className={styles.name}>{props.name}</div>
            {props.description && (
                <div className={styles.description}>
                    <VariableCell>{props.description}</VariableCell>
                </div>
            )}
        </div>
    );
};

export default VariableNameAndDescriptionCell;
