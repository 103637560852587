import * as URI from "urijs";

export function getQueryFromUrl(url: () => string) {
    return <T = string>(name: string, callback: (value: string) => T): T => {
        const { relative } = getRelativeAndAbsoluteUris(url);
        let result = null;

        relative.hasQuery(name, (value: any) => {
            result = callback(value);
        });

        return result;
    };
}

export const getQueryFromWindow = getQueryFromUrl(() => window.location.toString());

export function getUpdatedQuery(url: () => string) {
    return (name: string, value: string) => {
        const { absolute, relative } = getRelativeAndAbsoluteUris(url);
        relative.setQuery(name, value);
        return absolute.hash(relative.toString()).toString();
    };
}

export const getUpdatedWindowQuery = getUpdatedQuery(() => window.location.toString());

export function replaceHistory(nextPath: () => string) {
    const path = nextPath();
    window.history.replaceState({ path }, "", path);
}

export function pushHistory(nextPath: () => string) {
    const path = nextPath();
    window.history.pushState({ path }, "", path);
}

function getRelativeAndAbsoluteUris(url: () => string) {
    const absolute = new URI(url());
    return {
        absolute,
        relative: new URI(absolute.fragment()),
    };
}
