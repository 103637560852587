import { repository, session } from "clientInstance";
import { CertificateResource, ResourceCollection } from "client/resources";

//let certificatesAsync: Promise<ResourceCollection<CertificateResource>>;
let certificatesAsync: Promise<CertificateResource[]>;

//const getAll = (): Promise<ResourceCollection<CertificateResource>> => {
const getAll = (): Promise<CertificateResource[]> => {
    certificatesAsync = repository.Certificates.all();
    return certificatesAsync;
};

interface CertificateIndex {
    [certificateId: string]: string;
}

const getCertificateIndex = async (): Promise<CertificateIndex> => {
    const idx: { [certificateId: string]: string } = {};
    const certificates = await getAll();
    if (certificates) {
        certificates.forEach(cert => (idx[cert.Id] = cert.Name));
    }
    return idx;
};

export { getAll, getCertificateIndex, CertificateIndex };
