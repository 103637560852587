import React = require("react");
import ExternalLink from "components/Navigation/ExternalLink";
import { LearnMoreResources, TopicsContainer } from "./CommonViews";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
const HelpImageWorkerPool = require("../../Images/WorkerPool.svg");
import ImageWithPlaceholder from "components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";

export const CommonWorkerPoolOverview: React.StatelessComponent<{}> = props => {
    return (
        <OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="WorkerPools">Worker pools</ExternalLink> allow you to organize workers into groups. One pool might be in a particular network security zone. Another pool might have a specific set of tools installed.
                </p>
                <ImageWithPlaceholder src={HelpImageWorkerPool} alt={"Worker Pool"} />
            </OverviewHighlightText>
        </OverviewContainer>
    );
};

export const CommonWorkerPoolTopics: React.StatelessComponent<{}> = props => {
    return (
        <TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="OnboardingWorkerPoolsLearnMore">Worker Pools</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>
    );
};
