import * as React from "react";
import InfrastructureLayout from "../InfrastructureLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { repository } from "clientInstance";
import { AccountResource, AccountType } from "client/resources/accountResource";
import { sortBy, uniq } from "lodash";
import PaperLayout from "components/PaperLayout/PaperLayout";
import MarkdownDescription from "components/MarkdownDescription";
import { environmentChipListIncludingMissing, tenantChipListIncludingMissing } from "../../../../components/Chips/index";
import { EnvironmentResource } from "client/resources/environmentResource";
import { TenantResource } from "../../../../client/resources/tenantResource";
import { SimpleList } from "components/List/SimpleList";
import ListTitle from "components/ListTitle/ListTitle";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import routeLinks from "../../../../routeLinks";
import { AccountTypeDetailsMap } from "../../InfrastructureDetails";
const styles = require("./style.less");
import * as _ from "lodash";
import { RaisedButton, Popover, Menu, MenuItem } from "material-ui";
import { white, success } from "theme/colors";
import InternalLink from "components/Navigation/InternalLink";
import { convertQueryToQueryString } from "components/QueryStringFilters/QueryStringFilters";
import { FormSectionHeading, Note } from "components/form";
import Onboarding from "./Onboarding";
import { RaisedButtonProps } from "material-ui";

const addAccountButtonStyle: Partial<RaisedButtonProps> = {
    labelColor: white,
    backgroundColor: success,
    labelStyle: {
        fontSize: "0.8125rem",
        whiteSpace: "nowrap",
    },
};

const popoverStyle = {
    backgroundColor: success,
    color: white,
};

const menuItemStyle = {
    color: white,
};

class AccountList extends SimpleList<AccountResource> {}

interface AccountsLayoutState extends DataBaseComponentState {
    accounts: AccountResource[];
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    open: boolean;
    anchor?: any;
}

export default class AccountsLayout extends DataBaseComponent<any, AccountsLayoutState> {
    constructor(props: any) {
        super(props);
        this.state = {
            accounts: null,
            environments: null,
            tenants: null,
            open: false,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const accounts = await repository.Accounts.all();
            const envIds = uniq(accounts.reduce((list, acc) => list.concat(acc.EnvironmentIds), []));
            const tenantIds = uniq(accounts.reduce((list, acc) => list.concat(acc.TenantIds), []));

            this.setState({
                accounts,
                environments: await repository.Environments.all({ ids: envIds }),
                tenants: await repository.Tenants.all({ ids: tenantIds }),
            });
        });
    }

    renderEnvironments = (account: AccountResource) => {
        if (account.EnvironmentIds.length === 0) {
            return null;
        }

        return <div>{environmentChipListIncludingMissing(this.state.environments, account.EnvironmentIds)}</div>;
    };

    renderAccountTypes(types: AccountType[], name: string, icon: JSX.Element, description: JSX.Element) {
        let accounts = this.state.accounts.filter(account => types.indexOf(account.AccountType) !== -1);
        accounts = sortBy(accounts, account => account.Name);
        if (!accounts || accounts.length === 0) {
            return null;
        }

        const title = (
            <div className={styles.typeHeader}>
                {icon}
                <div className={styles.typeHeaderTitleContainer}>{name}</div>
            </div>
        );
        return (
            <React.Fragment>
                <FormSectionHeading title={title} />
                <div className={styles.typeBody}>
                    <AccountList items={accounts} onRow={this.renderRow} onRowRedirectUrl={(account: AccountResource) => routeLinks.infrastructure.account(account.Id)} />
                </div>
            </React.Fragment>
        );
    }

    renderRow = (account: AccountResource) => {
        return (
            <div key={account.Id} className={styles.account}>
                <ListTitle>{account.Name}</ListTitle>
                {environmentChipListIncludingMissing(this.state.environments, account.EnvironmentIds)}
                {tenantChipListIncludingMissing(this.state.tenants, account.TenantIds)}
                <MarkdownDescription markup={account.Description} />
            </div>
        );
    };

    renderContent = () => {
        const accountTypeDetailsMap = AccountTypeDetailsMap();
        return (
            <div>
                {_.chain(accountTypeDetailsMap.map(account => this.renderAccountTypes(account.types, account.namePlural, account.icon, account.description)))
                    // flatten the nested array
                    .flatten()
                    // remove empty elements
                    .filter(x => x !== undefined)
                    .value()}
            </div>
        );
    };

    render() {
        const accountTypeDetailsMap = AccountTypeDetailsMap();
        const addAccountButton = (
            <PermissionCheck permission={Permission.AccountCreate} wildcard={true}>
                <RaisedButton {...addAccountButtonStyle} label="Add Account" labelPosition="before" icon={<i className="fa fa-caret-down" style={{ color: white }} />} onClick={this.handleTouchTap}>
                    <Popover
                        open={this.state.open}
                        style={popoverStyle}
                        canAutoPosition={false}
                        anchorEl={this.state.anchor}
                        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                        targetOrigin={{ horizontal: "right", vertical: "top" }}
                        onRequestClose={this.handleRequestClose}
                    >
                        <Menu maxHeight={16 * 40}>
                            {accountTypeDetailsMap.map(accountTypeDetails => {
                                if (accountTypeDetails.types.length === 0) {
                                    return null;
                                }
                                const accountCreateRoute = {
                                    pathname: routeLinks.infrastructure.accounts.create,
                                    search: convertQueryToQueryString({ accountType: accountTypeDetails.types[0] }),
                                };
                                return (
                                    <InternalLink key={`add-new-account-${accountTypeDetails.name}`} to={accountCreateRoute}>
                                        <MenuItem primaryText={`${accountTypeDetails.name}`} style={menuItemStyle} />
                                    </InternalLink>
                                );
                            })}
                        </Menu>
                    </Popover>
                </RaisedButton>
            </PermissionCheck>
        );

        return (
            <InfrastructureLayout {...this.props}>
                <PaperLayout busy={this.state.busy} errors={this.state.errors} title="Accounts" sectionControl={addAccountButton}>
                    {!this.state.accounts || (this.state.accounts.length === 0 && <Onboarding />)}
                    {this.state.accounts && this.state.accounts.length > 0 && <React.Fragment>{this.renderContent()}</React.Fragment>}
                </PaperLayout>
            </InfrastructureLayout>
        );
    }

    handleTouchTap = (event: any) => {
        event.preventDefault();
        this.setState({
            open: true,
            anchor: event.currentTarget,
        });
    };

    handleRequestClose = () => {
        this.setState({
            open: false,
        });
    };
}
