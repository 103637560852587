import Resource from "./resource";

export abstract class NamedResource extends Resource {
    Name: string;
}

export abstract class NewNamedResource {
    Name: string;
}

export default NamedResource;
