import * as React from "react";
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import { onExpanderStateChanged } from "components/form/Sections/reducers/expanders";

export interface GlobalDispatchControlExpandersProps {
    setExpanderState(key: string, open: boolean): void;
}

// HOC that adds an openExpander prop for direct control over expanders
export function ControlExpanders<Props extends unknown>(Comp: React.ComponentType<Props & GlobalDispatchControlExpandersProps>) {
    type ExternalProps = Props & Partial<GlobalDispatchControlExpandersProps>;
    const mapGlobalActionDispatchersToProps = (dispatch: Dispatch<Action<any>>) => {
        return {
            setExpanderState: (key: string, open: boolean) => dispatch(onExpanderStateChanged(null, key, open)),
        };
    };

    const BaseComponent = Comp as React.ComponentType<GlobalDispatchControlExpandersProps>;

    return (connect(
        null,
        mapGlobalActionDispatchersToProps
    )(BaseComponent) as any) as React.ComponentClass<ExternalProps>;
}
