import NamedResource from "./namedResource";

export enum WorkerPoolType {
    Static = "StaticWorkerPool",
    Dynamic = "DynamicWorkerPool",
}

export class WorkerPoolsSupportedTypes {
    SupportedPoolTypes: WorkerPoolType[];
}

export class DynamicWorkerType {
    Id: string;
    Description: string;
}
