import * as React from "react";
import { Switch } from "react-router-dom";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import { Channels } from "./Channels";
import { Edit } from "./Edit";
import pageIds from "pageIds";
import { withPage } from "components/Page/Page";

export const EditChannelPage = withPage({ page: pageIds.project().channel })(Edit);
export const CreateChannelPage = withPage({ page: pageIds.project().channelNew })(Edit);
export const ChannelsPage = withPage({ page: pageIds.project().channel })(Channels);

export class ChannelsRoute extends React.Component<any, any> {
    render() {
        const match = this.props.match;
        return (
            <Switch>
                <ReloadableRoute path={`${match.path}/edit/:channelId`} component={EditChannelPage} />
                <ReloadableRoute path={`${match.path}/create`} render={routeProps => <CreateChannelPage create {...routeProps} />} />
                <ReloadableRoute path={`${match.path}`} component={ChannelsPage} />
            </Switch>
        );
    }
}
