import * as React from "react";
import Callout, { CalloutType } from "components/Callout";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";

type RestrictedPermissionsCalloutProps = { isVisible: boolean };
export const RestrictedPermissionsCallout: React.FC<RestrictedPermissionsCalloutProps> = ({ isVisible }) => (
    <>
        {isVisible && (
            <Callout title="Permissions are restricted" type={CalloutType.Warning}>
                Your current license has restricted permissions, which means that all users are able to do everything in the system. Any changes you make to teams and roles will be overriden by this restriction. You can read more about
                <ExternalLink href="RestrictedPermissions"> restricted permissions</ExternalLink>
                on our website.
            </Callout>
        )}
    </>
);

export default RestrictedPermissionsCallout;
