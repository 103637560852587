import Resource from "./resource";

export class SystemInfoResource extends Resource {
    Version: string;
    OSVersion: string;
    WorkingSetBytes: number;
    ClrVersion: string;
    ThreadCount: number;
    Uptime: string;
}

export default SystemInfoResource;
