import { combineReducers } from "redux";

export const OverlayActions = {
    OVERLAY_UPDATED: "OVERLAY_UPDATED",
};

export interface OverlayAreaState {
    config: OverlayState;
}

export interface OverlayState {
    tipsAvailable: boolean;
    showTips: boolean;
}

export function overlayStateUpdated(state: OverlayState) {
    return { type: OverlayActions.OVERLAY_UPDATED, state };
}

const updateConfig = (state: OverlayState, patch: Partial<OverlayState>) => (state ? { ...state, ...patch } : state);
const INITIAL_STATE: OverlayState = { showTips: false, tipsAvailable: false };

const overlayConfig = (state: OverlayState = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case OverlayActions.OVERLAY_UPDATED:
            const config = action.state as OverlayState;
            return updateConfig(state, config);
        default:
            return state;
    }
};

const overlayArea = combineReducers({ config: overlayConfig });

export default overlayArea;
