import * as React from "react";
import { UserResource } from "client/resources/userResource";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent/FormBaseComponent";
import { session, repository } from "clientInstance";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import { Section } from "components/Section/Section";
import { Text } from "components/form";
import { required } from "components/form/Validators";

interface UserEditModel {
    original: UserResource;
    password?: string;
    confirmPassword?: string;
}

interface ChangePasswordProps {
    userId: string;
}

interface UserEditState extends OptionalFormBaseComponentState<UserEditModel> {
    user: UserResource;
    dontMatch: string | null;
}

export default class UserChangePasswordDialog extends FormBaseComponent<ChangePasswordProps, UserEditState, UserEditModel> {
    constructor(props: ChangePasswordProps) {
        super(props);

        this.state = {
            user: null,
            model: null,
            cleanModel: null,
            dontMatch: null,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const user = this.props.userId ? await repository.Users.get(this.props.userId) : null;

            this.setState({
                user,
                model: this.buildModel(user),
                cleanModel: this.buildModel(user),
            });
        });
    }

    buildModel(user: UserResource): UserEditModel {
        return user
            ? {
                  original: user,
              }
            : {
                  original: null,
              };
    }

    handleSaveClick = async () => {
        const user: UserResource = {
            ...this.state.user,
            Password: this.state.model.password,
        };

        if (!this.isConfirmPasswordCorrect()) {
            this.setState({
                dontMatch: "The passwords do not match",
            });

            return false;
        }

        return this.doBusyTask(async () => {
            const result = await repository.Users.save(user);

            this.setState({
                cleanModel: this.buildModel(user),
                model: this.buildModel(user),
            });
        });
    };

    render() {
        return (
            this.state.model && (
                <SaveDialogLayout onSaveClick={() => this.handleSaveClick()} busy={this.state.busy} errors={this.state.errors} title={this.isSelf() ? "Change Your Password" : "Change Password for This User"}>
                    <Section>
                        <Text value={this.state.model.password} type={"password"} onChange={password => this.setModelState({ password })} label="New password" autoFocus />
                    </Section>
                    <Section>
                        <Text
                            value={this.state.model.confirmPassword}
                            type={"password"}
                            onChange={confirmPassword => this.setModelState({ confirmPassword })}
                            label="Confirm new password"
                            error={this.state.dontMatch}
                            validate={this.isConfirmRequired()}
                        />
                    </Section>
                </SaveDialogLayout>
            )
        );
    }

    private isConfirmPasswordCorrect() {
        if (!this.state.model.password) {
            return true;
        }

        return this.state.model.password && this.state.model.password.localeCompare(this.state.model.confirmPassword) === 0;
    }

    private isConfirmRequired() {
        return this.isConfirmPasswordCorrect() ? null : required("Passwords don't match");
    }

    private isSelf() {
        return session.currentUser.Id === this.props.userId;
    }
}
