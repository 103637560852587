import * as React from "react";
import { ActionTemplateParameterResource } from "client/resources/actionTemplateParameterResource";
import ActionTemplateParameterList from "components/ActionTemplateParametersList/ActionTemplateParametersList";
import { Permission, ControlType } from "client/resources";

import { Section } from "components/Section/Section";

interface VariableSetTemplatesProps {
    parameters: ActionTemplateParameterResource[];
    onParametersChanged(parameters: ActionTemplateParameterResource[]): void;
}

export default class VariableSetTemplates extends React.Component<VariableSetTemplatesProps> {
    render() {
        return (
            <Section>
                <ActionTemplateParameterList
                    parameters={this.props.parameters}
                    name="template"
                    excludedControlTypes={[ControlType.StepName]}
                    editPermission={{
                        permission: Permission.VariableEdit,
                        wildcard: true,
                    }}
                    onParametersChanged={parameters => this.props.onParametersChanged(parameters)}
                />
            </Section>
        );
    }
}
