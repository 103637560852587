import * as React from "react";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { ActionEditProps } from "components/Actions/pluginRegistry";
import Note from "components/form/Note/Note";
import { VariableLookupText } from "components/form/VariableLookupText";
import { BoundSelect } from "components/form/Select/Select";
import { WildflyCertificateProperties } from "components/Actions/wildfly/wildflyCertificateProperties";
import { BoundSensitive } from "components/form/Sensitive/Sensitive";

/**
 * A component that displays the details of a keystore to reference
 */
export default abstract class WildflyRelativeLocationComponent extends BaseComponent<ActionEditProps<WildflyCertificateProperties>, never> {
    render() {
        const properties = this.props.properties;
        return (
            <div>
                <VariableLookupText
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    value={properties["Java.Certificate.KeystoreFilename"]}
                    onChange={x => this.props.setProperties({ ["Java.Certificate.KeystoreFilename"]: x })}
                    label="Keystore Filename"
                    error={this.props.getFieldError("Java.Certificate.KeystoreFilename")}
                />
                <Note>
                    The path to the existing keystore file. This can be an absolute file path, or can be relative to one of the standard base paths defined by the application server, which can be selected in the <em>Relative base path</em> list.
                </Note>
                <BoundSelect
                    variableLookup={{
                        localNames: this.props.localNames,
                        projectId: this.props.projectId,
                    }}
                    resetValue={"NONE"}
                    value={properties["WildFly.Deploy.CertificateRelativeTo"]}
                    items={this.getBasePaths()}
                    label="Relative base path"
                    onChange={x => this.props.setProperties({ ["WildFly.Deploy.CertificateRelativeTo"]: x })}
                />
                <Note>
                    This defines the relative path that the keystore file will be resolved from. Select <em>No relative path base</em> if the keystore filename is an absolute path.
                </Note>
                <BoundSensitive
                    variableLookup={{
                        localNames: this.props.localNames,
                        projectId: this.props.projectId,
                    }}
                    resetValue={""}
                    value={properties["Java.Certificate.Password"]}
                    onChange={x => this.props.setProperties({ ["Java.Certificate.Password"]: x })}
                    label="Private Key Password"
                />
                <Note>
                    You can set an optional password which will be assigned to the private key. If this field is left blank, the default password of <em>changeit</em> will be used.
                </Note>
                <VariableLookupText
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    value={properties["Java.Certificate.KeystoreAlias"]}
                    onChange={x => this.props.setProperties({ ["Java.Certificate.KeystoreAlias"]: x })}
                    label="Keystore Alias"
                />
                <Note>
                    The optional alias to assign the private key to. If left blank, the default alias of <em>Octopus</em> will be used
                </Note>
            </div>
        );
    }

    private getBasePaths() {
        const commonItems = [
            { text: "none: Keystore Filename is an absolute path", value: "NONE" },
            { text: "jboss.home.dir: Root directory of the application server distribution", value: "jboss.home.dir" },
            { text: "user.home: Application server user's home directory", value: "user.home" },
            { text: "user.dir: Application server user's current working directory", value: "user.dir" },
            { text: "java.home: Java installation directory", value: "java.home" },
        ];
        if (this.props.properties["WildFly.Deploy.ServerType"] === "Domain") {
            commonItems.push(
                { text: "jboss.domain.base.dir: Base directory for domain content.", value: "jboss.domain.base.dir" },
                { text: "jboss.domain.config.dir: Directory that contains the domain configuration.", value: "jboss.domain.config.dir" },
                { text: "jboss.domain.data.dir: Directory that the domain will use for persistent data file storage.", value: "jboss.domain.data.dir" },
                { text: "jboss.domain.log.dir: Directory that the domain will use for persistent log file storage.", value: "jboss.domain.log.dir" },
                { text: "jboss.domain.temp.dir: Directory that the domain will use for temporary file storage.", value: "jboss.domain.temp.dir" },
                { text: "jboss.domain.deployment.dir: Directory that the domain will use for storing deployed content.", value: "jboss.domain.deployment.dir" },
                {
                    text: "jboss.domain.servers.dir: Directory that the domain will use for storing outputs of the managed domain instances.",
                    value: "jboss.domain.servers.dir",
                }
            );
        } else {
            commonItems.push(
                { text: "jboss.server.base.dir: Root directory for an individual server instance", value: "jboss.server.base.dir" },
                { text: "jboss.server.config.dir: Directory the server will use for configuration file storage", value: "jboss.server.config.dir" },
                { text: "jboss.server.data.dir: Directory the server will use for persistent data file storage", value: "jboss.server.data.dir" },
                { text: "jboss.server.log.dir: Directory the server will use for log file storage", value: "jboss.server.log.dir" },
                { text: "jboss.server.temp.dir: Directory the server will use for temporary file storage", value: "jboss.server.temp.dir" },
                { text: "jboss.controller.temp.dir: Directory the server will use for temporary file storage", value: "jboss.controller.temp.dir" }
            );
        }
        return commonItems;
    }
}
