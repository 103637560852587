import { PackagingRegistration } from "../../Registry/packagingRegistry";

import BuildServerPackagingInstruction from "./BuildServerPackagingInstruction";
import ManualPackagingInstruction from "./ManualPackagingInstruction";
import CommandLinePackagingInstruction from "./CommandLinePackagingInstruction";

export const NodePackagingRegistration: PackagingRegistration = {
    displayOrder: 500,
    instructions: [BuildServerPackagingInstruction, ManualPackagingInstruction, CommandLinePackagingInstruction],
    name: "Node.js",
    id: "Node.js",
};

export default NodePackagingRegistration;
