import * as React from "react";
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import { defaultContainerKey, onExpanderCreated, onExpanderStateChanged, onAllExpandersCreated, ExpanderContainer } from "components/form/Sections/reducers/expanders";

interface StateProps {
    expanders: { [errorKey: string]: boolean | undefined };
}

interface GlobalDispatchProps {
    onExpandedChanged(key: string, isExpanded: boolean): void;
    registerAllExpanders(keys: string[]): void;
}

export type GroupedExpandableProps = StateProps & GlobalDispatchProps;

// HOC that hooks up a component that manages expansion for multiple child components
// to the redux store
export default function Expandable<Props extends any>(containerKey: string, Comp: React.ComponentClass<Props & GroupedExpandableProps>) {
    type InternalProps = StateProps & GlobalDispatchProps & Props;
    type ExternalProps = Omit<InternalProps, keyof GroupedExpandableProps>;

    class ExpandableInternal extends React.Component<InternalProps> {
        render() {
            return <Comp {...this.props} />;
        }
    }

    function mapGlobalStateToProps(state: GlobalState, props: InternalProps): StateProps {
        const container: ExpanderContainer | null = state.expanders && state.expanders.hasOwnProperty(containerKey) ? state.expanders[containerKey] : null;
        const storedValues: { [errorKey: string]: boolean | undefined | null } = container ? container.expanderValues : {};
        const initialState = container ? container.initialState : false;
        const expanders = Object.keys(storedValues).reduce((p: any, c) => {
            p[c] = getValueForKey(c);
            return p;
        }, {});

        function getValueForKey(errorKey: string) {
            const storedValue = storedValues[errorKey];
            return storedValue === undefined ? false : storedValue === null ? initialState : storedValue;
        }
        return { expanders };
    }

    function mapGlobalActionDispatchersToProps(dispatch: Dispatch<Action<any>>, props: InternalProps): GlobalDispatchProps {
        return {
            registerAllExpanders: (keys: string[]) => {
                dispatch(onAllExpandersCreated(containerKey, keys, false));
            },
            onExpandedChanged: (key: string, state: boolean) => {
                dispatch(onExpanderStateChanged(containerKey, key, state));
            },
        };
    }

    return (connect<StateProps, GlobalDispatchProps, Props>(
        mapGlobalStateToProps,
        mapGlobalActionDispatchersToProps
    )(ExpandableInternal) as any) as React.ComponentType<ExternalProps>;
}
