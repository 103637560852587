import * as React from "react";
import { List as MaterialList, ListItem } from "material-ui";
import { match } from "react-router-dom";
import PagingBaseComponent from "components/PagingBaseComponent";
import { PagingBaseProps, PagingBaseState, HasId } from "components/PagingBaseComponent";
import ListInternalLink from "components/Navigation/InternalLink/ListInternalLink";
const styles = require("./style.less");
import { hover } from "theme/colors";
import { getNavigationUrl } from "../PagingBaseComponent/PagingBaseComponent";
import { Section } from "components/Section/Section";
import Divider from "../Divider/Divider";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import { NoResults } from "components/NoResults/NoResults";
import { Note } from "components/form";

interface ListProps<R extends HasId> extends PagingBaseProps<R> {
    empty?: React.ReactNode;
    match?: match<any>;
    wrapRowsInListItem?: boolean;
}

interface ListState<R extends HasId> extends PagingBaseState<R> {
    redirectTo: string;
}

export abstract class List<R extends HasId> extends PagingBaseComponent<R, ListProps<R>, ListState<R>> {
    private empty: React.ReactNode = <NoResults />;

    constructor(props: ListProps<R>) {
        super(props);
        this.empty = props.empty || this.empty;
    }

    componentDidUpdate() {
        if (this.empty !== this.props.empty) {
            return (this.empty = this.props.empty);
        }
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true} />;
        }

        if (!this.state.data || !this.state.data.Items) {
            return <Section />;
        }

        return (
            <TransitionAnimation>
                {this.renderFilterSearchComponents()}
                {this.state.data.Items.length === 0 ? (
                    <Section>
                        <Note>{this.empty}</Note>
                    </Section>
                ) : (
                    <MaterialList>
                        {this.state.data.Items.filter((item: any) => this.onFilter(this.state.filter, item)).map((item: any, index: number) => this.buildItem(item, index))}
                        {this.state.data.Items.length < this.state.data.TotalResults ? (this.props.showPagingInNumberedStyle ? this.showPagingInNumberedStyle() : this.showPagingInLoadMoreStyle()) : null}
                    </MaterialList>
                )}
            </TransitionAnimation>
        );
    }

    private buildItem(item: R, index: number) {
        const redirectUrl = getNavigationUrl(this.props as any, item);
        let nodeRow = this.props.onRow(item);
        if (redirectUrl) {
            // Make these actual href links, so people can still right-click > open new window (this is expected usability for things you can click).
            nodeRow = <ListInternalLink to={redirectUrl}>{nodeRow}</ListInternalLink>;
        }
        const nodes: JSX.Element[] = [
            this.props.wrapRowsInListItem ? (
                <ListItem key={item.Id} className={styles.listItem} onClick={e => this.navigate(item)} hoverColor={hover}>
                    {nodeRow}
                </ListItem>
            ) : (
                <div onClick={e => this.navigate(item)}>{nodeRow}</div>
            ),
        ];

        if (index < this.state.data.Items.length - 1) {
            nodes.push(<Divider />);
        }

        return React.Children.toArray(nodes);
    }
}

(List as any).defaultProps = {
    showFilterWithinSection: true,
    autoFocusOnFilterSearch: true,
    wrapRowsInListItem: true,
};
