const sizes = ["B", "KB", "MB", "GB", "TB", "PB"];

function formattedFileSize(bytes: number): string {
    if (isNaN(bytes)) {
        return "";
    }
    if (bytes === 0) {
        return "0 bytes";
    }

    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return +(bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
}

export default formattedFileSize;
