import * as React from "react";
import { useProjectContext } from "../../context";
import { DeploymentStepResource, DeploymentProcessResource } from "client/resources";
import { useDoBusyTaskEffect, DoBusyTask } from "components/DataBaseComponent";
import { repository } from "clientInstance";
import { ActionScope } from "components/Actions/pluginRegistry";
import { withRouter, RouteComponentProps } from "react-router";
import { useRequiredContext } from "hooks";
import { isEqual } from "lodash";

export interface FilterNamedResource {
    Id?: string;
    Name?: string;
}

export interface DeploymentProcessEditorFilter {
    filterKeyword: string;
    environment?: FilterNamedResource | null;
    channel?: FilterNamedResource | null;
    includeUnscoped?: boolean;
}

const notEmpty = <TValue extends unknown>(value: TValue | null | undefined): value is TValue => {
    return value !== null && value !== undefined;
};

type FilteredStep = { step: DeploymentStepResource; index: number; filtered: boolean };
type FilterResult = {
    filterCount: number;
    steps: FilteredStep[];
};

const EMPTY_FILTER_RESULT: FilterResult = { filterCount: 0, steps: [] };

export function getEmptyFilter(): DeploymentProcessEditorFilter {
    return {
        filterKeyword: "",
        environment: null,
        channel: null,
        includeUnscoped: true,
    };
}

interface DeploymentProcessContextState {
    process: DeploymentProcessResource;
    filter: DeploymentProcessEditorFilter;
}

type DeploymentProcessContextProps = ReturnType<typeof useDeploymentProcess>;

export const DeploymentProcessContext = React.createContext<DeploymentProcessContextProps>(undefined);

export const useDeploymentProcessContext = () => {
    return useRequiredContext(DeploymentProcessContext, "Deployment Process");
};

const useDeploymentProcessState = (initialFilter: DeploymentProcessEditorFilter) => {
    return React.useState<DeploymentProcessContextState>({ filter: initialFilter, process: null });
};

const useFilteredSteps = (process: DeploymentProcessResource, filter: DeploymentProcessEditorFilter) => {
    return React.useMemo(() => getFilteredSteps(process, filter), [filter, process]);
};

const getStepContainsKeywordPredicate = (keyword: string = "") => {
    return (step: DeploymentStepResource) => {
        const keywordLower = keyword.toLowerCase();
        return step.Name.toLowerCase().includes(keywordLower) || step.Actions.filter(a => !!a.Name && a.Name.toLowerCase().includes(keywordLower)).length > 0;
    };
};

const getStepEnvironmentPredicate = (environment: FilterNamedResource, includeUnscoped: boolean = true) => {
    return (step: DeploymentStepResource) => {
        return step.Actions.some(
            a =>
                (includeUnscoped && a.Environments.length === 0 && !a.ExcludedEnvironments.some(e => e === environment.Id)) || // unscoped and not excluded
                (a.Environments.some(e => e === environment.Id) && !a.ExcludedEnvironments.some(e => e === environment.Id)) // scoped and not excluded
        );
    };
};

const getStepHasChannelPredicate = (channel: FilterNamedResource, includeUnscoped: boolean = true) => {
    return (step: DeploymentStepResource) => {
        return step.Actions.some(
            a =>
                (includeUnscoped && a.Channels.length === 0) || // unscoped
                a.Channels.some(e => e === channel.Id) // scoped
        );
    };
};

type StepPredicate = (step: DeploymentStepResource) => boolean;

export const getFilteredSteps = (deploymentProcess: DeploymentProcessResource, filter: DeploymentProcessEditorFilter = { filterKeyword: "" }) => {
    const { channel, environment, includeUnscoped, filterKeyword } = filter;
    const filters: StepPredicate[] = [
        getStepContainsKeywordPredicate(filterKeyword),
        filter.channel ? getStepHasChannelPredicate(channel, includeUnscoped) : null,
        filter.environment ? getStepEnvironmentPredicate(environment, includeUnscoped) : null,
    ].filter(notEmpty);

    return deploymentProcess
        ? deploymentProcess.Steps.reduce((prev, step, index) => {
              const shouldFilter = filters.every(predicate => predicate(step));
              return {
                  filterCount: shouldFilter ? prev.filterCount + 1 : prev.filterCount,
                  steps: [
                      ...prev.steps,
                      {
                          step,
                          index: index + 1,
                          filtered: shouldFilter,
                      },
                  ],
              };
          }, EMPTY_FILTER_RESULT)
        : EMPTY_FILTER_RESULT;
};

const useLoadDeploymentProcessEffect = (id: string, doBusyTask: DoBusyTask, onLoaded: (process: DeploymentProcessResource) => void) => {
    return useDoBusyTaskEffect(
        doBusyTask,
        async () => {
            const process = await repository.DeploymentProcesses.get(id);
            if (onLoaded) {
                onLoaded(process);
            }
        },
        [id]
    );
};

//We need to keep the process on the project context updated until we introduce the next screens and routes for ops processes
const useProjectContextProcessUpdate = (process: DeploymentProcessResource) => {
    const context = useProjectContext();
    React.useEffect(() => {
        context.actions.onDeploymentProcessUpdated(process);
    }, [process]);
};

const getStateUpdaters = (setState: React.Dispatch<React.SetStateAction<DeploymentProcessContextState>>) => {
    return {
        onFilterChange: (callback: (prev: DeploymentProcessEditorFilter) => DeploymentProcessEditorFilter) => setState(current => ({ ...current, filter: callback(current.filter) })),
        onClearFilter: () => setState(current => ({ ...current, filter: getEmptyFilter() })),
        onDeploymentProcessChange: (process: DeploymentProcessResource) =>
            setState(current => {
                return { ...current, process };
            }),
    };
};

const useDeploymentProcess = (id: string, doBusyTask: DoBusyTask, initialFilter?: DeploymentProcessEditorFilter) => {
    const [state, setState] = useDeploymentProcessState(initialFilter);
    const filteredSteps = useFilteredSteps(state.process, state.filter);
    const refreshDeploymentProcess = useLoadDeploymentProcessEffect(id, doBusyTask, process => setState(current => ({ ...current, process })));
    const updaters = getStateUpdaters(setState);
    useProjectContextProcessUpdate(state.process);

    const saveDeploymentProcess = async (process: DeploymentProcessResource): Promise<any> => {
        const success = await doBusyTask(async () => {
            const result = await repository.DeploymentProcesses.modify(process);
            //If for whatever reason we are modifying another deployment process i.e. cloning a step for example
            //then we really shouldn't try and update the current process for the context.
            if (process && state.process && state.process.Id === process.Id) {
                updaters.onDeploymentProcessChange(result);
            }
            return true;
        });
        if (!success) {
            // Any failures, revert changes
            const result = await repository.DeploymentProcesses.get(id);
            updaters.onDeploymentProcessChange(result);
            return false;
        }
    };

    return {
        state,
        setState,
        filteredSteps,
        isFiltering: !isEqual(state.filter, getEmptyFilter()),
        getEmptyFilter,
        actions: {
            ...updaters,
            refreshDeploymentProcess,
            saveDeploymentProcess,
        },
    };
};

interface DeploymentProcessContextProviderProps {
    initialFilter?: DeploymentProcessEditorFilter;
    id: string;
    doBusyTask: DoBusyTask;
    children: (renderProps: DeploymentProcessContextProps) => React.ReactNode;
}

export const DeploymentProcessContextProvider: React.FC<DeploymentProcessContextProviderProps> = ({ children, initialFilter = getEmptyFilter(), doBusyTask, id }) => {
    const value = useDeploymentProcess(id, doBusyTask, initialFilter);
    return <DeploymentProcessContext.Provider value={value}>{children(value)}</DeploymentProcessContext.Provider>;
};

export type WithDeploymentProcessContextInjectedProps = { deploymentProcessContext: DeploymentProcessContextProps };

export const withDeploymentProcessContext = <T extends unknown>(Component: React.ComponentType<T & WithDeploymentProcessContextInjectedProps>) => {
    const WithProjectContext: React.FC<T> = props => {
        const context = useDeploymentProcessContext();
        return <Component deploymentProcessContext={context} {...props} />;
    };
    return WithProjectContext;
};
