import { pageId } from "utils/pageId";

export default () => {
    return {
        tasks: {
            root: pageId("Tasks"),
            console: pageId("Tasks.ScriptConsole", "Script Console"),
        },
        task: () => {
            return {
                root: pageId("Task"),
                raw: pageId("Task.Raw", "Raw Task Log"),
            };
        },
    };
};
