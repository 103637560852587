import { RunCondition } from "client/resources/deploymentStepResource";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import { Note } from "components/form";
import { ExpandableFormSection, Summary } from "components/form";
import RadioButton from "components/form/RadioButton/RadioButton";
import RadioButtonGroup from "components/form/RadioButton/RadioButtonGroup";
import { VariableLookupText } from "components/form/VariableLookupText";
import * as React from "react";

class RunConditionRadioButtonGroup extends RadioButtonGroup<RunCondition> {}

const RunConditionText = {
    [RunCondition.Success]: "Success: only run when previous steps succeed",
    [RunCondition.Failure]: "Failure: only run when a previous step failed",
    [RunCondition.Always]: "Always run",
    [RunCondition.Variable]: "Variable: only run when the variable expression is true",
};

interface RunTriggerExpanderProps {
    isFirstStep: boolean;
    condition: RunCondition;
    variableExpression: string;
    localNames?: string[];
    projectId: string;
    onConditionChange(condition: RunCondition): void;
    onVariableExpressionChange(expression: string): void;
}

class RunTriggerExpander extends React.Component<RunTriggerExpanderProps> {
    shouldComponentUpdate(nextProps: RunTriggerExpanderProps) {
        return nextProps.condition !== this.props.condition || nextProps.variableExpression !== this.props.variableExpression;
    }

    render() {
        const conditions = this.props.isFirstStep ? [RunCondition.Success, RunCondition.Always, RunCondition.Variable] : [RunCondition.Success, RunCondition.Failure, RunCondition.Always, RunCondition.Variable];

        return (
            <ExpandableFormSection
                title="Run Condition"
                help="Limit when this step runs by setting a condition."
                errorKey="runcondition"
                summary={this.props.condition === RunCondition.Success ? Summary.default(RunConditionText[this.props.condition]) : Summary.summary(RunConditionText[this.props.condition])}
            >
                <RunConditionRadioButtonGroup value={this.props.condition} onChange={this.props.onConditionChange}>
                    {conditions.map(rc => (
                        <RadioButton value={rc} label={RunConditionText[rc]} key={rc} />
                    ))}
                </RunConditionRadioButtonGroup>
                {this.props.condition === RunCondition.Variable && (
                    <div>
                        <VariableLookupText localNames={this.props.localNames} projectId={this.props.projectId} label="Variable expression" value={this.props.variableExpression} onChange={this.props.onVariableExpressionChange} />
                        <Note>
                            Limit when this step runs by setting a boolean variable expression that will be evaluated at run-time. See the <ExternalLink href="VariableSubstitutionSyntax">Variable Substitution Syntax</ExternalLink> documentation for
                            examples.
                        </Note>
                    </div>
                )}
            </ExpandableFormSection>
        );
    }
}

export default RunTriggerExpander;
