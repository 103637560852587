import { pageId } from "utils/pageId";

export default () => ({
    tenants: pageId("Tenants"),
    tenant: {
        root: pageId("Tenants"),
        overview: pageId("Tenant.Overview", "Tenant Overview"),
        settings: pageId("Tenant.Settings", "Tenant Settings"),
        variables: pageId("Tenant.Variables", "Tenant Variables"),
    },
});
