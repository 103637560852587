import * as React from "react";
import Checkbox from "components/form/Checkbox/Checkbox";
import AuthProvider from "../AuthProvider";
import { client, repository, session } from "clientInstance";
import { AuthenticationProviderElement } from "client/authentication";
import { FeaturesConfigurationResource, UserPermissionSetResource, UserResource, LoginCommand, LoginState, OctopusError } from "client/resources";
import * as URI from "urijs";
import Logger from "client/logger";
import * as cn from "classnames";
import { Text, Note } from "components/form";
import { ActionButton, ActionButtonType } from "components/Button";
import Environment from "environment";
import loginStateCalculator from "./loginStateCalculator";
import ErrorPanel from "components/ErrorPanel/ErrorPanel";
import BaseComponent from "components/BaseComponent";
import ExternalLink from "components/Navigation/ExternalLink";
const styles = require("./style.less");
import PageTitleHelper from "utils/PageTitleHelper";
import { TextInput } from "components/form/Text/Text";
import InternalRedirect from "../../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../../routeLinks";
import { RouteComponentProps } from "react-router";
import { SpaceRouteParams } from "../../../components/Navbar/SpacesMenu";
import { Collapse } from "@material-ui/core";

const waitingForOctopusImagePath = require("resources/images/loading-image.svg");

interface SignInState {
    inProgressMessage: string;
    connectError?: OctopusError;
    isReady: boolean;
    redirectToReferrer: boolean;
    userName: string;
    password: string;
    rememberMe: boolean;
    loginState: LoginState;
    authenticationError?: AuthenticationError;
    showAccountTypeSelector: boolean;
    busyIndicator?: Promise<any>;
    shouldAutoSignIn: boolean;
    autoSignInProviderName: string;
    showFullErrorMessage: boolean;
}

interface AuthenticationError {
    ErrorMessage: string;
    Errors: string[];
}

type SignInProps = RouteComponentProps<void>;

export default class SignIn extends BaseComponent<SignInProps, SignInState> {
    private authenticationProviders: AuthenticationProviderElement[];
    private anyFormsAuthenticationProviders: boolean;
    private anyAuthenticationProviders: any | boolean;
    private formsProvidersWithLinks: AuthenticationProviderElement[];
    private anyNonFormsAuthenticationProviders: boolean;
    private nonFormsAuthenticationProviders: AuthenticationProviderElement[];
    private isGuestProviderEnabled: boolean;
    private passwordField: TextInput | null = null;

    constructor(props: SignInProps) {
        super(props);
        PageTitleHelper.setRootPageTitle();

        const { from } = this.props.location && this.props.location.state ? this.props.location.state : { from: { pathname: "/" } };

        this.state = {
            inProgressMessage: "Trying to connect to Octopus",
            isReady: false,
            redirectToReferrer: false,
            showAccountTypeSelector: false,
            shouldAutoSignIn: false,
            autoSignInProviderName: "",
            userName: "",
            password: "",
            rememberMe: false,
            loginState: this.calculateLoginState(from.pathname + (from.search || "")),
            showFullErrorMessage: false,
        };
    }

    async componentWillReceiveProps(nextProps: SignInProps) {
        await this.connectClient();
    }

    async componentDidMount() {
        await this.connectClient();
    }

    async connectClient() {
        await client.connect((progress: string, error: OctopusError) => {
            Logger.log("Progress: ", progress);
            Logger.log("Error: ", error);
            this.setState({ inProgressMessage: progress, connectError: error });
        });

        const alreadyAuthenticated = await this.continueIfAlreadyAuthenticated();
        if (!alreadyAuthenticated) {
            const browserURI = URI(window.location);
            browserURI.hasQuery("error", (error: any) => {
                if (!error) {
                    return;
                }
                this.setError(error);
            });

            await this.setupAuthenticationProviders();

            this.chromeAutoFillFix();
        }

        this.setState({ isReady: true });
    }

    render() {
        const { from } = this.props.location && this.props.location.state ? this.props.location.state : { from: { pathname: routeLinks.root } };

        if (!this.state.isReady) {
            return this.progressStatus();
        }

        if (this.state.redirectToReferrer) {
            return <InternalRedirect to={from} />;
        }

        return (
            <div className={styles.container}>
                {!this.anyAuthenticationProviders ? (
                    this.noAuthenticationProvidersEnabled()
                ) : (
                    <div>
                        {this.state.showAccountTypeSelector ? (
                            this.accountTypeSelection()
                        ) : (
                            <div>
                                <div className={styles.content}>
                                    <div className={styles.logo}>
                                        <div>
                                            <em className="fontoctopus-octopus" />
                                        </div>
                                        {!this.state.authenticationError && <div>{this.state.shouldAutoSignIn ? <h4>Signing in, please wait...</h4> : <h4>Welcome! Please sign in.</h4>}</div>}
                                    </div>
                                    {this.state.authenticationError && this.showError(this.state.authenticationError)}
                                    {(this.anyFormsAuthenticationProviders || this.formsProvidersWithLinks.length > 0) && (
                                        <div>
                                            {this.anyFormsAuthenticationProviders && (
                                                <form onSubmit={this.signIn} className={styles.form}>
                                                    <Text value={this.state.userName} label="Username" onChange={userName => this.setState({ userName })} autoFocus={true} name="userName" id="userName" />
                                                    <Text
                                                        label="Password"
                                                        textInputRef={ref => (this.passwordField = ref)}
                                                        value={this.state.password}
                                                        name="password"
                                                        id="password"
                                                        type="password"
                                                        onChange={password => this.setState({ password })}
                                                    />
                                                    <Checkbox label="Remember me on this computer" onChange={rememberMe => this.setState({ rememberMe })} value={this.state.rememberMe} className={styles.rememberMe} />
                                                    <ActionButton type={ActionButtonType.Primary} label="SIGN IN" busyLabel="SIGNING IN..." onClick={this.signIn} />
                                                </form>
                                            )}
                                            {this.formsProvidersWithLinks.map(p => (
                                                <div className={styles.externalAuthProvider} key={p.Name}>
                                                    <AuthProvider
                                                        provider={p}
                                                        shouldAutoSignIn={this.state.shouldAutoSignIn}
                                                        autoSignInProviderName={this.state.autoSignInProviderName}
                                                        loginState={this.state.loginState}
                                                        onError={this.onExternalAuthenticationProviderError}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    {this.anyNonFormsAuthenticationProviders && (
                                        <div className={styles.externalNonFormsProviders}>
                                            {this.nonFormsAuthenticationProviders.map(p => (
                                                <div className={styles.externalAuthProvider} key={p.Name}>
                                                    <AuthProvider
                                                        provider={p}
                                                        shouldAutoSignIn={this.state.shouldAutoSignIn}
                                                        autoSignInProviderName={this.state.autoSignInProviderName}
                                                        loginState={this.state.loginState}
                                                        onError={this.onExternalAuthenticationProviderError}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>

                                {this.isGuestProviderEnabled && (
                                    <div className={styles.guestProvider} onClick={this.signInAsGuest}>
                                        <a>I AM A GUEST</a>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }

    private setError(error: any) {
        if (!error) {
            return;
        }

        Logger.error(error);

        const authenticationError: AuthenticationError = {
            ErrorMessage: error.ErrorMessage || error.message || error.toString(),
            Errors: error.Errors || [],
        };

        this.setState({ authenticationError });
    }

    private progressStatus() {
        return (
            <>
                <div className={styles.loading}>
                    <div className={styles.inProgressMessage}>{this.state.inProgressMessage}</div>
                    <img src={waitingForOctopusImagePath} alt="Waiting" />
                    {this.state.authenticationError && this.showError(this.state.authenticationError)}
                    {this.state.connectError && (
                        <div className={styles.errorInfoPanel}>
                            <ActionButton onClick={this.toggleFullErrorMessage} label={this.state.showFullErrorMessage ? "Hide Details" : "Show Details"} type={ActionButtonType.Ternary} />
                        </div>
                    )}
                </div>

                <Collapse in={this.state.showFullErrorMessage} timeout="auto" unmountOnExit={true}>
                    {this.state.connectError && (
                        <div className={styles.errorInfoPanelDetails}>
                            <h3>{this.state.connectError.ErrorMessage}</h3>
                            <Note>
                                {this.state.connectError.Errors.length > 1 ? (
                                    <div>
                                        Errors:{" "}
                                        {this.state.connectError.Errors.map(x => (
                                            <li>{x}</li>
                                        ))}
                                    </div>
                                ) : (
                                    <div>{this.state.connectError.Errors[0]}</div>
                                )}
                                {this.state.connectError.FullException && <div>FullException: {this.state.connectError.FullException}</div>}
                            </Note>
                        </div>
                    )}
                </Collapse>
            </>
        );
    }

    private toggleFullErrorMessage = () => {
        this.setState({ showFullErrorMessage: !this.state.showFullErrorMessage });
    };

    private accountTypeSelection() {
        return (
            <div className={cn(styles.accountTypeSelection, styles.areas)}>
                <div className={styles.guestAccount} onClick={this.signInAsGuest}>
                    <div className={styles.guestAccountIcon} />
                    <h2>I am a guest</h2>
                </div>
                <div className={styles.authenticatedAccount} onClick={this.hideAccountTypeSelector}>
                    <div className={styles.authenticatedAccountIcon} />
                    <h2>I have an account</h2>
                </div>
            </div>
        );
    }

    private async setupAuthenticationProviders() {
        const document = await repository.Authentication.get();
        this.authenticationProviders = document.AuthenticationProviders;

        this.nonFormsAuthenticationProviders = this.authenticationProviders.filter(p => {
            return !p.FormsLoginEnabled && !this.isActiveDirectory(p) && p.IdentityType !== "Guest";
        });

        const formsAuthenticationProviders = this.authenticationProviders.filter(p => {
            return p.FormsLoginEnabled && p.IdentityType !== "Guest";
        });
        this.anyFormsAuthenticationProviders = formsAuthenticationProviders.length > 0;
        this.anyNonFormsAuthenticationProviders = this.nonFormsAuthenticationProviders.length > 0;

        this.formsProvidersWithLinks = this.authenticationProviders.filter(this.isActiveDirectory);

        this.anyAuthenticationProviders = this.anyFormsAuthenticationProviders || this.anyNonFormsAuthenticationProviders || this.formsProvidersWithLinks.length > 0;

        this.isGuestProviderEnabled = this.authenticationProviders.filter(p => p.IdentityType === "Guest").length > 0;

        const singleNonFormsAuthenticationProvider = !this.anyFormsAuthenticationProviders && this.nonFormsAuthenticationProviders.length + this.formsProvidersWithLinks.length === 1;
        const showAccountTypeSelector = this.isGuestProviderEnabled && !this.state.authenticationError;
        let shouldAutoSignIn = document.AutoLoginEnabled && !this.state.authenticationError && singleNonFormsAuthenticationProvider && !showAccountTypeSelector;
        let autoSignInProviderName = "";
        if (shouldAutoSignIn) {
            autoSignInProviderName = this.authenticationProviders[0].Name;
        }

        if (this.props.location.search) {
            const provider = await repository.Authentication.wasLoginInitiated(this.props.location.search);

            if (provider && provider.WasLoginInitiated) {
                shouldAutoSignIn = true;
                autoSignInProviderName = provider.ProviderName;
                if (provider.ProviderName === "Octopus - Guest") {
                    await this.signInAsGuest();
                }
            }
        }

        this.setState({ showAccountTypeSelector, shouldAutoSignIn, autoSignInProviderName });
    }

    private async authenticationSucceeded(user: UserResource) {
        await this.startSession(user);
        this.setState({ redirectToReferrer: true });
    }

    private authenticate = async (userName: string, password: string, rememberMe: boolean) => {
        try {
            Logger.log(`Attempting to sign in: ${userName}`);
            const loginCommand: LoginCommand = {
                Username: userName,
                Password: password,
                RememberMe: rememberMe,
                State: this.state.loginState,
            };
            const user = await repository.Users.signIn(loginCommand);

            await this.authenticationSucceeded(user);
        } catch (error) {
            this.setError(error);
        }
    };

    private async startSession(user: UserResource) {
        try {
            const features = await repository.FeaturesConfiguration.get();
            session.start(user, features);
        } catch (error) {
            Logger.log(error);

            const message = "The sign in succeeded but we failed to get the resultant permissions for this user account. ";

            const reason = error.StatusCode === 401 ? "This can happen if the Octopus authentication cookie is blocked." : "There was a problem communicating with the Octopus Server: " + error.ErrorMessage;

            const errorMessage = message + reason;

            this.setError(errorMessage);
        }
    }

    private isActiveDirectory(provider: AuthenticationProviderElement) {
        return provider.Name === "Active Directory";
    }

    private onExternalAuthenticationProviderError = (error: any) => {
        this.setError(error);
    };

    private signIn = async (e: any) => {
        e.preventDefault();

        const busyIndicator = this.authenticate(this.state.userName, this.state.password, this.state.rememberMe);
        this.setState({ busyIndicator });
        await busyIndicator;
    };

    private signInAsGuest = async () => {
        await this.authenticate("guest", "guest", true);
    };

    private hideAccountTypeSelector = () => {
        this.setState({ showAccountTypeSelector: false });
    };

    private async continueIfAlreadyAuthenticated() {
        try {
            const user = await repository.Users.getCurrent();
            Logger.log("User already authenticated");
            await this.authenticationSucceeded(user);
            return true;
        } catch (authenticationError) {
            Logger.log(authenticationError);
        }

        return false;
    }

    private noAuthenticationProvidersEnabled() {
        return (
            <div>
                There are no authentication providers enabled. Learn about enabling <ExternalLink href="AuthenticationProviders">authentication providers</ExternalLink>
            </div>
        );
    }

    private calculateLoginState(redirectToPath: string) {
        return loginStateCalculator(location.href, redirectToPath, Environment.isInDevelopmentMode());
    }

    private showError(authenticationError: AuthenticationError) {
        return (
            <div className={styles.authenticationError}>
                <ErrorPanel message={authenticationError.ErrorMessage} details={authenticationError.Errors} />
            </div>
        );
    }

    private chromeAutoFillFix() {
        //Sniff for chrome
        if (!window.hasOwnProperty("chrome")) {
            return;
        }

        // When chrome autofills a password it doesn't actually put the value
        // in until the page gets clicked, but it does show the password placeholders.
        // So the text control is still in "no value" state and the label sits
        // under the placeholders.

        // Poll for the appearance of the pseudo-class chrome adds, and if we find
        // it force the control into "has value" mode.

        // More details https://stackoverflow.com/questions/35049555/chrome-autofill-autocomplete-no-value-for-password
        const checkForAutofill = setInterval(() => {
            const filled = document.querySelector("input[type=password]:-webkit-autofill");
            if (filled && this.passwordField) {
                this.passwordField.forceHasValue();
                clearInterval(checkForAutofill);
            }
        }, 250);

        setTimeout(() => clearInterval(checkForAutofill), 5000);
    }
}
