import * as React from "react";
import { ScheduleIntervalResource, TriggerScheduleIntervalResource, TriggerScheduleResource, DaysPerWeekTriggerScheduleResource, ServerTimezoneResource, TriggerScheduleIntervalType, TriggerScheduleRunType } from "client/resources";
import ScheduleInterval from "./ScheduleInterval";
import ScheduleStart from "./ScheduleStart";
import { ExpandableFormSection, Summary, Checkbox } from "components/form";
import * as cn from "classnames";
const styles = require("../style.less");
import { Moment } from "moment";
import * as moment from "moment";
import { ReactNode } from "react";

interface DaysPerWeekScheduledTriggerEditorProps {
    schedule: DaysPerWeekTriggerScheduleResource;
    timezones: ServerTimezoneResource[];
    onScheduleChange(schedule: DaysPerWeekTriggerScheduleResource): void;
}

interface DaysPerWeekScheduleTriggerEditorState {
    scheduleDays: {
        Monday: boolean;
        Tuesday: boolean;
        Wednesday: boolean;
        Thursday: boolean;
        Friday: boolean;
        Saturday: boolean;
        Sunday: boolean;
    };
    scheduleInterval: TriggerScheduleIntervalResource;
    scheduleStartTime: Moment;
    scheduleRunType: TriggerScheduleRunType;
    scheduleTimezone: string;
}

export class DaysPerWeekScheduledTriggerEditor extends React.Component<DaysPerWeekScheduledTriggerEditorProps, DaysPerWeekScheduleTriggerEditorState> {
    constructor(props: DaysPerWeekScheduledTriggerEditorProps) {
        super(props);
        this.state = this.initState(this.props.schedule);
    }

    componentWillReceiveProps(newProps: DaysPerWeekScheduledTriggerEditorProps) {
        if (
            newProps.schedule.Interval !== this.props.schedule.Interval ||
            newProps.schedule.HourInterval !== this.props.schedule.HourInterval ||
            newProps.schedule.MinuteInterval !== this.props.schedule.MinuteInterval ||
            newProps.schedule.StartTime !== this.props.schedule.StartTime ||
            newProps.schedule.Timezone !== this.props.schedule.Timezone ||
            newProps.schedule.RunType !== this.props.schedule.RunType
        ) {
            this.setState(this.initState(newProps.schedule));
        }
    }

    render() {
        return (
            <div>
                <ExpandableFormSection errorKey="ScheduleDaysOfWeek" title="Run Days" summary={this.getRunOnDaysSummary()} help="Select what days of the week the schedule should execute.">
                    <div className={cn(styles.checkboxGroup, styles.horizontal)}>
                        <div className={styles.checkbox}>
                            <Checkbox label="Monday" value={this.state.scheduleDays.Monday} onChange={(checked: boolean) => this.onScheduleDaysChange({ Monday: checked })} />
                        </div>
                        <div className={styles.checkbox}>
                            <Checkbox label="Tuesday" value={this.state.scheduleDays.Tuesday} onChange={(checked: boolean) => this.onScheduleDaysChange({ Tuesday: checked })} />
                        </div>
                        <div className={styles.checkbox}>
                            <Checkbox label="Wednesday" value={this.state.scheduleDays.Wednesday} onChange={(checked: boolean) => this.onScheduleDaysChange({ Wednesday: checked })} />
                        </div>
                        <div className={styles.checkbox}>
                            <Checkbox label="Thursday" value={this.state.scheduleDays.Thursday} onChange={(checked: boolean) => this.onScheduleDaysChange({ Thursday: checked })} />
                        </div>
                        <div className={styles.checkbox}>
                            <Checkbox label="Friday" value={this.state.scheduleDays.Friday} onChange={(checked: boolean) => this.onScheduleDaysChange({ Friday: checked })} />
                        </div>
                        <div className={styles.checkbox}>
                            <Checkbox label="Saturday" value={this.state.scheduleDays.Saturday} onChange={(checked: boolean) => this.onScheduleDaysChange({ Saturday: checked })} />
                        </div>
                        <div className={styles.checkbox}>
                            <Checkbox label="Sunday" value={this.state.scheduleDays.Sunday} onChange={(checked: boolean) => this.onScheduleDaysChange({ Sunday: checked })} />
                        </div>
                    </div>
                </ExpandableFormSection>
                <ScheduleInterval interval={this.state.scheduleInterval} onChange={this.onScheduleIntervalChange} />
                <ScheduleStart
                    startTime={this.state.scheduleStartTime}
                    runType={this.state.scheduleRunType}
                    showRunContinuously={this.state.scheduleInterval.Interval !== TriggerScheduleIntervalType.OnceDaily}
                    timezone={this.state.scheduleTimezone}
                    timezones={this.props.timezones}
                    onStartTimeChanged={this.onScheduleStartChange}
                    onTimezoneChanged={this.onScheduleTimezoneChange}
                    onRunTypeChanged={this.onScheduleRunTypeChange}
                />
            </div>
        );
    }

    initState(value?: DaysPerWeekTriggerScheduleResource) {
        const schedule = value || new DaysPerWeekTriggerScheduleResource();
        return {
            scheduleDays: {
                Monday: schedule.Monday,
                Tuesday: schedule.Tuesday,
                Wednesday: schedule.Wednesday,
                Thursday: schedule.Thursday,
                Friday: schedule.Friday,
                Saturday: schedule.Saturday,
                Sunday: schedule.Sunday,
            },
            scheduleInterval: {
                Interval: schedule.Interval,
                HourInterval: schedule.HourInterval,
                MinuteInterval: schedule.MinuteInterval,
            },
            scheduleStartTime: moment(schedule.StartTime).utc(),
            scheduleRunType: schedule.RunType,
            scheduleTimezone: schedule.Timezone,
        };
    }

    private getRunOnDaysSummary() {
        if (this.noDaysSelected()) {
            return Summary.placeholder("Select what days of the week the schedule should execute.");
        }
        let summary: ReactNode = "";
        if (this.runsOnWeekdays()) {
            summary = (
                <span>
                    Scheduled to run on <strong>weekdays</strong>
                </span>
            );
        } else if (this.runsOnWeekends()) {
            summary = (
                <span>
                    Scheduled to run on <strong>weekends</strong>
                </span>
            );
        } else {
            summary = (
                <span>
                    Scheduled to run on <strong>{this.getDaysScheduledToRunOn()}</strong>
                </span>
            );
        }
        return Summary.summary(summary);
    }

    private noDaysSelected() {
        return (
            !this.state.scheduleDays.Monday &&
            !this.state.scheduleDays.Tuesday &&
            !this.state.scheduleDays.Wednesday &&
            !this.state.scheduleDays.Thursday &&
            !this.state.scheduleDays.Friday &&
            !this.state.scheduleDays.Saturday &&
            !this.state.scheduleDays.Sunday
        );
    }
    private runsOnWeekdays(): boolean {
        return (
            this.state.scheduleDays.Monday &&
            this.state.scheduleDays.Tuesday &&
            this.state.scheduleDays.Wednesday &&
            this.state.scheduleDays.Thursday &&
            this.state.scheduleDays.Friday &&
            !this.state.scheduleDays.Saturday &&
            !this.state.scheduleDays.Sunday
        );
    }

    private runsOnWeekends(): boolean {
        return (
            this.state.scheduleDays.Saturday &&
            this.state.scheduleDays.Sunday &&
            !this.state.scheduleDays.Monday &&
            !this.state.scheduleDays.Tuesday &&
            !this.state.scheduleDays.Wednesday &&
            !this.state.scheduleDays.Thursday &&
            !this.state.scheduleDays.Friday
        );
    }

    private getDaysScheduledToRunOn(): string {
        const daysToRunOn = [];
        if (this.state.scheduleDays.Monday) {
            daysToRunOn.push("Monday");
        }
        if (this.state.scheduleDays.Tuesday) {
            daysToRunOn.push("Tuesday");
        }
        if (this.state.scheduleDays.Wednesday) {
            daysToRunOn.push("Wednesday");
        }
        if (this.state.scheduleDays.Thursday) {
            daysToRunOn.push("Thursday");
        }
        if (this.state.scheduleDays.Friday) {
            daysToRunOn.push("Friday");
        }
        if (this.state.scheduleDays.Saturday) {
            daysToRunOn.push("Saturday");
        }
        if (this.state.scheduleDays.Sunday) {
            daysToRunOn.push("Sunday");
        }
        return daysToRunOn.join(", ");
    }

    private onScheduleDaysChange = (value: any) => {
        this.setState(
            prev => ({
                scheduleDays: {
                    ...prev.scheduleDays,
                    ...value,
                },
            }),
            () => this.raiseChange()
        );
    };

    private onScheduleIntervalChange = (interval: TriggerScheduleIntervalResource) => {
        this.setState(
            {
                scheduleInterval: interval,
            },
            () => this.raiseChange()
        );
    };

    private onScheduleStartChange = (startTime: Moment) => {
        this.setState(
            {
                scheduleStartTime: startTime,
            },
            () => this.raiseChange()
        );
    };

    private onScheduleRunTypeChange = (runType: TriggerScheduleRunType) => {
        this.setState(
            {
                scheduleRunType: runType,
                scheduleStartTime: runType === TriggerScheduleRunType.Continuously ? null : moment(new Date()).utc(),
            },
            () => this.raiseChange()
        );
    };

    private onScheduleTimezoneChange = (timezone: string) => {
        this.setState(
            {
                scheduleTimezone: timezone,
            },
            () => this.raiseChange()
        );
    };

    private raiseChange() {
        this.props.onScheduleChange({
            ...this.props.schedule,
            Monday: this.state.scheduleDays.Monday,
            Tuesday: this.state.scheduleDays.Tuesday,
            Wednesday: this.state.scheduleDays.Wednesday,
            Thursday: this.state.scheduleDays.Thursday,
            Friday: this.state.scheduleDays.Friday,
            Saturday: this.state.scheduleDays.Saturday,
            Sunday: this.state.scheduleDays.Sunday,
            Interval: this.state.scheduleInterval.Interval,
            HourInterval: this.state.scheduleInterval.HourInterval,
            MinuteInterval: this.state.scheduleInterval.MinuteInterval,
            StartTime: this.state.scheduleStartTime ? this.state.scheduleStartTime.toDate() : null,
            RunType: this.state.scheduleRunType,
            Timezone: this.state.scheduleTimezone,
        });
    }
}
