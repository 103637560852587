import * as React from "react";
import { FeaturePlugin, ActionEditProps } from "components/Actions/pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { ExpandableFormSection, Summary, SummaryNode, Note, RadioButton } from "components/form";
import { VariableLookupText } from "components/form/VariableLookupText";
import { BoundStringRadioButtonGroup } from "components/form/RadioButton/RadioButtonGroup";
import isBound from "components/form/BoundField/isBound";

interface FeatureProperties {
    "Octopus.Action.Aws.CloudFormation.ChangeSet.GenerateName": string;
    "Octopus.Action.Aws.CloudFormation.ChangeSet.Name": string;
    "Octopus.Action.Aws.CloudFormation.ChangeSet.Defer": string;
}

const getSummary = (getProps: (props: keyof FeatureProperties) => string): SummaryNode => {
    const generatedNamed = getProps("Octopus.Action.Aws.CloudFormation.ChangeSet.GenerateName") === "True";
    return generatedNamed ? Summary.summary("Change sets will have automatically generated names") : Summary.summary(<span>Change set named {getProps("Octopus.Action.Aws.CloudFormation.ChangeSet.Name")}</span>);
};

interface CloudFormationChangesetFeatureState {
    generateNameBound: boolean;
}

class CloudFormationChangesetFeature extends BaseComponent<ActionEditProps<FeatureProperties>, CloudFormationChangesetFeatureState> {
    componentDidMount() {
        if (!this.props.properties["Octopus.Action.Aws.CloudFormation.ChangeSet.GenerateName"]) {
            this.props.setProperties({ "Octopus.Action.Aws.CloudFormation.ChangeSet.GenerateName": "True" });
        }

        if (!this.props.properties["Octopus.Action.Aws.CloudFormation.ChangeSet.Defer"]) {
            this.props.setProperties({ "Octopus.Action.Aws.CloudFormation.ChangeSet.Defer": "False" });
        }
    }

    render() {
        return (
            <ExpandableFormSection summary={getSummary((prop: keyof FeatureProperties) => this.props.properties[prop])} title="Change Sets" errorKey="Changesets" isExpandedByDefault={this.props.expandedByDefault}>
                <BoundStringRadioButtonGroup
                    onIsBoundChanged={bound => this.setState({ generateNameBound: bound })}
                    label={"Change Set Naming"}
                    resetValue={"True"}
                    variableLookup={{
                        localNames: this.props.localNames,
                        projectId: this.props.projectId,
                    }}
                    value={this.props.properties["Octopus.Action.Aws.CloudFormation.ChangeSet.GenerateName"]}
                    onChange={(val: string) => this.props.setProperties({ "Octopus.Action.Aws.CloudFormation.ChangeSet.GenerateName": val })}
                >
                    <RadioButton value="True" label="Automatically Generate Change Set Name" isDefault />
                    <RadioButton value="False" label="Manually Specify Change Set Name" />
                </BoundStringRadioButtonGroup>

                {(this.props.properties["Octopus.Action.Aws.CloudFormation.ChangeSet.GenerateName"] !== "True" || isBound(this.props.properties["Octopus.Action.Aws.CloudFormation.ChangeSet.GenerateName"])) && (
                    <React.Fragment>
                        <VariableLookupText
                            localNames={this.props.localNames}
                            projectId={this.props.projectId}
                            label={"Change Set Name"}
                            error={this.props.getFieldError("Octopus.Action.Aws.CloudFormation.ChangeSet.Name")}
                            value={this.props.properties["Octopus.Action.Aws.CloudFormation.ChangeSet.Name"]}
                            onChange={(val: string) => this.props.setProperties({ "Octopus.Action.Aws.CloudFormation.ChangeSet.Name": val })}
                        />
                        <Note>Please note that change sets must be unique for a given stack.</Note>
                    </React.Fragment>
                )}

                <BoundStringRadioButtonGroup
                    label="Change Set Execution"
                    resetValue={"False"}
                    variableLookup={{
                        localNames: this.props.localNames,
                        projectId: this.props.projectId,
                    }}
                    value={this.props.properties["Octopus.Action.Aws.CloudFormation.ChangeSet.Defer"]}
                    onChange={(val: string) => this.props.setProperties({ "Octopus.Action.Aws.CloudFormation.ChangeSet.Defer": val })}
                >
                    <RadioButton value="False" label="Execute Immediately" isDefault />
                    <RadioButton value="True" label="Defer Change Set Execution" />
                </BoundStringRadioButtonGroup>
            </ExpandableFormSection>
        );
    }
}

export default (): FeaturePlugin => ({
    featureName: "Octopus.Features.CloudFormation.ChangeSet.Feature",
    title: "CloudFormation Change Sets",
    description: "Use Cloud Formation Change sets when updating or creating stacks.",
    edit: CloudFormationChangesetFeature,
    disable: properties => {
        Object.keys(properties)
            .filter(name => {
                return name.indexOf("Octopus.Action.Aws.CloudFormation.ChangeSet.") === 0;
            })
            .forEach(name => {
                delete properties[name];
            });
    },
    priority: 1,
});
