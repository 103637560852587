import { Dictionary } from "lodash";
import { getType, ActionType, createAction } from "typesafe-actions";

// State Type
export enum NotificationType {
    happy,
    info,
    warn,
    error,
}

export interface NotificationItem {
    id: string;
    value?: string;
    text: string;
    description: string;
    links?: Array<NotificationItemLink | NotificationItemHrefLink>;
    type: NotificationType;
}

export type NotificationItemHrefLink = {
    type: "Internal" | "External";
    label: string;
    href: string;
};

export type NotificationItemLink = {
    type: "None" | "Refresh";
    label: string;
};

export type NotificationItemState = Dictionary<NotificationItem>;

export const notificationActions = {
    raiseNotification: createAction("NotificationActions/RAISE", resolve => (notification: NotificationItem) => resolve(notification)),
    dismissNotification: createAction("NotificationActions/DISMISS", resolve => (id: string) => resolve(id)),
    dismissAllNotifications: createAction("NotificationActions/DISMISS_ALL"),
};

export type NotificationAction = ActionType<typeof notificationActions>;

// Reducer
export const notificationReducer = (state: NotificationItemState = {}, action: NotificationAction): NotificationItemState => {
    switch (action.type) {
        case getType(notificationActions.raiseNotification):
            return {
                ...state,
                [action.payload.id]: action.payload,
            };
        case getType(notificationActions.dismissNotification):
            if (action.payload && state.hasOwnProperty(action.payload)) {
                const newState = { ...state };
                delete newState[action.payload];
                return newState;
            }
            break;
        case getType(notificationActions.dismissAllNotifications):
            return {};
    }
    return state;
};
