import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import NavigationSidebarLayout, { Navigation, NavItem } from "components/NavigationSidebarLayout/index";
import AreaTitle from "components/AreaTitle";
import BusyIndicator from "components/BusyIndicator/BusyIndicator";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import routeLinks from "routeLinks";
import Permission from "client/resources/permission";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import BusyFromPromise from "components/BusyFromPromise/BusyFromPromise";
import ErrorPanel from "components/ErrorPanel/ErrorPanel";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import { DisabledChip } from "components/Chips";
import { ProjectContextProvider, ProjectContextState } from "../../context";
import { repository } from "clientInstance";
import { ProjectGroupResource } from "client/resources";
import { connect } from "react-redux";
const styles = require("./style.less");

export interface ProjectRouteParams {
    projectSlug: string;
}

interface GlobalConnectedProps {
    isMultiTenancyEnabled?: boolean;
}

interface ProjectLayoutProps extends RouteComponentProps<ProjectRouteParams> {
    busy?: Promise<any> | boolean;
}

type Props = ProjectLayoutProps & GlobalConnectedProps;
interface ProjectLayoutState extends DataBaseComponentState {
    projectGroups: ProjectGroupResource[];
}

class ProjectLayoutInternal extends DataBaseComponent<Props, ProjectLayoutState> {
    async componentDidMount() {
        return this.doBusyTask(async () => {
            const projectGroups = await repository.ProjectGroups.all();
            this.setState({ projectGroups });
        });
    }

    render() {
        const projectSlug = this.props.match.params.projectSlug;
        return (
            <ProjectContextProvider doBusyTask={this.doBusyTask} projectIdOrSlug={projectSlug}>
                {({ state }) => {
                    const numberOfSteps = state.deploymentProcess && state.deploymentProcess.Steps ? state.deploymentProcess.Steps.length : 0;
                    const projectLogo = state.model && state.model && state.model.Links.Logo;
                    return (
                        <React.Fragment>
                            {state.model && (
                                <main id="maincontent">
                                    {this.areaTitle(state, false)}
                                    {this.renderErrors()}
                                    <NavigationSidebarLayout
                                        logoUrl={projectLogo}
                                        name={state.model.Name}
                                        description={state.model.Description}
                                        preNavbarComponent={
                                            <div>
                                                {state.model.IsDisabled && (
                                                    <div>
                                                        <DisabledChip />
                                                    </div>
                                                )}
                                                {!state.model.IsDisabled && numberOfSteps > 0 && (
                                                    <PermissionCheck permission={Permission.ReleaseCreate} project={state.model.Id} tenant="*">
                                                        <div className={styles.specialActions} key={numberOfSteps}>
                                                            {/* Note: We can't just use a NavigationButton here, because once you click it, it renders a redirect and disappears :) */}
                                                            <InternalLink to={routeLinks.project(projectSlug).releaseCreate}>
                                                                <ActionButton type={ActionButtonType.CreateRelease} label="Create release" />
                                                            </InternalLink>
                                                        </div>
                                                    </PermissionCheck>
                                                )}
                                            </div>
                                        }
                                        navLinks={this.navLinks(projectSlug, state.model.Id)}
                                        content={this.props.children}
                                    />
                                </main>
                            )}
                        </React.Fragment>
                    );
                }}
            </ProjectContextProvider>
        );
    }

    renderErrors() {
        const errors = this.state.errors;
        if (!errors) {
            return null;
        }
        return <ErrorPanel message={errors.message} details={errors.details} detailLinks={errors.detailLinks} helpText={errors.helpText} helpLink={errors.helpLink} />;
    }

    private renderBusy(forceBusy: boolean) {
        return <BusyFromPromise promise={this.props.busy || forceBusy}>{(busy: boolean) => <BusyIndicator show={busy || forceBusy} />}</BusyFromPromise>;
    }

    private areaTitle(state: ProjectContextState, forceBusy: boolean) {
        const hasAccessibleProjectGroup = this.state.projectGroups && state.model.ProjectGroupId && this.state.projectGroups.find(pg => pg.Id === state.model.ProjectGroupId);
        return !hasAccessibleProjectGroup ? (
            <AreaTitle link={routeLinks.projects.root} title="Projects" busyIndicator={this.renderBusy(forceBusy)} />
        ) : (
            <AreaTitle breadcrumbTitle="Projects" breadcrumbPath={routeLinks.projects.root} link={routeLinks.projects.filteredByGroup(state.model.ProjectGroupId)} title={hasAccessibleProjectGroup.Name} busyIndicator={this.renderBusy(forceBusy)} />
        );
    }

    private navLinks = (projectSlug: string, projectId: string) => {
        // Note: Use projectSlug from our route (and NOT this.state.project) to avoid the sidebar refreshing unnecessarily.
        const projectLinks = routeLinks.project(projectSlug || "");
        const navigationLinks: NavItem[] = [];
        navigationLinks.push(Navigation.navItem("Overview", projectLinks.overview));
        navigationLinks.push(
            Navigation.navItem("Process", projectLinks.process.root, null, {
                permission: Permission.ProcessView,
                project: projectId,
                tenant: "*",
            })
        );
        const variableSubLinks: NavItem[] = [];
        variableSubLinks.push(Navigation.navItem("Project", projectLinks.variables.root, true));
        if (this.props.isMultiTenancyEnabled) {
            variableSubLinks.push(Navigation.navItem("Project Templates", projectLinks.variables.projectTemplates));
            variableSubLinks.push(Navigation.navItem("Common Templates", projectLinks.variables.commonTemplates));
        }
        variableSubLinks.push(Navigation.navItem("Library Sets", projectLinks.variables.library));
        variableSubLinks.push(Navigation.navItem("All", projectLinks.variables.all));
        variableSubLinks.push(Navigation.navItem("Preview", projectLinks.variables.preview));
        navigationLinks.push(
            Navigation.navGroup("Variables", projectLinks.variables.root, variableSubLinks, {
                permission: Permission.VariableView,
                project: projectId,
                wildcard: true,
            })
        );
        navigationLinks.push(
            Navigation.navItem("Triggers", projectLinks.triggers, null, {
                permission: Permission.TriggerView,
                project: projectId,
            })
        );
        navigationLinks.push(
            Navigation.navItem("Channels", projectLinks.channels, null, {
                permission: Permission.ProcessView,
                project: projectId,
                tenant: "*",
            })
        );
        navigationLinks.push(
            Navigation.navItem("Releases", projectLinks.releases, null, {
                permission: Permission.ReleaseView,
                project: projectId,
                tenant: "*",
            })
        );
        navigationLinks.push(
            Navigation.navItem("Settings", projectLinks.settings, null, {
                permission: Permission.ProjectView,
                project: projectId,
                projectGroup: "*",
                tenant: "*",
            })
        );
        return navigationLinks;
    };
}

const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled,
    };
};

export default withRouter(connect(mapGlobalStateToProps)(ProjectLayoutInternal));
