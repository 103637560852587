import * as React from "react";

const styles = require("./NoResults.less");
export class NoResults extends React.PureComponent<{}> {
    render() {
        const onboardingImage = require("./noResults.svg");
        return (
            <div className={styles.container}>
                <img className={styles.onboardingImage} src={onboardingImage} alt={"It's deserted here"} title={"It's deserted here"} />
            </div>
        );
    }
}
