import * as React from "react";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { EnvironmentsSummaryResource, TagSetResource, TagResource } from "client/resources";
const styles = require("./style.less");
import Tag from "components/Tag";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import { secondaryText } from "theme/colors";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import routeLinks from "../../../../routeLinks";
import { MapStateToProps, connect } from "react-redux";
import { sortBy } from "lodash";

interface TagSetsCardProps {
    environmentSummary: EnvironmentsSummaryResource;
    tagSets: TagSetResource[];
}

interface GlobalConnectedProps {
    isMultiTenancyEnabled: boolean;
}

interface TagSetsCardState extends DataBaseComponentState {
    filterText?: string;
}

type Props = TagSetsCardProps & GlobalConnectedProps;

class TagSetsCard extends DataBaseComponent<Props, TagSetsCardState> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        if (
            !this.props.environmentSummary ||
            !this.props.tagSets ||
            !this.props.isMultiTenancyEnabled ||
            !isAllowed({
                permission: Permission.TenantView,
                tenant: "*",
            })
        ) {
            return null;
        }

        let totalTagSetTags = 0;
        const tenantTagSummaries = this.props.environmentSummary.MachineTenantTagSummaries;
        const tagSetComponents = this.props.tagSets.map(tagSet => {
            const tags = sortBy(tagSet.Tags.filter(item => this.onFilter(this.state.filterText, item)), t => t.SortOrder);
            if (tags.length === 0) {
                return null;
            }
            totalTagSetTags += tags.length;
            const tagSetRows = tags.map(tag => {
                const totalMachines = (tenantTagSummaries && (tenantTagSummaries as any)[tag.Id]) || 0;
                return (
                    <div className={styles.tenantTagRowsContainer} key={tag.Name}>
                        <div className={styles.tenantTagName}>
                            <Tag tagName={tag.Name} tagColor={tag.Color} key={tag.Name} description={tag.Description} to={routeLinks.infrastructure.machines.filtered({ tenantTags: tag.CanonicalTagName })} />
                        </div>
                        <div className={styles.tenantTagMachinesCount}>{totalMachines.toLocaleString()}</div>
                    </div>
                );
            });
            return (
                tags.length > 0 && (
                    <div className={styles.tenantTagRowsOuterContainer} key={tagSet.Id}>
                        <h5>{tagSet.Name}</h5>
                        {tagSetRows}
                    </div>
                )
            );
        });

        return (
            <div className={styles.card}>
                <div className={styles.cardTitleContainer}>
                    <div className={styles.cardTitle}>
                        Tenant Tag Sets <span className={styles.cardCount}>({totalTagSetTags.toLocaleString()})</span>
                    </div>
                </div>
                <div className={styles.cardContentContainer}>
                    {(totalTagSetTags > 1 || this.state.filterText) && (
                        <div>
                            <FilterSearchBox
                                hintText="Filter..."
                                onChange={value => this.setState({ filterText: value.toLowerCase() })}
                                inputClassName={styles.dashboardFilterInputClassName}
                                iconColor={secondaryText}
                                iconStyle={{ width: "20px", height: "20px", top: "14px" }}
                            />
                        </div>
                    )}
                    {tagSetComponents}
                </div>
            </div>
        );
    }

    private onFilter(filter: string, resource: TagResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }
}

const mapGlobalStateToProps = (state: GlobalState, props: TagSetsCardProps): GlobalConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled,
    };
};

export default connect(mapGlobalStateToProps)(TagSetsCard);
