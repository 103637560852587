import ParseHelper from "utils/ParseHelper";
import * as React from "react";
import { Switch } from "react-router-dom";
import DeploymentProcessOverview from "./DeploymentProcessOverview";
import StepDetailsLoader from "./StepDetailsLoader";
import ActionTemplateSelector from "areas/projects/components/ActionTemplateSelector/ActionTemplateSelector";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import pageIds from "pageIds";
import { withPage } from "components/Page/Page";
import { withProjectDeploymentProcessId, WithProjectDeploymentProcessInjectedProps } from "./withProjectDeploymentProcess";
import { withActionScope, WithActionScopeInjectedProps } from "components/Actions/withActionScope";
import { ActionScope } from "components/Actions/pluginRegistry";

const specificProjectPage = pageIds.project();

// Preparation for supporting multiple processes: We currently don't have routes setup for this and injecting the scope and process id
// from the context avoids having to change the routes.
const withProjectDeploymentProcess = <T extends unknown>(Component: React.ComponentType<T & WithActionScopeInjectedProps & WithProjectDeploymentProcessInjectedProps>) => {
    return withProjectDeploymentProcessId(withActionScope(ActionScope.Deployments)(Component));
};

export const CreateStepDetailsPage = withPage({ page: specificProjectPage.process.stepNew })(withProjectDeploymentProcess(StepDetailsLoader));
export const StepDetailsPage = withPage({ page: specificProjectPage.process.step })(withProjectDeploymentProcess(StepDetailsLoader));
export const ActionTemplateSelectionPage = withPage({ page: specificProjectPage.process.stepTemplates })(withProjectDeploymentProcess(ActionTemplateSelector));
export const DeploymentProcessOverviewPage = withPage({ page: specificProjectPage.process.root })(withProjectDeploymentProcess(DeploymentProcessOverview));

export class DeploymentProcessRoute extends React.Component<any, any> {
    static nextStepReloadPath(currentPath: string, currentKey: string): string {
        let path = currentPath;
        if (currentKey) {
            const keyNum = ParseHelper.safeParseInt(currentKey, 0) + 1;
            path = path.slice(0, -currentKey.length) + keyNum.toString();
        } else {
            path = path + "/1";
        }
        return path;
    }

    render() {
        const match = this.props.match;
        return (
            <Switch>
                <ReloadableRoute path={`${match.path}/step/new/:actionType/:template?`} component={CreateStepDetailsPage} />
                <ReloadableRoute path={`${match.path}/step/:stepId/new/:actionType/:template?`} component={CreateStepDetailsPage} />
                <ReloadableRoute path={`${match.path}/step/:stepId/:reloadKey?`} component={StepDetailsPage} />
                <ReloadableRoute path={`${match.path}/steptemplates`} component={ActionTemplateSelectionPage} />
                <ReloadableRoute path={`${match.path}/childsteptemplates/:parentStepId`} component={ActionTemplateSelectionPage} />
                <ReloadableRoute path={`${match.path}`} component={DeploymentProcessOverviewPage} />
            </Switch>
        );
    }
}
