import endpointRegistry from "./endpointRegistry";
import { azureCloudServiceEndpointRegistration } from "./AzureCloudServiceEndpoint";
import { azureServiceFabricClusterEndpointRegistration } from "./AzureServiceFabricClusterEndpoint";
import { azureWebAppEndpointRegistration } from "./AzureWebAppEndpoint";
import { azureVmRegistration } from "./AzureVmEndpointRegistration";
import { cloudRegionEndpointRegistration } from "./CloudRegionEndpoint";
import { activeTentacleWindowsEndpointRegistration, activeTentacleLinuxEndpointRegistration } from "./TentacleActiveEndpoint";
import { passiveTentacleWindowsEndpointRegistration, passiveTentacleLinuxEndpointRegistration } from "./TentaclePassiveEndpoint";
import { sshEndpointRegistration } from "./SshEndpoint";
import { offlineDropEndpointRegistration } from "./OfflineDropEndpoint";
import { kubernetesEndpointRegistration } from "./KubernetesEndpoint";

endpointRegistry.registerEndpoint(activeTentacleLinuxEndpointRegistration);
endpointRegistry.registerEndpoint(activeTentacleWindowsEndpointRegistration);
endpointRegistry.registerEndpoint(passiveTentacleWindowsEndpointRegistration);
endpointRegistry.registerEndpoint(passiveTentacleLinuxEndpointRegistration);

endpointRegistry.registerEndpoint(sshEndpointRegistration);
endpointRegistry.registerEndpoint(offlineDropEndpointRegistration);

endpointRegistry.registerEndpoint(azureCloudServiceEndpointRegistration);
endpointRegistry.registerEndpoint(azureServiceFabricClusterEndpointRegistration);
endpointRegistry.registerEndpoint(azureWebAppEndpointRegistration);
endpointRegistry.registerEndpoint(azureVmRegistration);

endpointRegistry.registerEndpoint(kubernetesEndpointRegistration);
endpointRegistry.registerEndpoint(cloudRegionEndpointRegistration);
