import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";

export const ConfigurationNodesServerSettingsRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.configuration.nodes.serverSettings,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return null;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return null;
    },
};
