import * as React from "react";
import Onboarding from "./Onboarding";
import { DeploymentProcessResource, TenantedDeploymentMode, WorkerPoolResource, TagSetResource } from "client/resources";
import { client, repository } from "clientInstance";
import PaperLayout from "components/PaperLayout";
import SpecialVariables from "client/specialVariables";
import DeploymentPart from "./DeploymentPart";
import { DeploymentActionResource } from "client/resources/deploymentActionResource";
import Roles from "components/Actions/Roles";
import pluginRegistry, { ActionScope } from "components/Actions/pluginRegistry";
import { NavigationButton, NavigationButtonType } from "components/Button/NavigationButton";
import SideBar, { ScriptModule } from "./SideBar";
import { ProjectResource } from "client/resources/projectResource";
import { ActionButtonType } from "components/Button/ActionButton";
import ActionList from "components/ActionList/ActionList";
import DeploymentPartSorter from "./DeploymentPartSorter";
import OverflowMenu, { OverflowMenuItems } from "components/Menu/OverflowMenu";
import { DeploymentStepResource } from "client/resources/deploymentStepResource";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { VariableSetContentType } from "client/resources/libraryVariableSetResource";
import { EnvironmentResource } from "client/resources/environmentResource";
import { LifecycleResource } from "client/resources/lifecycleResource";
import { ResourcesById } from "client/repositories/basicRepository";
import { ChannelResource } from "client/resources/channelResource";
import { keyBy, flatten } from "lodash";
import * as tenantTagsets from "components/tenantTagsets";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import SidebarLayout from "components/SidebarLayout/SidebarLayout";
import { RouteComponentProps } from "react-router";
import routeLinks from "routeLinks";
import Permission from "client/resources/permission";
import PermissionCheck, { isAllowed } from "components/PermissionCheck/PermissionCheck";
import getActionLogoUrl from "../getActionLogoUrl";
import InternalRedirect from "components/Navigation/InternalRedirect/InternalRedirect";
import Callout, { CalloutType } from "components/Callout";
import InternalLink from "components/Navigation/InternalLink";
import { UnstructuredFormSection, Select, Checkbox } from "components/form";
import DeploymentPartContextMenu, { DeploymentProcessEditorFilter } from "./DeploymentPartContextMenu";
import FilterSearchBox from "components/FilterSearchBox";
import { NoResults } from "components/NoResults/NoResults";
import Logger from "client/logger";
import AdvancedFilterLayout from "components/AdvancedFilterLayout/AdvancedFilterLayout";
import { withProjectContext, WithProjectContextInjectedProps, ActionContextProvider } from "../../context";
import { WithDeploymentProcessContextInjectedProps, FilterNamedResource, DeploymentProcessContextProvider } from "./DeploymentProcessContext";
import ActionTemplateSearchResource from "client/resources/actionTemplateSearchResource";

const deploymentPartStyles = require("./DeploymentPart.less");
const rollingStep = require("./step-rolling.svg");

class FilterLayout extends AdvancedFilterLayout<DeploymentProcessEditorFilter> {}

interface DeploymentProcessOverviewState extends DataBaseComponentState {
    project: ProjectResource;
    redirectTo?: string;
    open: boolean;
    isLookupDataLoaded: boolean;
    isSaving: boolean;
    selectedParentStepId: string;
    includedScriptModules: ScriptModule[];
    lifecyclePreview: LifecycleResource;
    environmentsById?: ResourcesById<EnvironmentResource>;
    channelsById?: ResourcesById<ChannelResource>;
    tagSets: TagSetResource[];
    workerPoolsById: ResourcesById<WorkerPoolResource>;
}

// tslint:disable-next-line:no-empty-interface
interface DeploymentProcessOverviewProps
    extends RouteComponentProps<{
        projectSlug: string;
        filterKeyword: string;
        environmentId: string;
    }> {
    processId: string;
    scope: ActionScope;
}

type Props = DeploymentProcessOverviewProps & WithProjectContextInjectedProps;

type ProcessContextProps = WithDeploymentProcessContextInjectedProps["deploymentProcessContext"];

class DeploymentProcessOverview extends DataBaseComponent<Props, DeploymentProcessOverviewState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            redirectTo: null,
            project: null,
            open: false,
            isLookupDataLoaded: false,
            isSaving: false,
            selectedParentStepId: null,
            includedScriptModules: [],
            lifecyclePreview: null,
            tagSets: null,
            workerPoolsById: null,
        };
    }

    async loadData() {
        const project = await repository.Projects.get(this.props.match.params.projectSlug);
        const [scriptModules, environmentsById, lifecycle, channels, tagSets, wokerPools] = await Promise.all([
            isAllowed({ permission: Permission.LibraryVariableSetView })
                ? repository.LibraryVariableSets.all({
                      contentType: VariableSetContentType.ScriptModule,
                  })
                : [],
            repository.Environments.allById(),
            isAllowed({ permission: Permission.LifecycleView }) ? repository.Lifecycles.get(project.LifecycleId) : null,
            repository.Projects.getChannels(project),
            tenantTagsets.getAll(),
            repository.WorkerPools.all(),
        ]);

        const lifecyclePreview = isAllowed({ permission: Permission.LifecycleView }) ? await repository.Lifecycles.preview(lifecycle) : null;
        const channelsById = keyBy(channels.Items, ch => ch.Id);
        const workerPoolsById = keyBy(wokerPools, wp => wp.Id);
        const includedScriptModules = scriptModules.filter(sm => project.IncludedLibraryVariableSetIds.includes(sm.Id));

        this.setState({
            environmentsById,
            project,
            includedScriptModules,
            lifecyclePreview,
            channelsById,
            tagSets,
            workerPoolsById,
            isLookupDataLoaded: true,
        });
    }

    async componentDidMount() {
        await this.doBusyTask(() => this.loadData());
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true} />;
        }
        return (
            <ActionContextProvider doBusyTask={this.doBusyTask}>
                {actionContext => (
                    <DeploymentProcessContextProvider id={this.props.processId} doBusyTask={this.doBusyTask}>
                        {processContext => {
                            const { state: processState, actions: processActions } = processContext;
                            const hasLoaded: boolean = this.state.isLookupDataLoaded && !!processContext.state.process;

                            const actions: Array<React.ReactElement<any>> = [];
                            if (hasLoaded) {
                                if (processState.process.Steps && processState.process.Steps.length > 1) {
                                    actions.push(this.reorderStepsButton(processContext));
                                }
                                actions.push(this.addStepButton());
                                actions.push(
                                    <OverflowMenu
                                        menuItems={[
                                            OverflowMenuItems.downloadItem(
                                                "Download as JSON",
                                                this.state.project.Slug + "-process.json",
                                                client.resolveLinkTemplate("DeploymentProcesses", {
                                                    id: processState.process.Id,
                                                })
                                            ),
                                            [
                                                OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([processState.process.Id]), null, {
                                                    permission: Permission.EventView,
                                                    wildcard: true,
                                                }),
                                            ],
                                        ]}
                                    />
                                );
                            }
                            return (
                                <PaperLayout busy={this.state.busy} errors={this.state.errors} title="Process" sectionControl={hasLoaded && <ActionList actions={actions} />}>
                                    {hasLoaded && processState.process.Steps.length === 0 && <Onboarding />}
                                    {hasLoaded && processState.process.Steps.length > 0 && (
                                        <>
                                            <SidebarLayout
                                                sideBar={
                                                    <SideBar
                                                        deploymentProcess={processState.process}
                                                        includedScriptModules={this.state.includedScriptModules}
                                                        lifecyclePreview={this.state.lifecyclePreview}
                                                        environmentsById={this.state.environmentsById}
                                                        onDataChanged={() => this.refreshData()}
                                                    />
                                                }
                                            >
                                                <FilterLayout
                                                    filter={processState.filter}
                                                    filterSections={[
                                                        {
                                                            render: (
                                                                <>
                                                                    <PermissionCheck permission={Permission.EnvironmentView} wildcard={true}>
                                                                        <Select
                                                                            value={processState.filter.environment && processState.filter.environment.Id}
                                                                            onChange={environmentId => {
                                                                                let environment: FilterNamedResource;
                                                                                if (environmentId) {
                                                                                    const resource = this.state.environmentsById[environmentId];
                                                                                    environment = {
                                                                                        Id: resource.Id,
                                                                                        Name: resource.Name,
                                                                                    };
                                                                                }
                                                                                processActions.onFilterChange(t => ({ ...t, environment }));
                                                                            }}
                                                                            items={Object.values(this.state.environmentsById).map(e => ({ value: e.Id, text: e.Name }))}
                                                                            allowClear={true}
                                                                            allowFilter={true}
                                                                            fieldName="environment"
                                                                        />
                                                                    </PermissionCheck>
                                                                    {Object.keys(this.state.channelsById).length > 1 && (
                                                                        <Select
                                                                            value={processState.filter.channel && processState.filter.channel.Id}
                                                                            onChange={channelId => {
                                                                                let channel: FilterNamedResource;
                                                                                if (channelId) {
                                                                                    const resource = this.state.channelsById[channelId];
                                                                                    channel = {
                                                                                        Id: resource.Id,
                                                                                        Name: resource.Name,
                                                                                    };
                                                                                }
                                                                                processActions.onFilterChange(t => ({ ...t, channel }));
                                                                            }}
                                                                            items={Object.values(this.state.channelsById).map(e => ({ value: e.Id, text: e.Name }))}
                                                                            allowClear={true}
                                                                            allowFilter={true}
                                                                            fieldName="channel"
                                                                        />
                                                                    )}
                                                                    <Checkbox
                                                                        label="Include unscoped steps"
                                                                        value={processState.filter.includeUnscoped}
                                                                        onChange={includeUnscoped => {
                                                                            processActions.onFilterChange(prev => ({ ...prev, includeUnscoped }));
                                                                        }}
                                                                    />
                                                                </>
                                                            ),
                                                        },
                                                    ]}
                                                    onFilterReset={filter => processActions.onFilterChange(prev => filter)}
                                                    defaultFilter={processContext.getEmptyFilter()}
                                                    initiallyShowFilter={processContext.isFiltering}
                                                    additionalHeaderFilters={[
                                                        <FilterSearchBox
                                                            hintText="Filter by name..."
                                                            value={processState.filter.filterKeyword}
                                                            onChange={filterKeyword => processActions.onFilterChange(prev => ({ ...prev, filterKeyword }))}
                                                            autoFocus={true}
                                                        />,
                                                    ]}
                                                    renderContent={() => (
                                                        <>
                                                            {this.getInvalidConfigurationCallouts(processContext)}
                                                            <div className={deploymentPartStyles.stepList}>
                                                                {processContext.filteredSteps.steps.length > 0 ? (
                                                                    processContext.filteredSteps.steps
                                                                        .filter(x => x.filtered)
                                                                        .map(({ step: filteredStep, index }) => {
                                                                            const step = this.findStepByName(processState.process, filteredStep.Name);
                                                                            if (!step) {
                                                                                Logger.log(`Failed to find step with name ${filteredStep.Name}`);
                                                                                return null;
                                                                            }
                                                                            return step.Actions.length === 1
                                                                                ? this.buildAction(processContext, this.props.scope, actionContext.state.actionTemplates, step, step.Actions[0], index)
                                                                                : this.buildParentStep(processContext, this.props.scope, actionContext.state.actionTemplates, step, index);
                                                                        })
                                                                ) : (
                                                                    <NoResults />
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </SidebarLayout>
                                        </>
                                    )}
                                </PaperLayout>
                            );
                        }}
                    </DeploymentProcessContextProvider>
                )}
            </ActionContextProvider>
        );
    }

    private findStepByName(process: DeploymentProcessResource, name: string) {
        // We lookup by .Name due to cloned steps not having an ID initially.
        return process.Steps.find(x => x.Name === name);
    }

    private buildParentStep(processContext: ProcessContextProps, scope: ActionScope, actionTemplates: ActionTemplateSearchResource[], step: DeploymentStepResource, stepIndex: number) {
        const showWindowSize = step.Properties[SpecialVariables.Action.MaxParallelism] ? step.Properties[SpecialVariables.Action.MaxParallelism].toString().length > 0 : false;
        const parentStepLabel = showWindowSize ? (
            <span>Rolling deployment</span>
        ) : (
            <span>
                Multi-step deployment across
                <br />
                deployment targets
            </span>
        );
        return (
            <div key={step.Id} className={deploymentPartStyles.group}>
                <DeploymentPartContextMenu
                    scope={this.props.scope}
                    keywordSearch={processContext.state.filter && processContext.state.filter.filterKeyword}
                    step={step}
                    stepIndex={stepIndex}
                    isParentGroup={true}
                    render={renderProps => {
                        return (
                            <DeploymentPart actionType={parentStepLabel} logoUrl={rollingStep} {...renderProps}>
                                {step.Properties[SpecialVariables.Action.MaxParallelism] ? <span>Rolling deployment</span> : <span>Multi-step deployment</span>}
                                &nbsp;across deployment targets in&nbsp;
                                <Roles rolesAsCSV={step.Properties[SpecialVariables.Action.TargetRoles] as string} />
                            </DeploymentPart>
                        );
                    }}
                />
                {step.Actions.map((action, index) => this.buildAction(processContext, scope, actionTemplates, step, action, index + 1, stepIndex))}
            </div>
        );
    }

    private buildAction(processContext: ProcessContextProps, scope: ActionScope, actionTemplates: ActionTemplateSearchResource[], step: DeploymentStepResource, action: DeploymentActionResource, actionIndex: number, stepIndex?: number) {
        const isChildAction = !!stepIndex;
        let actionTypeName = action.ActionType;
        const actionTemplate = actionTemplates.find(x => x.Type === action.ActionType);
        if (actionTemplate) {
            actionTypeName = actionTemplate.Name;
        }

        const environmentsUserCanAccess = (environments: string[]) => environments.filter(e => Object.keys(this.state.environmentsById).includes(e));

        return (
            <DeploymentPartContextMenu
                scope={this.props.scope}
                key={action.Id}
                step={step}
                action={action}
                stepIndex={stepIndex}
                actionIndex={actionIndex}
                isChildAction={isChildAction}
                keywordSearch={processContext.state.filter && processContext.state.filter.filterKeyword}
                render={renderProps => {
                    return (
                        <DeploymentPart
                            actionType={actionTypeName}
                            logoUrl={getActionLogoUrl(action)}
                            environments={environmentsUserCanAccess(action.Environments).map(id => this.state.environmentsById[id])}
                            excludedEnvironments={environmentsUserCanAccess(action.ExcludedEnvironments).map(id => this.state.environmentsById[id])}
                            channelsLookup={action.Channels.map(id => ({
                                Id: id,
                                Channel: this.state.channelsById[id],
                            }))}
                            tags={this.getTags(action)}
                            {...renderProps}
                        >
                            {pluginRegistry
                                .getAction(action.ActionType, scope)
                                .summary(action.Properties, isChildAction ? null : (step.Properties[SpecialVariables.Action.TargetRoles] as string), action.Packages, action.WorkerPoolId ? this.state.workerPoolsById[action.WorkerPoolId].Name : null)}
                        </DeploymentPart>
                    );
                }}
            />
        );
    }

    private refreshData = async () => {
        await this.doBusyTask(() => this.loadData());
    };

    private calculateDetailsUrl(processContext: ProcessContextProps, id: string): string | null {
        return routeLinks.project(processContext.state.process.ProjectId).process.step(id);
    }

    private getInvalidAutomaticReleaseCreationConfigurationCallout(processContext: ProcessContextProps) {
        if (this.state.project.AutoCreateRelease) {
            if (this.state.project.ReleaseCreationStrategy == null || this.state.project.ReleaseCreationStrategy.ReleaseCreationPackage == null) {
                return (
                    <div>
                        This project is configured to use Automatic Release Creation, but the step is missing. Please adjust the <InternalLink to={routeLinks.project(this.state.project.Slug).triggers}>Automatic Release Creation</InternalLink>{" "}
                        configuration.
                    </div>
                );
            } else {
                const action = flatten(processContext.state.process.Steps.map(step => step.Actions)).filter(a => a.Name === this.state.project.ReleaseCreationStrategy.ReleaseCreationPackage.DeploymentAction);
                if (action && action.length > 0 && action[0].IsDisabled) {
                    return (
                        <div>
                            Step <InternalLink to={this.calculateDetailsUrl(processContext, action[0].Id)}>{action[0].Name}</InternalLink> is currently used for Automatic Release Creation, but it has been disabled. Please re-enable the step, or
                            adjust the <InternalLink to={routeLinks.project(this.state.project.Slug).triggers}>Automatic Release Creation</InternalLink> configuration.
                        </div>
                    );
                }
            }
        }
        return null;
    }

    private getInvalidVersioningConfigurationCallout(processContext: ProcessContextProps) {
        if (this.state.project.VersioningStrategy.DonorPackage) {
            const action = flatten(processContext.state.process.Steps.map(step => step.Actions)).filter(a => a.Name === this.state.project.VersioningStrategy.DonorPackage.DeploymentAction);
            if (action && action.length > 0 && action[0].IsDisabled) {
                return (
                    <div>
                        Step <InternalLink to={this.calculateDetailsUrl(processContext, action[0].Id)}>{action[0].Name}</InternalLink> is currently used for release versioning, but it has been disabled.
                        <br />
                        Please re-enable the step or adjust the <InternalLink to={routeLinks.project(this.state.project.Slug).settings}>release versioning</InternalLink> configuration.
                    </div>
                );
            }
        }
        return null;
    }

    private getInvalidConfigurationCallouts(processContext: ProcessContextProps) {
        if (this.state.isLookupDataLoaded && !this.state.isSaving) {
            const arcCallout = this.getInvalidAutomaticReleaseCreationConfigurationCallout(processContext);
            const versioningCallout = this.getInvalidVersioningConfigurationCallout(processContext);
            if (arcCallout || versioningCallout) {
                return (
                    <UnstructuredFormSection stretchContent={true}>
                        <Callout type={CalloutType.Warning} title="Invalid Configuration">
                            {arcCallout}
                            {versioningCallout}
                        </Callout>
                    </UnstructuredFormSection>
                );
            }
        }
        return null;
    }

    private reorderStepsButton(processContext: ProcessContextProps) {
        return (
            <PermissionCheck permission={Permission.ProcessEdit} project={this.state.project.Id} tenant="*">
                <OpenDialogButton label="Reorder steps" type={ActionButtonType.Secondary}>
                    <DeploymentPartSorter title="Reorder Steps" deploymentProcessId={this.state.project.DeploymentProcessId} saveDone={this.refreshData} onDeploymentProcessUpdated={processContext.actions.onDeploymentProcessChange} />
                </OpenDialogButton>
            </PermissionCheck>
        );
    }

    private addStepButton() {
        return (
            <PermissionCheck permission={Permission.ProcessEdit} project={this.state.project.Id} tenant="*">
                <NavigationButton type={NavigationButtonType.Primary} label="Add Step" href={routeLinks.project(this.state.project).steptemplates} />
            </PermissionCheck>
        );
    }

    private getTags(action: DeploymentActionResource): string[] {
        if (this.state.project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted) {
            return [];
        }
        return action.TenantTags;
    }
}

const EnhancedDeploymentProcessOverview = withProjectContext(DeploymentProcessOverview);

export default EnhancedDeploymentProcessOverview;
