import Client from "../client";
import OnboardingResource from "client/resources/onboardingResource";

class UserOnBoardingRepository {
    private client: Client;

    constructor(client: Client) {
        this.client = client;
    }

    get(): Promise<OnboardingResource> {
        return this.client.get(this.client.getLink("UserOnboarding"));
    }
}

export default UserOnBoardingRepository;
