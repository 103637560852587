import * as React from "react";
import { DaysPerMonthTriggerScheduleResource, MonthlyScheduleType } from "client/resources";
import { Summary } from "components/form";
import { Item } from "components/form/Select/Select";

export default class DaysPerMonthDescriptionHelper {
    constructor(private readonly schedule: DaysPerMonthTriggerScheduleResource) {}

    getSummary(emptyText: string) {
        if (this.schedule.MonthlyScheduleType === MonthlyScheduleType.DateOfMonth && this.schedule.DateOfMonth) {
            const dateOfMonth = this.getDateOfMonthOptions().find(domo => {
                if (domo.value === this.schedule.DateOfMonth.toString()) {
                    return true;
                }
            });
            return (
                <span>
                    on the <strong>{dateOfMonth.text}</strong> day of every month
                </span>
            );
        }

        if (this.schedule.MonthlyScheduleType === MonthlyScheduleType.DayOfMonth && this.schedule.DayOfWeek && this.schedule.DayNumberOfMonth) {
            const dayOfWeekOrdinal = this.getDayOfWeekOrdinalOptions().find(dowo => {
                return dowo.value === this.schedule.DayNumberOfMonth;
            });
            const dayOfWeek = this.getMonthSpecifiedDaysOfWeek().find(dow => {
                return dow.text === this.schedule.DayOfWeek.toString();
            });
            return (
                <span>
                    {" "}
                    on the{" "}
                    <strong>
                        {dayOfWeekOrdinal.text.toLowerCase()} {dayOfWeek.text}
                    </strong>{" "}
                    of every month
                </span>
            );
        }
    }

    getDateOfMonthOptions() {
        const items: Item[] = [];
        let text: string;
        for (let value = 1; value <= 31; value++) {
            switch (value) {
                case 1:
                case 21:
                case 31:
                    text = `${value}st`;
                    break;
                case 2:
                case 22:
                    text = `${value}nd`;
                    break;
                case 3:
                case 23:
                    text = `${value}rd`;
                    break;
                default:
                    text = `${value}th`;
                    break;
            }
            items.push({ value: value.toString(), text });
        }
        items.push({ value: "L", text: "Last" });
        return items;
    }

    getDayOfWeekOrdinalOptions() {
        return [{ value: "1", text: "First" }, { value: "2", text: "Second" }, { value: "3", text: "Third" }, { value: "4", text: "Fourth" }, { value: "L", text: "Last" }];
    }

    getMonthSpecifiedDaysOfWeek() {
        return [{ value: "1", text: "Monday" }, { value: "2", text: "Tuesday" }, { value: "3", text: "Wednesday" }, { value: "4", text: "Thursday" }, { value: "5", text: "Friday" }, { value: "6", text: "Saturday" }, { value: "0", text: "Sunday" }];
    }
}
