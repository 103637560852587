import { createVisibilityReducer } from "utils/Reducers/visibilityReducer";

const dialogSliceSelector = (state: GlobalState) => state.dialogs;
const { reducer, actions, getSelectors } = createVisibilityReducer("dialog");
const { createVisibleSelector } = getSelectors(dialogSliceSelector);

const selectors = {
    createDialogOpenSelector: createVisibleSelector,
};

export default reducer;
export { actions, selectors };
