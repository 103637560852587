import isBound from "../../form/BoundField/isBound";
import * as React from "react";
import pluginRegistry, { ActionEditProps } from "../pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import SpecialVariables from "client/specialVariables";
import { repository } from "clientInstance";
import Roles from "../Roles";
import { ActionSummaryProps } from "../actionSummaryProps";
import { ActionExecutionLocation } from "../../../client/resources/actionExecutionLocation";
import { ExpandableFormSection, Summary, FormSectionHeading, Text, Note, UnstructuredFormSection } from "components/form";
import { AccountType, AccountResource, AzureCloudServiceEndpointDeploymentSlot, Permission, InitialisePrimaryPackageReference, GetPrimaryPackageReference, SetPrimaryPackageReference } from "client/resources";
import FeedResource, { FeedType } from "client/resources/feedResource";
import CommonSummaryHelper from "utils/CommonSummaryHelper/CommonSummaryHelper";
import PackageSelector from "components/PackageSelector/PackageSelector";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import { BoundAccountSelect } from "../../form/AccountSelect/AccountSelect";
import RadioButton from "components/form/RadioButton/RadioButton";
import RadioButtonGroup from "components/form/RadioButton/RadioButtonGroup";
import AzureServerTargetRolesInfo from "components/Actions/azurePowerShell/AzureServerTargetRolesInfo";
import AzurePowerShellScriptInfo from "components/Actions/azurePowerShell/AzurePowerShellScriptInfo";
import { getFeedName } from "../getFeedName";
import { TargetRoles } from "areas/projects/components/DeploymentProcess/ActionDetails";
import { BoundSelect } from "components/form/Select/Select";
import { Callout, CalloutType } from "components/Callout/Callout";
import PermissionCheck, { isAllowed } from "../../PermissionCheck/PermissionCheck";
import { BoundStringCheckbox } from "components/form/Checkbox/StringCheckbox";
import { VariableLookupText } from "../../form/VariableLookupText";

interface AzureCloudServiceActionSummaryState {
    feedName: string;
}

class AzureCloudServiceActionSummary extends BaseComponent<ActionSummaryProps, AzureCloudServiceActionSummaryState> {
    constructor(props: ActionSummaryProps) {
        super(props);
        this.state = { feedName: null };
    }

    async componentDidMount() {
        const pkg = GetPrimaryPackageReference(this.props.packages);
        if (pkg) {
            this.setState({ feedName: await getFeedName(pkg.FeedId) });
        }
    }

    render() {
        const pkg = GetPrimaryPackageReference(this.props.packages);
        return pkg ? (
            <div>
                Deploy Azure Cloud Service <strong> {pkg.PackageId} </strong>
                from {this.state.feedName ? <strong>{this.state.feedName}</strong> : <em>{pkg.FeedId}</em>}
                {this.props.targetRolesAsCSV && (
                    <span>
                        {" "}
                        on behalf of targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} />{" "}
                    </span>
                )}
            </div>
        ) : (
            <Callout type={CalloutType.Warning} title="Misconfigured step">
                Package was not selected or cannot be found. Please review this step and ensure a valid package is selected.
            </Callout>
        );
    }
}

interface AzureCloudServiceProperties {
    "Octopus.Action.Azure.IsLegacyMode": string;
    "Octopus.Action.Azure.SubscriptionId": string;
    "Octopus.Action.Azure.AccountId": string;
    "Octopus.Action.Azure.CloudServiceName": string;
    "Octopus.Action.Azure.StorageAccountName": string;
    "Octopus.Action.Azure.Slot": string;
    "Octopus.Action.Azure.SwapIfPossible": string;
    "Octopus.Action.Azure.UseCurrentInstanceCount": string;
}

interface AzureCloudServiceActionEditState {
    feeds: FeedResource[];
    accounts: AccountResource[];
}

class AzureCloudServiceActionEdit extends BaseComponent<ActionEditProps<AzureCloudServiceProperties>, AzureCloudServiceActionEditState> {
    constructor(props: ActionEditProps<AzureCloudServiceProperties>) {
        super(props);
        this.state = {
            feeds: [],
            accounts: [],
        };
    }

    async componentDidMount() {
        await this.loadFeeds(feeds => this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, feeds)));
        await this.props.doBusyTask(async () => {
            if (!!this.props.properties["Octopus.Action.Azure.AccountId"] && isAllowed({ permission: Permission.AccountView, wildcard: true })) {
                this.setState({
                    accounts: await repository.Accounts.all(),
                });
            }

            const newProperties: any = {};
            if (!!this.props.properties["Octopus.Action.Azure.AccountId"]) {
                if (!this.props.properties["Octopus.Action.Azure.Slot"]) {
                    newProperties["Octopus.Action.Azure.Slot"] = AzureCloudServiceEndpointDeploymentSlot.Staging;
                }
                if (!this.props.properties["Octopus.Action.Azure.SwapIfPossible"]) {
                    newProperties["Octopus.Action.Azure.SwapIfPossible"] = true;
                }
                if (!this.props.properties["Octopus.Action.Azure.UseCurrentInstanceCount"]) {
                    newProperties["Octopus.Action.Azure.UseCurrentInstanceCount"] = true;
                }
                newProperties["Octopus.Action.Azure.IsLegacyMode"] = "True";
            }
            this.props.setProperties(newProperties, true);
        });
    }

    accountSummary() {
        const accountId = this.props.properties["Octopus.Action.Azure.AccountId"];
        if (accountId) {
            const account = this.state.accounts.find(x => x.Id === accountId);
            if (account) {
                return Summary.summary(
                    <span>
                        Using the <strong>{account.Name}</strong> account
                    </span>
                );
            }
            if (isBound(accountId, false)) {
                return Summary.summary(
                    <span>
                        Account is bound to <strong>{accountId}</strong>
                    </span>
                );
            }
        }
        return Summary.placeholder("No account has been selected");
    }

    slotSummary() {
        const slot = this.props.properties["Octopus.Action.Azure.Slot"];
        if (slot) {
            if (slot === AzureCloudServiceEndpointDeploymentSlot.Staging) {
                return Summary.default(<span>Using the {slot} deployment slot</span>);
            }
            return Summary.summary(<span>Using the {slot} deployment slot</span>);
        }
        return Summary.placeholder("Deployment slot not specified");
    }

    swapSummary() {
        const swap = this.props.properties["Octopus.Action.Azure.SwapIfPossible"];
        if (swap === "True") {
            return Summary.default("When deploying to production swap staging to production, if possible");
        }
        if (swap === "False") {
            return Summary.summary("When deploying to production always perform a new deployment");
        }
        return Summary.placeholder("Swap setting not specified");
    }

    instanceCountSummary() {
        const instanceCount = this.props.properties["Octopus.Action.Azure.UseCurrentInstanceCount"];
        if (instanceCount === "True") {
            return Summary.default("Using the instance count from the current Azure deployment");
        }
        if (instanceCount === "False") {
            return Summary.summary("Using the instance count defined in the service configuration XML file");
        }
        return Summary.placeholder("Instance count not specified");
    }

    render() {
        // The package is initialized in componentDidMount, but render gets called before the update is propagated
        if (!this.props.packages || this.props.packages.length === 0) {
            return null;
        }

        const pkg = GetPrimaryPackageReference(this.props.packages);

        const properties = this.props.properties;
        const isLegacyActionType = this.isLegacyMode();
        const help =
            this.state.feeds.length > 0 ? (
                <span>
                    This step is used to deploy the contents of a package. The package that you wish to deploy should contain all the files needed to run your application. Learn more about{" "}
                    <ExternalLink href="DocumentationPackaging">what your packages should contain, and how to create them</ExternalLink>.
                </span>
            ) : (
                <span>Choose the package you wish to deploy</span>
            );

        return (
            <div>
                <UnstructuredFormSection stretchContent={true}>
                    <Callout type={CalloutType.Warning} title="Cloud Services are considered legacy">
                        Azure has announced that Cloud Services are now a legacy service and <i>Service Fabric</i> is recommended for new development. Please consider <ExternalLink href="WindowsAzure">alternate applications</ExternalLink> that you
                        can deploy to Azure.
                    </Callout>
                </UnstructuredFormSection>

                <UnstructuredFormSection stretchContent={true}>
                    {this.props.additionalActions && <AzureServerTargetRolesInfo stepTargetRoles={this.props.additionalActions.stepTargetRoles} isCompatibleWithCloudRegions={false} />}
                    <AzurePowerShellScriptInfo actionType={this.props.plugin.actionType} />
                </UnstructuredFormSection>

                <FormSectionHeading title="Package" />
                <ExpandableFormSection
                    errorKey="Octopus.Action.Package.PackageId|Octopus.Action.Package.FeedId"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Package"
                    summary={CommonSummaryHelper.packageSummary(pkg, this.state.feeds)}
                    help={help}
                >
                    <PackageSelector
                        packageId={pkg.PackageId}
                        feedId={pkg.FeedId}
                        onPackageIdChange={packageId => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))}
                        onFeedIdChange={feedId => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))}
                        packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")}
                        feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")}
                        projectId={this.props.projectId}
                        feeds={this.state.feeds}
                        localNames={this.props.localNames}
                        refreshFeeds={this.loadFeeds}
                    />
                </ExpandableFormSection>

                {isLegacyActionType && (
                    <div>
                        <FormSectionHeading title="Azure" />
                        <PermissionCheck
                            permission={Permission.AccountView}
                            wildcard={true}
                            alternate={
                                <Callout type={CalloutType.Information} title={"Permission required"}>
                                    The {Permission.AccountView} permission is required to change the Azure settings.
                                </Callout>
                            }
                        >
                            <ExpandableFormSection
                                errorKey="Octopus.Action.Azure.AccountId"
                                isExpandedByDefault={this.props.expandedByDefault}
                                title="Account"
                                summary={this.accountSummary()}
                                help={"Select the account to use for the connection. Only Azure management-certificate accounts may be selected."}
                            >
                                {this.props.properties["Octopus.Action.Azure.SubscriptionId"] && !this.props.properties["Octopus.Action.Azure.AccountId"] && (
                                    <UnstructuredFormSection>
                                        <Note>
                                            This step is using a subscription ID value imported from Octopus Deploy 2. This value, along with the default Azure certificate, will be used until an Account is selected below. From then on the
                                            subscription details from the selected Account will be used.
                                        </Note>
                                        <Text value={this.props.properties["Octopus.Action.Azure.SubscriptionId"]} label="Subscription" disabled={true} onChange={null} />
                                    </UnstructuredFormSection>
                                )}
                                <BoundAccountSelect
                                    variableLookup={{
                                        localNames: this.props.localNames,
                                        projectId: this.props.projectId,
                                    }}
                                    resetValue={properties["Octopus.Action.Azure.AccountId"]}
                                    label="Account"
                                    value={properties["Octopus.Action.Azure.AccountId"]}
                                    type={[AccountType.AzureSubscription]}
                                    allowClear={true}
                                    onChange={x => {
                                        this.props.setProperties({ ["Octopus.Action.Azure.AccountId"]: x });
                                        if (!x) {
                                            // This is a key field in determining legacy Azure steps, so if this is cleared, also
                                            // clear related data that is now contributed by the target.
                                            this.clearLegacyModeProps();
                                        }
                                    }}
                                    error={this.props.getFieldError("Octopus.Action.Azure.AccountId")}
                                    items={this.state.accounts}
                                    onRequestRefresh={this.refreshAccounts}
                                />
                            </ExpandableFormSection>

                            <ExpandableFormSection
                                errorKey={"CloudServiceName"}
                                title="Cloud Service"
                                isExpandedByDefault={this.props.expandedByDefault}
                                summary={this.props.properties["Octopus.Action.Azure.CloudServiceName"] ? Summary.summary(this.props.properties["Octopus.Action.Azure.CloudServiceName"]) : Summary.placeholder("No Cloud Service provided")}
                                help="The name of the cloud service to which files will be deployed."
                            >
                                <VariableLookupText label="Cloud Service Name" value={this.props.properties["Octopus.Action.Azure.CloudServiceName"]} onChange={val => this.props.setProperties({ ["Octopus.Action.Azure.CloudServiceName"]: val })} />
                                <Note>The Cloud Service Name provided must exactly match the Azure Cloud Service resource being targeted.</Note>
                            </ExpandableFormSection>

                            <ExpandableFormSection
                                errorKey={"StorageAccountName"}
                                title="Storage Account"
                                isExpandedByDefault={this.props.expandedByDefault}
                                summary={this.props.properties["Octopus.Action.Azure.StorageAccountName"] ? Summary.summary(this.props.properties["Octopus.Action.Azure.StorageAccountName"]) : Summary.placeholder("No Storage Account provided")}
                                help="The name of a storage account that CSPKG files will be uploaded to before deployment."
                            >
                                <VariableLookupText
                                    label="Storage Account Name"
                                    value={this.props.properties["Octopus.Action.Azure.StorageAccountName"]}
                                    onChange={val => this.props.setProperties({ ["Octopus.Action.Azure.StorageAccountName"]: val })}
                                />
                                <Note>The Storage Account Name provided must exactly match the Azure Storage Account resource being used.</Note>
                            </ExpandableFormSection>

                            <FormSectionHeading title="Deployment" />
                            <ExpandableFormSection errorKey="Octopus.Action.Azure.Slot" isExpandedByDefault={this.props.expandedByDefault} title="Slot" summary={this.slotSummary()} help="Select a slot to deploy to.">
                                <Note>
                                    Deployment Slots provide a nice way to implement Blue-Green deployments. Learn more about <ExternalLink href="VipSwap">Deployment Slots</ExternalLink>
                                </Note>
                                <BoundSelect
                                    variableLookup={{
                                        localNames: this.props.localNames,
                                        projectId: this.props.projectId,
                                    }}
                                    resetValue={""}
                                    label="Slot"
                                    value={this.props.properties["Octopus.Action.Azure.Slot"]}
                                    items={["Staging", "Production"].map(item => ({ value: item, text: item }))}
                                    onChange={val => this.props.setProperties({ ["Octopus.Action.Azure.Slot"]: val })}
                                    error={this.props.getFieldError("Octopus.Action.Azure.Slot")}
                                />
                            </ExpandableFormSection>

                            <ExpandableFormSection
                                errorKey="Octopus.Action.Azure.SwapIfPossible"
                                isExpandedByDefault={this.props.expandedByDefault}
                                title="Swap"
                                summary={this.swapSummary()}
                                help="Specify to swap staging to production rather than a new deployment."
                            >
                                <RadioButtonGroup
                                    value={this.props.properties["Octopus.Action.Azure.SwapIfPossible"]}
                                    onChange={(val: string) => this.props.setProperties({ ["Octopus.Action.Azure.SwapIfPossible"]: val })}
                                    error={this.props.getFieldError("Octopus.Action.Azure.SwapIfPossible")}
                                >
                                    <RadioButton value={"True"} label="Swap staging to production if possible" />
                                    <Note>Azure can swap staging to production deployments by switching virtual IP addresses.</Note>
                                    <RadioButton value={"False"} label="Always deploy" />
                                </RadioButtonGroup>
                            </ExpandableFormSection>

                            <ExpandableFormSection
                                errorKey="Octopus.Action.Azure.UseCurrentInstanceCount"
                                isExpandedByDefault={this.props.expandedByDefault}
                                title="Instance Count"
                                summary={this.instanceCountSummary()}
                                help="Select the source to use the instance count from."
                            >
                                <Note>
                                    If you have previously scaled your service by changing the number of instances using the Azure management portal, Octopus can retrieve these values and use them in the deployment, so that the instance count remains
                                    unchanged.
                                </Note>
                                <RadioButtonGroup
                                    value={this.props.properties["Octopus.Action.Azure.UseCurrentInstanceCount"]}
                                    onChange={(val: string) => this.props.setProperties({ ["Octopus.Action.Azure.UseCurrentInstanceCount"]: val })}
                                    error={this.props.getFieldError("Octopus.Action.Azure.UseCurrentInstanceCount")}
                                >
                                    <RadioButton value={"True"} label="Use the instance count from the current Azure deployment" />
                                    <RadioButton value={"False"} label="Use the instance count defined in the service configuration XML file" />
                                </RadioButtonGroup>
                            </ExpandableFormSection>
                        </PermissionCheck>
                    </div>
                )}
                <ExpandableFormSection
                    errorKey="Octopus.Action.Azure.IsLegacyMode"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Enable Legacy Mode"
                    summary={
                        properties["Octopus.Action.Azure.IsLegacyMode"] === "True"
                            ? Summary.summary(
                                  <span>
                                      <strong>Enabled</strong>: Account-related properties are configured on this step
                                  </span>
                              )
                            : Summary.default(<span>Not enabled: Account-related properties are configured on the Deployment Target</span>)
                    }
                    help={"Select legacy mode if you wish to configure account-related properties on the step and not through Azure Targets."}
                >
                    <BoundStringCheckbox
                        variableLookup={{
                            localNames: this.props.localNames,
                            projectId: this.props.projectId,
                        }}
                        resetValue={""}
                        value={properties["Octopus.Action.Azure.IsLegacyMode"]}
                        onChange={async x => {
                            if (x === "True") {
                                this.props.setProperties({
                                    ["Octopus.Action.Azure.IsLegacyMode"]: "True",
                                    ["Octopus.Action.Azure.Slot"]: AzureCloudServiceEndpointDeploymentSlot.Staging, // Default
                                    ["Octopus.Action.Azure.SwapIfPossible"]: "True", // Default
                                    ["Octopus.Action.Azure.UseCurrentInstanceCount"]: "True", // Default
                                });
                                await this.refreshAccounts();
                            } else {
                                this.clearLegacyModeProps();
                            }
                        }}
                        label="Enable Legacy Mode"
                    />
                    <Callout type={CalloutType.Warning} title={"Not recommended"}>
                        Toggling this <strong>on</strong> will allow account-related properties on the step <strong>(not recommended)</strong>.<br />
                        Toggling this <strong>off</strong> will clear the account-related properties on this step and allow these to be configured from your Deployment Targets.
                    </Callout>
                </ExpandableFormSection>
            </div>
        );
    }

    private refreshAccounts = () => {
        return this.props.doBusyTask(async () => {
            this.setState({ accounts: await repository.Accounts.all() });
        });
    };

    private loadFeeds = (callback?: (feeds: FeedResource[]) => void) => {
        return this.props.doBusyTask(async () => {
            this.setState({ feeds: await repository.Feeds.all() }, () => callback && callback(this.state.feeds));
        });
    };

    private isLegacyMode(): boolean {
        const properties = this.props.properties;
        const isLegacyActionType = !!properties && !!(properties["Octopus.Action.Azure.AccountId"] || properties["Octopus.Action.Azure.IsLegacyMode"] === "True");
        return isLegacyActionType;
    }

    private clearLegacyModeProps = () => {
        this.props.setProperties(
            {
                ["Octopus.Action.Azure.IsLegacyMode"]: null,
                ["Octopus.Action.Azure.AccountId"]: null,
                ["Octopus.Action.Azure.CloudServiceName"]: null,
                ["Octopus.Action.Azure.StorageAccountName"]: null,
                ["Octopus.Action.Azure.Slot"]: null,
                ["Octopus.Action.Azure.SwapIfPossible"]: null,
                ["Octopus.Action.Azure.UseCurrentInstanceCount"]: null,
            },
            false,
            this.props.refreshRunOn
        );
    };
}

pluginRegistry.registerActionForAllScopes({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.AzureCloudService",
    summary: (properties, targetRolesAsCSV, packages) => <AzureCloudServiceActionSummary properties={properties} packages={packages} targetRolesAsCSV={targetRolesAsCSV} />,
    edit: AzureCloudServiceActionEdit,
    canHaveChildren: step => false,
    canBeChild: true,
    targetRoleOption: action => {
        // Azure steps (pre 2018.5) allowed you to select accounts directly on the step, making target selection optional.
        const isLegacyActionType = !!(action ? action.Properties["Octopus.Action.Azure.AccountId"] || action.Properties["Octopus.Action.Azure.IsLegacyMode"] === "True" : null);
        return isLegacyActionType ? TargetRoles.Optional : TargetRoles.Required;
    },
    hasPackages: action => true,
    features: {
        optional: [
            "Octopus.Features.ConfigurationTransforms",
            "Octopus.Features.ConfigurationVariables",
            "Octopus.Features.CustomDirectory",
            "Octopus.Features.CustomScripts",
            "Octopus.Features.JsonConfigurationVariables",
            "Octopus.Features.SubstituteInFiles",
        ],
    },
});
