import * as React from "react";
import { SFC } from "react";
import { StringRadioButtonGroup } from "components/form/RadioButton/RadioButtonGroup";
import RadioButton from "components/form/RadioButton/RadioButton";
import { ScriptingLanguage } from "components/scriptingLanguage";

interface ScriptingLanguageSelectorProps {
    value: ScriptingLanguage;
    onChange: (value: ScriptingLanguage) => void;
    onlyRunnableOnServer?: boolean;
    autoFocus?: boolean;
    doNotAutoFocus?: boolean;
    supportedLanguages?: SupportedLanguage;
}

enum SupportedLanguage {
    All,
    PowerShell,
    PowerShellAndBash,
}
export { SupportedLanguage };

const ScriptingLanguageSelector: SFC<ScriptingLanguageSelectorProps> = (props: ScriptingLanguageSelectorProps) => {
    const options = determineAvailableLanguages(props.supportedLanguages || SupportedLanguage.All, props);

    return (
        <StringRadioButtonGroup horizontal={true} value={props.value} autoFocus={props.autoFocus} onChange={props.onChange}>
            {options}
        </StringRadioButtonGroup>
    );
};

function determineAvailableLanguages(supportedLanguages: SupportedLanguage, props: ScriptingLanguageSelectorProps) {
    const options = [<RadioButton value={ScriptingLanguage.PowerShell} label="PowerShell" key={ScriptingLanguage.PowerShell} />];
    if (supportedLanguages === SupportedLanguage.All) {
        options.push(<RadioButton value={ScriptingLanguage.CSharp} label="C#" key={ScriptingLanguage.CSharp} />);
    }
    if (supportedLanguages !== SupportedLanguage.PowerShell && !props.onlyRunnableOnServer) {
        options.push(<RadioButton value={ScriptingLanguage.Bash} label="Bash" key={ScriptingLanguage.Bash} />);
    }
    if (supportedLanguages === SupportedLanguage.All) {
        options.push(<RadioButton value={ScriptingLanguage.FSharp} label="F#" key={ScriptingLanguage.FSharp} />);
    }
    if (supportedLanguages === SupportedLanguage.All) {
        options.push(<RadioButton value={ScriptingLanguage.Python} label="Python3" key={ScriptingLanguage.Python} />);
    }
    return options;
}

ScriptingLanguageSelector.displayName = "ScriptingLanguageSelector";

ScriptingLanguageSelector.defaultProps = {
    doNotAutoFocus: true,
};

export default ScriptingLanguageSelector;
