import * as React from "react";
import { EnvironmentResource } from "client/resources";
import { FormFieldProps } from "components/form";
import { EnvironmentChip } from "components/Chips";
import ReferenceDataItem from "client/resources/referenceDataItem";
import LookupReferenceDataItemChip from "components/LookupReferenceDataItemChip";
import { ChipIcon } from "components/Chips";
import MultiSelect from "components/MultiSelect/MultiSelect";
import { FocusableComponent } from "components/VirtualListWithKeyboard/FocusableComponent";

export const EnvironmentTypedMultiSelect = MultiSelect<ReferenceDataItem>();

interface EnvironmentMultiSelectProps extends FormFieldProps<string[]> {
    items: ReferenceDataItem[];
    openOnFocus?: boolean;
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    hideFloatingLabel?: boolean;
    multiSelectRef?(component: FocusableComponent | null): void;
}

const EnvironmentMultiSelect: React.StatelessComponent<EnvironmentMultiSelectProps> = props => {
    const chipRenderer = (r: EnvironmentResource, onRequestDelete: (event: object) => void) => {
        return <LookupReferenceDataItemChip lookupCollection={props.items} lookupId={r.Id} type={ChipIcon.Environment} chipRender={item => <EnvironmentChip onRequestDelete={onRequestDelete} environmentName={item.Name} />} />;
    };

    return <EnvironmentTypedMultiSelect items={props.items} fieldName="environments" renderChip={chipRenderer} {...props} />;
};

export default EnvironmentMultiSelect;
