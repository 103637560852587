import * as React from "react";
const styles = require("./style.less");

export enum CalloutType {
    Information,
    Success,
    Warning,
    Danger,
}

interface CalloutProps {
    title: React.ReactNode;
    type: CalloutType;
}

export const Callout: React.StatelessComponent<CalloutProps> = props => {
    let iconName = null;
    let style = styles.none;
    switch (props.type) {
        case CalloutType.Information: // blue = information
            iconName = "info-circle";
            style = styles.information;
            break;
        case CalloutType.Success: // green = success
            iconName = "check-circle";
            style = styles.success;
            break;
        case CalloutType.Warning: // yellow = warning
            iconName = "exclamation-circle";
            style = styles.warning;
            break;
        case CalloutType.Danger: // red = danger
            iconName = "exclamation-triangle";
            style = styles.danger;
            break;
    }

    return (
        <div className={style}>
            <div className={styles.title}>
                <em style={{ marginRight: "0.250rem" }} className={"fa fa-" + iconName} aria-hidden="true" />
                <strong>{props.title}</strong>
            </div>
            {props.children && <div>{props.children}</div>}
        </div>
    );
};

Callout.displayName = "Callout";

export default Callout;
