import React = require("react");
import { Note } from "components/form";
import ExternalLink from "components/Navigation/ExternalLink";
import { LearnMoreResources, TopicsContainer } from "./CommonViews";
import { OverviewContainer, OverviewHighlightText, TermsText } from "./CommonViews";
const HelpImageTargets = require("../../Images/DeploymentTargets.svg");
import ImageWithPlaceholder from "components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";

export const CommonDeploymentTargetOverview: React.StatelessComponent<{}> = props => {
    return (
        <OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="OnboardingDeploymentTargetsLearnMore">Deployment targets</ExternalLink> represent the servers, machines and cloud services where your software and services will be deployed.
                </p>
                <ImageWithPlaceholder src={HelpImageTargets} alt={"Deployment targets"} />
            </OverviewHighlightText>
            <TermsText>
                <Note heading={"Target roles"}>
                    Multiple deployment targets can exist with an environment and these targets can be tagged with <ExternalLink href="TargetRoles">target roles</ExternalLink>. Target roles are used during the deployment process to specify which
                    targets are to be deployed to.
                </Note>
            </TermsText>
        </OverviewContainer>
    );
};

export const CommonDeploymentTargetTopics: React.StatelessComponent<{}> = props => {
    return (
        <TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="OnboardingDeploymentTargetsLearnMore">Deployment Targets</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="KubeTargets">Windows Targets</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="SSHTargets">Linux Targets</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="AzureTargets">Azure</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="">Kubernetes Cluster</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="OfflinePackageDrop">Offline Package Drops</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="CloudRegionTargets">Cloud Regions</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="TargetRoless">Target Roles</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>
    );
};
