import * as React from "react";
import { repository } from "clientInstance";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { SpaceResource } from "client/resources/spaceResource";
import List from "components/List";
import { RouteComponentProps } from "react-router";
import { ResourceCollection, LicenseStatusResource } from "client/resources";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import styles = require("./style.less");
import { DefaultSpaceChip } from "components/Chips";
import ActionButton, { ActionButtonType, IconPosition } from "components/Button/ActionButton";
import OpenDialogButton from "../../../../components/Dialog/OpenDialogButton";
import AddSpaceDialog from "./AddSpaceDialog";
import routeLinks from "../../../../routeLinks";
import PaperLayout from "../../../../components/PaperLayout/PaperLayout";
import { Section } from "../../../../components/Section/Section";
import { ReactNode } from "react";
import Logo from "../../../../components/Logo/Logo";
import ListTitle from "../../../../components/ListTitle/ListTitle";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import ExternalLink from "components/Navigation/ExternalLink";
import { SpaceLimitReachedDialogLayout } from "components/LicenseLimitReachedDialogLayout";
import { Note } from "components/form";
import isWithinLicenceLimit from "../License/isWithinLicenceLimit";
import Dialog from "components/Dialog/Dialog";

interface SpacesState extends DataBaseComponentState {
    spacesResponse?: ResourceCollection<SpaceResource>;
    licenseStatus?: LicenseStatusResource;
    openUpgradeDialog: boolean;
    redirectTo?: string;
}

class SpaceList extends List<SpaceResource> {}

export class Spaces extends DataBaseComponent<RouteComponentProps<{}>, SpacesState> {
    constructor(props: RouteComponentProps<{}>) {
        super(props);
        this.state = {
            openUpgradeDialog: false,
        };
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const getSpacesResponse = repository.Spaces.list();
            const getLicenseStatus = repository.Licenses.getCurrentStatus();
            this.setState({
                spacesResponse: await getSpacesResponse,
                licenseStatus: await getLicenseStatus,
            });
        });
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} />;
        }

        const isWithinSpaceLimit = isWithinLicenceLimit(this.state.licenseStatus, "Spaces");

        const addButton = isWithinSpaceLimit ? (
            <PermissionCheck permission={Permission.SpaceCreate}>
                <OpenDialogButton label="Add Space" type={ActionButtonType.Primary}>
                    <AddSpaceDialog onSpaceCreated={space => this.setState({ redirectTo: routeLinks.configuration.space(space) })} />
                </OpenDialogButton>
            </PermissionCheck>
        ) : (
            <div className={styles.spaceLimitHit}>
                <ActionButton label="UNLOCK MORE SPACES" type={ActionButtonType.Primary} onClick={() => this.showUpgradeSpacesDialog()} />
                <Note>You've hit the maximum number of Spaces.</Note>
            </div>
        );

        return (
            <PaperLayout title="Spaces" busy={this.state.busy} errors={this.state.errors} sectionControl={this.state.spacesResponse && addButton}>
                {this.state.spacesResponse && (
                    <SpaceList
                        initialData={this.state.spacesResponse}
                        onRow={(item: SpaceResource) => this.buildSpaceRow(item)}
                        onFilter={this.filter}
                        filterSearchEnabled={true}
                        apiSearchParams={["partialName"]}
                        match={this.props.match}
                        filterHintText="Filter by name..."
                    />
                )}

                <Dialog open={this.state.openUpgradeDialog}>
                    <SpaceLimitReachedDialogLayout onActionClick={() => this.setState({ openUpgradeDialog: false })} />
                </Dialog>
            </PaperLayout>
        );
    }

    private showUpgradeSpacesDialog() {
        this.setState({ openUpgradeDialog: true });
    }

    private buildSpaceRow(space: SpaceResource): ReactNode {
        return (
            <div className={styles.row}>
                <div className={styles.logo}>
                    <Logo url={space.Links.Logo} />
                </div>
                <div>
                    <ListTitle>
                        {space.Name}
                        {space.IsDefault && <DefaultSpaceChip />}
                    </ListTitle>
                    <div>{space.Description}</div>
                </div>
            </div>
        );
    }

    private filter(filter: string, resource: SpaceResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase()) || (resource.Description && resource.Description.toLowerCase().includes(filter.toLowerCase()));
    }
}

export default Spaces;
