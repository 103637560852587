import * as React from "react";
import { RouteComponentProps } from "react-router";
import LibraryLayout from "../../LibraryLayout/LibraryLayout";
import PaperLayout from "components/PaperLayout";
import { NavigationButton, NavigationButtonType } from "components/Button";
import LifecycleResource from "../../../../../client/resources/lifecycleResource";
import EnvironmentResource from "../../../../../client/resources/environmentResource";
import { repository } from "../../../../../clientInstance";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import { Permission, ResourceCollection } from "client/resources";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import List from "components/List";
import ListTitle from "components/ListTitle/ListTitle";
import LifecycleMap from "../LifecycleMap";
import MarkdownDescription from "components/MarkdownDescription";
import { ResourcesById } from "client/repositories/basicRepository";
import routeLinks from "../../../../../routeLinks";

class LifecycleList extends List<LifecycleResource> {}

interface LifecyclesState extends DataBaseComponentState {
    lifecycles: ResourceCollection<LifecycleResource>;
    environmentsById: ResourcesById<EnvironmentResource>;
}

export interface LifecycleParams {
    id: string;
}

class Lifecycles extends DataBaseComponent<RouteComponentProps<LifecycleParams>, LifecyclesState> {
    constructor(props: RouteComponentProps<LifecycleParams>) {
        super(props);

        this.state = {
            lifecycles: null,
            environmentsById: null,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const task = Promise.all<ResourceCollection<LifecycleResource>, {}>([repository.Lifecycles.list(), repository.Environments.allById()]);
            const [lifecycles, environmentsById] = await task;
            this.setState({
                lifecycles,
                environmentsById,
            });
        });
    }

    render() {
        const addLifecycleButton = (
            <PermissionCheck permission={Permission.LifecycleCreate}>
                <NavigationButton type={NavigationButtonType.Primary} label="Add Lifecycle" href={routeLinks.library.lifecyclesCreate} />
            </PermissionCheck>
        );

        return (
            <LibraryLayout {...this.props}>
                <PaperLayout title="Lifecycles" sectionControl={addLifecycleButton} busy={this.state.busy} errors={this.state.errors}>
                    {this.state.lifecycles && (
                        <LifecycleList
                            initialData={this.state.lifecycles}
                            onRow={(item: any) => this.buildLifeCycleRow(item, this.state.environmentsById)}
                            onFilter={this.filter}
                            filterSearchEnabled={true}
                            apiSearchParams={["partialName"]}
                            match={this.props.match}
                            filterHintText="Filter by name or phase name..."
                            showPagingInNumberedStyle={false}
                            onNewItems={this.loadLifecyclesWithPreviewPhases}
                        />
                    )}
                </PaperLayout>
            </LibraryLayout>
        );
    }

    private filter = (filter: string, resource: LifecycleResource) => {
        if (!filter || filter.length === 0 || !resource) {
            return true;
        }

        const loweredFilter = filter.toLowerCase();
        const filterByPhaseName = () => resource.Phases.find(p => p.Name.toLowerCase().includes(loweredFilter) || filterByOptionalDeploymentTargets(p.OptionalDeploymentTargets)) !== undefined;
        const filterByOptionalDeploymentTargets = (optionalDeploymentTargets: string[]) =>
            optionalDeploymentTargets.find(op => {
                const env = this.state.environmentsById[op];
                return env === undefined ? false : env.Name.toLowerCase().includes(loweredFilter);
            }) !== undefined;

        return resource.Name.toLowerCase().includes(loweredFilter) || filterByPhaseName();
    };

    private loadLifecyclesWithPreviewPhases = async (lifecycles: LifecycleResource[]): Promise<LifecycleResource[]> => {
        const promises = lifecycles.map(x => repository.Lifecycles.preview(x));
        let updatedLifecycles: LifecycleResource[] = null;
        await this.doBusyTask(async () => {
            const results = await Promise.all(promises);
            updatedLifecycles = results;
            return results;
        });
        return updatedLifecycles;
    };

    private buildLifeCycleRow(lifecycle: LifecycleResource, environmentsById: {}) {
        return (
            <div>
                <ListTitle>{lifecycle.Name}</ListTitle>
                <MarkdownDescription markup={lifecycle.Description} />
                <LifecycleMap lifecyclePreview={lifecycle} environmentsById={environmentsById} limitLargeLifecycles={true} />
            </div>
        );
    }
}

export default Lifecycles;
