import * as React from "react";
const styles = require("./style.less");
import * as cn from "classnames";

type SummaryNodeType = "placeholder" | "summary" | "default";

export interface SummaryNode {
    node: React.ReactNode;
    type: SummaryNodeType;
}

export default class Summary {
    static placeholder(node: React.ReactNode): SummaryNode {
        return {
            node,
            type: "placeholder",
        };
    }

    static default(node: React.ReactNode): SummaryNode {
        return {
            node,
            type: "default",
        };
    }

    static summary(node: React.ReactNode): SummaryNode {
        return {
            node,
            type: "summary",
        };
    }
}

export interface ClassNameProps {
    className?: string;
}

export interface SummaryNodeProps {
    forceMobileBehaviour: boolean;
}

const getSummaryClasses = (props: SummaryNodeProps & ClassNameProps, ...other: string[]) => cn(props.className, { [styles.cardSubTitleForceMobile]: props.forceMobileBehaviour }, other);

const SummaryContainer: React.SFC<ClassNameProps> = ({ className, children }) => <span className={className} children={children} />;

export const PlaceholderSummary: React.FC<SummaryNodeProps> = props => <SummaryContainer className={getSummaryClasses(props, styles.cardPlaceholderSubTitle)}>{props.children}</SummaryContainer>;

export const DefaultSummary: React.FC<SummaryNodeProps> = props => <SummaryContainer className={getSummaryClasses(props, styles.cardDefaultSubTitle)}>{props.children}</SummaryContainer>;

export const OverflowSummary: React.FC<SummaryNodeProps> = props => <SummaryContainer className={getSummaryClasses(props, styles.cardSubTitle)}>{props.children}</SummaryContainer>;

const lookup: Record<SummaryNodeType, React.ComponentType<SummaryNodeProps>> = {
    default: DefaultSummary,
    placeholder: PlaceholderSummary,
    summary: OverflowSummary,
};

export const getSummary = (node: SummaryNode | React.ReactNode, props: SummaryNodeProps) => {
    if (isSummaryNode(node)) {
        const { type, node: content } = node;
        const Component = lookup[type];
        return <Component {...props}>{content}</Component>;
    }
    return node;
};

const isSummaryNode = (node: SummaryNode | React.ReactNode): node is SummaryNode => {
    const summaryNode = node as SummaryNode;
    return summaryNode && summaryNode.type && lookup.hasOwnProperty(summaryNode.type);
};
