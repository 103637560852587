import * as React from "react";
import { DimensionTypes, DataCube, DashboardFilters, DimensionGetters } from "./DataCube";
import { DashboardItemResource, TagSetResource } from "client/resources";
import * as cn from "classnames";
import ToolTip from "components/ToolTip/index";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";
const styles = require("./style.less");
import routeLinks from "../../../../routeLinks";
const untenantedDeploymentLogo = require("./un-tenanted-deployment-logo.svg");
import Logo from "../../../../components/Logo/Logo";
import Tag from "../../../../components/Tag/Tag";
import { sortBy } from "lodash";
import MissingVariablesIcon from "../../../tenants/MissingVariablesIcon/MissingVariablesIcon";
import tenantMatchesFilter from "areas/tenants/TenantMatchesFilter";

interface DataSet {
    matrix: Matrix;
    columnDimension: DimensionTypes;
    groupDimension: DimensionTypes;
    rowDimension: DimensionTypes;
    getGroups(): string[];
    groupTitle(groupId: string): React.ReactNode;
    getRowsForGroup(groupId: string, take?: number): string[];
    rowTitle(rowId: string): React.ReactNode;
    getColumnsForGroup(groupId: string): string[];
    columnTitle(columnId: string): string;
    rowLabel(): string;
}

interface Matrix {
    [groupId: string]: { [rowId: string]: { [columnId: string]: DashboardItemResource[] } };
}

function getDataSet(filter: DashboardFilters, self: DataCube): DataSet {
    const rowDimension = filter.rowDimension || DimensionTypes.None;
    const columnDimension = filter.columnDimension || DimensionTypes.None;
    const groupDimension = filter.groupBy || DimensionTypes.None;

    let deployments = self.deployments;
    if (filter[DimensionTypes.Release]) {
        //To make the rest of the process quicker, filter releases (probably could filter any & all)
        deployments = deployments.filter(i => {
            return filter[DimensionTypes.Release][DimensionGetters[DimensionTypes.Release](i)];
        });
    }

    const groupedTagSet: TagSetResource = groupDimension === DimensionTypes.TagSet ? self.tagSetIndex[filter.groupByExtra] : null;

    const matrix = enterTheMatrix(deployments, getGroupGetter(groupDimension, self), rowDimension, columnDimension);

    const showUntenantedRow = showUntenanted(self);

    return {
        matrix,

        getGroups: () => {
            let groups: any[] = [null];
            if (groupDimension === DimensionTypes.TagSet) {
                groups = [null].concat(sortBy(groupedTagSet.Tags, t => t.SortOrder).map(t => t.CanonicalTagName));
            } else if (groupDimension === DimensionTypes.ProjectGroup) {
                groups = sortBy(self.projectGroupIndex, g => g.Name).map(g => g.Id);
            } else if (filter.groupBy === DimensionTypes.Channel) {
                groups = Object.keys(self.channelIndex)
                    .map(c => self.channelIndex[c])
                    .sort((channelA, channelB) => {
                        return (
                            // Sort by default channel first
                            (channelA.IsDefault === channelB.IsDefault ? 0 : channelA.IsDefault ? -1 : 1) ||
                            // Then sort by name alphabetically
                            (channelA.Name.toLowerCase() === channelB.Name.toLowerCase() ? 0 : channelA.Name.toLowerCase() < channelB.Name.toLowerCase() ? -1 : 1)
                        );
                    })
                    .map(channel => channel.Id);
            }

            if (filter.groupBy === DimensionTypes.TagSet) {
                // If we are filtering by the same tag set that we are grouping by,
                // then all tenants within an excluded group will be filtered out in `getRowsForGroup`
                // But we still want to exclude tenants that don't have any tags from the tag set we are grouping by (i.e. the `null` group)
                return groups.filter(g => g !== null);
            } else if (filter[filter.groupBy]) {
                return groups.filter(groupId => filter[filter.groupBy][groupId]);
            }
            return groups;
        },

        groupTitle: groupId => {
            if (!groupDimension || !groupId) {
                return null;
            }

            if (groupDimension === DimensionTypes.Channel) {
                if (Object.keys(self.channelIndex).length < 2) {
                    return null;
                }
                return <div>Channel: {self.channelIndex[groupId].Name}</div>;
            } else if (groupDimension === DimensionTypes.TagSet) {
                const tag = groupedTagSet.Tags.find(t => t.CanonicalTagName === groupId);
                return (
                    <div>
                        {groupedTagSet.Name}: <Tag tagName={tag.Name} description={tag.Description} tagColor={tag.Color} showTooltip={false} />
                    </div>
                );
            } else if (groupDimension === DimensionTypes.ProjectGroup) {
                return self.projectGroupIndex[groupId].Name;
            } else if (filter.groupBy === DimensionTypes.Channel) {
                if (Object.keys(self.channelIndex).length < 2) {
                    return null;
                }
                return <div>Channel: {self.channelIndex[groupId].Name}</div>;
            }

            throw new Error("Only Channel Grouping Supported");
        },

        getRowsForGroup: (groupId: string, take?: number) => {
            let rows: string[] = [];
            if (rowDimension === DimensionTypes.Tenant) {
                rows = Object.keys(self.tenantIndex);
                if (showUntenantedRow) {
                    if (groupDimension !== DimensionTypes.TagSet || (groupId === null && groupDimension === DimensionTypes.TagSet)) {
                        rows = [null].concat(rows);
                    }
                }

                if (groupDimension === DimensionTypes.TagSet) {
                    rows = rows.filter(tenantId => {
                        const tenant = self.tenantIndex[tenantId];
                        if (groupId === null) {
                            return !tenant || !groupedTagSet.Tags.find(t => tenant.TenantTags.indexOf(t.CanonicalTagName) !== -1);
                        } else {
                            return tenant && tenant.TenantTags.indexOf(groupId) !== -1;
                        }
                    });
                }

                // Tenant tag filters on the row only really makes sense if the row is tenants
                if (filter[DimensionTypes.TagSet]) {
                    const filters = Object.keys(filter[DimensionTypes.TagSet]).filter(k => filter[DimensionTypes.TagSet][k]);
                    const filteredTagsGroupedByTagSet = Object.keys(self.tagSetIndex)
                        .map(k => self.tagSetIndex[k])
                        .map(ts => ts.Tags.map(t => t.CanonicalTagName).filter(t => filters.includes(t)))
                        .filter(t => t.length > 0);
                    rows = rows.filter(tenantId => {
                        const tenant = self.tenantIndex[tenantId];
                        return tenant && tenantMatchesFilter(tenant.TenantTags, filteredTagsGroupedByTagSet);
                    });
                }
            } else if (rowDimension === DimensionTypes.Project) {
                rows = Object.keys(self.projectIndex);

                if (groupDimension === DimensionTypes.ProjectGroup) {
                    rows = rows.filter(projectId => self.projectIndex[projectId].ProjectGroupId === groupId);

                    if (filter[DimensionTypes.ProjectName]) {
                        const filterName = Object.keys(filter[DimensionTypes.ProjectName])[0];
                        rows = rows.filter(projectId => self.projectIndex[projectId].Name.toLocaleUpperCase().includes(filterName.toLocaleUpperCase()));
                    }

                    if (take) {
                        rows = limitProjects(rows, deployments, take);
                    }
                }
            } else if (rowDimension === DimensionTypes.Release) {
                rows = filter.groupBy === DimensionTypes.Channel ? Object.keys(self.releaseIndex).filter(r => self.releaseIndex[r].ChannelId === groupId) : Object.keys(self.releaseIndex);
            }

            if (filter[rowDimension]) {
                return rows.filter(rowId => filter[rowDimension][rowId]);
            }

            return rows;
        },

        rowTitle: rowId => {
            if (rowDimension === DimensionTypes.Tenant) {
                if (rowId === null) {
                    return tile(untenantedDeploymentLogo, "Untenanted");
                } else {
                    const hasMissingVariable = self.missingVariableTenants.indexOf(rowId) !== -1;
                    const tenant = self.tenantIndex[rowId];
                    return (
                        <div className={styles.rowHeader} style={{ justifyContent: "space-between" }}>
                            {tile(tenant.Links.Logo, tenant.Name, routeLinks.tenant(rowId).root)}
                            {hasMissingVariable && (
                                <InternalLink to={routeLinks.tenant(rowId).variables()}>
                                    <MissingVariablesIcon show={true} />
                                </InternalLink>
                            )}
                        </div>
                    );
                }
            } else if (rowDimension === DimensionTypes.Project) {
                const project = self.projectIndex[rowId];
                return tile(project.Links.Logo, project.Name, routeLinks.project(project).root, project.IsDisabled);
            } else if (rowDimension === DimensionTypes.Release) {
                const release = self.releaseIndex[rowId];
                const msg = "This release has been blocked from future deployments. View the release details for more information.";
                return (
                    <div className={styles.rowCell}>
                        <div className={styles.rowHeader}>
                            <InternalLink to={routeLinks.project(self.projectIndex[release.ProjectId]).release(release).root}>{release.Version}</InternalLink>
                        </div>
                        {self.blockedReleases.indexOf(rowId) !== -1 && (
                            <div className={styles.blockAlert}>
                                <ToolTip content={msg}>
                                    <em className={cn("fa fa-exclamation-triangle", styles.blockAlertIcon)} />
                                </ToolTip>
                            </div>
                        )}
                    </div>
                );
            }
        },

        getColumnsForGroup: (groupId: string) => {
            let colms = Object.keys(self.environmentIndex);

            if (groupDimension === DimensionTypes.Channel) {
                colms = self.channelEnvironments[groupId].filter(environmentId => colms.includes(environmentId));
            } else if (groupDimension === DimensionTypes.ProjectGroup) {
                const projectEnvironments = Object.keys(self.projectIndex)
                    .map(p => self.projectIndex[p])
                    .filter(p => p.ProjectGroupId === groupId)
                    .reduce((arr, p) => arr.concat(p.EnvironmentIds), []);
                colms = colms.filter(environmentId => projectEnvironments.indexOf(environmentId) !== -1);
            }

            if (filter[columnDimension]) {
                return colms.filter(rowId => filter[columnDimension][rowId]);
            }
            return colms;
        },

        columnTitle: columnId => {
            //Currently only supports environments for column
            return self.environmentIndex[columnId].Name;
        },

        rowLabel: () => {
            switch (rowDimension) {
                case DimensionTypes.Tenant:
                    return "Tenant";
                case DimensionTypes.Release:
                    return "Release";
                default:
                    return "";
            }
        },

        groupDimension,
        rowDimension,
        columnDimension,
    };
}

function getGroupGetter(groupDimension: DimensionTypes, cube: DataCube) {
    if (groupDimension === DimensionTypes.TagSet) {
        // Tag sets need special getter since they aren't stored on the deployment item itself
        return (item: DashboardItemResource) => (item.TenantId ? cube.tenantTagIndex[item.TenantId] || [] : []);
        //groupedTagSet = cube.tagSetIndex[filter.groupByExtra];
    } else if (groupDimension === DimensionTypes.ProjectGroup) {
        // Project groups need special getter since they aren't stored on the deployment item itself
        return (item: DashboardItemResource) => {
            const project = cube.projectIndex[item.ProjectId]; //the project won't be here if we've filterd due to project limit
            return project ? project.ProjectGroupId : null;
        };
    }
    return DimensionGetters[groupDimension];
}

function showUntenanted(self: DataCube): boolean {
    const canPerformUntenanted = self.projectIndex[Object.keys(self.projectIndex)[0]].CanPerformUntenantedDeployment;
    if (canPerformUntenanted) {
        return true;
    }

    return !!self.deployments.find(d => d.TenantId === null);
}

function tile(logoUrl: string, name: string, toUrl?: string, isDisabled?: boolean) {
    // These need to be in separate spans (otherwise lots of text smooshes the image, aka. main dashboard).

    if (toUrl) {
        return (
            <InternalLink to={toUrl} className={cn(styles.rowHeader, isDisabled ? styles.disabled : null)}>
                <span>
                    <Logo url={logoUrl} size="2.25rem" isDisabled={isDisabled} />
                </span>
                <span style={{ paddingLeft: "0.25rem" }} title={isDisabled ? "Disabled" : ""}>
                    {name}
                </span>
            </InternalLink>
        );
    } else {
        return (
            <div className={cn(styles.rowHeader, isDisabled ? styles.disabled : null)}>
                <span>
                    <Logo url={logoUrl} size="2.25rem" isDisabled={isDisabled} />
                </span>
                <span style={{ paddingLeft: "0.25rem" }} title={isDisabled ? "Disabled" : ""}>
                    {name}
                </span>
            </div>
        );
    }
}

type GroupingFunction = ((item: DashboardItemResource) => string[]) | ((item: DashboardItemResource) => string);

function enterTheMatrix(deployments: DashboardItemResource[], groupByFunction: GroupingFunction, rowDimension: DimensionTypes, columnDimension: DimensionTypes): Matrix {
    const rowFromTask = DimensionGetters[rowDimension];
    const columnFromTask = DimensionGetters[columnDimension];

    const matrix: Matrix = {};

    deployments.forEach((deploymentTask: DashboardItemResource) => {
        let groupingIds = groupByFunction(deploymentTask);

        if (!Array.isArray(groupingIds)) {
            groupingIds = [groupingIds];
        }
        if (groupingIds.length === 0) {
            groupingIds = [null];
        }

        groupingIds.forEach(groupingId => {
            let group = matrix[groupingId];
            if (!group) {
                group = matrix[groupingId] = {};
            }

            const rowId = rowFromTask(deploymentTask);
            let row = group[rowId];
            if (!row) {
                row = group[rowId] = {};
            }

            const columnId = columnFromTask(deploymentTask);
            if (!row[columnId]) {
                row[columnId] = [];
            }

            row[columnId].push(deploymentTask);
        });
    });

    Object.keys(matrix).forEach(groupId =>
        Object.keys(matrix[groupId]).forEach(rowId =>
            Object.keys(matrix[groupId][rowId]).forEach(columnId => {
                const latestByContext = getLatestDeploymentPerContext(matrix[groupId][rowId][columnId]);
                matrix[groupId][rowId][columnId] = sortBy(latestByContext, [(t: DashboardItemResource) => t.ReleaseVersion, (t: DashboardItemResource) => new Date(t.CompletedTime || t.Created)]);
            })
        )
    );

    return matrix;
}

function getLatestDeploymentPerContext(deployments: DashboardItemResource[]) {
    const latestPerDeploymentContext = deployments.reduce<{ [key: string]: DashboardItemResource }>((idx: { [index: string]: DashboardItemResource }, item: DashboardItemResource) => {
        const key = item.EnvironmentId + item.ReleaseId + item.TenantId + item.ProjectId;
        const current = idx[key] || null;
        if (!current || new Date(current.CompletedTime || current.Created) < new Date(item.CompletedTime || current.Created)) {
            idx[key] = item;
        }
        return idx;
    }, {});

    return Object.keys(latestPerDeploymentContext).map(e => latestPerDeploymentContext[e]);
}

function limitProjects(data: string[], items: DashboardItemResource[], limit: number): string[] {
    if (data.length <= limit) {
        return data;
    }

    const included = limit > 0 ? data.slice(0, limit) : [];
    const erroredOrRunning = items.filter(p => p.HasWarningsOrErrors || !p.IsCompleted);

    const keep = data.slice(limit).filter(p => erroredOrRunning.some(e => e.ProjectId === p));

    return included.concat(keep);
}

export { DataSet, Matrix, getDataSet };
