import * as React from "react";

interface EndpointThumbnailProps {
    src: string;
    alt?: string;
}

export const EndpointThumbnail: React.SFC<EndpointThumbnailProps> = props => {
    return <img src={props.src} alt={props.alt} />;
};
