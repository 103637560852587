import * as React from "react";
import ExternalLink from "../Navigation/ExternalLink";
import { repository } from "clientInstance";
import Divider from "components/Divider/Divider";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { default as Callout, CalloutType } from "components/Callout/Callout";
import { ServerStatusResource } from "../../client/resources";
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import { clearUnhandledErrors, raiseUnhandledError, currentUnhandledErrorSelector } from "components/UnhandledError/reducers";

import { KnownErrorCodes, UnhandledError } from "components/UnhandledError";

const styles = require("./style.less");

interface SystemMessagesBannerState extends DataBaseComponentState {
    browserNotSupported: boolean;
    browserName: string;
    serverStatusMessage: string;
}

interface GlobalConnectedProps {
    error: UnhandledError;
}

interface GlobalDispatchProps {
    onError: (error: Error, id?: string) => void;
    clearError: () => void;
}

type SystemMessagesBannerProps = GlobalConnectedProps & GlobalDispatchProps;

export class SystemMessagesBanner extends DataBaseComponent<SystemMessagesBannerProps, SystemMessagesBannerState> {
    // this resource object is needed only once so that we can obtain the health url
    singleUseServerStatusResource: ServerStatusResource = null;

    constructor(props: SystemMessagesBannerProps) {
        super(props);

        let browserNotSupported = false;
        let browserName = "";

        const ieVersion = (document as any)["documentMode"];
        if (ieVersion && ieVersion <= 10) {
            browserNotSupported = true;
            browserName = "Internet Explorer " + ieVersion;
        }

        this.state = {
            serverStatusMessage: null,
            browserNotSupported,
            browserName,
        };
    }

    async componentDidMount() {
        this.singleUseServerStatusResource = await repository.ServerStatus.getServerStatus();
        await this.doBusyTask(async () => {
            await this.startRefreshLoop(() => this.refreshData(), 10000);
        });
    }

    async refreshData() {
        const health = await repository.ServerStatus.getHealth(this.singleUseServerStatusResource).then(
            succ => {
                if (this.props.error && this.props.error.id === KnownErrorCodes.NetworkError) {
                    this.props.clearError();
                }
                return succ;
            },
            err => {
                // Returns 418 if the server node is draining or offline
                // Treat other non 200 as a standard failure
                if (err.StatusCode === 418) {
                    return err;
                } else if (err.StatusCode === KnownErrorCodes.NetworkError) {
                    this.props.onError(err);
                    return null;
                }
                throw err;
            }
        );

        return { serverStatusMessage: health && !health.IsOperatingNormally ? health.Description : null };
    }

    render() {
        return (
            <div className={styles.pageBanners}>
                {this.state.serverStatusMessage && (
                    <Callout type={CalloutType.Warning} title={"Server status"}>
                        {this.state.serverStatusMessage}
                    </Callout>
                )}
                {this.state.serverStatusMessage && this.state.browserNotSupported && <Divider />}
                {this.state.browserNotSupported && (
                    <Callout
                        type={CalloutType.Warning}
                        title={
                            <span>
                                Your current browser, <strong>{this.state.browserName}</strong>, is not supported in <strong>Octopus\n" + "4</strong>.
                            </span>
                        }
                    >
                        We still allow you to use Octopus but no guarantee that everything works!
                        <br />
                        If you have any questions or feedback then please share them with us{" "}
                        <strong>
                            <ExternalLink href="https://github.com/OctopusDeploy/Issues/issues/3457">here</ExternalLink>
                        </strong>
                        .
                    </Callout>
                )}
            </div>
        );
    }
}

const mapGlobalActionDispatchersToProps = (dispatch: Dispatch<Action>) => ({
    onError: (error: Error, id?: string) => dispatch(raiseUnhandledError(error, id)),
    clearError: () => dispatch(clearUnhandledErrors()),
});

const mapGlobalStateToProps = (state: GlobalState) => ({
    error: currentUnhandledErrorSelector(state),
});

const EnhancedSystemMessagesBanner = connect(
    mapGlobalStateToProps,
    mapGlobalActionDispatchersToProps
)(SystemMessagesBanner);
export default EnhancedSystemMessagesBanner;
