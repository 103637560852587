import * as React from "react";
import { createContext } from "react";

export interface FocusContextState {
    current: React.ReactElement;
}

const { Provider, Consumer } = createContext<FocusContextState>(null);

export { Provider as FocusContextProvider, Consumer as FocusContextConsumer };
