import { MetadataTypeCollection } from "./dynamicFormResources";

export class ExtensionSettingsValues {
    ExtensionId: string;
    Values: any;
}

export class EnvironmentSettingsMetadata {
    ExtensionId: string;
    Metadata: MetadataTypeCollection;
}
