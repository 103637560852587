import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonWorkerPoolOverview, CommonWorkerPoolTopics } from "../../CommonComponents/CommonWorkerPoolViews";

export const InfrastructureWorkerPoolNewRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.infrastructure.workerPools.create,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonWorkerPoolOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonWorkerPoolTopics />;
    },
};
