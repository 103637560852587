import * as React from "react";
import { LetsEncryptConfigurationResource, NewTaskResource } from "client/resources";
import { Callout, CalloutType } from "components/Callout/Callout";
import TimeFromNowLabel from "components/TimeLabels/TimeFromNowLabel";
import { ActionButton } from "components/Button";
import { ConfigureLetsEncryptArguments, TaskName, TaskResource } from "client/resources/taskResource";
import { sortBy, last } from "lodash";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import { NavigationButton, NavigationButtonType } from "components/Button/NavigationButton";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { repository } from "clientInstance";
import { RouteComponentProps } from "react-router";
import { Section } from "components/Section/Section";
import { ActionButtonType } from "components/Button/ActionButton";
import OpenDeleteDialogButton from "components/Button/OpenDeleteDialogButton";
import routeLinks from "../../../../routeLinks";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import SectionNote from "components/SectionNote/SectionNote";

interface LetsEncryptState extends DataBaseComponentState {
    config?: LetsEncryptConfigurationResource;
    latestTasks?: Array<TaskResource<ConfigureLetsEncryptArguments>>;
    redirectToTask?: string;
    octopusServerIsInACluster?: boolean;
}

export default class LetsEncrypt extends DataBaseComponent<RouteComponentProps<{}>, LetsEncryptState> {
    constructor(props: RouteComponentProps<{}>) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        return this.doBusyTask(async () => {
            const getLetsEncryptConfiguration = repository.LetsEncryptConfiguration.get();
            const nodes = repository.OctopusServerNodes.all();
            const getLetsEncryptTasks = repository.Tasks.list({ name: TaskName.ConfigureLetsEncrypt, take: 30 });
            this.setState({
                config: await getLetsEncryptConfiguration,
                latestTasks: (await getLetsEncryptTasks).Items,
                octopusServerIsInACluster: (await nodes).length > 1,
            });
        });
    }

    getMostRecentCompleted() {
        const completed = this.state.latestTasks.filter(t => !!t.CompletedTime);
        return last(sortBy(completed, t => t.CompletedTime));
    }

    getMostRecentQueued() {
        return last(sortBy(this.state.latestTasks, t => t.QueueTime));
    }

    getEnabledStatusMessage() {
        const mostRecentCompleted = this.getMostRecentCompleted();

        if (!mostRecentCompleted) {
            const mostRecentQueued = this.getMostRecentQueued();

            return mostRecentQueued ? (
                <Section>
                    Let's Encrypt integration is currently enabled, but has not yet completed it's initial configuration. The <InternalLink to={routeLinks.task(mostRecentQueued).root}>task</InternalLink> was queued
                    <TimeFromNowLabel time={mostRecentQueued.QueueTime} /> and is currently {mostRecentQueued.State}
                </Section>
            ) : (
                <Section>Let's Encrypt integration is currently enabled, but no Let's Encrypt renewal task can be found. It may have been deleted.</Section>
            );
        }

        const taskLink = (
            <InternalLink to={routeLinks.task(mostRecentCompleted).root}>
                <TimeFromNowLabel time={mostRecentCompleted.CompletedTime} />
            </InternalLink>
        );
        const expiryLabel = <TimeFromNowLabel time={this.state.config.CertificateExpiryDate} />;

        return mostRecentCompleted.FinishedSuccessfully ? (
            <Section>
                <p>
                    Let's Encrypt integration is currently enabled; the SSL certificate was last renewed {taskLink} and is due to expire {expiryLabel}.
                </p>
                <p>Octopus will automatically request a new certificate 21 days before this certificate expires.</p>
            </Section>
        ) : (
            <Callout type={CalloutType.Danger} title={"Unable to renew certificate"}>
                Let's Encrypt integration is currently enabled, but we have been unable to renew the certificate successfully. The last attempt was {taskLink}. The certificate is due to expire {expiryLabel}.
            </Callout>
        );
    }

    render() {
        if (this.state.redirectToTask) {
            return <InternalRedirect to={routeLinks.task(this.state.redirectToTask).root} push={true} />;
        }

        const config = this.state.config;

        const button =
            config && config.Enabled ? (
                <OpenDeleteDialogButton label="Disable" dialogTitle="Disable Let's Encrypt" dialogButtonLabel="Disable" dialogButtonBusyLabel="Disabling..." disabled={false} onDeleteClick={() => this.disable()}>
                    <div>Are you sure you want to disable Let's Encrypt?</div>
                </OpenDeleteDialogButton>
            ) : !this.state.octopusServerIsInACluster ? (
                <NavigationButton type={NavigationButtonType.Primary} label="Configure" href={`${this.props.match.url}/configure`} />
            ) : null;

        const renewNowButton = config && config.Enabled && !!config.CertificateExpiryDate && <ActionButton type={ActionButtonType.Primary} label="Renew Now" onClick={() => this.renewNow()} />;

        const body = config && (
            <div>
                {this.state.octopusServerIsInACluster ? (
                    <Callout type={CalloutType.Warning} title={"Not supported in High Availability configurations"}>
                        Let's Encrypt is only supported in single node Octopus Server configurations. <ExternalLink href="LetsEncryptIntegration#high-availability-configurations-not-supported">Learn more</ExternalLink>
                    </Callout>
                ) : null}
                {this.state.config.Enabled ? (
                    this.getEnabledStatusMessage()
                ) : (
                    <SectionNote>
                        Octopus can integrate with <ExternalLink href="LetsEncryptOrg">Let's Encrypt</ExternalLink> to automatically manage the TLS/SSL certificate for the Octopus Portal.{" "}
                        <ExternalLink href="LetsEncryptIntegration">Learn more</ExternalLink>.
                    </SectionNote>
                )}
            </div>
        );

        return (
            <PaperLayout
                title="Let's Encrypt"
                sectionControl={
                    <span>
                        {" "}
                        {renewNowButton} {button}{" "}
                    </span>
                }
                busy={this.state.busy}
                errors={this.state.errors}
            >
                {this.state.config && body}
            </PaperLayout>
        );
    }

    private async disable() {
        const config = await repository.LetsEncryptConfiguration.get();
        config.Enabled = false;
        const updated = await repository.LetsEncryptConfiguration.modify(config);
        this.setState({ config: updated });
        return true;
    }

    private renewNow() {
        return this.doBusyTask(async () => {
            const config = this.state.config;

            const task = await repository.Tasks.createRenewLetsEncryptTask({
                DnsName: config.DnsName,
                RegistrationEmailAddress: config.RegistrationEmailAddress,
                AcceptLetsEncryptTermsOfService: config.AcceptLetsEncryptTermsOfService,
                HttpsPort: config.HttpsPort,
                IPAddress: config.IPAddress,
                Path: config.Path,
            });

            this.setState({ redirectToTask: task.Id });
        });
    }
}
