import * as React from "react";
import { Switch } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import SecureRoute from "./components/SecureRoute";
import { StandardLayout } from "./components/StandardLayout";
import SignIn from "./areas/authentication/SignIn";
import SignOut from "./areas/authentication/SignOut";
import Register from "./areas/authentication/Register";
import getUserConfirmation from "./components/ConfirmNavigate";
import AuthenticationLayout from "./areas/authentication/AuthenticationLayout";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import routeLinks from "./routeLinks";

export default class App extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <HashRouter getUserConfirmation={getUserConfirmation as any}>
                <Switch>
                    <ReloadableRoute
                        path={routeLinks.currentUser.signIn}
                        render={props => (
                            <AuthenticationLayout>
                                <SignIn {...props} />
                            </AuthenticationLayout>
                        )}
                    />
                    <ReloadableRoute
                        path={routeLinks.currentUser.signOut}
                        render={(props: any) => (
                            <AuthenticationLayout>
                                <SignOut {...props} />
                            </AuthenticationLayout>
                        )}
                    />
                    <ReloadableRoute
                        path={routeLinks.currentUser.register(":inviteCode")}
                        render={(props: any) => (
                            <AuthenticationLayout>
                                <Register {...props} />
                            </AuthenticationLayout>
                        )}
                    />
                    <SecureRoute path={routeLinks.root} component={StandardLayout} />
                </Switch>
            </HashRouter>
        );
    }
}
