import * as React from "react";
import { TenantResource } from "client/resources";
import MultiSelect from "./MultiSelect";
import { FormFieldProps } from "components/form";
import { TenantChip } from "components/Chips";
import LookupReferenceDataItemChip from "components/LookupReferenceDataItemChip";
import { ChipIcon } from "components/Chips";

interface TenantMultiSelectProps extends FormFieldProps<string[]> {
    items: TenantResource[];
    label?: string | JSX.Element;
    error?: string;
}

const TenantTypedMultiSelect = MultiSelect<TenantResource>();

const TenantMultiSelect: React.StatelessComponent<TenantMultiSelectProps> = props => {
    const chipRenderer = (r: TenantResource, onRequestDelete: (event: object) => void) => {
        return <LookupReferenceDataItemChip lookupCollection={props.items} lookupId={r.Id} type={ChipIcon.Tenant} chipRender={(item: TenantResource) => <TenantChip onRequestDelete={onRequestDelete} tenantName={item.Name} />} />;
    };

    return <TenantTypedMultiSelect items={props.items} fieldName="tenants" renderChip={chipRenderer} {...props} />;
};

export default TenantMultiSelect;
