import ExternalLink from "../../Navigation/ExternalLink";
import * as React from "react";
import pluginRegistry, { ActionEditProps } from "../../Actions/pluginRegistry";
import { BaseComponent } from "../../BaseComponent/BaseComponent";
import { ExpandableFormSection, Summary } from "../../form";
import Note from "../../form/Note/Note";
import { VariableLookupText } from "../../form/VariableLookupText";
import MoreInfo from "../../form/Sections/MoreInfo";
import ActionProperties from "client/resources/actionProperties";
import { ValueInPropertiesOrErrorsHasChanged } from "utils/ShouldUpdate/ValueInPropertiesHasChanged";

const StringProperties = {
    "Octopus.Action.Package.JsonConfigurationVariablesEnabled": "",
    "Octopus.Action.Package.JsonConfigurationVariablesTargets": "",
};

type JsonConfigurationProperties = { [P in keyof typeof StringProperties]: string };

class JsonConfigurationVariablesEdit extends BaseComponent<ActionEditProps<JsonConfigurationProperties>, never> {
    shouldComponentUpdate(nextProps: ActionEditProps<JsonConfigurationProperties>) {
        return ValueInPropertiesOrErrorsHasChanged(StringProperties, nextProps, this.props);
    }

    summary() {
        const json = this.props.properties["Octopus.Action.Package.JsonConfigurationVariablesTargets"] || "";
        if (json.length > 0) {
            return Summary.summary(<span>Variable substitution will be performed on configured JSON files</span>);
        } else {
            return Summary.placeholder("No JSON files configured");
        }
    }
    render() {
        const properties = this.props.properties;

        return (
            <ExpandableFormSection errorKey="jsonvariables" isExpandedByDefault={this.props.expandedByDefault} title="JSON Configuration Variables" summary={this.summary()} help="Configure JSON file variable replacements.">
                <VariableLookupText
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    value={properties["Octopus.Action.Package.JsonConfigurationVariablesTargets"]}
                    onChange={x => this.props.setProperties({ ["Octopus.Action.Package.JsonConfigurationVariablesTargets"]: x })}
                    multiLine={true}
                    error={this.props.getFieldError("Octopus.Action.Package.JsonConfigurationVariablesTargets")}
                    label="Target files"
                />
                <Note>
                    A comma- or newline-separated list of file names to replace settings in, relative to the package contents. Extended wildcard syntax is supported. E.g., <em>appsettings.json</em>, <em>Config\*.json</em>,{" "}
                    <em>**\specific-folder\*.json.</em>. Configuration values can be replaced using a <code>:</code> separated key, starting from the root of the hierarchy.
                </Note>
                <MoreInfo
                    content={
                        <div>
                            <p>
                                Consider the following <em>appsettings.json</em> file:
                            </p>
                            <pre>{`{
    "Data": {
    "DefaultConnection": {
    "ConnectionString": "Server=(localdb)\\SQLEXPRESS;Database=OctoFX;Trusted_Connection=True"
         }
    }
}`}</pre>
                            <p>
                                To replace the value of <code>ConnectionString</code> you would add a variable name <code>Data:DefaultConnection:ConnectionString</code> to your project variables / library variable set. The JSON also supports{" "}
                                <ExternalLink href="VariableSubstitutionSyntaxExtended">extended template syntax</ExternalLink>.
                            </p>
                            <p>
                                The 'Target files' field supports <ExternalLink href="VariableSubstitutionSyntaxExtended">extended template syntax</ExternalLink>. Conditional <code>if</code> and <code>unless</code>:
                            </p>
                            <pre>{`#{if MyVar}...#{/if}`}</pre>
                            <p>
                                Iteration over variable sets or comma-separated values with <code>each</code>:
                            </p>
                            <pre>{`#{each mv in MyVar}...#{mv}...#{/each}`}</pre>
                        </div>
                    }
                />
            </ExpandableFormSection>
        );
    }
}

pluginRegistry.registerFeatureForAllScopes({
    featureName: "Octopus.Features.JsonConfigurationVariables",
    title: "JSON Configuration Variables",
    description: "Replace settings in selected JSON files with variables defined in Octopus.",
    edit: JsonConfigurationVariablesEdit,
    priority: 15,
    enable: (properties: ActionProperties) => {
        properties["Octopus.Action.Package.JsonConfigurationVariablesEnabled"] = "True";
    },
    disable: (properties: ActionProperties) => {
        delete properties["Octopus.Action.Package.JsonConfigurationVariablesEnabled"];
        delete properties["Octopus.Action.Package.JsonConfigurationVariablesTargets"];
    },
});
