import pageHelpRegistry, { isPageHelpRegistration, PageHelpRegistration } from "./pageHelpRegistry";

type RequireContext = __WebpackModuleApi.RequireContext;
type Exports = Record<string, any> | null;

const mapExports = (request: string, exports: Exports): PageHelpRegistration => {
    if (!exports) {
        throw new Error(`${request} did not export anything.`);
    }

    const keys = Object.keys(exports).filter(value => /Registration$/.test(value));
    const found = exports[keys[0] || "default"];

    if (!found) {
        throw new Error(`Could not register page help for ${request}. There wasn't an export ending with 'Registration' nor a default export`);
    } else if (!isPageHelpRegistration(found)) {
        throw new Error(`Could not register page help for ${request}. The exported value did not appear to be a valid PageHelpRegistration`);
    }

    return found;
};

const registerAllPageHelpers = (r: RequireContext) =>
    context.keys().forEach((request: string) => {
        const exports: Exports = r(request);
        const help = mapExports(request, exports);
        pageHelpRegistry.registerPageHelp(help);
    });

const context = require.context("../Registrations", true, /Registration\.(ts|tsx)$/);
registerAllPageHelpers(context);
