import * as _ from "lodash";

export const required = (message: string) => {
    return (value: string) => {
        if (!value || value.length < 1) {
            return message;
        }
        return "";
    };
};

export const email = (message: string) => {
    return (value: string) => {
        if (!value || !value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            return message;
        }
        return "";
    };
};

export const minimum = (message: string, min: number) => {
    return (value: string) => {
        const result: number = Number(value);
        if (isNaN(result)) {
            return message;
        }
        return result < min ? message : "";
    };
};

export const dropdownOptionsValidator = () => {
    return (value: string) => {
        if (!value || value === "") {
            return "";
        }
        const result = _.map(value.split("\n"), (line: string, index: number) => {
            if (line.indexOf("|") === -1) {
                return `Line ${index + 1} does not contain a | delimiter`;
            }
            if (line.indexOf(" |") > -1 || line.indexOf("| ") > -1) {
                return `Line ${index + 1} contains spaces next to the | delimiter, which can cause binding issues`;
            }
            if (line.indexOf("|") === 0) {
                return `Line ${index + 1} does not contain a value preceding the | delimiter`;
            }
            if (line.indexOf("|") === line.length - 1) {
                return `Line ${index + 1} does not contain display text after the | delimiter`;
            }
        }).filter(x => x);
        return result.length > 0 ? result[0] : "";
    };
};
