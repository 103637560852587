import * as React from "react";
import { RefreshIndicator } from "material-ui";
import { secondaryText } from "theme/colors";

interface BusyRefreshIndicatorProps {
    show: boolean;
}

const BusyRefreshIndicator: React.StatelessComponent<BusyRefreshIndicatorProps> = props => {
    return (
        props.show && (
            <RefreshIndicator
                size={28} /* This size matches the .title class line-height */
                left={10}
                top={0}
                status="loading"
                loadingColor={secondaryText}
                style={{
                    display: "inline-block",
                    position: "relative",
                    boxShadow: "unset",
                }}
            />
        )
    );
};

BusyRefreshIndicator.displayName = "BusyRefreshIndicator";

export default BusyRefreshIndicator;
