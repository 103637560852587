import * as React from "react";
import FormFieldProps from "../form/FormFieldProps";
import { ScriptingLanguage } from "components/scriptingLanguage";
import InputLabel from "components/form/InputLabel/InputLabel";
import IconButton, { Icon } from "components/IconButton/IconButton";
const styles = require("./styles.less");
import { GlobalConnectedProps, TextInputRef, withVariableLookup } from "components/form/VariableLookup/VariableLookup";

import * as cn from "classnames";
import { noOp } from "utils/noOp";

const CodeMirror = require("@skidding/react-codemirror");
const codeMirror = require("./codeMirror.css");
require("codemirror/mode/powershell/powershell");
require("codemirror/mode/javascript/javascript");
require("codemirror/mode/clike/clike");
require("codemirror/mode/mllike/mllike");
require("codemirror/mode/shell/shell");
require("codemirror/mode/python/python");
require("codemirror/mode/xml/xml");
require("codemirror/mode/htmlmixed/htmlmixed");
require("codemirror/mode/css/css");
require("codemirror/mode/properties/properties");
require("codemirror/mode/coffeescript/coffeescript");
require("codemirror/mode/markdown/markdown");
require("codemirror/mode/dockerfile/dockerfile");
require("codemirror/mode/yaml/yaml");
require("codemirror/lib/codemirror");
require("codemirror/addon/display/fullscreen");
require("codemirror/addon/fold/foldgutter");
require("codemirror/addon/fold/foldcode");
require("codemirror/addon/fold/brace-fold.js");
require("codemirror/addon/fold/xml-fold.js");
require("codemirror/addon/fold/indent-fold.js");
require("codemirror/addon/fold/markdown-fold.js");
require("codemirror/addon/fold/comment-fold.js");

interface CodeEditorProps extends FormFieldProps<string> {
    containerClassName?: string;
    language: ScriptingLanguage | Language | TextFormat;
    allowFullScreen?: boolean;
    readOnly?: boolean;
    label?: string | JSX.Element;
    autoFocus?: boolean;
    onEscPressed?(): void;
    textInputRef?(textInputRef: TextInputRef | null): void;
}

interface CodeEditorState {
    containerClassName: string;
    isInFullScreen: boolean;
}

export enum Language {
    HTML = "HTML",
    CSS = "CSS",
    Markdown = "Markdown",
    DockerFile = "DockerFile",
    INI = "INI",
    CoffeeScript = "CoffeeScript",
}

export enum TextFormat {
    JSON = "JSON",
    PlainText = "PlainText",
    XML = "XML",
    YAML = "YAML",
}

export default class CodeEditor extends React.Component<CodeEditorProps, CodeEditorState> {
    public static defaultProps: Partial<CodeEditorProps> = {
        readOnly: false,
    };

    static toMode(language: ScriptingLanguage | Language | TextFormat) {
        switch (language) {
            case ScriptingLanguage.Bash:
                return "shell";
            case ScriptingLanguage.CSharp:
                return "text/x-csharp";
            case ScriptingLanguage.FSharp:
                return "text/x-fsharp";
            case ScriptingLanguage.Python:
                return "text/x-python";
            case TextFormat.JSON:
                return "application/json";
            case TextFormat.PlainText:
                return "null";
            case ScriptingLanguage.PowerShell:
                return "powershell";
            case TextFormat.XML:
                return "text/html";
            case TextFormat.YAML:
                return "text/x-yaml";
            case Language.CoffeeScript:
                return "application/vnd.coffeescript";
            case Language.CSS:
                return "text/css";
            case Language.DockerFile:
                return "text/x-dockerfile";
            case Language.HTML:
                return "text/html";
            case Language.INI:
                return "text/x-ini";
            case Language.Markdown:
                return "text/x-markdown";
            default:
                return "null";
        }
    }

    private codeMirrorInstance: any;
    private cursorPosition: { line: number; ch: number } | null = null;

    constructor(props: CodeEditorProps) {
        super(props);
        this.state = {
            containerClassName: styles.codeEditorContainer,
            isInFullScreen: false,
        };
    }

    componentDidMount() {
        requestAnimationFrame(() => {
            //Code mirror has some issues with refreshing things, so queue things to focus and refresh as soon as we are done mounting
            //this fixes issues where the editor isn't rendered, focused or line numbers don't align.
            if (this.props.autoFocus) {
                this.focus();
            }

            this.codeMirrorInstance ? this.codeMirrorInstance.getCodeMirror().refresh() : noOp();
        });
    }

    componentWillMount() {
        if (this.props.textInputRef) {
            this.props.textInputRef(this);
        }
    }

    componentWillUnmount() {
        if (this.props.textInputRef) {
            this.props.textInputRef(null);
        }
        if (this.state.isInFullScreen) {
            this.toggleFullScreen();
        }
    }

    render() {
        const options = {
            mode: CodeEditor.toMode(this.props.language),
            lineNumbers: true,
            extraKeys: {},
            readOnly: this.props.readOnly,
            gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
            foldOptions: {
                widget: "...",
            },
            foldGutter: true,
        };
        const val = this.props.value ? this.props.value : "";

        if (this.props.allowFullScreen) {
            options.extraKeys = {
                Esc: (cm: any) => {
                    if (cm.getOption("fullScreen")) {
                        this.toggleFullScreen();
                    }
                },
            };
        }

        // This one override the full screen rule as user wants to handle it explicitly
        if (this.props.onEscPressed) {
            options.extraKeys = {
                Esc: () => {
                    this.props.onEscPressed();
                },
            };
        }

        return (
            <React.Fragment>
                {this.props.label && <InputLabel label={this.props.label} />}
                <div className={cn(this.state.containerClassName, this.props.containerClassName)}>
                    {this.props.allowFullScreen && this.fullScreenToggle()}
                    <CodeMirror
                        ref={(ref: any) => (this.codeMirrorInstance = ref)}
                        // tslint:disable-next-line: object-literal-key-quotes
                        className={cn({ readonly: this.props.readOnly })}
                        preserveScrollPosition={true}
                        value={val}
                        onFocusChange={this.onFocusChange}
                        onChange={this.handleChange}
                        options={options}
                    />
                </div>
            </React.Fragment>
        );
    }

    focus() {
        if (this.codeMirrorInstance) {
            this.codeMirrorInstance.focus();
            if (this.cursorPosition) {
                this.codeMirrorInstance.getCodeMirror().setCursor(this.cursorPosition);
            }
        }
    }

    blur() {
        if (this.codeMirrorInstance) {
            this.codeMirrorInstance
                .getCodeMirror()
                .getInputField()
                .blur();
        }
    }

    insertAtCursor(value: string) {
        this.codeMirrorInstance.getCodeMirror().replaceSelection(value, "end");
        this.cursorPosition = this.codeMirrorInstance.getCodeMirror().getCursor();
    }

    private onFocusChange = (focused: boolean) => {
        if (!focused) {
            this.cursorPosition = this.codeMirrorInstance.getCodeMirror().getCursor();
        }
    };

    private handleChange = (value: any) => {
        this.props.onChange(value);
    };

    private toggleFullScreen = () => {
        const current = this.codeMirrorInstance.getCodeMirror().getOption("fullScreen");
        const containerClassName = current ? styles.codeEditorContainer : styles.codeEditorContainerFullScreen;
        this.setState({ containerClassName, isInFullScreen: !current });
        this.codeMirrorInstance.getCodeMirror().setOption("fullScreen", !current);
    };

    private fullScreenToggle() {
        const isInFullScreen = this.state.isInFullScreen;

        return (
            <div className={isInFullScreen ? styles.exitFullScreen : styles.enterFullScreen}>
                <div>
                    <IconButton toolTipContent={`${isInFullScreen ? "Exit" : "Enter"} full screen`} onClick={this.toggleFullScreen} icon={isInFullScreen ? Icon.ExitFullScreen : Icon.EnterFullScreen} />
                </div>
            </div>
        );
    }
}

const CodeEditorWithInputRef = (props: CodeEditorProps & GlobalConnectedProps) => <CodeEditor textInputRef={props.textInputRef} {...props} />;

export const VariableLookupCodeEditor = withVariableLookup()(CodeEditorWithInputRef);
