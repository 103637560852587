import React = require("react");
import { Note } from "components/form";
import { primaryDarkest } from "theme/colors";
const styles = require("./Registrations.less");
import HelpOutlineIcon from "material-ui/svg-icons/action/help-outline";

export const OverviewContainer: React.StatelessComponent<{}> = props => {
    return <div className={styles.overviewContainer}>{props.children}</div>;
};

export const TopicsContainer: React.StatelessComponent<{}> = props => {
    return <div className={styles.topicsContainer}>{props.children}</div>;
};

export const OverviewHighlightText: React.StatelessComponent<{}> = props => {
    return <div className={styles.overviewHighlight}>{props.children}</div>;
};

export const TermsText: React.StatelessComponent<{}> = props => {
    return <div className={styles.terms}>{props.children}</div>;
};

export const LearnMoreResources: React.StatelessComponent<{}> = props => {
    return (
        <div className={styles.docs}>
            <h5>Learn more</h5>
            {props.children}
        </div>
    );
};

export const SupportResources: React.StatelessComponent<{}> = props => {
    return (
        <div className={styles.support}>
            <h5>Support</h5>
            {props.children}
        </div>
    );
};

export const NewToOctopusResources: React.StatelessComponent<{}> = props => {
    return (
        <div className={styles.newToOctopus}>
            <h5>New to Octopus?</h5>
            {props.children}
        </div>
    );
};

export const NeedHelpResources: React.StatelessComponent<{}> = props => {
    return (
        <div className={styles.needHelp}>
            <h5>Need some help?</h5>
            {props.children}
        </div>
    );
};

export const TutorialResources: React.StatelessComponent<{}> = props => {
    return (
        <div className={styles.tutorials}>
            <h5>Tutorials</h5>
            {props.children}
        </div>
    );
};

export const WebinarResources: React.StatelessComponent<{}> = props => {
    return (
        <div className={styles.webinar}>
            <h5>Webinar</h5>
            {props.children}
        </div>
    );
};

export const VideoResources: React.StatelessComponent<{}> = props => {
    return (
        <div className={styles.video}>
            <h5>Video</h5>
            {props.children}
        </div>
    );
};

export const CommonNeedHelpInformation: React.StatelessComponent<{}> = props => {
    return (
        <div className={styles.helpInformation}>
            <Note>Look out for this icon to access help and related resources</Note>
            <span style={{ top: "0.5rem", position: "relative", marginLeft: "0.25rem" }}>
                <HelpOutlineIcon color={primaryDarkest} />
            </span>
        </div>
    );
};
