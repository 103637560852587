import * as React from "react";
import { ProjectResource } from "client/resources";
import MultiSelect from "./MultiSelect";
import { FormFieldProps } from "components/form";
import { SpaceChip } from "components/Chips/index";
import LookupResourceChipComponent from "components/LookupResourceChip";
import { ChipIcon } from "components/Chips";
import { SpaceResource } from "../../client/resources/spaceResource";

interface SpaceMultiSelectProps extends FormFieldProps<string[]> {
    items: SpaceResource[];
    autoFocus?: boolean;
    label?: string | JSX.Element;
    hintText?: string;
    error?: string;
}

const SpaceTypedMultiSelect = MultiSelect<SpaceResource>();
const LookupSpaceChip = LookupResourceChipComponent<SpaceResource>();

const SpaceMultiSelect: React.StatelessComponent<SpaceMultiSelectProps> = props => {
    const chipRenderer = (r: ProjectResource, onRequestDelete: (event: object) => void) => {
        return <LookupSpaceChip lookupCollection={props.items} lookupId={r.Id} type={ChipIcon.Space} chipRender={item => <SpaceChip onRequestDelete={onRequestDelete} space={item} />} />;
    };
    return <SpaceTypedMultiSelect items={props.items} label={props.label} hintText={props.hintText} fieldName="spaces" renderChip={chipRenderer} {...props} />;
};

export default SpaceMultiSelect;
