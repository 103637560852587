import BasicRepository from "./basicRepository";
import Client from "../client";
import ActionTemplateSearchResource from "client/resources/actionTemplateSearchResource";
import { ActionTemplateResource, ActionTemplateUsageResource, ActionTemplateCategoryResource } from "client/resources";
import { CommunityActionTemplateResource } from "client/resources/communityActionTemplateResource";

class ActionTemplateRepository extends BasicRepository<ActionTemplateResource, ActionTemplateResource> {
    constructor(client: Client) {
        super("ActionTemplates", client);
    }
    getUsage(template: Partial<ActionTemplateResource>): Promise<ActionTemplateUsageResource[]> {
        return this.client.get(template.Links["Usage"]);
    }
    getByCommunityTemplate(communityTemplate: CommunityActionTemplateResource) {
        const allArgs = { ...{}, ...{ id: communityTemplate.Id } };
        return this.client.get<ActionTemplateResource>(communityTemplate.Links["InstalledTemplate"], allArgs);
    }
    search(): Promise<ActionTemplateSearchResource[]> {
        return this.client.get<ActionTemplateSearchResource[]>(this.client.getLink("ActionTemplatesSearch"));
    }
    updateActions(actionTemplate: Partial<ActionTemplateResource>, actionIdsByProcessId: any, defaults = {}, overrides = {}) {
        defaults = defaults || {};
        overrides = overrides || {};
        return this.client.post(actionTemplate.Links["ActionsUpdate"], {
            actionIdsByProcessId,
            defaultPropertyValues: defaults,
            overrides,
            version: actionTemplate.Version,
        });
    }
    getVersion(actionTemplate: ActionTemplateResource, version: any): Promise<ActionTemplateResource> {
        return this.client.get(actionTemplate.Links["Versions"], { version });
    }
    categories(): Promise<ActionTemplateCategoryResource[]> {
        return this.client.get<ActionTemplateCategoryResource[]>(this.client.getLink("ActionTemplatesCategories"));
    }
}

export default ActionTemplateRepository;
