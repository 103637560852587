import * as React from "react";
const styles = require("./style.less");

interface InputLabelProps {
    label: string | JSX.Element;
}

const InputLabel: React.SFC<InputLabelProps> = props => {
    return <div className={styles.label}>{props.label}</div>;
};

export default InputLabel;
