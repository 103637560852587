import pluginRegistry, { ActionEditProps } from "components/Actions/pluginRegistry";
import getUploadS3Registration from "./awsUploadS3Action";
import getDeleteCloudFormationRegistration from "./awsDeleteCloudFormationAction";
import getDeployCloudFormationRegistration from "./awsDeployCloudFormationAction";
import getAwsRunScriptRegistration from "./awsRunScriptAction";
import getApplyChangesetRegistration from "./awsApplyCloudFormationAction";
import getChangesetsFeature from "./awsCloudFormationChangesetFeature";

pluginRegistry.registerActionForAllScopes(getAwsRunScriptRegistration());
pluginRegistry.registerActionForAllScopes(getDeleteCloudFormationRegistration());
pluginRegistry.registerActionForAllScopes(getDeployCloudFormationRegistration());
pluginRegistry.registerActionForAllScopes(getUploadS3Registration());
pluginRegistry.registerActionForAllScopes(getApplyChangesetRegistration());

pluginRegistry.registerFeatureForAllScopes(getChangesetsFeature());
