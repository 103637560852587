import * as React from "react";
import * as cn from "classnames";

const styles = require("./style.less");

export class DataTableToolsRow extends React.Component<any, any> {
    render() {
        return <tr className={cn(this.props.className, styles.dataTableToolsRow)}>{this.props.children}</tr>;
    }
}
