import * as React from "react";
import { buildServerPackagingInstruction } from "../../Instructions";
import { CommonBuildServerPackagingTextForOctoExe, CommonBuildServerPackagingTextForIntegration } from "../CommonRegistrationText";

export const BuildServerPackaging: React.SFC<{}> = () => (
    <div>
        <p>
            When packaging .NET Core applications from build servers, we recommend using <code>dotnet publish</code> on the project followed by <code>octo.exe pack</code> on the output directory.
        </p>
        {CommonBuildServerPackagingTextForOctoExe}
        {CommonBuildServerPackagingTextForIntegration}
    </div>
);

export default buildServerPackagingInstruction(() => <BuildServerPackaging />);
