import * as React from "react";
import { Callout, CalloutType } from "components/Callout";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import Note from "../../form/Note/Note";

const AzureServiceFabricSdkRequiredInfo = (props: {}) => {
    return (
        <Callout type={CalloutType.Information} title={"Service Fabric SDK install required"}>
            The <ExternalLink href="ServiceFabricSdkDownload">Service Fabric SDK</ExternalLink> must be installed on the <strong>Octopus Server</strong> for this step.{" "}
            <Note>
                {" "}
                Learn more about <ExternalLink href="ServiceFabricDeployingApps">Deploying to Service Fabric</ExternalLink>.
            </Note>
        </Callout>
    );
};

export default AzureServiceFabricSdkRequiredInfo;
