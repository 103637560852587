import Logo from "components/Logo";
import PromiseConfirmationDialog, { Deferred } from "components/Dialog/PromiseConfirmationDialog";
import ActionTemplateEditor from "components/ActionTemplateEditor/ActionTemplateEditor";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import {
    ActionExecutionLocation,
    DeploymentActionResource,
    DeploymentProcessResource,
    DeploymentStepResource,
    GetPrimaryPackageReference,
    HasManualInterventionResponsibleTeams,
    OctopusError,
    RunCondition,
    TenantedDeploymentMode,
    ActionTemplateResource,
} from "client/resources";
import { default as pluginRegistry, ActionScope } from "components/Actions/pluginRegistry";
import { find, difference, intersection, uniq, keyBy } from "lodash";
import { repository } from "clientInstance";
import FormBaseComponent from "components/FormBaseComponent";
import { OptionalFormBaseComponentState } from "components/FormBaseComponent";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import Text from "components/form/Text/Text";
import { required, Note, UnstructuredFormSection } from "components/form";
import ActionEditor from "components/ActionEditor/ActionEditor";
import { cloneDeep } from "lodash";
import { StepDetailsLoaderState, StepDetailsParams } from "./StepDetailsLoader";
import FeatureEditor from "components/FeatureEditor/FeatureEditor";
import { ExpandableFormSection, FormSectionHeading, Summary } from "components/form";
import { Callout, CalloutType } from "components/Callout";
import { ChannelMultiSelect } from "components/MultiSelect";
import { Feature } from "components/FeatureToggle";
import FeatureToggle from "components/FeatureToggle/FeatureToggle";
import Checkbox from "components/form/Checkbox/Checkbox";
import { ChannelChip, MissingChip, ChipIcon } from "components/Chips";
import ParseHelper from "utils/ParseHelper/ParseHelper";
import Permission from "client/resources/permission";
import StartTriggerExpander from "./StartTriggerExpander";
import RunTriggerExpander from "./RunTriggerExpander";
import PackageRequirementExpander from "./PackageRequirementExpander";
import OpenFeatureDialog from "components/OpenFeatureDialog/OpenFeatureDialog";
import { connect } from "react-redux";
import { DeploymentProcessRoute } from "./DeploymentProcessRoute";
import routeLinks from "routeLinks";
import Environments from "./Environments";
import ExecutionPlan from "./ExecutionPlan";
import TenantsExpander from "./TenantsExpander";
import ActionProperties from "client/resources/actionProperties";
import getActionLogoUrl from "../getActionLogoUrl";
import { PackageRequirement } from "client/resources";
import InternalRedirect from "components/Navigation/InternalRedirect/InternalRedirect";
import { PackageReference } from "client/resources/packageReference";
import StepName from "./StepName";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import { OverflowMenuItems } from "components/Menu";
import { ProjectContextState, useProjectContext, ProjectContextActions } from "../../context";

export enum EnvironmentOption {
    All = "all",
    Include = "include",
    Exclude = "exclude",
}

export interface EnvironmentSelection {
    unavailable: string[];
    unavailableExclusive: string[];
    inclusive: string[];
    exclusive: string[];
    hasHiddenEnvironments: boolean;
}

interface Model {
    step: DeploymentStepResource;
    action: DeploymentActionResource;
    environmentOption: EnvironmentOption;
    runOn: RunOn;
    condition: EnvironmentSelection;
}

interface ActionDetailsState extends OptionalFormBaseComponentState<Model> {
    canRunBeforeAcquisition: boolean;
    pageTitle: string;
    redirectTo?: string;
    confirmReadPromise?: Deferred<boolean>;
}

export enum RunOn {
    OctopusServer = "OctopusServer",
    WorkerPool = "WorkerPool",
    WorkerPoolForRoles = "WorkerPoolForRoles",
    OctopusServerForRoles = "OctopusServerForRoles",
    DeploymentTarget = "DeploymentTarget",
}

export enum TargetRoles {
    Optional,
    None,
    Required,
}

type ActionDetailsProps = { scope: ActionScope } & StepDetailsLoaderState & RouteComponentProps<StepDetailsParams>;

interface GlobalConnectedProps {
    isBuiltInWorkerEnabled: boolean;
}

type Props = ActionDetailsProps & ProjectContextState & { actions: ProjectContextActions };
type ExposedProps = ActionDetailsProps;

class ActionDetailsInternal extends FormBaseComponent<Props, ActionDetailsState, Model> {
    private get isNew(): boolean {
        return !!(this.state && this.state.model && this.state.model.action && !this.state.model.action.Id);
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            pageTitle: "Step",
            canRunBeforeAcquisition: props.canRunBeforeAcquisition,
        };
    }

    async componentDidMount() {
        const action = this.props.action;
        const environmentOption = (action.Environments || []).length > 0 ? EnvironmentOption.Include : (action.ExcludedEnvironments || []).length > 0 ? EnvironmentOption.Exclude : EnvironmentOption.All;

        const model = {
            step: this.props.step,
            runOn: this.whereToRun(action),
            action,
            environmentOption,
            condition: this.calculateEnvironmentConditions(),
        };

        this.setState({
            model,
            cleanModel: cloneDeep(model),
        });
    }

    refreshRunOn() {
        const runOn = this.whereToRun(this.state.model.action);
        this.setState(state => {
            return {
                model: {
                    ...state.model,
                    runOn,
                },
            };
        });
        this.setActionProperties({ ["Octopus.Action.RunOnServer"]: runOn === RunOn.DeploymentTarget ? "false" : "true" });
    }

    calculateEnvironmentConditions = (): EnvironmentSelection => {
        const action = this.props.action;
        const knownEnvironments = this.props.environments.map(e => e.Id);
        const unavailableEnvironments = difference(action.Environments || [], knownEnvironments);
        const unavailableExcludedEnvironments = difference(action.ExcludedEnvironments || [], knownEnvironments);
        const inclusiveEnvironments = intersection(action.Environments || [], knownEnvironments);
        const exclusiveEnvironments = intersection(action.ExcludedEnvironments || [], knownEnvironments);
        const hasHiddenEnvironments = unavailableExcludedEnvironments.length + unavailableExcludedEnvironments.length > 0;
        return {
            unavailable: unavailableEnvironments,
            unavailableExclusive: unavailableExcludedEnvironments,
            inclusive: inclusiveEnvironments,
            exclusive: exclusiveEnvironments,
            hasHiddenEnvironments,
        };
    };

    availableRunConditions = () => {
        return this.props.isFirstStep ? [RunCondition.Success, RunCondition.Always, RunCondition.Variable] : [RunCondition.Success, RunCondition.Failure, RunCondition.Always, RunCondition.Variable];
    };

    isConditionEditable = () => {
        const action = this.props.action;
        if (!action) {
            return true;
        }
        const step = this.props.step;
        return step.Actions.length === 1 || step.Actions.length === 0;
    };

    doBusyForChildren = (action: () => Promise<any>): Promise<boolean> => {
        // don't clear errors on child tasks since they should just
        // be loading and we don't want to clear a Save error
        // just because we load some lookup data
        return this.doBusyTask(action, false);
    };

    render() {
        const action = this.state.model && this.state.model.action;
        const step = this.state.model && this.state.model.step;
        const addFeaturesElement =
            this.props.hasFeatures && !this.props.actionTemplate && action ? (
                <OpenFeatureDialog scope={this.props.scope} actionType={action.ActionType} properties={action.Properties} saveDone={x => this.setActionProperties({ ["Octopus.Action.EnabledFeatures"]: x })} />
            ) : null;

        const actionEditorAdditionalActions = {
            packageAcquisition: {
                canRunBeforeAcquisition: this.state.canRunBeforeAcquisition,
                stepPackageRequirement: this.props.step.PackageRequirement,
                onCanRunBeforeAcquisitionChanged: (x: boolean) => {
                    this.setState({ canRunBeforeAcquisition: x });
                },
                onStepPackageRequirementChanged: (x: PackageRequirement) => {
                    this.setStepState({ PackageRequirement: x });
                },
            },
            stepTargetRoles: this.props.step.Properties["Octopus.Action.TargetRoles"] as string,
            actionType: this.props.plugin.actionType,
            workerPoolId: action && action.WorkerPoolId,
        };

        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} />;
        }

        const hasManualInterventionResponsibleTeams = action && HasManualInterventionResponsibleTeams(action);

        const processEditPermission = { permission: Permission.ProcessEdit, project: this.props.model.Id, tenant: "*" };
        const actions = [];
        if (step && step.Id) {
            // doesn't make sense to allow enable/disable/delete if the step hasn't been saved
            // it will also cause havoc - eg delete will delete *another* step.
            if (action) {
                actions.push(OverflowMenuItems.item(action.IsDisabled ? "Enable" : "Disable", this.handleEnabledToggle, processEditPermission));
            }
            if (action) {
                actions.push(OverflowMenuItems.deleteItemDefault("step", () => this.deleteStep(step && step.Id, action.Id), processEditPermission));
            } else {
                actions.push(OverflowMenuItems.deleteItemDefault("parent step", () => this.deleteStep(step && step.Id), processEditPermission));
            }

            if (this.props.actionTemplate) {
                actions.push(OverflowMenuItems.item("Detach Step Template", this.handleDetachStepTemplate));
            } else {
                actions.push(OverflowMenuItems.item("Extract Step Template", this.handleCreateStepTemplate));
            }
        }

        return (
            <FormPaperLayout
                title={<StepName name={action && action.Name} number={this.props.stepNumber} stepType={this.props.actionTypeName} />}
                titleLogo={action && <Logo url={getActionLogoUrl(action)} />}
                busy={this.state.busy}
                errors={this.state.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                savePermission={{
                    permission: Permission.ProcessEdit,
                    project: this.props.model.Id,
                    tenant: "*",
                }}
                overFlowActions={actions}
                onSaveClick={this.handleSaveClick}
                secondaryAction={addFeaturesElement}
                saveText="Step details updated"
                isNewRecord={this.isNew}
                disableHeaderAnimations={true} // Disabling due to the way the StepDetailsLoader and this component work together.
                fullWidth={true}
                flatStyle={true}
                hideHelpIcon={true}
            >
                {this.state.model && action && (
                    <TransitionAnimation>
                        <div>
                            {this.state.confirmReadPromise && (
                                <PromiseConfirmationDialog title="Conflict" continueButtonLabel="Restore the step" deferred={this.state.confirmReadPromise}>
                                    Someone else has deleted this step from the deployment process. Would you like to add the step back?
                                </PromiseConfirmationDialog>
                            )}

                            {this.state.cleanModel && this.state.cleanModel.action.IsDisabled && (
                                <UnstructuredFormSection stretchContent={true}>
                                    <Callout type={CalloutType.Warning} title={"This step is currently disabled"} />
                                </UnstructuredFormSection>
                            )}

                            <ExpandableFormSection
                                isExpandedByDefault={!action.Name}
                                errorKey="Name"
                                title="Step Name"
                                focusOnExpandAll
                                summary={action.Name ? Summary.summary(action.Name) : Summary.placeholder("Please enter a name for your step")}
                                help="A short, memorable, unique name for this step."
                            >
                                <Text value={action.Name} onChange={x => this.setActionState({ Name: x })} label="Step name" error={this.getFieldError("Name")} validate={required("Please enter a step name")} autoFocus={true} />
                            </ExpandableFormSection>

                            <ExpandableFormSection errorKey="IsDisabled" title="Enabled" summary={action.IsDisabled ? Summary.summary("No") : Summary.default("Yes")} help="Disable a step to prevent it from running.">
                                <Checkbox value={!action.IsDisabled} onChange={IsDisabled => this.setActionState({ IsDisabled: !IsDisabled })} label="Enabled" />
                            </ExpandableFormSection>

                            <ExecutionPlan
                                projectId={this.props.model.Id}
                                expandedByDefault={!action.Name}
                                executionLocation={this.props.plugin.executionLocation}
                                runOn={this.state.model.runOn}
                                onRunOnChanged={runOn => {
                                    this.setModelState({ runOn });
                                    this.setActionProperties({ ["Octopus.Action.RunOnServer"]: runOn === RunOn.DeploymentTarget ? "false" : "true" });
                                }}
                                targetRoleOption={this.props.plugin.targetRoleOption(action)}
                                targetRoles={this.state.model.step.Properties["Octopus.Action.TargetRoles"] as string}
                                disableAddTargetRoles={this.props.plugin.disableAddTargetRoles}
                                onTargetRolesChanged={roles => this.setStepProperties({ ["Octopus.Action.TargetRoles"]: ParseHelper.encodeCSV(roles) })}
                                targetRolesError={this.getFieldError("Octopus.Action.TargetRoles")}
                                isChildStep={this.props.isChildStep}
                                maxParallelism={this.state.model.step.Properties["Octopus.Action.MaxParallelism"] as string}
                                onMaxParallelismChanged={max => this.setStepProperties({ ["Octopus.Action.MaxParallelism"]: max })}
                                availableRoles={this.props.availableRoles}
                                availableWorkerPools={this.props.availableWorkerPools}
                                canRunOnWorker={this.canRunOnWorker()}
                                isBuiltInWorkerEnabled={this.props.isBuiltInWorkerEnabled}
                                targetWorkerPool={this.state.model.action.WorkerPoolId}
                                onTargetWorkerPoolChanged={targetWorkerPool => this.setActionState({ WorkerPoolId: targetWorkerPool })}
                                runsOnServer={this.runsOnServer(this.state.model.action, this.props.plugin.executionLocation)}
                            />

                            {!this.props.actionTemplate && (
                                <div>
                                    <ActionEditor
                                        scope={this.props.scope}
                                        plugin={this.props.plugin}
                                        projectId={this.props.model.Id}
                                        isNew={this.isNew}
                                        doBusyTask={this.doBusyForChildren}
                                        busy={this.state.busy}
                                        properties={this.state.model.action.Properties}
                                        packages={this.state.model.action.Packages}
                                        runOn={this.state.model.runOn}
                                        setProperties={(p, i, c) => this.setActionProperties(p, i, c)}
                                        setPackages={(p, i) => this.setActionPackages(p, i)}
                                        additionalActions={actionEditorAdditionalActions}
                                        getFieldError={this.getFieldError}
                                        errors={this.state.errors}
                                        expandedByDefault={!action.Name}
                                        refreshRunOn={() => this.refreshRunOn()}
                                    />

                                    {this.props.hasFeatures && (
                                        <div>
                                            {action.Properties["Octopus.Action.EnabledFeatures"] && <FormSectionHeading title="Features" />}
                                            <FeatureEditor
                                                scope={this.props.scope}
                                                plugin={this.props.plugin}
                                                projectId={this.props.model.Id}
                                                isNew={this.isNew}
                                                doBusyTask={this.doBusyForChildren}
                                                busy={this.props.busy}
                                                properties={this.state.model.action.Properties}
                                                packages={this.state.model.action.Packages}
                                                runOn={this.state.model.runOn}
                                                setProperties={(p, i, c) => this.setActionProperties(p, i, c)}
                                                setPackages={(p, i) => this.setActionPackages(p, i)}
                                                enabledFeatures={(action.Properties["Octopus.Action.EnabledFeatures"] as string) || ""}
                                                getFieldError={this.getFieldError}
                                                errors={this.state.errors}
                                                expandedByDefault={!action.Name}
                                                openFeaturesElement={addFeaturesElement}
                                                refreshRunOn={() => this.refreshRunOn()}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}

                            {this.props.actionTemplate && (
                                <ActionTemplateEditor
                                    actionTemplate={this.props.actionTemplate}
                                    projectId={this.props.model.Id}
                                    process={this.props.deploymentProcess}
                                    actionId={this.props.action.Id}
                                    properties={this.state.model.action.Properties}
                                    setProperties={p => this.setActionProperties(p)}
                                    doBusyTask={this.doBusyForChildren}
                                />
                            )}

                            <FormSectionHeading title="Conditions" />
                            <Environments
                                environmentOption={this.state.model.environmentOption}
                                hasHiddenEnvironments={this.state.model.condition.hasHiddenEnvironments}
                                environments={this.props.environments}
                                inclusiveEnvironments={this.state.model.condition.inclusive}
                                exclusiveEnvironments={this.state.model.condition.exclusive}
                                onEnvironmentOptionChanged={environmentOption => this.setModelState({ environmentOption })}
                                onInclusiveEnvironmentsChanged={val => this.setChildState2("model", "condition", { inclusive: val })}
                                onExclusiveEnvironmentsChanged={val => this.setChildState2("model", "condition", { exclusive: val })}
                            />

                            {(action.Channels.length > 0 || this.props.channels.length > 1) && (
                                <ExpandableFormSection title="Channels" help="Choose which channels this step applies to." summary={this.channelsSummary()} errorKey="channels">
                                    <Note>If nothing is selected this step will run for releases in any channel, otherwise it will only run for releases belonging to the selected channels.</Note>
                                    <ChannelMultiSelect items={this.props.channels} onChange={val => this.setActionState({ Channels: val })} value={this.state.model.action.Channels} />
                                </ExpandableFormSection>
                            )}

                            <FeatureToggle feature={Feature.MultiTenancy}>
                                {(this.props.model.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted || action.TenantTags.length > 0) && (
                                    <TenantsExpander doBusyTask={this.doBusyTask} tenantTags={action.TenantTags} tagIndex={this.props.tagIndex} onTenantTagsChange={tags => this.setActionState({ TenantTags: tags })} />
                                )}
                            </FeatureToggle>

                            {this.isConditionEditable() && step && (
                                <RunTriggerExpander
                                    isFirstStep={this.props.isFirstStep}
                                    condition={this.state.model.step.Condition}
                                    onConditionChange={val => this.setStepState({ Condition: val })}
                                    variableExpression={step.Properties["Octopus.Action.ConditionVariableExpression"] as string}
                                    onVariableExpressionChange={x => this.setStepProperties({ ["Octopus.Action.ConditionVariableExpression"]: x })}
                                    projectId={this.props.model.Id}
                                />
                            )}

                            {this.isConditionEditable() && !this.props.isFirstStep && <StartTriggerExpander startTrigger={this.state.model.step.StartTrigger} onChange={val => this.setStepState({ StartTrigger: val })} />}

                            {this.state.canRunBeforeAcquisition && step && <PackageRequirementExpander packageRequirement={this.state.model.step.PackageRequirement} onChange={val => this.setStepState({ PackageRequirement: val })} />}

                            <ExpandableFormSection
                                title="Required"
                                summary={
                                    this.state.model.action.IsRequired || hasManualInterventionResponsibleTeams
                                        ? Summary.summary(
                                              <span>
                                                  This step is <strong>required</strong> and cannot be skipped
                                              </span>
                                          )
                                        : Summary.summary(
                                              <span>
                                                  This step is <strong>not required</strong> and can be skipped
                                              </span>
                                          )
                                }
                                help="Required steps cannot be skipped when deploying a release"
                                errorKey="required"
                            >
                                {hasManualInterventionResponsibleTeams && <Note>Responsible teams are specified, therefore this step is always required.</Note>}

                                <Checkbox
                                    value={this.state.model.action.IsRequired || hasManualInterventionResponsibleTeams}
                                    label="Prevent this step from being skipped when deploying"
                                    disabled={hasManualInterventionResponsibleTeams}
                                    onChange={val => this.setActionState({ IsRequired: val })}
                                />
                            </ExpandableFormSection>
                        </div>
                    </TransitionAnimation>
                )}
            </FormPaperLayout>
        );
    }

    private async deleteStep(stepId: string, actionId?: string) {
        const deploymentProcess = cloneDeep(this.props.deploymentProcess);
        const step = deploymentProcess.Steps.find(x => x.Id === stepId);

        if (!step) {
            throw Error(`The step "${stepId}" could not be found in deployment process ${deploymentProcess.Id}`);
        }

        if (actionId) {
            step.Actions = step.Actions.filter(x => x.Id !== actionId);
        }

        if (step.Actions.length === 0) {
            deploymentProcess.Steps = deploymentProcess.Steps.filter(x => x.Id !== step.Id);
        } else if (step.Actions.length === 1) {
            step.Name = step.Actions[0].Name;
        }

        await this.doBusyTask(async () => {
            const result = await repository.DeploymentProcesses.modify(deploymentProcess);
            if (result) {
                this.props.actions.onDeploymentProcessUpdated(result);
                const redirectTo = routeLinks.project(this.props.model).process.root;
                this.setState({ redirectTo });
            }
        });
        return true;
    }

    private handleEnabledToggle = async () => {
        await this.doBusyTask(async () => {
            if (this.state && this.state.model) {
                this.state.model.action.IsDisabled = !this.state.model.action.IsDisabled;
                const result = await this.save(this.state.model);
                this.reloadThePage(result);
            }
        });
    };

    private handleDetachStepTemplate = async () => {
        await this.doBusyTask(async () => {
            delete this.state.model.action.Properties["Octopus.Action.Template.Id"];
            delete this.state.model.action.Properties["Octopus.Action.Template.Version"];

            const result = await this.save(this.state.model);
            this.reloadThePage(result);
        });
    };

    private handleCreateStepTemplate = async () => {
        const templateExists = (templates: ActionTemplateResource[], actionName: string) => {
            return templates.some(s => s.Name.toLocaleUpperCase() === actionName.toLocaleUpperCase());
        };

        const getNewTemplateName = (templates: ActionTemplateResource[], action: DeploymentActionResource) => {
            let suffix = "";
            let counter = 1;
            while (templateExists(templates, action.Name + suffix)) {
                suffix = " (" + counter + ")";
                counter++;
            }

            return action.Name + suffix;
        };

        const createStepTemplateFromAction = async (action: DeploymentActionResource) => {
            const existingTemplates = await repository.ActionTemplates.all();
            const newName = getNewTemplateName(existingTemplates, action);
            const newTemplate = JSON.parse(JSON.stringify(action));
            newTemplate.Name = newName;
            newTemplate.Id = "";
            newTemplate.Version = 1;
            newTemplate.Description = "Created from step '" + this.props.action.Name + "' in project '" + this.props.model.Name + "'";
            return newTemplate;
        };

        await this.doBusyTask(async () => {
            //save, so we get are sure we've got a valid step
            let saveResult = await this.save(this.state.model);
            if (saveResult) {
                const newTemplate = await createStepTemplateFromAction(this.state.model.action);
                const result = await repository.ActionTemplates.create(newTemplate);

                if (result) {
                    this.state.model.action.Properties["Octopus.Action.Template.Id"] = result.Id;
                    this.state.model.action.Properties["Octopus.Action.Template.Version"] = result.Version.toString();
                    saveResult = await this.save(this.state.model);
                    this.reloadThePage(saveResult);
                }
            }
        });
    };

    private canRunOnWorker() {
        return this.props.plugin.canRunOnWorker === false ? this.props.plugin.canRunOnWorker : true;
    }

    private whereToRun(action: DeploymentActionResource) {
        const runsOnServer = this.runsOnServer(action, this.props.plugin.executionLocation);

        if (!runsOnServer) {
            return RunOn.DeploymentTarget;
        }

        if (this.props.availableWorkerPools.length > 0 && this.canRunOnWorker()) {
            return this.showRolesForServer(this.props, this.state.model ? this.state.model.action : this.props.action) ? RunOn.WorkerPoolForRoles : RunOn.WorkerPool;
        } else if (this.props.isBuiltInWorkerEnabled) {
            return this.showRolesForServer(this.props, this.state.model ? this.state.model.action : this.props.action) ? RunOn.OctopusServerForRoles : RunOn.OctopusServer;
        } else {
            return RunOn.DeploymentTarget;
        }
    }

    private setActionProperties = (properties: Partial<ActionProperties>, initialise?: boolean, callback?: () => void) => {
        this.setState(
            (state: ActionDetailsState) => {
                return state && state.model && state.model.action
                    ? {
                          model: {
                              ...state.model,
                              action: {
                                  ...state.model.action,
                                  Properties: {
                                      ...state.model.action.Properties,
                                      ...properties,
                                  },
                              },
                          },
                      }
                    : {};
            },
            () => {
                if (initialise) {
                    this.initialiseModel();
                }
                if (callback) {
                    callback();
                }
            }
        );
    };

    private setActionPackages = (packages: PackageReference[], initialise?: boolean) => {
        this.setActionState({ Packages: packages }, () => {
            if (initialise) {
                this.initialiseModel();
            }
        });
    };

    private initialiseModel = () => {
        this.setState(prev => ({
            cleanModel: cloneDeep(prev.model),
        }));
    };

    private setStepProperties = (properties: Partial<ActionProperties>) => {
        this.setState((state: ActionDetailsState) => {
            return state && state.model && state.model.step
                ? {
                      model: {
                          ...state.model,
                          step: {
                              ...state.model.step,
                              Properties: {
                                  ...state.model.step.Properties,
                                  ...properties,
                              },
                          },
                      },
                  }
                : {};
        });
    };

    private setActionState<K extends keyof DeploymentActionResource>(state: Pick<DeploymentActionResource, K>, callback?: () => void) {
        this.setChildState2("model", "action", state, callback);
    }

    private setStepState<K extends keyof DeploymentStepResource>(state: Pick<DeploymentStepResource, K>, callback?: () => void) {
        this.setChildState2("model", "step", state, callback);
    }

    private channelsSummary() {
        return this.state.model && this.state.model.action.Channels.length > 0
            ? Summary.summary(<span>This step will only run for releases in {this.state.model.action.Channels.map(ch => this.getChipForChannel(ch))}</span>)
            : Summary.default("This step will run for releases in any channel");
    }

    private getChipForChannel(id: string) {
        const channel = this.props.channels.find(c => c.Id === id);
        return channel ? <ChannelChip key={channel.Id} channelName={channel.Name} /> : <MissingChip lookupId={id} type={ChipIcon.Channel} />;
    }

    private runsOnServer = (action: DeploymentActionResource, executionLocation: ActionExecutionLocation): boolean => {
        return action && executionLocation && (executionLocation === ActionExecutionLocation.AlwaysOnServer || (executionLocation === ActionExecutionLocation.TargetOrServer && action.Properties["Octopus.Action.RunOnServer"] === "true"));
    };

    private findAction = (deploymentProcess: DeploymentProcessResource, id: string) => {
        for (let i = 0; i < deploymentProcess.Steps.length; i++) {
            const step = deploymentProcess.Steps[i];
            for (let j = 0; j < step.Actions.length; j++) {
                const action = step.Actions[j];
                if (action.Id === id) {
                    return { action, step, actionIndex: j, stepIndex: i };
                }
            }
        }
        return null;
    };

    private getPossibleFeatures(): { [key: string]: string } {
        if (!this.props.plugin.features) {
            return {};
        }

        return keyBy([...(this.props.plugin.features.initial || []), ...(this.props.plugin.features.optional || []), ...(this.props.plugin.features.permanent || [])], x => x);
    }

    private updateFeatures(action: DeploymentActionResource) {
        const enabledFeatureNames = ((action.Properties["Octopus.Action.EnabledFeatures"] as string) || "").split(",").filter(name => {
            return name !== "";
        });

        const possibleFeatures = this.getPossibleFeatures();

        const enabledFeatures = enabledFeatureNames.map(f => {
            return pluginRegistry.getFeature(f, this.props.scope);
        });

        const errors = {};

        enabledFeatures.forEach(feature => {
            if (feature.validate) {
                feature.validate(action.Properties, errors);
            }
        });

        if (Object.keys(errors).length > 0) {
            const exception = new OctopusError(0, "There was a problem with your request.");
            exception.Errors = Object.values(errors);
            exception.Details = errors;
            throw exception;
        }

        const properties = { ...action.Properties };
        pluginRegistry.getAllFeatures(this.props.scope).forEach(feature => {
            if (enabledFeatureNames.indexOf(feature.featureName) === -1) {
                if (feature.disable && possibleFeatures.hasOwnProperty(feature.featureName)) {
                    feature.disable(properties);
                }
            }
        });

        enabledFeatures.forEach(feature => {
            if (feature.preSave) {
                feature.preSave(properties);
            }
        });

        action.Properties = properties;
    }

    private updateEnvironments(action: DeploymentActionResource) {
        if (!this.state.model) {
            return;
        }

        action.Environments = uniq((this.state.model.condition.inclusive || []).concat(this.state.model.condition.unavailable));
        action.ExcludedEnvironments = uniq((this.state.model.condition.exclusive || []).concat(this.state.model.condition.unavailableExclusive));

        if (this.state.model.environmentOption !== EnvironmentOption.Include) {
            action.Environments.splice(0);
        }
        if (this.state.model.environmentOption !== EnvironmentOption.Exclude) {
            action.ExcludedEnvironments.splice(0);
        }
    }

    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            if (this.state && this.state.model) {
                const result = await this.save(this.state.model);
                this.reloadThePage(result);
            }
        });
    };

    private reloadThePage(saveResult: DeploymentProcessResource) {
        if (this.isNew) {
            const newActionId = this.findNewActionId(saveResult);
            const redirectTo = newActionId ? routeLinks.project(this.props.match.params.projectSlug).process.step(newActionId) : routeLinks.project(this.props.match.params.projectSlug).process.root;
            this.setState({ redirectTo });
        }

        const currentPath = this.props.location.pathname;
        const reloadKey = this.props.match.params.reloadKey;
        const path = DeploymentProcessRoute.nextStepReloadPath(currentPath, reloadKey);

        this.setState({ redirectTo: path });
    }

    private findNewActionId(saveResult: DeploymentProcessResource): string | null {
        // tslint:disable-next-line:prefer-for-of
        for (let s = 0; s < saveResult.Steps.length; s++) {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < saveResult.Steps[s].Actions.length; i++) {
                if (this.state.model && this.state.model.action && saveResult.Steps[s].Actions[i].Name === this.state.model.action.Name) {
                    return saveResult.Steps[s].Actions[i].Id;
                }
            }
        }
        return null;
    }

    private async save(model: Model): Promise<DeploymentProcessResource> {
        const action = model.action;
        const step = model.step;

        if (model.runOn === RunOn.OctopusServer || model.runOn === RunOn.WorkerPool) {
            step.Properties["Octopus.Action.TargetRoles"] = "";
        }

        if (model.runOn === RunOn.DeploymentTarget) {
            action.WorkerPoolId = null;
        }

        if (action) {
            if (!action.Name || action.Name.length === 0) {
                const primaryPackage = GetPrimaryPackageReference(action.Packages);
                if (primaryPackage) {
                    action.Name = primaryPackage.PackageId;
                }
            }

            this.updateFeatures(action);
            this.updateEnvironments(action);
        }

        // push action back into the step since they aren't the same object
        const actionIndex = step.Actions.findIndex(a => a.Id === action.Id);
        step.Actions[actionIndex] = action;

        if (step.Actions.length === 1) {
            // Step name should always match action name if there is only one action
            step.Name = step.Actions[0].Name;
        }

        const deploymentProcess = await repository.DeploymentProcesses.get(this.props.model.DeploymentProcessId);

        if (this.props.match.params.actionType) {
            if (this.props.match.params.stepId) {
                const s = find(deploymentProcess.Steps, st => st.Id === this.props.match.params.stepId);
                if (s) {
                    s.Actions.push(action);
                }
            } else {
                deploymentProcess.Steps.push(step);
            }
            return this.applyChange(deploymentProcess);
        }

        const foundAction = this.findAction(deploymentProcess, this.props.match.params.stepId);
        if (!foundAction) {
            const deferred = new Deferred<boolean>();
            this.setState({ confirmReadPromise: deferred });
            const result = await deferred.promise;
            if (result === false) {
                // throw an error so the user gets a clear visual indication of what we are doing.
                throw new OctopusError(500, "Someone else has deleted this step from the deployment process. Step not saved.");
            }
            const index = this.props.deploymentProcess.Steps.indexOf(step);
            deploymentProcess.Steps.splice(index, 0, step);
            return this.applyChange(deploymentProcess);
        } else {
            foundAction.step.Actions[foundAction.actionIndex] = action;
            foundAction.step.Name = step.Name;
            foundAction.step.PackageRequirement = step.PackageRequirement;
            foundAction.step.Condition = step.Condition;
            foundAction.step.StartTrigger = step.StartTrigger;
            Object.keys(step.Properties).forEach(key => (foundAction.step.Properties[key] = step.Properties[key]));
            return this.applyChange(deploymentProcess);
        }
    }

    private applyChange = async (deploymentProcess: any): Promise<DeploymentProcessResource> => {
        const process = await repository.DeploymentProcesses.modify(deploymentProcess);
        this.props.actions.onDeploymentProcessUpdated(process);
        return process;
    };

    private showRolesForServer(props: ActionDetailsProps, action: DeploymentActionResource) {
        const hasRoles = !!props.step.Properties["Octopus.Action.TargetRoles"];
        return hasRoles || props.plugin.targetRoleOption(action) === TargetRoles.Required;
    }
}

const mapGlobalStateToProps = (state: GlobalState, props: ActionDetailsProps): GlobalConnectedProps => {
    return {
        isBuiltInWorkerEnabled: state.configurationArea.features.isBuiltInWorkerEnabled,
    };
};

const ActionDetails = connect<void, {}, ActionDetailsProps>(
    mapGlobalStateToProps,
    null
)(ActionDetailsInternal);

const ActionDetailsWithContext: React.SFC<ExposedProps> = props => {
    const { state, actions } = useProjectContext();
    return <ActionDetails {...props} {...state} actions={actions} />;
};

export default ActionDetailsWithContext;
