import * as React from "react";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent/FormBaseComponent";
import { RouteComponentProps } from "react-router";
import OverflowMenu, { OverflowMenuItems } from "components/Menu/OverflowMenu";
import { repository } from "clientInstance";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import { ExpandableFormSection, Summary, UnstructuredFormSection, FormSectionHeading, Note } from "components/form";
import { required } from "components/form/Validators";
import Text from "components/form/Text/Text";
import { UserRoleResource } from "client/resources/userRoleResource";
import { Permission } from "../../../../client/resources/permission";
import StringHelper from "utils/StringHelper";
import routeLinks from "../../../../routeLinks";
import PermissionsTable from "areas/configuration/components/Roles/PermissionsTable";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import { UrlNavigationTabsContainer } from "components/Tabs";
import TabItem from "../../../../components/Tabs/TabItem";
import Callout, { CalloutType } from "../../../../components/Callout";
import ExternalLink from "../../../../components/Navigation/ExternalLink/ExternalLink";

interface RoleEditModel {
    name: string;
    description: string;
    spacePermissions: { [permission: string]: boolean };
    systemPermissions: { [permission: string]: boolean };
}

export interface PermissionDescription {
    name: string;
    description: string;
}

interface RoleEditState extends OptionalFormBaseComponentState<RoleEditModel> {
    role: UserRoleResource;
    spacePermissions: Array<{ name: string; description: string }>;
    systemPermissions: Array<{ name: string; description: string }>;
    deleted: boolean;
    newId?: string;
}

type Props = RouteComponentProps<{ roleId: string }>;

export default class RoleEdit extends FormBaseComponent<Props, RoleEditState, RoleEditModel> {
    constructor(props: Props) {
        super(props);
        this.state = {
            role: null,
            spacePermissions: null,
            systemPermissions: null,
            model: null,
            cleanModel: null,
            deleted: false,
        };
    }

    currentRoleId(): string {
        return this.props.match.params.roleId;
    }

    async componentDidMount() {
        if (this.state.deleted) {
            return;
        }
        await this.doBusyTask(async () => {
            const allPermissions = await repository.PermissionDescriptions.all();
            const systemPermissions = this.getSystemPermissionDescriptions(allPermissions);
            const spacePermissions = this.getSpacePermissionDescriptions(allPermissions);
            const role = this.currentRoleId() ? await repository.UserRoles.get(this.currentRoleId()) : null;
            this.setState({
                role,
                spacePermissions,
                systemPermissions,
                model: this.buildModel(role),
                cleanModel: this.buildModel(role),
            });
        });
    }

    buildModel(role: UserRoleResource): RoleEditModel {
        if (role) {
            return {
                name: role.Name,
                description: role.Description,
                spacePermissions: role.GrantedSpacePermissions.reduce((acc: { [key: string]: boolean }, a: string) => {
                    acc[a] = true;
                    return acc;
                }, {}),
                systemPermissions: role.GrantedSystemPermissions.reduce((acc: { [key: string]: boolean }, a: string) => {
                    acc[a] = true;
                    return acc;
                }, {}),
            };
        }
        return {
            name: "",
            description: "",
            spacePermissions: {},
            systemPermissions: {},
        };
    }

    handleDeleteConfirm = async () => {
        const result = await repository.UserRoles.del(this.state.role);
        this.setState(state => {
            return {
                model: null,
                cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                deleted: true,
            };
        });
        return true;
    };

    handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            let role: UserRoleResource = {
                ...this.state.role,
                Name: this.state.model.name,
                Description: this.state.model.description,
                GrantedSpacePermissions: Object.keys(this.state.model.spacePermissions) as Permission[],
                GrantedSystemPermissions: Object.keys(this.state.model.systemPermissions) as Permission[],
            };
            role = await repository.UserRoles.save(role);
            this.setState({
                role,
                model: this.buildModel(role),
                cleanModel: this.buildModel(role),
                newId: this.currentRoleId() ? null : role.Id,
            });
        });
    };

    render() {
        const title = !this.currentRoleId() ? "New Role" : this.state.model ? this.state.model.name : StringHelper.ellipsis;

        const overFlowActions = [];
        if (this.state.role && this.state.role.CanBeDeleted) {
            overFlowActions.push(OverflowMenuItems.deleteItemDefault("role", this.handleDeleteConfirm, { permission: Permission.UserRoleEdit }));
        }
        if (this.state.role) {
            overFlowActions.push([
                OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingUserRole(this.state.role), null, {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ]);
        }

        return (
            <FormPaperLayout
                title={title}
                breadcrumbTitle={"User Roles"}
                breadcrumbPath={routeLinks.configuration.roles.root}
                saveText="Role details changed"
                busy={this.state.busy}
                errors={this.state.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                expandAllOnMount={!this.currentRoleId()}
                savePermission={{ permission: Permission.UserRoleEdit }}
                onSaveClick={this.handleSaveClick}
                overFlowActions={overFlowActions}
            >
                {this.state.deleted && <InternalRedirect to={routeLinks.configuration.roles.root} />}
                {this.state.newId && <InternalRedirect to={routeLinks.configuration.role(this.state.newId)} />}
                {this.state.model && (
                    <TransitionAnimation>
                        {!this.currentRoleId() && (
                            <UnstructuredFormSection>
                                <Callout type={CalloutType.Warning} title="Possible problems ahead!">
                                    <p>
                                        Most pages in the Octopus Web Portal require users to have a combination of several permissions. These requirements evolve over time and there are <strong>{this.countPermissions()}</strong> permissions in the
                                        system which makes it very difficult to create stable custom user roles. And this is why we recommend customers try to use <strong>only</strong> built-in user roles.
                                    </p>
                                    <p>
                                        Please, <ExternalLink href="HelpGeneral">let us know</ExternalLink> if you think there is an user role that is missing and we should add it to Octopus.
                                    </p>
                                </Callout>
                            </UnstructuredFormSection>
                        )}
                        <ExpandableFormSection
                            errorKey="name"
                            title="Name"
                            focusOnExpandAll
                            summary={this.state.model.name ? Summary.summary(this.state.model.name) : Summary.placeholder("Please enter a name for the custom role")}
                            help={"A short, memorable, unique name for this role. Example: Project Support."}
                        >
                            <Text value={this.state.model.name} onChange={name => this.setModelState({ name })} label="Name" validate={required("Please enter a role name")} error={this.getFieldError("name")} autoFocus={true} />
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            errorKey="description"
                            title="Description"
                            summary={this.state.model.description ? Summary.summary(this.state.model.description) : Summary.placeholder("No description provided")}
                            help={"Briefly describe what this role grants."}
                        >
                            <Text value={this.state.model.description} onChange={description => this.setModelState({ description })} label="Description" />
                        </ExpandableFormSection>
                        <FormSectionHeading title="Permissions" />
                        <UnstructuredFormSection>
                            <UrlNavigationTabsContainer defaultValue="spacePermissions">
                                <TabItem label="System Permissions" value="systemPermissions">
                                    <Note style={{ marginTop: "0.5rem" }}>
                                        System level permissions are those that involve administering the entire system, but do not include permissions within an individual space.
                                        <br />
                                        Learn more about <ExternalLink href="spaces-and-permissions">system and space permissions</ExternalLink>, including scoping and custom user roles.
                                    </Note>
                                    <PermissionsTable allPermissions={this.state.systemPermissions} permissions={this.state.model.systemPermissions} onPermissionsChanged={systemPermissions => this.setModelState({ systemPermissions })} />
                                </TabItem>
                                <TabItem label="Space Permissions" value="spacePermissions">
                                    <Note style={{ marginTop: "0.5rem" }}>
                                        Space level permissions are those that apply to resources within spaces.
                                        <br />
                                        Learn more about <ExternalLink href="spaces-and-permissions">system and space permissions</ExternalLink>, including scoping and custom user roles.
                                    </Note>
                                    <PermissionsTable allPermissions={this.state.spacePermissions} permissions={this.state.model.spacePermissions} onPermissionsChanged={spacePermissions => this.setModelState({ spacePermissions })} />
                                </TabItem>
                            </UrlNavigationTabsContainer>
                        </UnstructuredFormSection>
                    </TransitionAnimation>
                )}
            </FormPaperLayout>
        );
    }

    private countPermissions() {
        return Object.keys(Permission).length;
    }

    private getSpacePermissionDescriptions(allPermissions: any) {
        return Object.keys(allPermissions)
            .filter(p => {
                const detail = allPermissions[p];
                return detail.CanApplyAtSpaceLevel;
            })
            .map(p => ({
                name: p,
                description: allPermissions[p].Description,
            }));
    }

    private getSystemPermissionDescriptions(allPermissions: any) {
        return Object.keys(allPermissions)
            .filter(p => {
                const detail = allPermissions[p];
                return detail.CanApplyAtSystemLevel;
            })
            .map(p => ({
                name: p,
                description: allPermissions[p].Description,
            }));
    }
}
