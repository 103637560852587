import * as React from "react";
import * as _ from "lodash";
import { repository } from "clientInstance";
import FormPaperLayout from "components/FormPaperLayout";
import { ProjectRouteParams } from "areas/projects/components/ProjectLayout";
import { required } from "components/form/Validators";
import { RouteComponentProps } from "react-router";
import ExternalLink from "components/Navigation/ExternalLink";
import Markdown from "components/Markdown";
import { Text, ExpandableFormSection, Summary, Note, Checkbox, FormSectionHeading } from "components/form";
import {
    ChannelResource,
    DeploymentProcessResource,
    LifecycleResource,
    ProjectResource,
    ChannelVersionRuleResource,
    DeploymentActionResource,
    TenantedDeploymentMode,
    Permission,
    IsNamedPackageReference,
    IsDeployReleaseAction,
} from "client/resources";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent";
import { StepMultiSelect } from "components/MultiSelect";
import MarkdownEditor from "components/form/MarkdownEditor/MarkdownEditor";
import Select from "components/form/Select/Select";
import { ActionButton } from "components/Button";
import { FeatureToggle, Feature } from "components/FeatureToggle";
import * as tenantTagsets from "components/tenantTagsets";
import TagsList from "components/TagsList/TagsList";
import { TagIndex } from "components/tenantTagsets";
import { OverflowMenuItems } from "components/Menu";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import DesignRule from "areas/projects/components/Channels/DesignRule";
const styles = require("./style.less");
import StringHelper from "utils/StringHelper";
import routeLinks from "../../../../routeLinks";
import { AdvancedTenantTagsSelector } from "components/AdvancedTenantSelector/AdvancedTenantSelector";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import RemovableExpandersList from "components/RemovableExpandersList";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import { PackageReference } from "../../../../client/resources/packageReference";
import { PackageAcquisitionLocation } from "../../../../client/resources/packageAcquisitionLocation";
import { OctopusProjectFeedResource } from "client/resources/feedResource";
import { DeploymentActionPackageResource, deploymentActionPackages } from "../../../../client/resources/deploymentActionPackageResource";
import DeploymentActionPackageMultiSelect, { DeploymentActionPackageReferenceDataItem } from "../../../../components/MultiSelect/DeploymentActionPackageMultiSelect";

interface EditState extends OptionalFormBaseComponentState<ChannelResource> {
    lifecycles: LifecycleResource[];
    project: ProjectResource;
    deploymentActions: DeploymentActionResource[];
    packageActions: DeploymentActionPackageResource[];
    tagIndex: TagIndex;
    redirectTo?: string;
}

interface EditChannelRouteProps extends RouteComponentProps<ProjectRouteParams & { channelId: string }> {}

interface EditProps extends EditChannelRouteProps {
    create: boolean;
}

export class Edit extends FormBaseComponent<EditProps, EditState, ChannelResource> {
    private static NoId = "-1";

    constructor(props: EditProps) {
        super(props);

        this.state = {
            lifecycles: [],
            project: null,
            deploymentActions: [],
            packageActions: [],
            tagIndex: null,
        };

        this.throttledVersionRange = _.throttle(this.throttledVersionRange, 500);
        this.throttledTag = _.throttle(this.throttledTag, 500);
    }

    async componentDidMount() {
        let newChannel: ChannelResource = null;
        let loadChannel: Promise<ChannelResource> = null;
        let project: ProjectResource;

        await this.doBusyTask(async () => {
            project = await repository.Projects.get(this.props.match.params.projectSlug);
        });

        const lookupData = Promise.all([repository.DeploymentProcesses.get(project.DeploymentProcessId), repository.Lifecycles.all(), tenantTagsets.getTagIndex(), repository.Feeds.getOctopusProject()]);

        if (this.props.create) {
            newChannel = {
                Id: null,
                ProjectId: project.Id,
                Name: "",
                Description: "",
                IsDefault: false,
                LifecycleId: null,
                Rules: [],
                TenantTags: [],
                Links: null,
            };
        } else {
            loadChannel = repository.Channels.get(this.props.match.params.channelId);
        }

        await this.doBusyTask(async () => {
            const results = await Promise.all<[DeploymentProcessResource, LifecycleResource[], TagIndex, OctopusProjectFeedResource], ChannelResource>([lookupData, loadChannel]);

            const [deploymentProcesses, lifecycles, tagIndex, octopusProjectFeed] = results[0];
            const channel = results[1];
            const deploymentActions = _.flatMap(deploymentProcesses.Steps, step => step.Actions);
            const packageActions = deploymentActionPackages(deploymentActions);

            // const actions = _.flatMap(deploymentProcesses.Steps, step => step.Actions);
            //
            // const versionRulePackageReferences = _.flatMap(actions, action => {
            //     const actionPackages = _.map(action.Packages, pkg => {
            //         return new ChannelVersionRuleActionPackage(action, pkg, octopusProjectFeed);
            //     });
            //
            //     if (IsDeployReleaseAction(action)) {
            //         const pkg: PackageReference = {
            //             AcquisitionLocation: PackageAcquisitionLocation.Server,
            //             PackageId: null,
            //             FeedId: octopusProjectFeed.Id,
            //             Name: "",
            //             Properties: {}
            //         };
            //
            //         actionPackages.push(new ChannelVersionRuleActionPackage(action, pkg, octopusProjectFeed));
            //     }
            //
            //     return actionPackages;
            // });
            //
            // // Build a dictionary of Action package-references, with the key being the special ID syntax used for channel-version-rules:
            // // e.g. {DeploymentActionName}:{PackageReferenceName}
            // const actionsByName: ChannelVersionRuleActionPackages = _.keyBy(versionRulePackageReferences, x => x.Id);

            lifecycles.unshift({
                Phases: [],
                Name: "Inherit from parent",
                Id: Edit.NoId,
                ReleaseRetentionPolicy: null,
                TentacleRetentionPolicy: null,
                Links: null,
            });

            this.setState({
                model: newChannel || channel,
                cleanModel: _.cloneDeep(newChannel || channel),
                lifecycles,
                deploymentActions,
                packageActions,
                project,
                tagIndex,
            });
        });
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true} />;
        }

        const title = this.props.create ? "New Channel" : this.state.model ? this.state.model.Name : StringHelper.ellipsis;

        const overFlowActions =
            !this.props.create && this.state.model
                ? [
                      OverflowMenuItems.deleteItemDefault("channel", this.deleteChannel, {
                          permission: Permission.ProcessEdit,
                          project: this.state.project && this.state.project.Id,
                          tenant: "*",
                      }),
                      [
                          OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([this.state.model.Id]), null, {
                              permission: Permission.EventView,
                              wildcard: true,
                          }),
                      ],
                  ]
                : [];

        const saveText: string = this.props.create ? "Channel created" : "Channel details updated";

        return (
            <FormPaperLayout
                busy={this.state.busy}
                errors={this.state.errors}
                title={title}
                breadcrumbTitle={"Channels"}
                breadcrumbPath={routeLinks.project(this.props.match.params.projectSlug).channels}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                onSaveClick={this.saveChannel}
                overFlowActions={overFlowActions}
                expandAllOnMount={this.props.create}
                saveText={saveText}
            >
                {this.state.model && (
                    <TransitionAnimation>
                        <ExpandableFormSection
                            errorKey="Name"
                            title="Name"
                            focusOnExpandAll
                            summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your channel")}
                            help="Enter a name for your channel."
                        >
                            <Text value={this.state.model.Name || ""} onChange={Name => this.setModelState({ Name })} label="Channel name" validate={required("Please enter a channel name")} error={this.getFieldError("Name")} autoFocus={true} />
                            <Note>
                                A short, memorable, unique name for this channel. Example: <em>1.x Normal, 2.x Beta</em>
                            </Note>
                        </ExpandableFormSection>
                        <ExpandableFormSection errorKey="description" title="Description" summary={this.descriptionSummary()} help="Enter a description for your channel.">
                            <MarkdownEditor value={this.state.model.Description} label="Channel description" onChange={Description => this.setModelState({ Description })} />
                        </ExpandableFormSection>
                        <ExpandableFormSection errorKey="lifecycle" title="Lifecycle" summary={this.lifecycleSummary()} help="Select a lifecycle for your channel.">
                            <Select value={this.state.model.LifecycleId || Edit.NoId} onChange={this.handleLifecycleChanged} items={this.state.lifecycles.map(pg => ({ value: pg.Id, text: pg.Name }))} label="Lifecycle" />
                            <Note>
                                The lifecycle defines how releases can be promoted between environments. Lifecycles can be defined in the <InternalLink to={routeLinks.library.lifecycles}>Library</InternalLink>. If no lifecycle is selected, the
                                default lifecycle for the project will be used.
                            </Note>
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            errorKey="defaultChannel"
                            title="Is Default Channel"
                            summary={
                                this.state.model.IsDefault
                                    ? Summary.summary(
                                          <span>
                                              <strong>This channel</strong> will be chosen by default when creating releases
                                          </span>
                                      )
                                    : Summary.summary(
                                          <span>
                                              <strong>A different channel</strong> will be chosen by default when creating releases
                                          </span>
                                      )
                            }
                            help="This channel will be selected by default when creating releases."
                        >
                            <Checkbox label="Default channel" value={this.state.model.IsDefault} onChange={IsDefault => this.setModelState({ IsDefault })} />
                        </ExpandableFormSection>

                        <FormSectionHeading title="Version Rules" />
                        <RemovableExpandersList
                            helpElement={
                                <div>
                                    Define package version rules that will be enforced when creating releases in this channel.{" "}
                                    <Note>
                                        Learn about <ExternalLink href={"ChannelVersionRules"}>channel version rules</ExternalLink>.
                                    </Note>
                                </div>
                            }
                            typeDisplayName={"Version Rule"}
                            data={this.state.model.Rules}
                            listActions={[<ActionButton key="AddVersion" label="Add version rule" onClick={() => this.addVersionRule()} />]}
                            onRow={(item: ChannelVersionRuleResource, index: number) => {
                                return this.renderVersionRule(item, index);
                            }}
                            onRowSummary={(item: ChannelVersionRuleResource) => {
                                return this.ruleSummary(item);
                            }}
                            onRowHelp={(item: ChannelVersionRuleResource) => {
                                return "Define package version rules that will be enforced when creating releases in this channel.";
                            }}
                            onRemoveRowByIndex={this.handleVersionRuleDeleteByIndex}
                        />

                        <FeatureToggle feature={Feature.MultiTenancy}>
                            {((this.state.project && this.state.project.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted) || this.state.model.TenantTags.length > 0) && (
                                <React.Fragment>
                                    <FormSectionHeading title="Tenants" />
                                    <ExpandableFormSection
                                        errorKey="tenantTags"
                                        title="Tenants"
                                        summary={
                                            this.state.model.TenantTags.length > 0
                                                ? Summary.summary(
                                                      <span>
                                                          Releases in this channel can only be deployed to certain tenants:
                                                          <TagsList canonicalNames={this.state.model.TenantTags} tagIndex={this.state.tagIndex} />
                                                      </span>
                                                  )
                                                : Summary.default("Releases in this channel can be deployed to any tenants")
                                        }
                                        help={"Choose which tenants the releases in this channel apply to."}
                                    >
                                        <Note>
                                            Releases in this channel will only be deployed to tenants matching this filter. Clear the filter to make releases in this channel available to all tenants. Learn about{" "}
                                            <ExternalLink href={"TenantsAndChannels"}>tenants and channels</ExternalLink>.
                                        </Note>
                                        <AdvancedTenantTagsSelector selectedTenantTags={this.state.model.TenantTags} doBusyTask={this.doBusyTask} onChange={TenantTags => this.setModelState({ TenantTags })} showPreviewButton={true} />
                                    </ExpandableFormSection>
                                </React.Fragment>
                            )}
                        </FeatureToggle>
                    </TransitionAnimation>
                )}
            </FormPaperLayout>
        );
    }

    private ruleSummary(rule: ChannelVersionRuleResource) {
        const summarySpan = (
            <span>
                Applies to <strong>{rule.ActionPackages.map(pkg => this.deploymentActionPackageDisplayName(pkg)).join(", ")}</strong>
                &nbsp;with a version range matching <strong>{rule.VersionRange}</strong>
                {!!rule.Tag && (
                    <span>
                        {" "}
                        and a pre-release tag matching <strong>{rule.Tag}</strong>
                    </span>
                )}
            </span>
        );
        return Summary.summary(summarySpan);
    }

    private lifecycleSummary() {
        if (this.state.model.LifecycleId) {
            return Summary.summary(this.state.lifecycles.find(l => l.Id === this.state.model.LifecycleId).Name);
        }
        return Summary.default("Inherited from parent");
    }

    private handleLifecycleChanged = (lifecycleId: string) => {
        this.setModelState({
            LifecycleId: lifecycleId === Edit.NoId ? null : lifecycleId,
        });
    };

    private addVersionRule() {
        const rule: ChannelVersionRuleResource = {
            Id: null,
            Tag: "",
            VersionRange: "",
            ActionPackages: [],
            Links: null,
        };
        this.setState(state => {
            return {
                model: {
                    ...state.model,
                    Rules: [...state.model.Rules, rule],
                },
            };
        });
    }

    private handleVersionRuleDeleteByIndex = (index: number) => {
        this.setState(state => {
            const Rules = state.model.Rules.filter((x, i) => i !== index);
            return {
                model: {
                    ...state.model,
                    Rules,
                },
            };
        });
    };

    private renderVersionRule(item: ChannelVersionRuleResource, index: number) {
        const allSelectedActionPackages = this.state.model.Rules.map(r => r.ActionPackages).reduce((a, b) => a.concat(b), []); // flatten

        function actionIsEqual(a: DeploymentActionPackageResource, b: DeploymentActionPackageResource) {
            return a.DeploymentAction === b.DeploymentAction && (a.PackageReference || "") === (b.PackageReference || "");
        }

        const autoCompleteActionPackages = this.state.packageActions
            .filter(a => !allSelectedActionPackages.some(b => actionIsEqual(a, b)))
            .map(x => new DeploymentActionPackageReferenceDataItem(x, String(this.state.packageActions.findIndex(y => actionIsEqual(x, y)))));

        const itemSelectedActionPackages = this.state.packageActions
            .filter(a => item.ActionPackages.some(b => actionIsEqual(a, b)))
            .map(x => new DeploymentActionPackageReferenceDataItem(x, String(this.state.packageActions.findIndex(y => actionIsEqual(x, y)))));

        return (
            <div>
                <DeploymentActionPackageMultiSelect
                    items={[...autoCompleteActionPackages, ...itemSelectedActionPackages]}
                    value={itemSelectedActionPackages.map(x => x.Id)}
                    label="Package step(s)"
                    onChange={actionPackageIndexes => this.updateRuleProperty(index, "ActionPackages", actionPackageIndexes.map(i => this.state.packageActions[Number(i)]))}
                    openOnFocus={false}
                />
                <Text value={item.VersionRange || ""} onChange={value => this.updateRuleProperty(index, "VersionRange", value)} label="Version range" />
                <Note>
                    Use the <ExternalLink href="NuGetVersioning">NuGet</ExternalLink> or <ExternalLink href="MavenVersioning">Maven</ExternalLink> versioning syntax (depending on the feed type) to specify the range of versions to include.
                </Note>
                <Text value={item.Tag || ""} onChange={value => this.updateRuleProperty(index, "Tag", value)} label="Pre-release tag" />
                <Note>
                    A regular-expression which will select on the <ExternalLink href="NuGetVersioning">SemVer</ExternalLink> pre-release tag or the <ExternalLink href="MavenVersionParser"> Maven</ExternalLink> qualifier.
                </Note>
                <Note>
                    Check our <ExternalLink href="ChannelVersionRuleTags">documentation</ExternalLink> for more information on tags along with examples.
                </Note>
                <div className={styles.designRuleButton}>
                    <OpenDialogButton label="Design rule">
                        <DesignRule model={item} project={this.state.project} deploymentActions={this.state.deploymentActions} onOkClick={rule => this.updateRule(index, rule)} />
                    </OpenDialogButton>
                </div>
            </div>
        );
    }

    private updateRuleProperty = <K extends keyof ChannelVersionRuleResource>(index: number, propertyName: K, value: ChannelVersionRuleResource[K]) => {
        this.setState(state => {
            const rules = [...state.model.Rules];
            rules[index][propertyName] = value;
            return {
                model: {
                    ...state.model,
                    Rules: rules,
                },
            };
        });
    };

    private updateRule = (index: number, rule: ChannelVersionRuleResource) => {
        this.setState(state => {
            const rules = [...state.model.Rules];
            rules[index] = rule;
            return {
                model: {
                    ...state.model,
                    Rules: rules,
                },
            };
        });
    };

    private throttledVersionRange(idx: any, value: any) {
        this.updateRuleProperty(idx, "VersionRange", value);
    }

    private throttledTag(idx: any, value: any) {
        this.updateRuleProperty(idx, "Tag", value);
    }

    private descriptionSummary() {
        return this.state.model.Description ? Summary.summary(<Markdown markup={this.state.model.Description} />) : Summary.placeholder("No channel description provided");
    }

    private deploymentActionPackageDisplayName(pkg: DeploymentActionPackageResource) {
        return !!pkg.PackageReference ? `${pkg.DeploymentAction}/${pkg.PackageReference}` : pkg.DeploymentAction;
    }

    private deleteChannel = async () => {
        return this.doBusyTask(async () => {
            await repository.Channels.del(this.state.model);
            this.setState({ redirectTo: routeLinks.project(this.state.project).channels });
        });
    };

    private saveChannel = async () => {
        await this.doBusyTask(async () => {
            const result = await repository.Channels.save(this.state.model);
            if (this.props.create) {
                this.setState({ redirectTo: routeLinks.project(this.state.project).channel(result) });
            } else {
                this.setState({
                    model: result,
                    cleanModel: _.cloneDeep(result),
                });
            }
        });
    };
}
