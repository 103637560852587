import * as React from "react";
import BaseComponent from "components/BaseComponent";
import Logo from "components/Logo";
import InternalLink from "components/Navigation/InternalLink";
import ActionList from "components/ActionList/ActionList";
import { default as OverflowMenu, OverflowMenuDeleteItem, OverflowMenuNavLink, OverflowMenuDialogItem, OverflowMenuGenericItem, OverflowMenuDisabledItem } from "components/Menu/OverflowMenu";
import { EnvironmentResource } from "client/resources/environmentResource";
import { ChannelChip, EnvironmentChip, MissingChip, ChipIcon } from "components/Chips/index";
import { ChannelResource } from "client/resources/channelResource";
import FeatureToggle, { Feature } from "components/FeatureToggle/FeatureToggle";
import { DisabledChip } from "components/Chips/index";
import * as cn from "classnames";
import TenantTagsList from "components/TenantTagsList/TenantTagsList";
import { LocationDescriptor } from "history";
import ToolTip from "components/ToolTip";
import { ToolTipPosition } from "../../../../components/ToolTip";
import * as MediaQuery from "react-responsive";
import { toolTipBreakpoint } from "./DeploymentPartForSidebar";
import { deploymentPartNameClass } from "uiTestClasses";

const styles = require("./DeploymentPart.less");

export type MenuItem = OverflowMenuNavLink | OverflowMenuDialogItem | OverflowMenuDeleteItem | OverflowMenuGenericItem | OverflowMenuDisabledItem;

export interface CommonDeploymentPartProps {
    name: string;
    actionType: string | JSX.Element;
    logoUrl: string;
    index: string;
    children?: any;
    detailsUrl: string;
    menuItems?: Array<MenuItem | MenuItem[]>;
    isDisabled?: boolean;
    isParentGroup?: boolean;
    isPlaceholder?: boolean;
    isRunInParallelWithLast?: boolean;
}

interface ChannelsLookup {
    Id: string;
    Channel: ChannelResource;
}

interface DeploymentPartProps extends CommonDeploymentPartProps {
    environments?: EnvironmentResource[];
    excludedEnvironments?: EnvironmentResource[];
    channelsLookup?: ChannelsLookup[];
    tags?: string[];
}

class DeploymentPart extends BaseComponent<DeploymentPartProps, any> {
    render() {
        const deploymentPartNameSpan = <span className={`${deploymentPartNameClass}`}>{this.props.name}</span>;
        return withStepLink(
            <>
                {this.props.isRunInParallelWithLast && (
                    <MediaQuery minWidth={toolTipBreakpoint}>
                        {(matches: boolean) => {
                            if (matches) {
                                return (
                                    <div className={styles.stepWrapper}>
                                        <ToolTip content="Runs in parallel" position={ToolTipPosition.Left}>
                                            <div className={styles.parallelContainer}>
                                                <div className={styles.parallelIcon}>
                                                    <div className={styles.content}>&nbsp;</div>
                                                </div>
                                            </div>
                                        </ToolTip>
                                        <ToolTip content={this.props.actionType} position={ToolTipPosition.Left}>
                                            <div className={styles.stepLogo}>
                                                <Logo url={this.props.logoUrl} isDisabled={this.props.isDisabled} size={"2rem"} className={styles.logo} />
                                            </div>
                                        </ToolTip>
                                    </div>
                                );
                            } else {
                                return (
                                    <div className={styles.stepWrapper}>
                                        <ToolTip content="Runs in parallel">
                                            <div className={styles.parallelContainer}>
                                                <div className={styles.parallelIcon}>
                                                    <div className={styles.content}>&nbsp;</div>
                                                </div>
                                            </div>
                                        </ToolTip>
                                        <ToolTip content={this.props.actionType}>
                                            <div className={styles.stepLogo}>
                                                <Logo url={this.props.logoUrl} isDisabled={this.props.isDisabled} size={"2rem"} className={styles.logo} />
                                            </div>
                                        </ToolTip>
                                    </div>
                                );
                            }
                        }}
                    </MediaQuery>
                )}
                {!this.props.isRunInParallelWithLast && (
                    <MediaQuery minWidth={toolTipBreakpoint}>
                        {(matches: boolean) => {
                            if (matches) {
                                return (
                                    <ToolTip content={this.props.actionType} position={ToolTipPosition.Left}>
                                        <div className={styles.stepLogo}>
                                            <Logo url={this.props.logoUrl} isDisabled={this.props.isDisabled} size={"2rem"} className={styles.logo} />
                                        </div>
                                    </ToolTip>
                                );
                            } else {
                                return (
                                    <ToolTip content={this.props.actionType}>
                                        <div className={styles.stepLogo}>
                                            <Logo url={this.props.logoUrl} isDisabled={this.props.isDisabled} size={"2rem"} className={styles.logo} />
                                        </div>
                                    </ToolTip>
                                );
                            }
                        }}
                    </MediaQuery>
                )}
                <div className={styles.stepContainer}>
                    <div className={styles.stepContent}>
                        <div className={styles.stepNumber}>{this.props.index}</div>
                        <div className={styles.stepName}>
                            <div>
                                {deploymentPartNameSpan}
                                {this.props.isDisabled && (
                                    <>
                                        &nbsp;
                                        <DisabledChip />
                                    </>
                                )}
                            </div>
                            <div className={styles.summary}>
                                {this.props.children}
                                <div>
                                    {this.props.environments && this.props.environments.map(e => <EnvironmentChip key={e.Id} environmentName={e.Name} />)}
                                    {this.props.excludedEnvironments && this.props.excludedEnvironments.map(e => <EnvironmentChip key={e.Id} environmentName={e.Name} isExcluded={true} />)}
                                    {this.props.channelsLookup && this.props.channelsLookup.map(ch => this.getChipForChannel(ch.Id, ch.Channel))}
                                    <FeatureToggle feature={Feature.MultiTenancy} enabled={true}>
                                        {this.props.tags && <TenantTagsList tags={this.props.tags} />}
                                    </FeatureToggle>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.contextualActions}>
                        {this.props.isPlaceholder ? null : (
                            <ActionList actions={[<OverflowMenu key={`${this.props.name}-overflow`} menuKey={`${this.props.name}-overflow`} menuItems={this.props.menuItems} className={styles.overflowMenuButton} />].filter(a => !!a)} />
                        )}
                    </div>
                </div>
            </>,
            this.props.isPlaceholder,
            this.props.isDisabled,
            this.props.detailsUrl,
            this.props.isParentGroup
        );
    }

    private getChipForChannel(id: string, channel: ChannelResource) {
        return channel ? <ChannelChip key={channel.Id} channelName={channel.Name} /> : <MissingChip lookupId={id} type={ChipIcon.Channel} />;
    }
}

function withStepLink(children: React.ReactNode, isPlaceholder: boolean, isDisabled: boolean, detailsUrl: LocationDescriptor, isParentGroup: boolean) {
    return isPlaceholder ? (
        <div className={cn(styles.listItem, isParentGroup ? styles.isParentGroup : null)}>
            <div className={styles.listItemLine} />
            {children}
        </div>
    ) : (
        <InternalLink className={cn(styles.listItem, isDisabled ? styles.isDisabled : null, isParentGroup ? styles.isParentGroup : null)} to={detailsUrl}>
            <div className={styles.listItemLine} />
            {children}
        </InternalLink>
    );
}

export default DeploymentPart;
