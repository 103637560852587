import * as React from "react";
import Summary from "../../../../../components/form/Sections/Summary";
import ExpandableFormSection from "../../../../../components/form/Sections/ExpandableFormSection";
const styles = require("./style.less");
import RadioButtonGroup from "../../../../../components/form/RadioButton/RadioButtonGroup";
import RadioButton from "../../../../../components/form/RadioButton/RadioButton";

interface PackageDownloadOptionsProps {
    forcePackageDownload: boolean;
    onOptionChanged(value: boolean): void;
}

const PackageDownloadOptions: React.StatelessComponent<PackageDownloadOptionsProps> = props => {
    return (
        <ExpandableFormSection title="Package download" errorKey="options" help="Choose whether to force re-downloading packages" summary={buildSummary()}>
            <RadioButtonGroup value={props.forcePackageDownload} onChange={props.onOptionChanged}>
                <RadioButton value={false} label="Use cached packages (if available)" isDefault={true} />
                <RadioButton value={true} label="Re-download packages from feed" />
            </RadioButtonGroup>
        </ExpandableFormSection>
    );

    function buildSummary() {
        return props.forcePackageDownload ? Summary.summary("Re-download packages from feed") : Summary.default("Use cached packages (if available)");
    }
};
export default PackageDownloadOptions;
