import BasicRepository, { ListArgs } from "./basicRepository";
import Client from "../client";
import InterruptionResource from "client/resources/interruptionResource";

type InterruptionListArgs = {
    regarding?: string;
    pendingOnly?: boolean;
} & ListArgs;

class InterruptionRepository extends BasicRepository<InterruptionResource, InterruptionResource, InterruptionListArgs> {
    constructor(client: Client) {
        super("Interruptions", client);
    }
    submit(interruption: InterruptionResource, result: any) {
        return this.client.post(interruption.Links["Submit"], result);
    }
    takeResponsibility(interruption: InterruptionResource) {
        return this.client.put(interruption.Links["Responsible"]);
    }
}

export default InterruptionRepository;
