import * as React from "react";
import SortableList from "components/SortableList/SortableList";
import OkDialogLayout from "../DialogLayout/OkDialogLayout";
import { ActionTemplateParameterResource } from "../../client/resources/actionTemplateParameterResource";
import { BaseComponent } from "../BaseComponent/BaseComponent";

interface ActionTemplateParameterSorterProps {
    title: string;
    parameters: ActionTemplateParameterResource[];
    onOk(parameters: ActionTemplateParameterResource[]): void;
}

interface ActionTemplateParameterSorterState {
    sortedParameters: ActionTemplateParameterResource[];
}

export class ActionTemplateParameterSorter extends BaseComponent<ActionTemplateParameterSorterProps, ActionTemplateParameterSorterState> {
    constructor(props: ActionTemplateParameterSorterProps) {
        super(props);

        this.state = {
            sortedParameters: [...props.parameters],
        };
    }

    render() {
        return (
            <OkDialogLayout title={this.props.title} onOkClick={this.onOk} busy={false} errors={null}>
                <SortableList items={this.state.sortedParameters} onOrderChanged={(sortedParameters: ActionTemplateParameterResource[]) => this.setState({ sortedParameters })} />
            </OkDialogLayout>
        );
    }

    private onOk = () => {
        this.props.onOk(this.state.sortedParameters);
        return true;
    };
}
