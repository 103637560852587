import * as React from "react";
import { useProjectContext } from "../../context";

export type WithProjectDeploymentProcessInjectedProps = {
    processId: string;
};

//This HOC pulls the project ID off the current deployment process context and passes it into the wrapped component
export const withProjectDeploymentProcessId = <T extends unknown>(Component: React.ComponentType<T & WithProjectDeploymentProcessInjectedProps>) => {
    const WithProjectDeploymentProcess: React.FC<T> = props => {
        const context = useProjectContext();
        return <Component processId={context.state.model.DeploymentProcessId} {...props} />;
    };

    return WithProjectDeploymentProcess;
};

export default withProjectDeploymentProcessId;
