import * as React from "react";
import { values } from "lodash";

export interface DisplayOrder {
    displayOrder: number;
}
export interface PackagingInstruction extends DisplayOrder {
    name: string;
    render: () => React.ReactElement<any>;
}

export interface PackagingRegistration extends DisplayOrder {
    name: string;
    id: string;
    instructions: PackagingInstruction[];
}

class PackagingRegistry {
    private registrations: Record<string, PackagingRegistration> = {};

    public getRegistration(id: string) {
        return this.registrations[id];
    }

    public registerPackaging(registration: PackagingRegistration) {
        this.registrations[registration.id] = registration;
    }

    public getAllRegistrations(): PackagingRegistration[] {
        return values(this.registrations);
    }
}

const registry = new PackagingRegistry();
export default registry;
