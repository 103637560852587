import * as React from "react";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import OkDialogLayout from "components/DialogLayout/OkDialogLayout";
import { CalloutType, default as Callout } from "components/Callout";
import { SpaceResource } from "client/resources/spaceResource";
import { repository } from "clientInstance";
import ExternalLink from "../../../../components/Navigation/ExternalLink/ExternalLink";

interface ToggleDefaultSpaceDialogProps {
    disableDefaultSpace: boolean;
    onDefaultSpaceChanged(isEnabled: boolean): Promise<void>;
}

interface EnableDefaultSpaceDialogState extends DataBaseComponentState {
    disableDefaultSpace: boolean;
}

export default class ToggleDefaultSpaceDialog extends DataBaseComponent<ToggleDefaultSpaceDialogProps, EnableDefaultSpaceDialogState> {
    constructor(props: ToggleDefaultSpaceDialogProps) {
        super(props);
        this.state = {
            disableDefaultSpace: props.disableDefaultSpace,
        };
    }

    toggleChange = () => {
        return this.doBusyTask(() => {
            return this.props.onDefaultSpaceChanged(!this.state.disableDefaultSpace);
        });
    };

    render() {
        const disableDefaultSpace: boolean = this.state.disableDefaultSpace;
        return (
            <OkDialogLayout title={"Change the default space"} busy={this.state.busy} errors={this.state.errors} okButtonLabel="Yes I'm sure" okButtonDisabled={false} onOkClick={() => this.toggleChange()}>
                <p>Are you sure that you would like to {disableDefaultSpace ? "disable" : "enable"} the default space?</p>
                <Callout
                    title={"Hold on! Before we make a big change..."}
                    type={CalloutType.Danger}
                    children={
                        <p>
                            {this.state.disableDefaultSpace ? "Disabling" : "Enabling"} the default space has some big impacts on how your Octopus Server works. This change might cause errors in any integrations you have with this server. Please
                            understand the <ExternalLink href="spaces#change-the-default-space">ramifications</ExternalLink> before continuing.
                        </p>
                    }
                />
            </OkDialogLayout>
        );
    }
}
