import * as React from "react";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { DialogLayout, DialogLayoutDispatchProps } from "components/DialogLayout/DialogLayout";
import { DialogLayoutConnect } from "components/Dialog/DialogLayoutConnect";

interface UpdateVariablesProps {
    onUpdateVariablesClicked(): any;
}

interface UpdateVariablesState extends DataBaseComponentState {
    isBusy: boolean;
}

export class UpdateVariablesMessage extends React.PureComponent {
    render() {
        return (
            <p>
                Octopus takes a snapshot of the variables each time a release is created. By updating the variables, the current snapshot will be discarded, and the latest variables (as seen on the Variables tab) will be imported. As your deployment
                process has changed since the release was created, if you removed any deployment steps, keep in mind that variables might also have been deleted that you still rely on for this release.
            </p>
        );
    }
}

class UpdateVariablesInternal extends DataBaseComponent<UpdateVariablesProps & DialogLayoutDispatchProps, UpdateVariablesState> {
    constructor(props: UpdateVariablesProps & DialogLayoutDispatchProps) {
        super(props);
        this.state = {
            isBusy: false,
        };
    }

    render() {
        const actions = [];
        actions.push(<ActionButton label="Cancel" key="CancelBlock" onClick={() => this.props.close()} />);
        actions.push(
            <ActionButton
                label="Update variables"
                key="UpdateVariables"
                type={ActionButtonType.Primary}
                disabled={this.state.isBusy}
                onClick={() =>
                    this.doBusyTask(async () => {
                        this.setState({ isBusy: true });
                        await this.props.onUpdateVariablesClicked();
                        this.setState({ isBusy: false });
                        this.props.close();
                    })
                }
            />
        );
        return (
            <DialogLayout title="Confirm Variable Update" busy={this.state.busy} errors={this.state.errors} key="UpdateVariables" closeDialog={this.props.close} actions={actions}>
                <UpdateVariablesMessage />
            </DialogLayout>
        );
    }
}

const UpdateVariables = DialogLayoutConnect.to<UpdateVariablesProps>(UpdateVariablesInternal);
export default UpdateVariables;
