import * as React from "react";
import { repository } from "clientInstance";
import { ProjectResource, TriggerResource, ResourceCollection, EnvironmentResource, EventCategoryResource, EventGroupResource, ChannelResource, DeploymentProcessResource, TriggerFilterType } from "client/resources";
import { ProjectRouteParams } from "../ProjectLayout";
import List from "components/List";
import PaperLayout from "components/PaperLayout/index";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import Trigger from "./Trigger";
import SideBar from "./SideBar";
import { flatten } from "lodash";
import { RouteComponentProps } from "react-router";
import SidebarLayout from "components/SidebarLayout/SidebarLayout";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import ScheduledTrigger from "./Scheduled/ScheduledTrigger";
import { OverflowMenuItems } from "components/Menu";
import { RaisedButton, Popover, Menu, MenuItem, RaisedButtonProps } from "material-ui";
import { white, success } from "theme/colors";
import AdvancedFilterLayout from "components/AdvancedFilterLayout/AdvancedFilterLayout";
import FilterSearchBox from "components/FilterSearchBox";
import { AdvancedFilterCheckbox } from "components/AdvancedFilterLayout";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";
import * as _ from "lodash";
import { DeploymentActionPackageResource } from "../../../../client/resources/deploymentActionPackageResource";
import routeLinks from "routeLinks";
import Callout, { CalloutType } from "components/Callout";
import ActionList from "components/ActionList";

interface TriggersState extends DataBaseComponentState {
    project: ProjectResource;
    triggersResponse: ResourceCollection<TriggerResource>;
    environments: EnvironmentResource[];
    categories: EventCategoryResource[];
    groups: EventGroupResource[];
    actionPackages: DeploymentActionPackageResource[];
    deploymentProcess: DeploymentProcessResource;
    builtInPackageRepositoryInUse: boolean;
    showAutomaticReleaseCreation: boolean;
    channels: ChannelResource[];
    open: boolean;
    anchor?: any;
    filter: TriggersFilter;
    isSearching: boolean;
}

interface TriggersFilter {
    searchText: string;
    showDeploymentTargetTriggers: boolean;
    showScheduledTriggers: boolean;
}

class FilterLayout extends AdvancedFilterLayout<TriggersFilter> {}
class TriggersList extends List<TriggerResource> {}

const addTriggerButtonStyle: Partial<RaisedButtonProps> = {
    labelColor: white,
    backgroundColor: success,
    labelStyle: {
        fontSize: "0.8125rem",
        whiteSpace: "nowrap",
    },
};

const popoverStyle = {
    backgroundColor: success,
    color: white,
};

const menuItemStyle = {
    color: white,
};

export class Triggers extends DataBaseComponent<RouteComponentProps<ProjectRouteParams>, TriggersState> {
    private match: any = null;

    constructor(props: RouteComponentProps<ProjectRouteParams>) {
        super(props);
        this.match = this.props.match;
        this.state = {
            project: null,
            triggersResponse: null,
            environments: null,
            categories: null,
            deploymentProcess: null,
            groups: null,
            actionPackages: null,
            builtInPackageRepositoryInUse: null,
            showAutomaticReleaseCreation: null,
            channels: null,
            open: false,
            filter: null,
            isSearching: false,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = await repository.Projects.get(this.props.match.params.projectSlug);

            const [categories, environments, groups, triggersResponse, deploymentProcess, channels] = await Promise.all([
                repository.Events.categories({ appliesTo: "Machine" }),
                repository.Environments.all(),
                repository.Events.groups({ appliesTo: "Machine" }),
                repository.Projects.getTriggers(project),
                isAllowed({ permission: Permission.ProcessView, project: project.Id, tenant: "*" }) ? repository.DeploymentProcesses.get(project.DeploymentProcessId) : (Promise.resolve(null) as Promise<DeploymentProcessResource>),
                isAllowed({ permission: Permission.ProcessView, project: project.Id, tenant: "*" }) ? repository.Projects.getChannels(project) : (Promise.resolve(null) as Promise<ResourceCollection<ChannelResource>>),
            ]);

            const actions = deploymentProcess && flatten(deploymentProcess.Steps.map(step => step.Actions)).filter(a => !a.IsDisabled);
            const builtInFeed = await repository.Feeds.getBuiltIn();
            const builtInFeedPackageActions =
                actions &&
                // start withe the steps
                _.chain(deploymentProcess.Steps)
                    // Get the step actionPackages
                    .flatMap(step => step.Actions)
                    // Filter by built-in feed id
                    // Convert them to deployment-action-packages
                    .flatMap(action =>
                        _.chain(action.Packages)
                            .filter(pkg => pkg.FeedId === builtInFeed.Id)
                            .map(pkg => ({ DeploymentAction: action.Name, PackageReference: pkg.Name }))
                            .value()
                    )
                    .value();

            const showAutomaticReleaseCreation = (builtInFeedPackageActions && builtInFeedPackageActions.length > 0) || project.AutoCreateRelease === true;

            this.setState({
                triggersResponse,
                environments,
                categories,
                deploymentProcess,
                groups,
                project,
                builtInPackageRepositoryInUse: builtInFeedPackageActions && builtInFeedPackageActions.length > 0,
                actionPackages: builtInFeedPackageActions,
                showAutomaticReleaseCreation,
                channels: channels && channels.Items,
                filter: this.createDefaultFilter(),
            });
        });
    }

    handleTouchTap = (event: any) => {
        event.preventDefault();
        this.setState({
            open: true,
            anchor: event.currentTarget,
        });
    };

    handleRequestClose = () => {
        this.setState({
            open: false,
        });
    };

    filterTriggers = (searchText: string, trigger: TriggerResource) => {
        const filter = this.state.filter;
        const deploymentTargetTriggerFilterTypes = [TriggerFilterType.MachineFilter];
        const scheduledTriggerFilterTypes = [TriggerFilterType.DailySchedule, TriggerFilterType.DaysPerWeekSchedule, TriggerFilterType.DaysPerMonthSchedule, TriggerFilterType.CronExpressionSchedule];
        return (
            (filter.searchText === "" || (filter.searchText !== "" && trigger.Name.toLowerCase().includes(filter.searchText.toLowerCase()))) &&
            (filter.showDeploymentTargetTriggers || (!filter.showDeploymentTargetTriggers && !deploymentTargetTriggerFilterTypes.includes(trigger.Filter.FilterType))) &&
            (filter.showScheduledTriggers || (!filter.showScheduledTriggers && !scheduledTriggerFilterTypes.includes(trigger.Filter.FilterType)))
        );
    };

    render() {
        const addTriggerButton = (
            <PermissionCheck permission={Permission.TriggerCreate} project={this.state.project && this.state.project.Id}>
                <RaisedButton {...addTriggerButtonStyle} label="Add trigger" labelPosition="before" icon={<i className="fa fa-caret-down" style={{ color: white }} />} onClick={this.handleTouchTap}>
                    <Popover
                        open={this.state.open}
                        style={popoverStyle}
                        canAutoPosition={false}
                        anchorEl={this.state.anchor}
                        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                        targetOrigin={{ horizontal: "right", vertical: "top" }}
                        onRequestClose={this.handleRequestClose}
                    >
                        <Menu maxHeight={16 * 40}>
                            <InternalLink key="add-new-trigger" to={`${this.props.match.url}/create`}>
                                <MenuItem primaryText={"Deployment target trigger"} style={menuItemStyle} />
                            </InternalLink>
                            <InternalLink key="add-new-scheduled-trigger" to={`${this.props.match.url}/scheduled/create`}>
                                <MenuItem primaryText={"Scheduled trigger"} style={menuItemStyle} />
                            </InternalLink>
                        </Menu>
                    </Popover>
                </RaisedButton>
            </PermissionCheck>
        );

        const list = (
            <TriggersList
                initialData={this.state.triggersResponse}
                onRow={(item: any) => this.buildTriggerRow(item)}
                match={this.match}
                onRowRedirectUrl={(trigger: TriggerResource) => (trigger.Filter.FilterType === TriggerFilterType.MachineFilter ? `${this.match.url}/edit/${trigger.Id}` : `${this.match.url}/scheduled/edit/${trigger.Id}`)}
                onFilter={this.filterTriggers}
            />
        );

        return (
            <PaperLayout busy={this.state.busy} errors={this.state.errors} title="Triggers" sectionControl={addTriggerButton}>
                <SidebarLayout
                    sideBar={
                        this.state.project &&
                        isAllowed({ permission: Permission.ProcessView, project: this.state.project.Id, tenant: "*" }) && (
                            <SideBar
                                project={this.state.project}
                                builtInPackageRepositoryInUse={this.state.builtInPackageRepositoryInUse}
                                actionPackages={this.state.actionPackages}
                                showAutomaticReleaseCreation={this.state.showAutomaticReleaseCreation}
                                channels={this.state.channels}
                                deploymentProcess={this.state.deploymentProcess}
                                onProjectUpdated={p => this.onProjectUpdated(p)}
                            />
                        )
                    }
                >
                    {this.getInvalidConfigurationCallout()}
                    {this.state.triggersResponse && (
                        <FilterLayout
                            filter={this.state.filter}
                            defaultFilter={this.createDefaultFilter()}
                            filterSections={[]}
                            additionalHeaderFilters={[
                                <FilterSearchBox
                                    hintText="Filter by name..."
                                    value={this.state.filter.searchText}
                                    autoFocus={true}
                                    onChange={searchText =>
                                        this.setFilterState({ searchText }, () => {
                                            this.onFilterChange();
                                        })
                                    }
                                />,
                                <ActionList
                                    actions={[
                                        <AdvancedFilterCheckbox
                                            label="Show deployment target triggers"
                                            value={this.state.filter.showDeploymentTargetTriggers}
                                            onChange={x =>
                                                this.setFilterState({ showDeploymentTargetTriggers: x }, () => {
                                                    this.onFilterChange();
                                                })
                                            }
                                        />,
                                        <AdvancedFilterCheckbox
                                            label="Show scheduled triggers"
                                            value={this.state.filter.showScheduledTriggers}
                                            onChange={x =>
                                                this.setFilterState({ showScheduledTriggers: x }, () => {
                                                    this.onFilterChange();
                                                })
                                            }
                                        />,
                                    ]}
                                />,
                            ]}
                            onFilterReset={(filter: TriggersFilter) => {
                                this.setState({ filter }, () => {
                                    this.onFilterChange();
                                    const location = { ...this.props.history, search: null as any };
                                    this.props.history.replace(location);
                                });
                            }}
                            renderContent={() => list}
                        />
                    )}
                </SidebarLayout>
            </PaperLayout>
        );
    }

    private getInvalidConfigurationCallout() {
        if (this.state.deploymentProcess && this.state.project.AutoCreateRelease) {
            if (this.state.project.ReleaseCreationStrategy == null || this.state.project.ReleaseCreationStrategy.ReleaseCreationPackage == null) {
                return (
                    <Callout type={CalloutType.Warning} title="Invalid Configuration">
                        This project is configured to use Automatic Release Creation, but the step is missing. Please adjust or disable the Automatic Release Creation configuration.
                    </Callout>
                );
            } else {
                const action = flatten(this.state.deploymentProcess.Steps.map(step => step.Actions)).filter(a => a.Name === this.state.project.ReleaseCreationStrategy.ReleaseCreationPackage.DeploymentAction);
                if (action && action.length > 0 && action[0].IsDisabled) {
                    return (
                        <Callout type={CalloutType.Warning} title="Invalid Configuration">
                            Step <InternalLink to={routeLinks.project(this.state.project).process.step(action[0].Id)}>{action[0].Name}</InternalLink> is currently used for Automatic Release Creation, but it has been disabled.
                            <br />
                            Please re-enable the step, disable Automatic Release Creation, or choose a different step.
                        </Callout>
                    );
                }
            }
        }
        return null;
    }

    private onFilterChange() {
        this.setState({ isSearching: true }, async () => {
            this.setState({ isSearching: false });
        });
    }

    private setFilterState<K extends keyof TriggersFilter>(state: Pick<TriggersFilter, K>, callback?: () => void) {
        this.setState(
            prev => ({
                filter: { ...(prev.filter as object), ...(state as object) },
            }),
            callback
        );
    }

    private createDefaultFilter() {
        return {
            searchText: "",
            showDeploymentTargetTriggers: true,
            showScheduledTriggers: true,
        };
    }

    private buildTriggerRow(trigger: TriggerResource) {
        const overflowMenuItems: any = this.getOverflowMenuItems(trigger);
        if (trigger.Filter.FilterType === TriggerFilterType.MachineFilter) {
            return <Trigger key={trigger.Id} trigger={trigger} menuItems={overflowMenuItems} environments={this.state.environments} categories={this.state.categories} groups={this.state.groups} />;
        }

        return <ScheduledTrigger key={trigger.Id} trigger={trigger} menuItems={overflowMenuItems} environments={this.state.environments} channels={this.state.channels} />;
    }

    private getOverflowMenuItems(trigger: TriggerResource) {
        const menuItems = [];
        const triggerEditPermission = { permission: Permission.TriggerEdit, project: this.state.project.Id };

        menuItems.push(OverflowMenuItems.item(trigger.IsDisabled ? "Enable" : "Disable", () => (trigger.IsDisabled ? this.enable(trigger) : this.disable(trigger)), triggerEditPermission));

        return menuItems;
    }

    private async enable(trigger: TriggerResource) {
        trigger.IsDisabled = false;
        await this.saveTrigger(trigger);
    }

    private async disable(trigger: TriggerResource) {
        trigger.IsDisabled = true;
        await this.saveTrigger(trigger);
    }

    private async saveTrigger(trigger: TriggerResource) {
        const isSuccess = await this.doBusyTask(async () => {
            await repository.ProjectTriggers.modify(trigger);
            const triggersResponse = await repository.Projects.getTriggers(this.state.project);
            this.setState({
                triggersResponse,
            });
        });

        if (!isSuccess) {
            await this.doBusyTask(async () => {
                this.setState({
                    triggersResponse: await repository.Projects.getTriggers(this.state.project),
                });
            }, false);
        }
    }
    private onProjectUpdated(project: ProjectResource) {
        this.setState({
            project,
        });
    }
}
