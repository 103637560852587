// copied from material-ui so that we can set tabIndex
import * as React from "react";
import * as PropTypes from "prop-types";
import OpenIcon from "material-ui/svg-icons/hardware/keyboard-arrow-up";
import CloseIcon from "material-ui/svg-icons/hardware/keyboard-arrow-down";
import IconButton from "material-ui/IconButton";

interface CardExpandableProps {
    expanded?: boolean;
    tabIndex?: number;
    position?: boolean;
    onExpanding?(event: any): void;
}

class CardExpandable extends React.Component<CardExpandableProps> {
    static contextTypes = {
        muiTheme: PropTypes.object.isRequired,
    };

    render() {
        return (
            <IconButton onClick={this.props.onExpanding} tabIndex={this.props.tabIndex} title={this.props.expanded ? "Collapse" : "Expand"}>
                {this.props.expanded ? <OpenIcon /> : <CloseIcon />}
            </IconButton>
        );
    }
}

export default CardExpandable;
