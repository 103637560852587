import * as React from "react";
import { Callout, CalloutType } from "components/Callout";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";

const AzureServerTargetRolesInfo = (props: { stepTargetRoles: string; isCompatibleWithCloudRegions: boolean }) => {
    return props.stepTargetRoles ? (
        <Callout type={CalloutType.Information} title={"No current targets match"}>
            Ensure you have at least one active deployment target matching the selected role(s) at the time of your deployment.
            {props.isCompatibleWithCloudRegions && (
                <span>
                    <ExternalLink href="CloudRegionTargets">Cloud Region targets</ExternalLink> can be used when deploying cloud applications to multiple geographic regions.
                </span>
            )}
        </Callout>
    ) : null;
};

export default AzureServerTargetRolesInfo;
