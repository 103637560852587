import { OctopusError } from "client/resources";

export interface Errors {
    message: string;
    details: string[];
    statusCode?: number;
    detailLinks?: string[];
    helpText?: string;
    helpLink?: string;
    fullException?: string;
    fieldErrors: {
        [other: string]: string;
    };
}

export interface FieldErrors {
    [key: string]: string;
}

export interface ErrorActions {
    getFieldError: (fieldName: string) => string;
    setError: (message: string, details: string[], fieldErrors: FieldErrors) => void;
    setOctopusError: (error: OctopusError, map?: (error: Errors) => Errors) => void;
}

export function createErrorsFromOctopusError(err: OctopusError): Errors {
    return {
        message: err.ErrorMessage,
        details: err.Errors || [],
        detailLinks: err.ParsedHelpLinks,
        helpLink: err.HelpLink,
        helpText: err.HelpText,
        fullException: err.FullException,
        fieldErrors: {},
        statusCode: err.StatusCode,
    };
}
