import ReferenceDataItem from "./referenceDataItem";

export class ProjectedTeamReferenceDataItem extends ReferenceDataItem {
    IsDirectlyAssigned: boolean;
    ExternalGroupNames: string[];
    SpaceId?: string;
    Id: string;
}

export default ProjectedTeamReferenceDataItem;
