import { ConfigurationAreaState, ConfigurationFeaturesState } from "./configurationArea";
import { createSelector } from "reselect";
import { identity } from "lodash";

const configurationAreaSelector = (state: GlobalState) => state.configurationArea;

export const createAreaSelectors = <T extends any = ConfigurationAreaState>(sliceSelector: (state: T) => ConfigurationAreaState = identity) => {
    const featureAreaSelector = (state: T) => sliceSelector(state).features;
    const spacesAreaSelector = (state: T) => sliceSelector(state).spaces;
    const currentSpaceAreaSelector = (state: T) => sliceSelector(state).currentSpace;

    const createFeatureEnabledSelector = (accessor: (toggle: ConfigurationFeaturesState) => boolean) => {
        return createSelector(
            [featureAreaSelector],
            slice => {
                return slice && accessor(slice);
            }
        );
    };

    const getHelpSidebarSupportLink = (state: T) => {
        return featureAreaSelector(state).helpSidebarSupportLink;
    };

    const getMultiTenancyEnabled = (state: T) => {
        return currentSpaceAreaSelector(state).isMultiTenancyEnabled;
    };

    const getAccessibleSpaces = (state: T) => {
        return spacesAreaSelector(state).usersAccessibleSpaces;
    };

    return {
        createFeatureEnabledSelector,
        getFeaturesState: featureAreaSelector,
        getAccessibleSpacesArea: spacesAreaSelector,
        getCurrentSpacesArea: currentSpaceAreaSelector,
        getHelpSidebarSupportLink,
        getMultiTenancyEnabled,
        getAccessibleSpaces,
    };
};

const globalStateSelectors = createAreaSelectors(configurationAreaSelector);
export default globalStateSelectors;
