import * as React from "react";
import * as cn from "classnames";

const styles = require("./style.less");

interface DataTableRowColumnProps {
    fullWidth?: boolean;
    fullHeight?: boolean;
    className?: string;
    colSpan?: number;
    title?: string;
}

const DataTableRowColumn: React.StatelessComponent<DataTableRowColumnProps> = props => {
    const { className, fullWidth, fullHeight, ...otherProps } = props;

    const style: React.CSSProperties = {};

    if (fullHeight) {
        style.paddingBottom = 0;
        style.paddingTop = 0;
    }

    if (fullWidth) {
        style.paddingRight = 0;
        style.paddingLeft = 0;
    }

    return (
        <td {...otherProps} style={style} className={cn(className, styles.dataTableRowColumn)}>
            {props.children}
        </td>
    );
};

export { DataTableRowColumn };
