import * as React from "react";
import StringHelper from "utils/StringHelper/StringHelper";

const styles = require("./style.less");

interface EventDetailsProps extends React.HTMLProps<HTMLElement> {
    eventDetails: string;
}

interface EventDetailsState {
    highlightCoords: ElementCoordinates[];
}

interface ElementCoordinates {
    top: number;
    height: number;
}

class EventDetails extends React.Component<EventDetailsProps, EventDetailsState> {
    private boundingDiv: HTMLElement | null = null;

    constructor(props: EventDetailsProps) {
        super(props);
        this.state = {
            highlightCoords: [],
        };
    }

    componentDidMount() {
        this.calculateOverlayDivHeights();
        window.addEventListener("resize", this.calculateHeight);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.calculateHeight);
    }

    calculateOverlayDivHeights() {
        const insertHighlightCoords = this.getTagLocations(this.boundingDiv.getElementsByTagName("ins"));
        const deleteHighlightCoords = this.getTagLocations(this.boundingDiv.getElementsByTagName("del"));
        const uniqueHighlightCoords = insertHighlightCoords.concat(deleteHighlightCoords).sort((a, b) => a.top - b.top);
        this.setState({ highlightCoords: uniqueHighlightCoords });
    }

    calculateHeight = () => {
        // Hack to get the heights to refresh on window resize.
        this.setState({ highlightCoords: [] }, () => {
            this.calculateOverlayDivHeights();
        });
    };

    getTagLocations(tags: HTMLCollectionOf<HTMLModElement>): ElementCoordinates[] {
        const tops: ElementCoordinates[] = [];
        if (tags) {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < tags.length; i++) {
                const tag = tags[i];
                tops.push({ top: tag.offsetTop, height: tag.offsetHeight });
            }
        }
        return tops;
    }

    render() {
        const { eventDetails, ...rest } = this.props;
        return (
            <div {...(rest as any)}>
                <pre className={styles.preWrapped}>
                    <div className={styles.changeIndicatorOverlay}>
                        {this.state.highlightCoords.map((x, index) => {
                            return <div key={index} className={styles.changeLine} style={{ top: x.top, height: x.height }} />;
                        })}
                    </div>
                    <div ref={div => (this.boundingDiv = div)} className={styles.changeSet} dangerouslySetInnerHTML={{ __html: StringHelper.formatDiff(eventDetails) }} />
                </pre>
            </div>
        );
    }
}

export default EventDetails;
