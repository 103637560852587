import * as React from "react";
import { DataBaseComponentState } from "../DataBaseComponent";
import { DataBaseComponent } from "../DataBaseComponent/DataBaseComponent";
import pluginRegistry, { ActionPlugin, FeaturePlugin, ActionScope } from "../Actions/pluginRegistry";
import OkDialogLayout from "../DialogLayout/OkDialogLayout";
import FeatureList from "./FeatureList";
import { Callout, CalloutType } from "../Callout";
import ActionProperties from "client/resources/actionProperties";

export interface FeatureItem {
    featureName: string;
    title: string;
    description: string;
    instance: FeaturePlugin;
    isEnabled: boolean;
    wasEnabled: boolean;
}

type ConfigureFeaturesContentProps = {
    actionType: string;
    properties: ActionProperties;
    scope: ActionScope;
    saveDone(enabledFeatures: string): void;
};

interface ConfigureFeaturesContentState extends DataBaseComponentState {
    features: FeatureItem[];
    removedFeatures: string[];
    actionType: ActionPlugin;
}

class ConfigureFeaturesContent extends DataBaseComponent<ConfigureFeaturesContentProps, ConfigureFeaturesContentState> {
    constructor(props: ConfigureFeaturesContentProps) {
        super(props);
        this.state = {
            features: [],
            removedFeatures: [],
            actionType: null,
        };
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const features: FeatureItem[] = [];
            const action = pluginRegistry.getAction(this.props.actionType, this.props.scope);
            const allFeatures = pluginRegistry.getAllFeatures(this.props.scope);
            const enabledFeatures = this.props.properties["Octopus.Action.EnabledFeatures"] ? (this.props.properties["Octopus.Action.EnabledFeatures"] as string).split(",") : [];

            if (action.features && action.features.optional) {
                allFeatures
                    .sort((a, b) => a.priority - b.priority)
                    .forEach(feature => {
                        if (!action.features.optional.includes(feature.featureName)) {
                            return;
                        }

                        features.push({
                            featureName: feature.featureName,
                            title: feature.title,
                            description: feature.description,
                            instance: feature,
                            isEnabled: enabledFeatures.includes(feature.featureName),
                            wasEnabled: enabledFeatures.includes(feature.featureName),
                        });
                    });
            }

            this.setState({
                features,
                actionType: action,
            });
        });
    }

    async save() {
        return this.doBusyTask(async () => {
            // permanent actions are always enabled
            const currentlyEnabled = this.state.actionType.features && this.state.actionType.features.permanent ? [...this.state.actionType.features.permanent] : [];

            this.state.features.forEach(feature => {
                if (feature.isEnabled) {
                    currentlyEnabled.push(feature.featureName);
                }

                if (!feature.wasEnabled && feature.isEnabled) {
                    if (feature.instance.enable) {
                        feature.instance.enable(this.props.properties);
                    }
                }
            });
            this.props.saveDone(currentlyEnabled.join(","));
        });
    }

    featureToggled = () => {
        const removedFeatures = this.state.features
            .filter(feature => {
                return feature.wasEnabled && !feature.isEnabled;
            })
            .map(feature => {
                return feature.title;
            });
        this.setState({
            removedFeatures,
        });
    };

    handleIsEnabledChanged = (value: boolean, featureName: string) => {
        this.setState((prevState: ConfigureFeaturesContentState) => {
            const features = prevState.features.map(f => {
                return f.featureName === featureName ? { ...f, isEnabled: value } : f;
            });

            return { features };
        }, this.featureToggled);
    };

    render() {
        return (
            <OkDialogLayout title="Enabled Features" busy={this.state.busy} errors={this.state.errors} onOkClick={() => this.save()}>
                {this.state.features && (
                    <div>
                        {this.state.removedFeatures.length > 0 && (
                            <Callout type={CalloutType.Warning} title={"Feature deselected"}>
                                <span>You have deselected the feature{this.state.removedFeatures.length > 1 ? <span>s</span> : ""} </span>
                                {this.state.removedFeatures.map((feature, index, arr) => {
                                    const last = index === arr.length - 1;
                                    const first = index === 0;
                                    const middle = !last && !first;
                                    return (
                                        <span key={"removed" + index}>
                                            {last && !first ? " and " : ""}
                                            {middle ? ", " : ""}
                                            <b>{feature}</b>
                                        </span>
                                    );
                                })}
                                <span>. This will remove any related settings for {this.state.removedFeatures.length > 1 ? <span>these features</span> : <span>this feature</span>}.</span>
                            </Callout>
                        )}
                        <FeatureList features={this.state.features} onIsEnabledChanged={this.handleIsEnabledChanged} />
                    </div>
                )}
            </OkDialogLayout>
        );
    }
}

export default ConfigureFeaturesContent;
