import * as React from "react";
import styles = require("./SpacesMenu.less");
import SpaceSwitcher from "./SpaceSwitcher";
import * as cn from "classnames";
import { client, repository, session } from "../../clientInstance";
import { withRouter, RouteComponentProps } from "react-router";
import { DataBaseComponent, DataBaseComponentState } from "../DataBaseComponent/DataBaseComponent";
import { SpaceResource } from "../../client/resources/spaceResource";
import InternalRedirect from "components/Navigation/InternalRedirect";
import { LicenseStatusResource } from "client/resources";
import { SpaceLimitReachedDialogLayout } from "components/LicenseLimitReachedDialogLayout";
import Dialog from "components/Dialog/Dialog";
import { connect, MapStateToProps } from "react-redux";
import { Action, Dispatch } from "redux";
import { configurationActions } from "../../areas/configuration/reducers/configurationArea";
import { SpaceContext } from "../StandardLayout/SpaceLoader";

const defaultLogo = require("resources/images/Octopus-96x96.png");

class SpaceIconProps {
    logo: string;
}

const SpaceIcon = (props: SpaceIconProps) => (
    <span className={styles.spaceIcon}>
        <img src={props.logo} />
    </span>
);

interface SpacesMenuState extends DataBaseComponentState {
    licenseStatus?: LicenseStatusResource;
    redirectTo: string | null;
    openSpaceSwitcher: boolean;
    openUpgradeDialog: boolean;
}

export interface SpaceRouteParams {
    spaceId: string;
}

interface ProvidedProps extends RouteComponentProps<SpaceRouteParams> {
    spaceContext: SpaceContext;
}

interface GlobalConnectedProps {
    spaces: SpaceResource[] | null; // null indicates that the spaces have not yet been loaded
}

interface GlobalDispatchProps {
    onUserAccessibleSpacesLoaded(spaces: SpaceResource[]): void;
}

type SpacesMenuProps = ProvidedProps & GlobalConnectedProps & GlobalDispatchProps;

class SpacesMenu extends DataBaseComponent<SpacesMenuProps, SpacesMenuState> {
    private anchorEl: HTMLElement;

    constructor(props: SpacesMenuProps) {
        super(props);

        this.state = {
            redirectTo: null,
            openSpaceSwitcher: false,
            openUpgradeDialog: false,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            this.setState({
                licenseStatus: await repository.Licenses.getCurrentStatus(),
            });
        });
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} />;
        }

        const spaces = this.props.spaces || [];
        const selectedSpace = spaces.find(s => s.Id === client.spaceId);
        const logoUrl = selectedSpace !== undefined ? client.resolve(selectedSpace.Links["Logo"]) : defaultLogo;

        return (
            <div className={styles.spaceMenu}>
                <a ref={this.setRef} onClick={this.onClick} href="#">
                    <SpaceIcon logo={logoUrl} />
                    <span className={styles.title}>{selectedSpace && selectedSpace.Name}</span>
                    <i className={cn("fa fa-caret-down", styles.caret)} />
                </a>

                <SpaceSwitcher
                    onRequestClose={this.closeSpaceSwitcher}
                    onUpgradeSpacesDialogRequested={this.onUpgradeSpacesDialogRequested}
                    open={this.state.openSpaceSwitcher}
                    anchorEl={this.anchorEl}
                    spaces={spaces}
                    licenseStatus={this.state.licenseStatus}
                    selected={selectedSpace}
                    spaceContext={this.props.spaceContext}
                />

                <Dialog open={this.state.openUpgradeDialog}>
                    <SpaceLimitReachedDialogLayout onActionClick={() => this.setState({ openUpgradeDialog: false })} />
                </Dialog>
            </div>
        );
    }

    private toggleSpaceSwitcher = async () => {
        this.setState({
            openSpaceSwitcher: !this.state.openSpaceSwitcher,
        });

        await this.doBusyTask(async () => {
            await this.refreshSpaces();
        });
    };

    private refreshSpaces = async () => {
        const spaces = await repository.Users.getSpaces(session.currentUser);
        this.props.onUserAccessibleSpacesLoaded(spaces);
    };

    private closeSpaceSwitcher = () => {
        this.setState({
            openSpaceSwitcher: false,
        });
    };

    private onClick = async (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        await this.toggleSpaceSwitcher();
    };

    private onUpgradeSpacesDialogRequested = () => {
        this.closeSpaceSwitcher();
        this.setState({
            openUpgradeDialog: true,
        });
    };

    private setRef = (el: HTMLElement) => {
        this.anchorEl = el;
    };
}

const mapGlobalActionDispatchersToProps = (dispatch: Dispatch<Action<any>>): GlobalDispatchProps => {
    return {
        onUserAccessibleSpacesLoaded: (spaces: SpaceResource[]) => {
            dispatch(configurationActions.userAccessibleSpacesFetched(spaces));
        },
    };
};

const mapGlobalStateToProps: MapStateToProps<GlobalConnectedProps, ProvidedProps, GlobalState> = state => {
    return {
        spaces: state.configurationArea.spaces ? state.configurationArea.spaces.usersAccessibleSpaces : null,
    };
};

export default withRouter(
    connect(
        mapGlobalStateToProps,
        mapGlobalActionDispatchersToProps
    )(SpacesMenu)
);
