import * as React from "react";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { connect } from "react-redux";
import { Drawer } from "@material-ui/core";
import classNames = require("classnames");
import { Theme, createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import * as MediaQuery from "react-responsive";
import { ControlledTabsContainer } from "components/Tabs";
import TabItem from "components/Tabs/TabItem";
import { pageConfigSelector } from "components/PageLayout/reducers/pageLayoutArea";
import IPageWrapper from "utils/pageId";
import { CommonPageHelpOverviewSelector, CommonPageHelpTopicsSelector } from "./PageHelpRegistry/PageHelpSelector";
import { isDrawerOpen, toggleDrawer } from "components/Drawer/reducers";
import { OverflowMenu, OverflowMenuItems } from "components/Menu";
import configurationSelectors from "areas/configuration/reducers/selectors";

const styles = require("./DrawerWrapperLayout.less");

const drawerWidth = 280; // If you update this, also consider the .drawerOffset in GettingStartedFooter.
export const drawerBreakpointWidth = 900;

interface GlobalConnectedProps {
    isOpen?: boolean;
    page?: IPageWrapper;
    isHelpSidebarEnabled?: boolean;
    helpSidebarSupportLink?: string;
}

interface GlobalDispatchProps {
    toggleDrawer?(): void;
}

enum DrawerTabKey {
    Overview = "overview",
    HelpTopics = "helpTopics",
}

// tslint:disable-next-line:no-empty-interface
interface DrawerWrapperLayoutPropsInternal extends WithStyles<typeof drawerStyles> {}

type DrawerWrapperLayoutProps = DrawerWrapperLayoutPropsInternal & GlobalConnectedProps & GlobalDispatchProps;

interface DrawerWrapperLayoutState {
    height: number;
    tab: string;
}

class DrawerWrapperLayoutInternal extends BaseComponent<DrawerWrapperLayoutProps, DrawerWrapperLayoutState> {
    private drawerDiv: HTMLElement | null = null;
    private initialTop: number | null = null;

    constructor(props: DrawerWrapperLayoutProps) {
        super(props);
        this.state = {
            height: 0,
            tab: DrawerTabKey.Overview,
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.calculateHeight);
        window.requestAnimationFrame(() => {
            this.calculateTop();
            this.calculateHeight();
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.calculateHeight);
    }

    calculateTop() {
        if (this.drawerDiv !== null) {
            this.initialTop = this.drawerDiv.getBoundingClientRect().top;
        }
    }

    calculateHeight = () => {
        const approxHeightOfPaddingAndOtherJunk = 0;
        const height = window.innerHeight - this.initialTop - approxHeightOfPaddingAndOtherJunk;
        this.setState({ height });
    };

    render() {
        if (!this.props.isHelpSidebarEnabled) {
            return this.props.children;
        }

        const { isOpen, classes } = this.props;
        return (
            <MediaQuery minWidth={drawerBreakpointWidth}>
                {(matches: boolean) => {
                    if (matches) {
                        return (
                            <div id="drawerwrapper">
                                <div className={classes.root}>
                                    <main
                                        className={classNames(classes.content, {
                                            [classes.contentShift]: isOpen,
                                        })}
                                    >
                                        {this.props.children}
                                    </main>
                                    <Drawer
                                        className={classNames(classes.drawer, {
                                            [classes.drawerHidden]: !isOpen,
                                        })}
                                        variant="persistent"
                                        anchor="right"
                                        open={isOpen}
                                        classes={{
                                            paper: classes.drawerPaper,
                                        }}
                                    >
                                        <div className={styles.container} ref={div => (this.drawerDiv = div)} style={{ height: this.state.height }}>
                                            <div className={styles.content}>
                                                <ControlledTabsContainer
                                                    value={this.state.tab}
                                                    onChange={tab => this.setState({ tab })}
                                                    afterTabsElement={<OverflowMenu menuItems={[OverflowMenuItems.item("Close", this.props.toggleDrawer)]} tabIndex={-1} />}
                                                >
                                                    <TabItem label="Help" value={DrawerTabKey.Overview}>
                                                        <CommonPageHelpOverviewSelector pageId={this.props.page} />
                                                    </TabItem>
                                                    <TabItem label="Resources" value={DrawerTabKey.HelpTopics}>
                                                        <CommonPageHelpTopicsSelector pageId={this.props.page} />
                                                    </TabItem>
                                                </ControlledTabsContainer>
                                            </div>
                                            <a href={this.props.helpSidebarSupportLink ? this.props.helpSidebarSupportLink : "https://g.octopushq.com/HelpGeneral"} target="_blank" rel="noopener noreferrer">
                                                <div className={styles.footer}>
                                                    <h4>Having issues?</h4>
                                                    <span>
                                                        {this.props.helpSidebarSupportLink ? "Get support" : "Our support team are here to help"}
                                                        <em style={{ marginLeft: "0.25rem" }} className="fa fa-external-link" aria-hidden="true" />
                                                    </span>
                                                </div>
                                            </a>
                                        </div>
                                    </Drawer>
                                </div>
                            </div>
                        );
                    } else {
                        return this.props.children;
                    }
                }}
            </MediaQuery>
        );
    }
}

const drawerStyles = (theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
        },
        hide: {
            display: "none",
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
            top: "auto!important",
            height: "auto!important",
            zIndex: 10, // Needs to be less than the GettingStartedFooter.
            borderTop: "1px solid #CCCCCC",
            marginTop: "-1px", // Needed when scrolling because of our floating area title.
        },
        drawerHeader: {
            display: "flex",
            alignItems: "center",
            padding: "0 8px",
            ...theme.mixins.toolbar,
            justifyContent: "flex-start",
        },
        content: {
            flexGrow: 1,
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginRight: -drawerWidth,
        },
        contentShift: {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginRight: 0,
        },
        drawerHidden: {
            transition: theme.transitions.create("visibility", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            visibility: "hidden", // Needed to stop the hidden drawer interfering with things.
        },
    });

const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    const currentOverlayState = {
        isOpen: isDrawerOpen(state),
        ...pageConfigSelector(state),
        isHelpSidebarEnabled: configurationSelectors.createFeatureEnabledSelector(t => t.isHelpSidebarEnabled)(state),
        helpSidebarSupportLink: state.configurationArea.features && state.configurationArea.features.helpSidebarSupportLink,
    };
    return currentOverlayState;
};

const mapGlobalActionDispatchersToProps = (dispatch: any): GlobalDispatchProps => {
    return {
        toggleDrawer: () => dispatch(toggleDrawer()),
    };
};

const DrawerWrapperLayout = connect<{}, {}, DrawerWrapperLayoutProps>(
    mapGlobalStateToProps,
    mapGlobalActionDispatchersToProps
)(DrawerWrapperLayoutInternal);

export default withStyles(drawerStyles, { withTheme: true })(DrawerWrapperLayout);
