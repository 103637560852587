import Resource from "./resource";

export class ApiKeyResource extends Resource {
    Purpose: string;
    UserId: string;
    ApiKey: string;
    Created: string; //TODO: datetimeoffset
}

export default ApiKeyResource;
