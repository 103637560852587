import * as React from "react";
import { BaseComponent } from "../BaseComponent/BaseComponent";
import DeleteDialog from "components/Dialog/DeleteDialog";
import { ReactNode } from "react";
import { DoBusyTask } from "../DataBaseComponent/DataBaseComponent";

interface OpenDeleteDialogMenuItemProps {
    dialogTitle: string;
    label?: string;
    disabled: boolean;
    deleteButtonLabel?: string;
    deleteButtonBusyLabel?: string;
    deleteButtonDisabled: boolean;
    acceptOnClick?: (onClick: () => void) => void;
    onDeleteClick(): Promise<boolean>;
    renderContent(doBusyTask: DoBusyTask): ReactNode;
}

interface OpenDeleteDialogMenuItemState {
    open: boolean;
}

export default class OpenDeleteDialogMenuItem extends BaseComponent<OpenDeleteDialogMenuItemProps, OpenDeleteDialogMenuItemState> {
    constructor(props: OpenDeleteDialogMenuItemProps) {
        super(props);
        this.state = { open: false };
    }

    onClick = () => {
        this.setState({ open: true });
    };

    render() {
        if (this.props.acceptOnClick) {
            this.props.acceptOnClick(this.onClick);
        }

        return (
            <DeleteDialog
                title={this.props.dialogTitle}
                open={this.state.open}
                deleteButtonLabel={this.props.deleteButtonLabel}
                deleteButtonBusyLabel={this.props.deleteButtonBusyLabel}
                deleteButtonDisabled={this.props.deleteButtonDisabled}
                onClose={() => this.setState({ open: false })}
                onDeleteClick={() => this.props.onDeleteClick()}
                renderContent={this.props.renderContent}
            />
        );
    }
}
