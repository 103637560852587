import * as React from "react";
import { ActionEditProps } from "../pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { ActionSummaryProps } from "../actionSummaryProps";
import { ActionExecutionLocation, IId } from "client/resources";
import { TargetRoles } from "areas/projects/components/DeploymentProcess/ActionDetails";
import pluginRegistry from "../pluginRegistry";
import FeedResource from "client/resources/feedResource";
import { repository } from "clientInstance";
import DialogOpener from "../../Dialog/DialogOpener";
import ContainerDialog, { CommandCheck, HttpGetCheck, TcpSocketCheck } from "./containerDialog";
import Summary from "../../form/Sections/Summary";
import { default as ExpandableFormSection } from "../../form/Sections/ExpandableFormSection";
import { VariableLookupText } from "../../form/VariableLookupText";
import StringKeyValueEditList from "../../EditList/KeyValueEditList";
import ActionButton from "../../Button";
import { RemoveItemsList } from "../../RemoveItemsList/RemoveItemsList";
import StringExtendedKeyValueEditList, { KeyValueOption } from "../../EditList/ExtendedKeyValueEditList";
import { clone } from "lodash";
import ListTitle from "../../ListTitle/ListTitle";
import * as _ from "lodash";
import { JsonUtils } from "../../../utils/jsonUtils";
import { PackageReference } from "client/resources/packageReference";
import { PackageAcquisitionLocation } from "client/resources/packageAcquisitionLocation";
import { StringRadioButtonGroup, FormSectionHeading } from "../../form";
import RadioButton from "../../form/RadioButton/RadioButton";
import Note from "../../form/Note/Note";
import CombinedVolumeDialog, { ConfigMapType, EmptyDirType, HostPathType, LinkedResource, PersistentVolumeClaimType, RawYamlType, SecretType } from "components/Actions/kubernetes/combinedVolumeDialog";
import { DataTable, DataTableBody, DataTableRow, DataTableRowColumn } from "components/DataTable";
const styles = require("./style.less");
import ExternalLink from "../../Navigation/ExternalLink/ExternalLink";
import isBound from "components/form/BoundField/isBound";
import { BoundStringRadioButtonGroup } from "components/form/RadioButton/RadioButtonGroup";
import PodAffinityDialog from "components/Actions/kubernetes/podAffinityDialog";
import NodeAffinityDialog from "components/Actions/kubernetes/nodeAffinityDialog";
import { safeLoad as jsyaml } from "js-yaml";
import KubernetesNamespaceFormSection from "./kubernetesNamespaceFormSection";

export const RequiredAffinity = "Required";
export const PreferredAffinity = "Preferred";
export const ExistsOperator = "Exists";
export const InOperator = "In";
export const DoesNotExistOperator = "DoesNotExist";
export const NotInOperator = "NotIn";
export const GreaterThanOperator = "Gt";
export const LessThanOperator = "Lt";

interface KubernetesDeploymentProperties {
    "Octopus.Action.KubernetesContainers.Namespace": string;
    "Octopus.Action.KubernetesContainers.DeploymentName": string;
    "Octopus.Action.KubernetesContainers.ProgressDeadlineSeconds": string;
    "Octopus.Action.KubernetesContainers.TerminationGracePeriodSeconds": string;
    "Octopus.Action.KubernetesContainers.DeploymentPaused": string;
    "Octopus.Action.KubernetesContainers.Replicas": string;
    "Octopus.Action.KubernetesContainers.Containers": string;
    "Octopus.Action.KubernetesContainers.CombinedVolumes": string;
    "Octopus.Action.KubernetesContainers.NodeAffinity": string;
    "Octopus.Action.KubernetesContainers.PodAffinity": string;
    "Octopus.Action.KubernetesContainers.PodAntiAffinity": string;
    "Octopus.Action.KubernetesContainers.DeploymentLabels": string;
    "Octopus.Action.KubernetesContainers.DeploymentStyle": string;
    "Octopus.Action.KubernetesContainers.DeploymentWait": string;
    "Octopus.Action.KubernetesContainers.MaxUnavailable": string;
    "Octopus.Action.KubernetesContainers.MaxSurge": string;
    "Octopus.Action.KubernetesContainers.PodSecurityFsGroup": string;
    "Octopus.Action.KubernetesContainers.PodSecurityRunAsGroup": string;
    "Octopus.Action.KubernetesContainers.PodSecurityRunAsUser": string;
    "Octopus.Action.KubernetesContainers.PodSecurityRunAsNonRoot": string;
    "Octopus.Action.KubernetesContainers.PodSecuritySeLinuxLevel": string;
    "Octopus.Action.KubernetesContainers.PodSecuritySeLinuxRole": string;
    "Octopus.Action.KubernetesContainers.PodSecuritySeLinuxType": string;
    "Octopus.Action.KubernetesContainers.PodSecuritySeLinuxUser": string;
    "Octopus.Action.KubernetesContainers.PodSecuritySysctls": string;
    "Octopus.Action.KubernetesContainers.PodSecuritySupplementalGroups": string;
    "Octopus.Action.KubernetesContainers.PodAnnotations": string;
    "Octopus.Action.KubernetesContainers.DeploymentAnnotations": string;
    "Octopus.Action.Package.FeedId": string;
    "Octopus.Action.Package.PackageId": string;
    "Octopus.Action.EnabledFeatures": string;
}

interface KubernetesDeploymentState {
    feeds: FeedResource[];
    containers: ContainerPackageDetails[];
    editContainer: ContainerPackageDetails;
    editContainerIndex: number;
    combinedVolumes: CombinedVolumeDetails[];
    editSecretVolume: CombinedVolumeDetails;
    editSecretVolumeIndex: number;
    podAffinityDetails: PodAffinityDetails[];
    editPodAffinity: PodAffinityDetails;
    editPodAffinityIndex: number;
    podAntiAffinityDetails: PodAffinityDetails[];
    editPodAntiAffinity: PodAffinityDetails;
    editPodAntiAffinityIndex: number;
    nodeAffinityDetails: NodeAffinityDetails[];
    editNodeAffinity: NodeAffinityDetails;
    editNodeAffinityIndex: number;
}

export interface ResourceRequirementsDetails {
    cpu: string;
    memory: string;
    ephemeralStorage: string;
}

/**
 * https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.11/#resourcerequirements-v1-core
 */
export interface ResourceRequirements {
    limits: ResourceRequirementsDetails;
    requests: ResourceRequirementsDetails;
}

/**
 * https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.12/#selinuxoptions-v1-core
 */
export interface SELinuxOptions {
    level: string;
    role: string;
    type: string;
    user: string;
}

/**
 * https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.11/#securitycontext-v1-core
 */
export interface SecurityContext {
    allowPrivilegeEscalation: string;
    privileged: string;
    readOnlyRootFilesystem: string;
    runAsGroup: string;
    runAsNonRoot: string;
    runAsUser: string;
    capabilities: Capabilities;
    seLinuxOptions: SELinuxOptions;
}

/**
 * https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.12/#capabilities-v1-core
 */
export interface Capabilities {
    add: string[];
    drop: string[];
}

/**
 * https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.11/#execaction-v1-core
 */
export interface ExecAction {
    command: string[];
}

/**
 * https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.11/#httpgetaction-v1-core
 */
export interface HTTPGetAction {
    scheme?: string;
    host?: string;
    path?: string;
    port: string;
    httpHeaders?: KeyValueOption[];
}

/**
 * https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.11/#tcpsocketaction-v1-core
 */
export interface TCPSocketAction {
    host?: string;
    port: string;
}

/**
 * https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.11/#probe-v1-core
 */
export interface Probe {
    failureThreshold: string;
    initialDelaySeconds: string;
    periodSeconds: string;
    successThreshold: string;
    timeoutSeconds: string;
    type: string;
    exec: ExecAction;
    httpGet: HTTPGetAction;
    tcpSocket: TCPSocketAction;
}

/**
 * Each package also has details about the ports and environment vars.
 */
export interface ContainerDetails {
    Name?: string;
    InitContainer?: string;
    ImagePullPolicy?: string;
    Ports: KeyValueOption[];
    EnvironmentVariables: KeyValueOption[];
    SecretEnvironmentVariables: KeyValueOption[];
    ConfigMapEnvironmentVariables: KeyValueOption[];
    FieldRefEnvironmentVariables: KeyValueOption[];
    VolumeMounts: KeyValueOption[];
    LivenessProbe: Probe;
    ReadinessProbe: Probe;
    Resources: ResourceRequirements;
    Command: string[];
    Args: string[];
    SecurityContext: SecurityContext;
    Lifecycle: {
        PostStart: LifecycleHandler;
        PreStop: LifecycleHandler;
    };
}

export type LifecycleHandler = {
    Exec?: ExecAction;
    HttpGet?: HTTPGetAction;
    TcpSocket?: TCPSocketAction;
};

/**
 * Internally we treat the details of a package and the container ports and env vars
 * as a single object. Even though these details are stored in two different locations
 * (the package list and a step property), the ui treats it as one thing.
 */
export interface ContainerPackageDetails extends PackageReference, ContainerDetails {
    IsNew?: boolean;
}

class ContainerList extends RemoveItemsList<ContainerPackageDetails> {}

class PodAffinityList extends RemoveItemsList<PodAffinityDetails> {}

class NodeAffinityList extends RemoveItemsList<NodeAffinityDetails> {}

interface GitRepoDetails {
    Repository: string;
    Revision: string;
}

interface SecretOrConfigMapDetails {
    ReferenceName: string;
    /**
     * This is set to "LinkedResource" if the volume name needs to match the
     * resource (secret or config map) that is created as part of the step,
     * and "Custom" if it references a custom resource.
     */
    ReferenceNameType: string;
    Items: KeyValueOption[];
}

interface EmptyDirDetails {
    EmptyDirMedium: string;
}

interface LocalDetails {
    LocalPath: string;
}

interface HostPathDetails {
    HostPathType: string;
    HostPathPath: string;
}

interface RawYamlDetails {
    RawYaml: string;
}

/**
 * We use a single dialog to capture the details of a volume,
 * and the dialog state therefor can contain all the values
 * that make up all the types of volumes.
 */
export interface CombinedVolumeDetails extends SecretOrConfigMapDetails, EmptyDirDetails, HostPathDetails, LocalDetails, RawYamlDetails, GitRepoDetails {
    Name: string;
    Type: string;
}

/**
 * These are the details for a pod (anti)affinity rule
 */
export interface PodAffinityDetails {
    Type: string;
    Weight: string;
    TopologyKey: string;
    NamespacesList: string;
    InMatch: KeyValueOption[];
    ExistMatch: KeyValueOption[];
}

/**
 * These are the details for a node affinity rule
 */
export interface NodeAffinityDetails {
    Type: string;
    Weight: string;
    InMatch: KeyValueOption[];
    ExistMatch: KeyValueOption[];
}

class KubernetesDeployContainersActionSummary extends BaseComponent<ActionSummaryProps, never> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }

    render() {
        return <div>Deploy containers to Kubernetes, optionally exposed by a service and an ingress rule.</div>;
    }
}

class CombinedVolumeList extends RemoveItemsList<CombinedVolumeDetails> {}

class KubernetesDeployContainersActionEdit extends BaseComponent<ActionEditProps<KubernetesDeploymentProperties>, KubernetesDeploymentState> {
    constructor(props: ActionEditProps<KubernetesDeploymentProperties>) {
        super(props);

        this.state = {
            feeds: [],
            containers: this.getContainerPackageDetails(this.props.properties["Octopus.Action.KubernetesContainers.Containers"], this.props.packages),
            editContainer: null,
            editContainerIndex: null,
            combinedVolumes: JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.CombinedVolumes"], []),
            editSecretVolume: null,
            editSecretVolumeIndex: null,
            editPodAffinityIndex: null,
            editPodAffinity: null,
            podAffinityDetails: JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.PodAffinity"], []),
            editPodAntiAffinityIndex: null,
            editPodAntiAffinity: null,
            podAntiAffinityDetails: JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.PodAntiAffinity"], []),
            editNodeAffinityIndex: null,
            editNodeAffinity: null,
            nodeAffinityDetails: JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.NodeAffinity"], []),
        };
    }

    async componentDidMount() {
        await this.loadFeeds();

        // Default to one replica
        if (!this.props.properties["Octopus.Action.KubernetesContainers.Replicas"]) {
            this.props.setProperties({ ["Octopus.Action.KubernetesContainers.Replicas"]: "1" });
        }

        // Default to a Recreate
        if (!this.props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"]) {
            this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentStyle"]: "Recreate" });
        }

        // Default to a Wait
        if (!this.props.properties["Octopus.Action.KubernetesContainers.DeploymentWait"]) {
            this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentWait"]: "Wait" });
        }

        // This step never has unnamed packages
        if (this.props.properties["Octopus.Action.Package.FeedId"] || this.props.properties["Octopus.Action.Package.PackageId"]) {
            this.props.setProperties({ ["Octopus.Action.Package.FeedId"]: "" });
            this.props.setProperties({ ["Octopus.Action.Package.PackageId"]: "" });
            this.props.setPackages(this.props.packages.filter(pkg => pkg.Name));
        }
    }

    componentWillReceiveProps(nextProps: ActionEditProps<KubernetesDeploymentProperties>) {
        if (
            this.props.properties["Octopus.Action.KubernetesContainers.Containers"] !== nextProps.properties["Octopus.Action.KubernetesContainers.Containers"] ||
            this.props.properties["Octopus.Action.KubernetesContainers.CombinedVolumes"] !== nextProps.properties["Octopus.Action.KubernetesContainers.CombinedVolumes"] ||
            this.props.properties["Octopus.Action.KubernetesContainers.PodAffinity"] !== nextProps.properties["Octopus.Action.KubernetesContainers.PodAffinity"] ||
            this.props.properties["Octopus.Action.KubernetesContainers.PodAntiAffinity"] !== nextProps.properties["Octopus.Action.KubernetesContainers.PodAntiAffinity"] ||
            this.props.properties["Octopus.Action.KubernetesContainers.NodeAffinity"] !== nextProps.properties["Octopus.Action.KubernetesContainers.NodeAffinity"] ||
            this.props.properties["Octopus.Action.Package.FeedId"] !== nextProps.properties["Octopus.Action.Package.FeedId"] ||
            this.props.properties["Octopus.Action.Package.PackageId"] !== nextProps.properties["Octopus.Action.Package.PackageId"]
        ) {
            this.setState({
                containers: this.getContainerPackageDetails(nextProps.properties["Octopus.Action.KubernetesContainers.Containers"], nextProps.packages),
                combinedVolumes: JsonUtils.tryParseArray(nextProps.properties["Octopus.Action.KubernetesContainers.CombinedVolumes"], []),
                podAffinityDetails: JsonUtils.tryParseArray(nextProps.properties["Octopus.Action.KubernetesContainers.PodAffinity"], []),
                podAntiAffinityDetails: JsonUtils.tryParseArray(nextProps.properties["Octopus.Action.KubernetesContainers.PodAntiAffinity"], []),
                nodeAffinityDetails: JsonUtils.tryParseArray(nextProps.properties["Octopus.Action.KubernetesContainers.NodeAffinity"], []),
            });
        }
    }

    render() {
        const editContainerDialog = (
            <DialogOpener open={!!this.state.editContainer} onClose={this.resetContainers} wideDialog={true}>
                <ContainerDialog
                    containerDetails={this.state.editContainer}
                    projectId={this.props.projectId}
                    doBusyTask={this.props.doBusyTask}
                    localNames={this.props.localNames}
                    onAdd={item => this.saveContainer(item)}
                    feeds={this.state.feeds}
                    volumes={this.state.combinedVolumes}
                    refreshFeeds={this.loadFeeds}
                />
            </DialogOpener>
        );

        const editSecretVolumeDialog = (
            <DialogOpener open={!!this.state.editSecretVolume} onClose={this.resetCombinedVolume} wideDialog={true} repositionOnUpdate={true}>
                <CombinedVolumeDialog
                    combinedVolumeDetails={this.state.editSecretVolume}
                    projectId={this.props.projectId}
                    doBusyTask={this.props.doBusyTask}
                    localNames={this.props.localNames}
                    featuresEnabled={this.props.properties["Octopus.Action.EnabledFeatures"]}
                    onAdd={item => this.saveCombinedVolume(item)}
                />
            </DialogOpener>
        );

        const editPodAffinity = (
            <DialogOpener open={!!this.state.editPodAffinity} onClose={this.resetPodAffinity} wideDialog={true}>
                <PodAffinityDialog
                    podAffinityDetails={this.state.editPodAffinity}
                    projectId={this.props.projectId}
                    doBusyTask={this.props.doBusyTask}
                    localNames={this.props.localNames}
                    antiAffinity={false}
                    onAdd={item => this.savePodAffinity(item)}
                />
            </DialogOpener>
        );

        const editPodAntiAffinity = (
            <DialogOpener open={!!this.state.editPodAntiAffinity} onClose={this.resetPodAntiAffinity} wideDialog={true}>
                <PodAffinityDialog
                    podAffinityDetails={this.state.editPodAntiAffinity}
                    projectId={this.props.projectId}
                    doBusyTask={this.props.doBusyTask}
                    localNames={this.props.localNames}
                    antiAffinity={true}
                    onAdd={item => this.savePodAntiAffinity(item)}
                />
            </DialogOpener>
        );

        const editNodeAffinity = (
            <DialogOpener open={!!this.state.editNodeAffinity} onClose={this.resetNodeAffinity} wideDialog={true}>
                <NodeAffinityDialog nodeAffinityDetails={this.state.editNodeAffinity} projectId={this.props.projectId} doBusyTask={this.props.doBusyTask} localNames={this.props.localNames} onAdd={item => this.saveNodeAffinity(item)} />
            </DialogOpener>
        );

        return (
            <div>
                {editContainerDialog}
                {editSecretVolumeDialog}
                {editPodAffinity}
                {editPodAntiAffinity}
                {editNodeAffinity}
                <FormSectionHeading title="Deployment" />
                <ExpandableFormSection
                    errorKey={
                        "Octopus.Action.KubernetesContainers.DeploymentName|" + "Octopus.Action.KubernetesContainers.Replicas|" + "Octopus.Action.KubernetesContainers.ProgressDeadlineSeconds|" + "Octopus.Action.KubernetesContainers.DeploymentLabels"
                    }
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Deployment"
                    summary={this.deploymentSummary()}
                    help={"Enter the details for the deployment."}
                >
                    <VariableLookupText
                        localNames={this.props.localNames}
                        projectId={this.props.projectId}
                        value={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentName"]}
                        onChange={x => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentName"]: x })}
                        error={this.props.getFieldError("Octopus.Action.KubernetesContainers.DeploymentName")}
                        label="Deployment name"
                    />
                    <Note>The name of the deployment must be unique, and is used by Kubernetes when updating an existing deployment.</Note>
                    <Note>
                        Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#deployment-name">deployment names</ExternalLink>.
                    </Note>
                    <Note>
                        Blue/green deployment strategies create a new uniquely named deployment resource each time, and directs the service to the new pods. The Octopus deployment ID will be appended to the deployment name e.g.{" "}
                        <code>my-deployment</code>
                        will become <code>my-deployment-deployments-981</code>.
                    </Note>
                    <Note>
                        Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#bluegreen-deployment-strategy">blue/green deployments</ExternalLink>.
                    </Note>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        projectId={this.props.projectId}
                        value={this.props.properties["Octopus.Action.KubernetesContainers.Replicas"]}
                        onChange={x => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.Replicas"]: x })}
                        error={this.props.getFieldError("Octopus.Action.KubernetesContainers.Replicas")}
                        label="Replicas"
                        hintText="1 (default)"
                    />
                    <Note>The number of pod replicas to create from this deployment.</Note>
                    <Note>
                        Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#replicas">replicas</ExternalLink>.
                    </Note>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        projectId={this.props.projectId}
                        value={this.props.properties["Octopus.Action.KubernetesContainers.ProgressDeadlineSeconds"]}
                        onChange={x => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.ProgressDeadlineSeconds"]: x })}
                        error={this.props.getFieldError("Octopus.Action.KubernetesContainers.ProgressDeadlineSeconds")}
                        label="Progression deadline in seconds"
                        hintText="600 (default)"
                    />
                    <Note>The maximum time for a deployment to make progress before it's considered to be failed. Blue/Green deployments will point the service to the new deployment only once the new deployments has succeeded.</Note>
                    <Note>
                        Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#progression-deadline">progression deadlines</ExternalLink>.
                    </Note>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        projectId={this.props.projectId}
                        value={this.props.properties["Octopus.Action.KubernetesContainers.TerminationGracePeriodSeconds"]}
                        onChange={x => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.TerminationGracePeriodSeconds"]: x })}
                        error={this.props.getFieldError("Octopus.Action.KubernetesContainers.TerminationGracePeriodSeconds")}
                        label="Pod termination grace period in seconds"
                        hintText="30 (default)"
                    />
                    <Note>
                        Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#pod-termination-grace-period">pod termination grace period</ExternalLink>.
                    </Note>
                    <p>
                        <strong>Labels</strong>
                        <br />
                        Add labels to be applied to the deployment resource, pods managed by the deployment resource, the service and the ingress.
                    </p>
                    <Note>
                        Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#add-label">labels</ExternalLink>.
                    </Note>
                    <StringKeyValueEditList
                        items={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"]}
                        name="Label"
                        separator=""
                        onChange={val => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentLabels"]: val })}
                        valueLabel="Value"
                        keyLabel="Name"
                        hideBindOnKey={false}
                        projectId={this.props.projectId}
                    />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Octopus.Action.KubernetesContainers.DeploymentStyle"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Deployment Strategy"
                    summary={this.deploymentStyleSummary()}
                    help={"Choose how the deployment will be updated."}
                >
                    <StringRadioButtonGroup value={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"]} onChange={val => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentStyle"]: val })}>
                        <RadioButton
                            value="Recreate"
                            label={
                                <span>
                                    <strong>Recreate deployments</strong> delete existing pods before creating new pods
                                </span>
                            }
                        />
                        <Note>Use this option when container versions can not be mixed. This strategy does result in downtime.</Note>
                        <Note>
                            Learn more about the <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#recreate-deployment-strategy">recreate strategy</ExternalLink>.
                        </Note>
                        <RadioButton
                            value="RollingUpdate"
                            label={
                                <span>
                                    <strong>Rolling update deployments</strong> deploys new pods while remove older pods
                                </span>
                            }
                        />
                        <Note>This option requires that two container versions can run side by side, and avoids downtime.</Note>
                        <Note>
                            Learn more about the <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#rolling-update-deployment-strategy">rolling update strategy</ExternalLink>.
                        </Note>
                        <RadioButton
                            value="BlueGreen"
                            label={
                                <span>
                                    <strong>Blue/Green deployments</strong> create a new deployment resource and points the service to new pods
                                </span>
                            }
                        />
                        <Note>This strategy requires that two container versions can run side by side, and ensures that traffic is cut over to the new pods in a single operation with no downtime.</Note>
                        <Note>
                            Learn more about the <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#bluegreen-deployment-strategy">blue/green strategy</ExternalLink>.
                        </Note>
                        {this.props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"] === "RollingUpdate" && (
                            <div>
                                <VariableLookupText
                                    localNames={this.props.localNames}
                                    projectId={this.props.projectId}
                                    value={this.props.properties["Octopus.Action.KubernetesContainers.MaxUnavailable"]}
                                    onChange={x => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.MaxUnavailable"]: x })}
                                    error={this.props.getFieldError("Octopus.Action.KubernetesContainers.MaxUnavailable")}
                                    label="Max Unavailable"
                                />
                                <VariableLookupText
                                    localNames={this.props.localNames}
                                    projectId={this.props.projectId}
                                    value={this.props.properties["Octopus.Action.KubernetesContainers.MaxSurge"]}
                                    onChange={x => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.MaxSurge"]: x })}
                                    error={this.props.getFieldError("Octopus.Action.KubernetesContainers.MaxSurge")}
                                    label="Max Surge"
                                />
                            </div>
                        )}
                        {(this.props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"] === "RollingUpdate" || this.props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"] === "Recreate") && (
                            <div>
                                <StringRadioButtonGroup value={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentWait"]} onChange={val => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentWait"]: val })}>
                                    <RadioButton value="Wait" label={<span>Wait for the deployment to succeed</span>} />
                                    <RadioButton value="NoWait" label={<span>Do not wait for the deployment to succeed</span>} />
                                </StringRadioButtonGroup>
                            </div>
                        )}
                    </StringRadioButtonGroup>
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Octopus.Action.Kubernetes.SecretVolumes"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Volumes"
                    summary={this.volumeSummary()}
                    help={"Add volumes to be exposed to the containers in this step."}
                >
                    <Note>
                        Volumes can reference externally managed storage devices, or can reference config map and secret resources managed by this step through the <strong>ConfigMap</strong> and <strong>Secret</strong> features.
                    </Note>
                    <Note>
                        Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#volumes">volumes</ExternalLink>.
                    </Note>
                    <CombinedVolumeList
                        listActions={[<ActionButton key="add" label="Add Volume" onClick={() => this.addCombinedVolume()} />]}
                        data={this.state.combinedVolumes}
                        onRow={this.combinedVolumeListItem}
                        onRowTouch={item => this.editCombinedVolume(item)}
                        onRemoveRow={item => this.removeCombinedVolume(item)}
                    />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Octopus.Action.KubernetesContainers.Containers"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Containers"
                    summary={this.containersSummary()}
                    help={"Add containers that make up the pod managed by this deployment"}
                >
                    <ContainerList
                        listActions={[<ActionButton key="add" label="Add Container" onClick={() => this.addContainerViaDialog()} />]}
                        data={this.state.containers}
                        onRow={this.containerListItem}
                        onRowTouch={item => this.editContainerViaDialog(item)}
                        onRemoveRow={item => this.removeContainerViaDialog(item)}
                    />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey={
                        "Octopus.Action.Kubernetes.PodSecurityFsGroup|" +
                        "Octopus.Action.KubernetesContainers.PodSecurityRunAsGroup|" +
                        "Octopus.Action.KubernetesContainers.PodSecurityRunAsUser|" +
                        "Octopus.Action.KubernetesContainers.PodSecurityRunAsNonRoot|" +
                        "Octopus.Action.KubernetesContainers.PodSecuritySupplementalGroups"
                    }
                    title="Pod Security Context"
                    summary={this.podSecuritySummary()}
                    help={"The security context to be applied to the pods."}
                >
                    <VariableLookupText
                        localNames={this.props.localNames}
                        projectId={this.props.projectId}
                        value={this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityFsGroup"]}
                        onChange={x => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecurityFsGroup"]: x })}
                        error={this.props.getFieldError("Octopus.Action.KubernetesContainers.PodSecurityFsGroup")}
                        label="FSGroup"
                    />
                    <Note>
                        A special supplemental group ID that applies to all containers in a pod. <ExternalLink href="KubernetesSecurityContext">More information</ExternalLink>.
                    </Note>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        projectId={this.props.projectId}
                        value={this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsGroup"]}
                        onChange={x => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecurityRunAsGroup"]: x })}
                        error={this.props.getFieldError("Octopus.Action.KubernetesContainers.PodSecurityRunAsGroup")}
                        label="Run as group ID"
                    />
                    <Note>
                        The group ID to run the entrypoint of the container process. Introduced in Kubernetes 1.10. <ExternalLink href="KubernetesSecurityContext">More information</ExternalLink>.
                    </Note>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        projectId={this.props.projectId}
                        value={this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsUser"]}
                        onChange={x => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecurityRunAsUser"]: x })}
                        error={this.props.getFieldError("Octopus.Action.KubernetesContainers.PodSecurityRunAsUser")}
                        label="Run as user ID"
                    />
                    <Note>
                        The user ID to run the entrypoint of the container process. <ExternalLink href="KubernetesSecurityContext">More information</ExternalLink>.
                    </Note>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        projectId={this.props.projectId}
                        value={this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySupplementalGroups"]}
                        onChange={x =>
                            this.props.setProperties({
                                ["Octopus.Action.KubernetesContainers.PodSecuritySupplementalGroups"]:
                                    // get the items
                                    x
                                        .split(",")
                                        // trim the items
                                        .map(y => y.trim())
                                        // remove empty items
                                        .filter(y => y)
                                        // join the list again
                                        .join(", ") +
                                    // was the last char a comma and optional whitespace? If so, add it back on
                                    (/,\s*$/.exec(x) || [""])[0],
                            })
                        }
                        error={this.props.getFieldError("Octopus.Action.KubernetesContainers.PodSecuritySupplementalGroups")}
                        label="Supplemental groups"
                    />
                    <Note>
                        A comma separated list of group IDs applied to the first process run in each container, in addition to the container's primary group ID.{" "}
                        <ExternalLink href="KubernetesSecurityContextSupplementalGroups">More information</ExternalLink>.
                    </Note>
                    <BoundStringRadioButtonGroup
                        variableLookup={{
                            localNames: this.props.localNames,
                            projectId: this.props.projectId,
                        }}
                        resetValue={"False"}
                        value={this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsNonRoot"]}
                        onChange={x => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecurityRunAsNonRoot"]: x })}
                        error={this.props.getFieldError("Octopus.Action.KubernetesContainers.PodSecurityRunAsNonRoot")}
                        label="Run as non-root"
                    >
                        <RadioButton value={"True"} label="Enable run as non-root" />
                        <RadioButton value={"False"} label="Do not enable run as non-root" />
                    </BoundStringRadioButtonGroup>
                    <Note>
                        Require that containers run as a non-root user. <ExternalLink href="KubernetesSecurityContextRunAsNonRoot">More information</ExternalLink>.
                    </Note>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        projectId={this.props.projectId}
                        value={this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxLevel"]}
                        onChange={x => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxLevel"]: x })}
                        error={this.props.getFieldError("Octopus.Action.KubernetesContainers.PodSecuritySeLinuxLevel")}
                        label="SELinux level"
                    />
                    <Note>
                        The SELinux level that applies to the container. <ExternalLink href="KubernetesSecurityContextSELinux">More information</ExternalLink>.
                    </Note>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        projectId={this.props.projectId}
                        value={this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxRole"]}
                        onChange={x => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxRole"]: x })}
                        error={this.props.getFieldError("Octopus.Action.KubernetesContainers.PodSecuritySeLinuxRole")}
                        label="SELinux role"
                    />
                    <Note>
                        The SELinux role that applies to the container. <ExternalLink href="KubernetesSecurityContextSELinux">More information</ExternalLink>.
                    </Note>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        projectId={this.props.projectId}
                        value={this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxType"]}
                        onChange={x => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxType"]: x })}
                        error={this.props.getFieldError("Octopus.Action.KubernetesContainers.PodSecuritySeLinuxType")}
                        label="SELinux type"
                    />
                    <Note>
                        The SELinux type that applies to the container. <ExternalLink href="KubernetesSecurityContextSELinux">More information</ExternalLink>.
                    </Note>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        projectId={this.props.projectId}
                        value={this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxUser"]}
                        onChange={x => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxUser"]: x })}
                        error={this.props.getFieldError("Octopus.Action.KubernetesContainers.PodSecuritySeLinuxUser")}
                        label="SELinux user"
                    />
                    <Note>
                        The SELinux user that applies to the container. <ExternalLink href="KubernetesSecurityContextSELinux">More information</ExternalLink>.
                    </Note>
                    <StringExtendedKeyValueEditList
                        items={this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySysctls"]}
                        name="Sysctl"
                        onChange={val => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecuritySysctls"]: val })}
                        valueLabel="Value"
                        keyLabel="Name"
                        hideBindOnKey={false}
                        projectId={this.props.projectId}
                    />
                    <Note>
                        The list of namespaced sysctls used for the pod. Introduced in Kubernetes 1.11. <ExternalLink href="KubernetesSecuritySysctl">More information</ExternalLink>.
                    </Note>
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Octopus.Action.KubernetesContainers.PodAffinity"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Pod Affinity / Anti-Affinity"
                    summary={this.podAffinitySummary()}
                    help={"Define the pod affinity or anti-affinity that determines where the pod resources created by this deployment resource will be placed."}
                >
                    <PodAffinityList
                        listActions={[<ActionButton key="add" label="Add Pod Affinity" onClick={() => this.addPodAffinity()} />]}
                        data={this.state.podAffinityDetails}
                        onRow={this.podAffinityListItem(false)}
                        onRowTouch={item => this.editPodAffinity(item)}
                        onRemoveRow={item => this.removePodAffinity(item)}
                    />
                    <PodAffinityList
                        listActions={[<ActionButton key="add" label="Add Pod Anti-Affinity" onClick={() => this.addPodAntiAffinity()} />]}
                        data={this.state.podAntiAffinityDetails}
                        onRow={this.podAffinityListItem(true)}
                        onRowTouch={item => this.editPodAntiAffinity(item)}
                        onRemoveRow={item => this.removePodAntiAffinity(item)}
                    />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Octopus.Action.KubernetesContainers.NodeAffinity"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Node Affinity"
                    summary={this.nodeAffinitySummary()}
                    help={"Define the node affinity that determines where the pod resources created by this deployment resource will be placed."}
                >
                    <NodeAffinityList
                        listActions={[<ActionButton key="add" label="Add Node Affinity" onClick={() => this.addNodeAffinity()} />]}
                        data={this.state.nodeAffinityDetails}
                        onRow={this.nodeAffinityListItem}
                        onRowTouch={item => this.editNodeAffinity(item)}
                        onRemoveRow={item => this.removeNodeAffinity(item)}
                    />
                    <Note>
                        Pods can be scheduled onto a node as long as at least one of the <code>Required</code> rules is satisfied. In effect this means the
                        <code>Required</code> rules are combined with the boolean "or" operator. <code>Preferred</code> rules will attempt to be satisfied, but if not the pods will still be scheduled.
                    </Note>
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Octopus.Action.KubernetesContainers.PodAnnotations"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Pod Annotations"
                    summary={this.podAnnotationsSummary()}
                    help={"Add annotations to configure the pods resources created by the deployment resource."}
                >
                    <StringExtendedKeyValueEditList
                        items={this.props.properties["Octopus.Action.KubernetesContainers.PodAnnotations"]}
                        name="Annotation"
                        onChange={val => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodAnnotations"]: val })}
                        valueLabel="Value"
                        keyLabel="Name"
                        hideBindOnKey={false}
                        projectId={this.props.projectId}
                        addToTop={true}
                    />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Octopus.Action.KubernetesContainers.DeploymentAnnotations"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Deployment Annotations"
                    summary={this.deploymentAnnotationsSummary()}
                    help={"Add annotations to configure the deployment resource."}
                >
                    <StringExtendedKeyValueEditList
                        items={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentAnnotations"]}
                        name="Annotation"
                        onChange={val => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentAnnotations"]: val })}
                        valueLabel="Value"
                        keyLabel="Name"
                        hideBindOnKey={false}
                        projectId={this.props.projectId}
                        addToTop={true}
                    />
                </ExpandableFormSection>
                <KubernetesNamespaceFormSection namespace={this.props.properties["Octopus.Action.KubernetesContainers.Namespace"]} onChange={ns => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.Namespace"]: ns })} />
            </div>
        );
    }

    private newContainer = (): ContainerPackageDetails => {
        const item: ContainerPackageDetails = {
            Id: null as string,
            IsNew: true,
            Ports: [],
            EnvironmentVariables: [],
            SecretEnvironmentVariables: [],
            ConfigMapEnvironmentVariables: [],
            FieldRefEnvironmentVariables: [],
            VolumeMounts: [],
            Name: "",
            PackageId: "",
            FeedId: "",
            AcquisitionLocation: PackageAcquisitionLocation.NotAcquired,
            Properties: {},
            Command: [],
            Args: [],
            Resources: {
                requests: {
                    memory: "",
                    cpu: "",
                    ephemeralStorage: "",
                },
                limits: {
                    memory: "",
                    cpu: "",
                    ephemeralStorage: "",
                },
            },
            LivenessProbe: {
                failureThreshold: "",
                initialDelaySeconds: "",
                periodSeconds: "",
                successThreshold: "",
                timeoutSeconds: "",
                type: "",
                exec: {
                    command: [],
                },
                httpGet: {
                    host: "",
                    path: "",
                    port: "",
                    scheme: "",
                    httpHeaders: [],
                },
                tcpSocket: {
                    host: "",
                    port: "",
                },
            },
            ReadinessProbe: {
                failureThreshold: "",
                initialDelaySeconds: "",
                periodSeconds: "",
                successThreshold: "",
                timeoutSeconds: "",
                type: "",
                exec: {
                    command: [],
                },
                httpGet: {
                    host: "",
                    path: "",
                    port: "",
                    scheme: "",
                    httpHeaders: [],
                },
                tcpSocket: {
                    host: "",
                    port: "",
                },
            },
            Lifecycle: {
                PreStop: null,
                PostStart: null,
            },
            SecurityContext: {
                allowPrivilegeEscalation: "",
                privileged: "",
                readOnlyRootFilesystem: "",
                runAsGroup: "",
                runAsNonRoot: "",
                runAsUser: "",
                capabilities: {
                    add: [],
                    drop: [],
                },
                seLinuxOptions: {
                    level: "",
                    role: "",
                    type: "",
                    user: "",
                },
            },
        };
        return item;
    };

    private addContainerViaDialog = () => {
        const item = this.newContainer();
        this.setState({
            editContainer: item,
            editContainerIndex: null,
        });
    };

    private editContainerViaDialog = (item: ContainerPackageDetails) => {
        this.setState({
            editContainer: clone(item),
            editContainerIndex: this.state.containers.indexOf(item),
        });
    };

    private removeContainerViaDialog = (item: ContainerPackageDetails) => {
        const packages = [...this.props.packages];
        packages.splice(packages.findIndex(pkg => pkg.Name === item.Name), 1);
        this.props.setPackages(packages);

        const items = [...this.state.containers].filter(container => packages.findIndex(pkg => pkg.Name === container.Name) !== -1);
        this.saveContainersJson(items);
    };

    private resetContainers = () => {
        this.setState({
            editContainer: null,
            editContainerIndex: null,
        });
    };

    private saveContainer = (item: ContainerPackageDetails) => {
        const packageReferences = [...this.props.packages];
        const items = [...this.state.containers];

        if (this.state.editContainerIndex === null) {
            packageReferences.push(item);
            items.push(item);
        } else {
            packageReferences[this.state.editContainerIndex] = item;
            items[this.state.editContainerIndex] = item;
        }

        this.props.setPackages(packageReferences);
        this.saveContainersJson(items);
        this.resetContainers();
        return true;
    };

    private containerListItem = (pkg: ContainerPackageDetails) => {
        const feed = this.state.feeds.find(f => f.Id === pkg.FeedId);
        return (
            <div>
                <ListTitle>{pkg.Name}</ListTitle>

                <div className={styles.summaryText}>
                    Configuring the <strong>{pkg.PackageId}</strong> {pkg.InitContainer === "True" && <strong>init</strong>} container from feed <strong>{feed != null ? feed.Name : pkg.FeedId}</strong>
                </div>

                {_.get(pkg, "Resources.requests.memory") && (
                    <div className={styles.summaryText}>
                        Requesting <strong>{pkg.Resources.requests.memory}</strong> of memory
                        {pkg.Resources.limits.memory && (
                            <span>
                                {" "}
                                with a limit of <strong>{pkg.Resources.limits.memory}</strong>
                            </span>
                        )}
                    </div>
                )}

                {!_.get(pkg, "Resources.requests.memory") && _.get(pkg, "Resources.limits.memory") && (
                    <div className={styles.summaryText}>
                        Limited to <strong>{pkg.Resources.limits.memory}</strong> of memory
                    </div>
                )}

                {_.get(pkg, "Resources.requests.cpu") && (
                    <div className={styles.summaryText}>
                        Requesting <strong>{pkg.Resources.requests.cpu}</strong> of CPU
                        {pkg.Resources.limits.cpu && (
                            <span>
                                {" "}
                                with a limit of <strong>{pkg.Resources.limits.cpu}</strong>
                            </span>
                        )}
                    </div>
                )}

                {!_.get(pkg, "Resources.requests.cpu") && _.get(pkg, "Resources.limits.cpu") && (
                    <div className={styles.summaryText}>
                        Limited to <strong>{pkg.Resources.limits.cpu}</strong> of CPU
                    </div>
                )}

                {_.get(pkg, "Resources.requests.ephemeralStorage") && (
                    <div className={styles.summaryText}>
                        Requesting <strong>{pkg.Resources.requests.ephemeralStorage}</strong> of epheremal storage
                        {pkg.Resources.limits.ephemeralStorage && (
                            <span>
                                {" "}
                                with a limit of <strong>{pkg.Resources.limits.ephemeralStorage}</strong>
                            </span>
                        )}
                    </div>
                )}

                {!_.get(pkg, "Resources.requests.ephemeralStorage") && _.get(pkg, "Resources.limits.ephemeralStorage") && (
                    <div className={styles.summaryText}>
                        Limited to <strong>{pkg.Resources.limits.ephemeralStorage}</strong> of ephemeral storage
                    </div>
                )}

                {pkg.Ports && _.isArray(pkg.Ports) && pkg.Ports.length !== 0 && (
                    <div className={styles.summarySection}>
                        <div className={styles.summarySectionKey}>Ports</div>
                        {pkg.Ports.map((rule: any) => (
                            <div key={rule.key} className={styles.summarySectionValue}>
                                {rule.key && <span>{rule.key}: </span>}
                                {rule.value}/{rule.option || "TCP"}
                            </div>
                        ))}
                    </div>
                )}

                {pkg.EnvironmentVariables && _.isArray(pkg.EnvironmentVariables) && pkg.EnvironmentVariables.length !== 0 && (
                    <div className={styles.summarySection}>
                        <div className={styles.summarySectionKey}>Environment Variables</div>
                        {pkg.EnvironmentVariables.map((envVar: any) => (
                            <div key={envVar.key} className={styles.summarySectionValue}>
                                {envVar.key} = "{envVar.value}"
                            </div>
                        ))}
                    </div>
                )}

                {pkg.SecretEnvironmentVariables && _.isArray(pkg.SecretEnvironmentVariables) && pkg.SecretEnvironmentVariables.length !== 0 && (
                    <div className={styles.summarySection}>
                        <div className={styles.summarySectionKey}>Secret Environment Variables</div>
                        {pkg.SecretEnvironmentVariables.map((envVar: any) => (
                            <div className={styles.summarySectionValue}>
                                {envVar.key} = {envVar.value}/{envVar.option}
                            </div>
                        ))}
                    </div>
                )}

                {pkg.ConfigMapEnvironmentVariables && _.isArray(pkg.ConfigMapEnvironmentVariables) && pkg.ConfigMapEnvironmentVariables.length !== 0 && (
                    <div className={styles.summarySection}>
                        <div className={styles.summarySectionKey}>ConfigMap Environment Variables</div>
                        {pkg.ConfigMapEnvironmentVariables.map((envVar: any) => (
                            <div key={envVar.key} className={styles.summarySectionValue}>
                                {envVar.key} = {envVar.value}/{envVar.option}
                            </div>
                        ))}
                    </div>
                )}

                {pkg.FieldRefEnvironmentVariables && _.isArray(pkg.FieldRefEnvironmentVariables) && pkg.FieldRefEnvironmentVariables.length !== 0 && (
                    <div className={styles.summarySection}>
                        <div className={styles.summarySectionKey}>Field Reference Environment Variables</div>
                        {pkg.FieldRefEnvironmentVariables.map((envVar: any) => (
                            <div key={envVar.key} className={styles.summarySectionValue}>
                                {envVar.key} = "{envVar.value}"
                            </div>
                        ))}
                    </div>
                )}

                {pkg.VolumeMounts && _.isArray(pkg.VolumeMounts) && pkg.VolumeMounts.length !== 0 && (
                    <div className={styles.summarySection}>
                        <div className={styles.summarySectionKey}>Volume Mounts</div>
                        {pkg.VolumeMounts.map((volMount: any, idx) => (
                            <div key={volMount.key} className={styles.summaryTable}>
                                <DataTable>
                                    <DataTableBody>
                                        <DataTableRow>
                                            <DataTableRowColumn className={styles.summaryTableKey}>Name</DataTableRowColumn>
                                            <DataTableRowColumn className={styles.summaryTableValue}>{volMount.key} </DataTableRowColumn>
                                        </DataTableRow>
                                        <DataTableRow>
                                            <DataTableRowColumn className={styles.summaryTableKey}>Path</DataTableRowColumn>
                                            <DataTableRowColumn className={styles.summaryTableValue}>{volMount.value}</DataTableRowColumn>
                                        </DataTableRow>
                                        {volMount.option && (
                                            <DataTableRow>
                                                <DataTableRowColumn className={styles.summaryTableKey}>SubPath</DataTableRowColumn>
                                                <DataTableRowColumn className={styles.summaryTableValue}>{volMount.option}</DataTableRowColumn>
                                            </DataTableRow>
                                        )}
                                        <DataTableRow>
                                            <DataTableRowColumn className={styles.summaryTableKey}>Read Only</DataTableRowColumn>
                                            <DataTableRowColumn className={styles.summaryTableValue}>{volMount.option === "True" ? "True" : "False"}</DataTableRowColumn>
                                        </DataTableRow>
                                    </DataTableBody>
                                </DataTable>
                            </div>
                        ))}
                    </div>
                )}

                {pkg.ReadinessProbe && pkg.ReadinessProbe.type && (
                    <div className={styles.summaryText}>
                        Performing a{pkg.ReadinessProbe.type === CommandCheck && <strong> exec </strong>}
                        {pkg.ReadinessProbe.type === HttpGetCheck && <strong> HTTP </strong>}
                        {pkg.ReadinessProbe.type === TcpSocketCheck && <strong> TCP socket </strong>}
                        readiness probe check
                        {pkg.ReadinessProbe.initialDelaySeconds && (
                            <span>
                                {" "}
                                after an initial delay of <strong>{pkg.ReadinessProbe.initialDelaySeconds}</strong> seconds
                            </span>
                        )}
                        {pkg.ReadinessProbe.periodSeconds && (
                            <span>
                                {" "}
                                every <strong>{pkg.ReadinessProbe.periodSeconds}</strong> seconds
                            </span>
                        )}
                        {pkg.ReadinessProbe.timeoutSeconds && (
                            <span>
                                {" "}
                                timing out after <strong>{pkg.ReadinessProbe.timeoutSeconds}</strong> seconds
                            </span>
                        )}
                        {pkg.ReadinessProbe.failureThreshold && (
                            <span>
                                {" "}
                                failing after <strong>{pkg.ReadinessProbe.failureThreshold}</strong> consecutive errors
                            </span>
                        )}
                    </div>
                )}

                {pkg.LivenessProbe && pkg.LivenessProbe.type && (
                    <div className={styles.summaryText}>
                        Performing a{pkg.LivenessProbe.type === CommandCheck && <strong> exec </strong>}
                        {pkg.LivenessProbe.type === HttpGetCheck && <strong> HTTP </strong>}
                        {pkg.LivenessProbe.type === TcpSocketCheck && <strong> TCP socket </strong>}
                        liveness probe check
                        {pkg.LivenessProbe.initialDelaySeconds && (
                            <span>
                                {" "}
                                after an initial delay of <strong>{pkg.LivenessProbe.initialDelaySeconds}</strong> seconds
                            </span>
                        )}
                        {pkg.LivenessProbe.periodSeconds && (
                            <span>
                                {" "}
                                every <strong>{pkg.LivenessProbe.periodSeconds}</strong> seconds
                            </span>
                        )}
                        {pkg.LivenessProbe.timeoutSeconds && (
                            <span>
                                {" "}
                                timing out after <strong>{pkg.LivenessProbe.timeoutSeconds}</strong> seconds
                            </span>
                        )}
                        {pkg.LivenessProbe.successThreshold && (
                            <span>
                                {" "}
                                succeeding after <strong>{pkg.LivenessProbe.failureThreshold}</strong> consecutive successes
                            </span>
                        )}
                        {pkg.LivenessProbe.failureThreshold && (
                            <span>
                                {" "}
                                restarting after <strong>{pkg.LivenessProbe.failureThreshold}</strong> consecutive errors
                            </span>
                        )}
                    </div>
                )}

                {pkg.Command && pkg.Command.filter(c => c && c.trim()).length !== 0 && (
                    <div className={styles.summaryText}>
                        Overriding container command with <strong>{pkg.Command.filter(c => c && c.trim()).join(" ")}</strong>
                    </div>
                )}
                {pkg.Args && pkg.Args.filter(c => c && c.trim()).length !== 0 && (
                    <div className={styles.summaryText}>
                        Passing the arguments <strong>{pkg.Args.filter(c => c && c.trim()).join(" ")}</strong>
                    </div>
                )}

                {pkg.SecurityContext && (
                    <div className={styles.summaryText}>
                        {pkg.SecurityContext.allowPrivilegeEscalation && pkg.SecurityContext.allowPrivilegeEscalation.trim().toUpperCase() === "TRUE" && (
                            <div>
                                Privilege escalation <strong>enabled</strong>
                            </div>
                        )}
                        {pkg.SecurityContext.privileged && pkg.SecurityContext.privileged.trim().toUpperCase() === "TRUE" && (
                            <div>
                                Privileged mode <strong>enabled</strong>
                            </div>
                        )}
                        {pkg.SecurityContext.runAsNonRoot && pkg.SecurityContext.runAsNonRoot.trim().toUpperCase() === "TRUE" && (
                            <div>
                                Run as non-root <strong>enabled</strong>
                            </div>
                        )}
                        {pkg.SecurityContext.readOnlyRootFilesystem && pkg.SecurityContext.readOnlyRootFilesystem.trim().toUpperCase() === "TRUE" && (
                            <div>
                                Read only root filesystem <strong>enabled</strong>
                            </div>
                        )}
                        {pkg.SecurityContext.allowPrivilegeEscalation && isBound(pkg.SecurityContext.allowPrivilegeEscalation) && (
                            <div>
                                Privilege escalation set to <strong>{pkg.SecurityContext.allowPrivilegeEscalation.trim()}</strong>
                            </div>
                        )}
                        {pkg.SecurityContext.privileged && isBound(pkg.SecurityContext.privileged) && (
                            <div>
                                Privileged mode set to <strong>{pkg.SecurityContext.privileged.trim()}</strong>
                            </div>
                        )}
                        {pkg.SecurityContext.runAsNonRoot && isBound(pkg.SecurityContext.runAsNonRoot) && (
                            <div>
                                Run as non-root set to <strong>{pkg.SecurityContext.runAsNonRoot.trim()}</strong>
                            </div>
                        )}
                        {pkg.SecurityContext.readOnlyRootFilesystem && isBound(pkg.SecurityContext.readOnlyRootFilesystem) && (
                            <div>
                                Read only root file system set to <strong>{pkg.SecurityContext.readOnlyRootFilesystem.trim()}</strong>
                            </div>
                        )}
                        {pkg.SecurityContext.runAsUser && pkg.SecurityContext.runAsUser.trim() && (
                            <div>
                                Run as user <strong>{pkg.SecurityContext.runAsUser.trim()}</strong>
                            </div>
                        )}
                        {pkg.SecurityContext.runAsGroup && pkg.SecurityContext.runAsGroup.trim() && (
                            <div>
                                Run as group <strong>{pkg.SecurityContext.runAsGroup.trim()}</strong>
                            </div>
                        )}
                        {_.get(pkg, "SecurityContext.capabilities.add") && pkg.SecurityContext.capabilities.add.length !== 0 && (
                            <div>
                                Adding the capabilities <strong>{pkg.SecurityContext.capabilities.add.join(", ")}</strong>
                            </div>
                        )}
                        {_.get(pkg, "SecurityContext.capabilities.drop") && pkg.SecurityContext.capabilities.drop.length !== 0 && (
                            <div>
                                Dropping the capabilities <strong>{pkg.SecurityContext.capabilities.drop.join(", ")}</strong>
                            </div>
                        )}
                        {_.get(pkg, "SecurityContext.seLinuxOptions.level") && pkg.SecurityContext.seLinuxOptions.level.trim() && (
                            <div>
                                Setting the SELinux level to <strong>{pkg.SecurityContext.seLinuxOptions.level.trim()}</strong>
                            </div>
                        )}
                        {_.get(pkg, "SecurityContext.seLinuxOptions.role") && pkg.SecurityContext.seLinuxOptions.role.trim() && (
                            <div>
                                Setting the SELinux role to <strong>{pkg.SecurityContext.seLinuxOptions.role.trim()}</strong>
                            </div>
                        )}
                        {_.get(pkg, "SecurityContext.seLinuxOptions.type") && pkg.SecurityContext.seLinuxOptions.type.trim() && (
                            <div>
                                Setting the SELinux type to <strong>{pkg.SecurityContext.seLinuxOptions.type.trim()}</strong>
                            </div>
                        )}
                        {_.get(pkg, "SecurityContext.seLinuxOptions.user") && pkg.SecurityContext.seLinuxOptions.user.trim() && (
                            <div>
                                Setting the SELinux user to <strong>{pkg.SecurityContext.seLinuxOptions.user.trim()}</strong>
                            </div>
                        )}
                    </div>
                )}

                {pkg.ImagePullPolicy && (
                    <div>
                        Using image pull policy <strong>{pkg.ImagePullPolicy}</strong>
                    </div>
                )}
            </div>
        );
    };

    private addCombinedVolume = () => {
        const item: CombinedVolumeDetails = {
            Items: [],
            Name: "",
            ReferenceName: "",
            ReferenceNameType: "Custom",
            EmptyDirMedium: "",
            HostPathType: "Directory",
            HostPathPath: "",
            LocalPath: "",
            Type: "ConfigMap",
            RawYaml: "",
            Repository: "",
            Revision: "",
        };

        this.setState({
            editSecretVolume: item,
            editSecretVolumeIndex: null,
        });
    };

    private editCombinedVolume = (item: CombinedVolumeDetails) => {
        this.setState({
            editSecretVolume: clone(item),
            editSecretVolumeIndex: this.state.combinedVolumes.indexOf(item),
        });
    };

    private removeCombinedVolume = (item: CombinedVolumeDetails) => {
        const volumes = [...this.state.combinedVolumes];
        volumes.splice(this.state.combinedVolumes.indexOf(item), 1);
        this.props.setProperties({ ["Octopus.Action.KubernetesContainers.CombinedVolumes"]: JSON.stringify(volumes) });
    };

    private resetCombinedVolume = () => {
        this.setState({
            editSecretVolume: null,
            editSecretVolumeIndex: null,
        });
    };

    private saveCombinedVolume = (item: CombinedVolumeDetails) => {
        const volumes = [...this.state.combinedVolumes];
        if (this.state.editSecretVolumeIndex === null) {
            volumes.push(item);
        } else {
            volumes[this.state.editSecretVolumeIndex] = item;
        }
        this.props.setProperties({ ["Octopus.Action.KubernetesContainers.CombinedVolumes"]: JSON.stringify(volumes) });
        this.resetCombinedVolume();
        return true;
    };

    private combinedVolumeListItem = (volume: CombinedVolumeDetails) => {
        return (
            <div>
                {this.configMapSummary(volume, false)}
                {this.secretSummary(volume, false)}
                {this.emptyDirSummary(volume, false)}
                {this.hostPathSummary(volume, false)}
                {this.persistentVOlumeClaimSummary(volume, false)}
                {this.rawYamlSummary(volume, false)}
            </div>
        );
    };

    private addPodAffinity = () => {
        const item: PodAffinityDetails = {
            Type: RequiredAffinity,
            NamespacesList: "",
            TopologyKey: "",
            Weight: "",
            InMatch: [],
            ExistMatch: [],
        };

        this.setState({
            editPodAffinity: item,
            editPodAffinityIndex: null,
        });
    };

    private savePodAffinity = (item: PodAffinityDetails) => {
        const podAffinityDetails = [...this.state.podAffinityDetails];
        if (this.state.editPodAffinityIndex === null) {
            podAffinityDetails.push(item);
        } else {
            podAffinityDetails[this.state.editPodAffinityIndex] = item;
        }
        this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodAffinity"]: JSON.stringify(podAffinityDetails) });
        this.resetPodAffinity();
        return true;
    };

    private editPodAffinity = (item: PodAffinityDetails) => {
        this.setState({
            editPodAffinity: clone(item),
            editPodAffinityIndex: this.state.podAffinityDetails.indexOf(item),
        });
    };

    private removePodAffinity = (item: PodAffinityDetails) => {
        const podAffinitySettings = [...this.state.podAffinityDetails];
        podAffinitySettings.splice(this.state.podAffinityDetails.indexOf(item), 1);
        this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodAffinity"]: JSON.stringify(podAffinitySettings) });
    };

    private resetPodAffinity = () => {
        this.setState({
            editPodAffinity: null,
            editPodAffinityIndex: null,
        });
    };

    private podAffinityListItem = (antiAffinity: boolean) => (podAffinity: PodAffinityDetails) => {
        return (
            <span>
                <strong>{podAffinity.Type}</strong> {antiAffinity ? "anti-affinity" : "affinity"}
                {podAffinity.Type === PreferredAffinity && podAffinity.Weight && (
                    <span>
                        {" "}
                        with weight <strong>{podAffinity.Weight}</strong>
                    </span>
                )}
                <span>
                    {" "}
                    matching topology key <strong>{podAffinity.TopologyKey}</strong>
                </span>
                {podAffinity.NamespacesList && podAffinity.NamespacesList.trim() && (
                    <span>
                        , pods in namespace{podAffinity.NamespacesList.indexOf(",") !== -1 && <span>s</span>} <strong>{podAffinity.NamespacesList.trim()}</strong> where
                    </span>
                )}
                {_.concat(
                    _.chain(podAffinity.InMatch)
                        .flatMap(match => [
                            <span>
                                {" "}
                                label key <strong>{match.key}</strong>
                                {match.value === InOperator && (
                                    <span>
                                        {" "}
                                        is <strong>in</strong>
                                    </span>
                                )}
                                {match.value === NotInOperator && (
                                    <span>
                                        {" "}
                                        is <strong>not in</strong>
                                    </span>
                                )}
                                <span>
                                    {" "}
                                    value{match.option.indexOf(",") !== -1 && "s"} <strong>{match.option}</strong>
                                </span>
                            </span>,
                            <span>, </span>,
                        ])
                        .value(),
                    _.chain(podAffinity.ExistMatch)
                        .flatMap(match => [
                            <span>
                                {match.value === ExistsOperator && (
                                    <span>
                                        {" "}
                                        label key <strong>{match.key} exists</strong>
                                    </span>
                                )}
                                {match.value === DoesNotExistOperator && (
                                    <span>
                                        {" "}
                                        label key <strong>{match.key} does not exist</strong>
                                    </span>
                                )}
                            </span>,
                            <span>, </span>,
                        ])
                        .value()
                ).slice(0, -1)}
            </span>
        );
    };

    private addPodAntiAffinity = () => {
        const item: PodAffinityDetails = {
            Type: RequiredAffinity,
            NamespacesList: "",
            TopologyKey: "",
            Weight: "",
            InMatch: [],
            ExistMatch: [],
        };

        this.setState({
            editPodAntiAffinity: item,
            editPodAntiAffinityIndex: null,
        });
    };

    private savePodAntiAffinity = (item: PodAffinityDetails) => {
        const podAffinityDetails = [...this.state.podAntiAffinityDetails];
        if (this.state.editPodAntiAffinityIndex === null) {
            podAffinityDetails.push(item);
        } else {
            podAffinityDetails[this.state.editPodAntiAffinityIndex] = item;
        }
        this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodAntiAffinity"]: JSON.stringify(podAffinityDetails) });
        this.resetPodAntiAffinity();
        return true;
    };

    private editPodAntiAffinity = (item: PodAffinityDetails) => {
        this.setState({
            editPodAntiAffinity: clone(item),
            editPodAntiAffinityIndex: this.state.podAntiAffinityDetails.indexOf(item),
        });
    };

    private removePodAntiAffinity = (item: PodAffinityDetails) => {
        const podAffinitySettings = [...this.state.podAntiAffinityDetails];
        podAffinitySettings.splice(this.state.podAntiAffinityDetails.indexOf(item), 1);
        this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodAntiAffinity"]: JSON.stringify(podAffinitySettings) });
    };

    private resetPodAntiAffinity = () => {
        this.setState({
            editPodAntiAffinity: null,
            editPodAntiAffinityIndex: null,
        });
    };

    private addNodeAffinity = () => {
        const item: NodeAffinityDetails = {
            Type: RequiredAffinity,
            Weight: "",
            InMatch: [],
            ExistMatch: [],
        };

        this.setState({
            editNodeAffinity: item,
            editNodeAffinityIndex: null,
        });
    };

    private saveNodeAffinity = (item: NodeAffinityDetails) => {
        const nodeAffinityDetails = [...this.state.nodeAffinityDetails];
        if (this.state.editNodeAffinityIndex === null) {
            nodeAffinityDetails.push(item);
        } else {
            nodeAffinityDetails[this.state.editNodeAffinityIndex] = item;
        }
        this.props.setProperties({ ["Octopus.Action.KubernetesContainers.NodeAffinity"]: JSON.stringify(nodeAffinityDetails) });
        this.resetNodeAffinity();
        return true;
    };

    private editNodeAffinity = (item: NodeAffinityDetails) => {
        this.setState({
            editNodeAffinity: clone(item),
            editNodeAffinityIndex: this.state.nodeAffinityDetails.indexOf(item),
        });
    };

    private removeNodeAffinity = (item: NodeAffinityDetails) => {
        const nodeAffinitySettings = [...this.state.nodeAffinityDetails];
        nodeAffinitySettings.splice(this.state.nodeAffinityDetails.indexOf(item), 1);
        this.props.setProperties({ ["Octopus.Action.KubernetesContainers.NodeAffinity"]: JSON.stringify(nodeAffinitySettings) });
    };

    private resetNodeAffinity = () => {
        this.setState({
            editNodeAffinity: null,
            editNodeAffinityIndex: null,
        });
    };

    private nodeAffinityListItem = (nodeAffinity: NodeAffinityDetails) => {
        return (
            <span>
                <strong>{nodeAffinity.Type}</strong> affinity
                {nodeAffinity.Type === PreferredAffinity && nodeAffinity.Weight && (
                    <span>
                        {" "}
                        with weight <strong>{nodeAffinity.Weight}</strong>
                    </span>
                )}{" "}
                where
                {_.concat(
                    _.chain(nodeAffinity.InMatch)
                        .flatMap(match => [
                            <span key={match.key + "a0"}>
                                {" "}
                                label key <strong>{match.key}</strong>
                                {match.value === InOperator && (
                                    <span>
                                        {" "}
                                        is <strong>in</strong>
                                    </span>
                                )}
                                {match.value === NotInOperator && (
                                    <span>
                                        {" "}
                                        is <strong>not in</strong>
                                    </span>
                                )}
                                {match.value === GreaterThanOperator && (
                                    <span>
                                        {" "}
                                        is <strong>greater than</strong>
                                    </span>
                                )}
                                {match.value === LessThanOperator && (
                                    <span>
                                        {" "}
                                        is <strong>less than</strong>
                                    </span>
                                )}
                                <span>
                                    {" "}
                                    value{match.option.indexOf(",") !== -1 && "s"} <strong>{match.option}</strong>
                                </span>
                            </span>,
                            <span key={match.key + "a1"}>, </span>,
                        ])
                        .value(),
                    _.chain(nodeAffinity.ExistMatch)
                        .flatMap(match => [
                            <span key={match.key + "b0"}>
                                {match.value === ExistsOperator && (
                                    <span>
                                        {" "}
                                        label key <strong>{match.key} exists</strong>
                                    </span>
                                )}
                                {match.value === DoesNotExistOperator && (
                                    <span>
                                        {" "}
                                        label key <strong>{match.key} does not exist</strong>
                                    </span>
                                )}
                            </span>,
                            <span key={match.key + "b0"}>, </span>,
                        ])
                        .value()
                ).slice(0, -1)}
            </span>
        );
    };

    /**
     * We only want to save the container specific information in the step property. Package details are saved
     * on a property on the step, and so we don't duplicate that information here.
     */
    private saveContainersJson(items: ContainerPackageDetails[]) {
        const containerDetails = items.map(item => ({
            Name: item.Name,
            Ports: item.Ports,
            EnvironmentVariables: item.EnvironmentVariables,
            SecretEnvironmentVariables: item.SecretEnvironmentVariables,
            ConfigMapEnvironmentVariables: item.ConfigMapEnvironmentVariables,
            FieldRefEnvironmentVariables: item.FieldRefEnvironmentVariables,
            VolumeMounts: item.VolumeMounts,
            Resources: item.Resources,
            LivenessProbe: item.LivenessProbe,
            ReadinessProbe: item.ReadinessProbe,
            Command: item.Command,
            Args: item.Args,
            InitContainer: item.InitContainer,
            ImagePullPolicy: item.ImagePullPolicy,
            SecurityContext: item.SecurityContext,
            Lifecycle: item.Lifecycle,
        }));
        this.props.setProperties({ ["Octopus.Action.KubernetesContainers.Containers"]: JSON.stringify(containerDetails) });
    }

    private deploymentStyleSummary() {
        if (!this.props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"]) {
            return Summary.placeholder("The deployment strategy has not been defined");
        }

        return Summary.summary(
            <span>
                Update the deployment with the
                {this.props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"] === "RollingUpdate" && (
                    <span>
                        {" "}
                        <strong>rolling deployment</strong> strategy
                        {this.props.properties["Octopus.Action.KubernetesContainers.MaxUnavailable"] && (
                            <span>
                                {" "}
                                with a maximum of <strong>{this.props.properties["Octopus.Action.KubernetesContainers.MaxUnavailable"]}</strong> unavailable
                                {this.props.properties["Octopus.Action.KubernetesContainers.MaxSurge"] && <span> and</span>}
                            </span>
                        )}
                        {this.props.properties["Octopus.Action.KubernetesContainers.MaxSurge"] && (
                            <span>
                                {" "}
                                a maximum surge of <strong>{this.props.properties["Octopus.Action.KubernetesContainers.MaxSurge"]}</strong>
                            </span>
                        )}
                    </span>
                )}
                {this.props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"] === "BlueGreen" && (
                    <span>
                        {" "}
                        <strong>blue/green deployment</strong> strategy
                    </span>
                )}
                {this.props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"] === "Recreate" && (
                    <span>
                        {" "}
                        <strong>recreate deployment </strong> strategy
                    </span>
                )}
            </span>
        );
    }

    private deploymentSummary() {
        if (!(this.props.properties["Octopus.Action.KubernetesContainers.DeploymentName"] || this.props.properties["Octopus.Action.KubernetesContainers.Replicas"] || this.props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"])) {
            return Summary.placeholder("No Kubernetes deployment resource has been defined");
        }

        const labels = _.toPairs(JsonUtils.tryParse(this.props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {}));

        return Summary.summary(
            <span>
                <span>Create a deployment</span>
                {this.props.properties["Octopus.Action.KubernetesContainers.DeploymentName"] && (
                    <span>
                        {" "}
                        called <strong>{this.props.properties["Octopus.Action.KubernetesContainers.DeploymentName"]}</strong>
                    </span>
                )}
                {this.props.properties["Octopus.Action.KubernetesContainers.Replicas"] && (
                    <span>
                        {" "}
                        with <strong>{this.props.properties["Octopus.Action.KubernetesContainers.Replicas"]}</strong> replicas
                    </span>
                )}
                {labels.length !== 0 && (
                    <span>
                        {" "}
                        and the label{labels.length > 1 && <span>s</span>}{" "}
                        {_.chain(labels)
                            .flatMap(pair => [
                                <strong>
                                    {pair[0]}: {pair[1]}
                                </strong>,
                                <span>, </span>,
                            ])
                            .slice(0, -1)
                            .value()}
                    </span>
                )}
            </span>
        );
    }

    private getContainerPackageDetails(containers: string, packages: PackageReference[]): ContainerPackageDetails[] {
        const containerArray: ContainerDetails[] = JsonUtils.tryParseArray(containers, []);

        return packages.map(packageReference => _.assign({ Id: null }, packageReference, containerArray.find(container => packageReference.Name === container.Name)));
    }

    private configMapSummary(volume: CombinedVolumeDetails, prefix: boolean) {
        if (volume.Type === ConfigMapType) {
            return (
                <span>
                    {prefix && <span> the </span>}
                    <strong>{volume.Type}</strong>
                    {volume.ReferenceNameType !== LinkedResource && (
                        <span>
                            {" "}
                            named <strong>{volume.ReferenceName}</strong>
                        </span>
                    )}
                    {volume.ReferenceNameType === LinkedResource && <span> defined in the feature below</span>}
                    {volume.Items && volume.Items.length !== 0 && (
                        <span>
                            <span> with items: </span>
                            {_.chain(volume.Items)
                                .flatMap(item => [
                                    <strong>
                                        {item.key} => {item.value}
                                    </strong>,
                                    <span>, </span>,
                                ])
                                .slice(0, -1)
                                .value()}
                        </span>
                    )}
                </span>
            );
        }
    }

    private secretSummary(volume: CombinedVolumeDetails, prefix: boolean) {
        if (volume.Type === SecretType) {
            return (
                <span>
                    {prefix && <span> the </span>}
                    <strong>{volume.Type}</strong>
                    {volume.ReferenceNameType !== LinkedResource && (
                        <span>
                            {" "}
                            named <strong>{volume.ReferenceName}</strong>
                        </span>
                    )}
                    {volume.ReferenceNameType === LinkedResource && <span> defined in the feature below</span>}
                    {volume.Items && volume.Items.length !== 0 && (
                        <span>
                            <span> with items: </span>
                            {_.chain(volume.Items)
                                .flatMap(item => [
                                    <strong>
                                        {item.key} => {item.value}
                                    </strong>,
                                    <span>, </span>,
                                ])
                                .slice(0, -1)
                                .value()}
                        </span>
                    )}
                </span>
            );
        }
    }

    private emptyDirSummary(volume: CombinedVolumeDetails, prefix: boolean) {
        if (volume.Type === EmptyDirType) {
            return (
                <span>
                    {prefix && <span> an </span>}
                    <strong>Empty Dir</strong>
                    {volume.EmptyDirMedium && (
                        <span>
                            {" "}
                            using medium <strong>{volume.EmptyDirMedium}</strong>
                        </span>
                    )}
                </span>
            );
        }
    }

    private hostPathSummary(volume: CombinedVolumeDetails, prefix: boolean) {
        if (volume.Type === HostPathType) {
            return (
                <span>
                    {" "}
                    a <strong>Host Path</strong>
                    {volume.HostPathType && (
                        <span>
                            {" "}
                            of type <strong>{volume.HostPathType}</strong>
                        </span>
                    )}
                    {volume.HostPathPath && (
                        <span>
                            {" "}
                            from path <strong>{volume.HostPathPath}</strong>
                        </span>
                    )}
                </span>
            );
        }
    }

    private persistentVOlumeClaimSummary(volume: CombinedVolumeDetails, prefix: boolean) {
        if (volume.Type === PersistentVolumeClaimType) {
            return (
                <span>
                    {prefix && <span> the </span>}
                    <strong>Persistent Volume Claim</strong>
                    {volume.ReferenceName && (
                        <span>
                            {" "}
                            called <strong>{volume.ReferenceName}</strong>
                        </span>
                    )}
                </span>
            );
        }
    }

    private rawYamlSummary(volume: CombinedVolumeDetails, prefix: boolean) {
        if (volume.Type === RawYamlType) {
            try {
                // Query the YAML and try and find the type of volume
                const yaml = jsyaml(volume.RawYaml);
                if (typeof yaml !== "string") {
                    const keys = Object.keys(yaml);
                    if (keys.length !== 0) {
                        return (
                            <span>
                                {prefix && <span> the </span>}
                                <strong>Raw YAML</strong> definition for a <strong>{keys[0]}</strong>
                            </span>
                        );
                    }
                }
            } catch {
                // ignore and fall through to the default summary
            }

            return (
                <span>
                    {prefix && <span> the </span>}
                    <strong>Raw YAML</strong> definition
                </span>
            );
        }
    }

    private volumeSummary() {
        if (this.state.combinedVolumes.length === 0) {
            return Summary.placeholder("No volumes have been included");
        }

        return Summary.summary(
            <span>
                <span>
                    Deploy volume{this.state.combinedVolumes.length !== 1 ? "s" : ""} called <strong>{this.state.combinedVolumes.map(v => v.Name).join(", ")}</strong>
                </span>
            </span>
        );
    }

    private podSecuritySummary() {
        if (
            !this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityFsGroup"] &&
            !this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsGroup"] &&
            !this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsUser"] &&
            !this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsNonRoot"] &&
            !this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxLevel"] &&
            !this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxRole"] &&
            !this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxType"] &&
            !this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxUser"] &&
            !this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySysctls"] &&
            !this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySupplementalGroups"]
        ) {
            return Summary.default("No pod security settings configured.");
        }

        return Summary.summary(
            <span>
                {this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityFsGroup"] && (
                    <span>
                        FSGroup set to <strong>{this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityFsGroup"]}</strong>.{" "}
                    </span>
                )}
                {this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsGroup"] && (
                    <span>
                        Run as group set to <strong>{this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsGroup"]}</strong>.{" "}
                    </span>
                )}
                {this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsUser"] && (
                    <span>
                        Run as user set to <strong>{this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsUser"]}</strong>.{" "}
                    </span>
                )}
                {this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsNonRoot"] && (
                    <span>
                        Run as non-root set to <strong>{this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsNonRoot"]}</strong>.{" "}
                    </span>
                )}
                {this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxLevel"] && (
                    <span>
                        SELinux level set to <strong>{this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxLevel"]}</strong>.{" "}
                    </span>
                )}
                {this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxRole"] && (
                    <span>
                        SELinux role set to <strong>{this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxRole"]}</strong>.{" "}
                    </span>
                )}
                {this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxType"] && (
                    <span>
                        SELinux type set to <strong>{this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxType"]}</strong>.{" "}
                    </span>
                )}
                {this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxUser"] && (
                    <span>
                        SELinux user set to <strong>{this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxUser"]}</strong>.{" "}
                    </span>
                )}
                {this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySupplementalGroups"] && (
                    <span>
                        Supplemental groups set to <strong>{this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySupplementalGroups"]}</strong>.{" "}
                    </span>
                )}
                {this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySysctls"] && (JSON.parse(this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySysctls"]) as KeyValueOption[]).length !== 0 && (
                    <span>
                        Sysctls set to{" "}
                        <strong>
                            {_.chain(JSON.parse(this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySysctls"]))
                                .flatMap((item: KeyValueOption) => [
                                    <span>
                                        {item.key}={item.value}
                                    </span>,
                                    <span>, </span>,
                                ])
                                .slice(0, -1)
                                .value()}
                        </strong>
                        .{" "}
                    </span>
                )}
            </span>
        );
    }

    private containersSummary() {
        if (this.state.containers.length === 0) {
            return Summary.placeholder("No containers have been included");
        }

        return Summary.summary(
            <span>
                <span>Deploy </span>
                <span>
                    {_.chain(this.state.containers)
                        .filter(c => c.InitContainer !== "True")
                        .flatMap((container, idx1) => (
                            <span key={idx1}>
                                image <strong>{container.PackageId}</strong>
                                {container.Ports && container.Ports.length !== 0 && (
                                    <span>
                                        {" "}
                                        exposing port{container.Ports.length > 1 && <span>s</span>}:{" "}
                                        {_.chain(container.Ports)
                                            .flatMap((port, idx) => (
                                                <span key={port.value}>
                                                    <strong>
                                                        {port.value}:{port.option || "TCP"}
                                                    </strong>
                                                    {idx === container.Ports.length - 1 ? "" : ","}{" "}
                                                </span>
                                            ))
                                            .value()}
                                    </span>
                                )}
                                {idx1 < this.state.containers.length ? ";" : ""}
                            </span>
                        ))
                        .value()}
                </span>
            </span>
        );
    }

    private podAffinitySummary() {
        const podAffinity: PodAffinityDetails[] = JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.PodAffinity"], []);

        const podAntiAffinity: PodAffinityDetails[] = JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.PodAntiAffinity"], []);

        if (podAffinity.length === 0 && podAntiAffinity.length === 0) {
            return Summary.default(<span>No affinity rules defined</span>);
        }

        return Summary.summary(
            <span>
                {_.concat(
                    _.chain(podAffinity)
                        .flatMap(a => [this.podAffinityListItem(false)(a), <span>; </span>])
                        .value(),
                    _.chain(podAntiAffinity)
                        .flatMap(a => [this.podAffinityListItem(true)(a), <span>; </span>])
                        .value()
                ).slice(0, -1)}
            </span>
        );
    }

    private nodeAffinitySummary() {
        const podAffinity: NodeAffinityDetails[] = JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.NodeAffinity"], []);

        if (podAffinity.length === 0) {
            return Summary.default(<span>No affinity rules defined</span>);
        }

        return Summary.summary(
            <span>
                {_.chain(podAffinity)
                    .flatMap((a, idx) => (
                        <span key={idx}>
                            {this.nodeAffinityListItem(a)}
                            {idx < podAffinity.length ? "; " : ""}
                        </span>
                    ))
                    .value()}
            </span>
        );
    }

    private loadFeeds = () => {
        return this.props.doBusyTask(async () => {
            this.setState({ feeds: await repository.Feeds.all() });
        });
    };

    private deploymentAnnotationsSummary() {
        const annotations: KeyValueOption[] = JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.DeploymentAnnotations"], []);

        if (annotations.length === 0) {
            return Summary.placeholder("No annotations have been included");
        }

        return Summary.summary(
            <span>
                Add the annotation{annotations.length > 1 && <span>s</span>}{" "}
                {_.chain(annotations)
                    .flatMap(annotation => [
                        <strong>
                            {annotation.key}: {annotation.value}
                        </strong>,
                        <span>, </span>,
                    ])
                    .slice(0, -1)
                    .value()}
            </span>
        );
    }

    private podAnnotationsSummary() {
        const annotations: KeyValueOption[] = JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.PodAnnotations"], []);

        if (annotations.length === 0) {
            return Summary.placeholder("No annotations have been included");
        }

        return Summary.summary(
            <span>
                Add the annotation{annotations.length > 1 && <span>s</span>}{" "}
                {_.chain(annotations)
                    .flatMap((annotation, idx) => (
                        <span key={annotation.key}>
                            <strong>
                                {annotation.key}: {annotation.value}
                            </strong>
                            {idx < annotations.length ? ", " : ""}
                        </span>
                    ))
                    .value()}
            </span>
        );
    }
}

pluginRegistry.registerActionForAllScopes({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.KubernetesDeployContainers",
    summary: (properties, targetRolesAsCSV) => <KubernetesDeployContainersActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    edit: KubernetesDeployContainersActionEdit,
    canHaveChildren: step => true,
    canBeChild: true,
    targetRoleOption: action => TargetRoles.Required,
    hasPackages: action => true,
    features: {
        initial: ["Octopus.Features.KubernetesService", "Octopus.Features.KubernetesIngress", "Octopus.Features.KubernetesConfigMap", "Octopus.Features.KubernetesSecret"],
        optional: ["Octopus.Features.KubernetesService", "Octopus.Features.KubernetesIngress", "Octopus.Features.KubernetesConfigMap", "Octopus.Features.KubernetesSecret", "Octopus.Features.KubernetesCustomResource"],
    },
});
