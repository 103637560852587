import * as React from "react";
import { repository } from "clientInstance";
import SimpleExpander from "components/SimpleExpander";
import { EnvironmentSummaryResource, MachineModelHealthStatus, ResourceCollection, DeploymentTargetResource, EnvironmentResource } from "client/resources";
import { EnvironmentIcon } from "components/Icon";
import PaperLayout from "components/PaperLayout/PaperLayout";
import OverflowMenu, { OverflowMenuItems } from "components/Menu/OverflowMenu";
import { ActionButton, ActionButtonType } from "components/Button";
import { isEqual, each } from "lodash";
import MachineHealthStatusHelper from "utils/MachineHealthStatusHelper";
import Permission from "client/resources/permission";
const styles = require("./style.less");
import routeLinks from "../../../../routeLinks";
import BaseMachinesSummary from "../BaseMachinesSummary/BaseMachinesSummary";
import { SmallCloseButton, BaseMachinesSummaryProps, BaseMachinesSummaryState } from "../BaseMachinesSummary/BaseMachinesSummary";
import MarkdownDescription from "components/MarkdownDescription";
import { Section } from "components/Section/Section";
import { EnvironmentMachinesArgs } from "client/repositories/environmentRepository";
import RequestRaceConditioner from "utils/RequestRaceConditioner";
import InternalRedirect from "components/Navigation/InternalRedirect/InternalRedirect";

interface EnvironmentSummarySectionProps extends BaseMachinesSummaryProps {
    environmentSummary: EnvironmentSummaryResource;
}

// tslint:disable-next-line:no-empty-interface
interface EnvironmentSummarySectionState extends BaseMachinesSummaryState {}

class EnvironmentSummarySection extends BaseMachinesSummary<EnvironmentSummarySectionProps, EnvironmentSummarySectionState> {
    private requestRaceConditioner = new RequestRaceConditioner();

    constructor(props: EnvironmentSummarySectionProps) {
        super(props);
        this.state = {
            machinesResponse: null,
            currentPageIndex: 0,
            expanded: false,
            healthStatusFilter: null,
            isDisabledFilter: false,
            machineHealthStatusFastLookup: {},
        };
    }

    componentWillReceiveProps(nextProps: EnvironmentSummarySectionProps) {
        if (this.state.expanded && !isEqual(this.props.filter, nextProps.filter)) {
            this.reloadDataAndCurrentPageIndex();
        }
    }

    render() {
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={routeLinks.task(this.state.redirectToTaskId).root} push={true} />;
        }
        const environmentSummary = this.props.environmentSummary;
        const machinesHealthyLinks = this.renderMachineSummaryLinks(environmentSummary, MachineModelHealthStatus.Healthy);
        const machinesUnavailableLinks = this.renderMachineSummaryLinks(environmentSummary, MachineModelHealthStatus.Unavailable);
        const machinesUnknownLinks = this.renderMachineSummaryLinks(environmentSummary, MachineModelHealthStatus.Unknown);
        const machinesHasWarningsLinks = this.renderMachineSummaryLinks(environmentSummary, MachineModelHealthStatus.HasWarnings);
        const machinesUnhealthyLinks = this.renderMachineSummaryLinks(environmentSummary, MachineModelHealthStatus.Unhealthy);
        const machinesDisabledLinks = this.renderMachineDisabledSummaryLinks(environmentSummary);
        const summaryComponents = [machinesHealthyLinks, machinesHasWarningsLinks, machinesUnhealthyLinks, machinesUnavailableLinks, machinesUnknownLinks, machinesDisabledLinks];

        const environment = environmentSummary.Environment;
        const overflowMenuItems: any[] = [
            [
                OverflowMenuItems.navItem("Add Deployment Target", routeLinks.infrastructure.machines.new(environment.Id), null, {
                    permission: Permission.MachineCreate,
                    environment: environment.Id,
                    tenant: "*",
                }),
                OverflowMenuItems.navItem("Edit", routeLinks.infrastructure.environment(environment), null, {
                    permission: Permission.EnvironmentEdit,
                    environment: environment.Id,
                }),
            ],
        ];

        // Only show machine-related actions if they actually have some machines in this environment.
        if (environmentSummary.TotalMachines > 0) {
            overflowMenuItems.push(
                OverflowMenuItems.item("Check Health", () => this.performHealthCheck(environment), {
                    permission: Permission.MachineEdit,
                    environment: environment.Id,
                    tenant: "*",
                })
            );
            overflowMenuItems.push(
                OverflowMenuItems.confirmUpgrade("Upgrade all Tentacles in this Environment", () => this.performTentacleUpgrade(environment), {
                    permission: Permission.MachineEdit,
                    environment: environment.Id,
                    tenant: "*",
                })
            );
            overflowMenuItems.push(
                OverflowMenuItems.confirmUpgrade("Upgrade Calamari on Deployment Targets", () => this.performCalamariUpgrade(environmentSummary.MachineIdsForCalamariUpgrade), {
                    permission: Permission.MachineEdit,
                    environment: environment.Id,
                    tenant: "*",
                })
            );
        }
        const titleContainer = (
            <div className={styles.cardTitleContainer}>
                <div className={styles.environmentIcon}>
                    <EnvironmentIcon />
                </div>
                <div className={styles.environmentName}>{environment.Name}</div>
                <div className={styles.environmentMachinesCount}>({environmentSummary.TotalMachines && environmentSummary.TotalMachines.toLocaleString()})</div>
                <div className={styles.environmentSummaryCounts}>{summaryComponents}</div>
                <div className={styles.environmentOverflowActions}>
                    <OverflowMenu menuItems={overflowMenuItems} />
                </div>
            </div>
        );

        return (
            <PaperLayout key={environment.Id} busy={this.state.busy} errors={this.state.errors} className={styles.paperLayoutOverride}>
                <SimpleExpander
                    errorKey={environment.Id}
                    key={environment.Id}
                    title={titleContainer}
                    onDidExpand={expanded => {
                        this.setState({ expanded });
                        if (expanded) {
                            this.reloadDataAndCurrentPageIndex();
                        } else {
                            // If we're not expanded, clear the DOM of these machines.
                            this.setState({
                                machinesResponse: null,
                                machineHealthStatusFastLookup: {},
                                currentPageIndex: 0,
                            });
                        }
                    }}
                >
                    {environment.Description && (
                        <Section>
                            <MarkdownDescription markup={environment.Description} />
                        </Section>
                    )}
                    {this.renderMachinesList()}
                </SimpleExpander>
            </PaperLayout>
        );
    }

    protected async loadData() {
        // We need to load ALL machines for a given environment that match the filtering criteria because
        // the design groups machines by their health status.
        const rolesCsv = this.props.filter.roles ? this.props.filter.roles.join(",") : null;
        const shellNamesCsv = this.props.filter.shellNames ? this.props.filter.shellNames.join(",") : null;

        // We need to consider both health status filters from our sidebar filter AND the expander links. If the user
        // has clicked a health status filter from the sidebar, that takes precendence.
        const applicableHealthStatusFilters = this.props.filter.healthStatuses.length > 0 ? this.props.filter.healthStatuses : [this.state.healthStatusFilter];
        const healthStatusCsv = applicableHealthStatusFilters ? applicableHealthStatusFilters.join(",") : null;
        // Same precendence logic applies to the "Disabled" filter.
        const isDisabled = this.props.filter.isDisabled ? this.props.filter.isDisabled : this.state.isDisabledFilter;

        const commStyleCsv = this.props.filter.commStyles.length > 0 ? this.props.filter.commStyles.join(",") : null;
        const tenantIdsCsv = this.props.filter.tenantIds.length > 0 ? this.props.filter.tenantIds.join(",") : null;
        const tenantTagsCsv = this.props.filter.tenantTags.length > 0 ? this.props.filter.tenantTags.join(",") : null;

        // mark.siedle - We do a TakeAll here because we need to group our response data by health status for this design, then page within each group.
        // Alternatively we could run separate paging queries for EACH health status, but this would increase the number of queries significantly
        // and adds complexity. I think for 80% of cases, minimising the number of requests is the preferred approach, happy to debate though, since this will cause
        // a massive response for users operating at scale.
        const args: EnvironmentMachinesArgs = {
            skip: 0, // Don't skip, just increase the take size.
            take: repository.takeAll, // No paging, just take all, because we need to group our response data by health status.
            partialName: this.props.filter.partialName,
            roles: rolesCsv,
            isDisabled,
            healthStatuses: healthStatusCsv,
            commStyles: commStyleCsv,
            tenantIds: tenantIdsCsv,
            tenantTags: tenantTagsCsv,
            shellNames: shellNamesCsv,
        };

        await this.requestRaceConditioner.avoidStaleResponsesForRequest(repository.Environments.machines(this.props.environmentSummary.Environment, args), response => {
            const machinesResponse = response as ResourceCollection<DeploymentTargetResource>;
            // mark.siedle - Bit of trickery here to emulate paging for large collections of machines :)
            // This makes the render operation much faster when dealing with thousands of machines in a given environment.
            const machineHealthStatusFastLookup = this.state.machineHealthStatusFastLookup;
            const objValues = Object.keys(MachineModelHealthStatus).map(k => (MachineModelHealthStatus as any)[k]);
            const names = objValues.filter(v => typeof v === "string") as string[];
            each(names, statusText => {
                const status = statusText as MachineModelHealthStatus;
                const machines = machinesResponse.Items.filter(x => x.HealthStatus === status);
                const machinesForHealthStatus = this.makeMachineResourceCollection(machines, this.machineListTakeSize);
                machineHealthStatusFastLookup[status] = machinesForHealthStatus;
            });
            // Insert "Disabled" separately.
            const disabledMachines = machinesResponse.Items.filter(x => x.IsDisabled);
            const disabledMachinesResourceCollection = this.makeMachineResourceCollection(disabledMachines, this.machineListTakeSize);
            machineHealthStatusFastLookup["Disabled"] = disabledMachinesResourceCollection;

            this.setState({
                machinesResponse,
                machineHealthStatusFastLookup,
            });
        });
    }

    private renderMachineSummaryLinks(environmentSummary: EnvironmentSummaryResource, healthStatus: MachineModelHealthStatus) {
        const healthStatusIcon = this.machineIconHelper.healthStatusIcons[healthStatus];
        const value = (environmentSummary.MachineHealthStatusSummaries as any)[healthStatus] as number;
        if (!value || value === 0) {
            // Don't show links if there's nothing to report.
            return null;
        }

        // If filtering health statuses from the sidebar, just show the health statuses that they've chosen to filter (and don't show them as links).
        if (this.props.filter.healthStatuses && this.props.filter.healthStatuses.length > 0) {
            return (
                <div key={healthStatus} className={styles.summaryCount}>
                    {healthStatusIcon && <img key={healthStatus} src={healthStatusIcon} className={styles.healthStatusIcon} alt="Health status" />}
                    {value.toLocaleString() + " " + MachineHealthStatusHelper.getFriendlyName(healthStatus as MachineModelHealthStatus).toLowerCase()}
                </div>
            );
        }

        // Else show environment-specific health status actions.
        if (this.state.healthStatusFilter === healthStatus) {
            return (
                <div key={healthStatus} className={styles.summaryCount}>
                    {healthStatusIcon && <img key={healthStatus} src={healthStatusIcon} className={styles.healthStatusIcon} alt="Health status" />}
                    {value.toLocaleString() + " " + MachineHealthStatusHelper.getFriendlyName(healthStatus as MachineModelHealthStatus).toLowerCase()}
                    <SmallCloseButton
                        onClose={() => {
                            this.setState({ healthStatusFilter: null }, () => {
                                if (this.state.expanded) {
                                    this.reloadDataAndCurrentPageIndex();
                                }
                            });
                        }}
                    />
                </div>
            );
        } else {
            return (
                <ActionButton
                    key={healthStatus}
                    icon={<img key={healthStatus} src={healthStatusIcon} className={styles.healthStatusIcon} alt="Health status" />}
                    className={styles.summaryCount}
                    type={ActionButtonType.Ternary}
                    label={value.toLocaleString() + " " + MachineHealthStatusHelper.getFriendlyName(healthStatus as MachineModelHealthStatus).toLowerCase()}
                    onClick={(e: any) => {
                        // The user may click a health status link to open an expander (but it shouldn't ever close it).
                        if (this.state.expanded) {
                            e.preventDefault();
                            e.stopPropagation(); //prevent clicking the link toggling the panel/expander.
                        }
                        // Clear any disabled filters when a healthStatus filter is clicked. You can't chain inline disabled and healthStatus
                        // filters together because they use different and/or logic at the API and it causes UI confusion.
                        this.setState(
                            {
                                healthStatusFilter: healthStatus,
                                isDisabledFilter: false,
                            },
                            () => {
                                if (this.state.expanded) {
                                    this.reloadDataAndCurrentPageIndex();
                                }
                            }
                        );
                    }}
                />
            );
        }
    }

    private renderMachineDisabledSummaryLinks(environmentSummary: EnvironmentSummaryResource) {
        const disabledComponentKey = "Disabled";
        const disabledIcon = this.machineIconHelper.healthStatusIcons["Disabled"];
        const value = environmentSummary.TotalDisabledMachines;
        if (!value || value === 0) {
            // Don't show links if there's nothing to report.
            return null;
        }

        // If filtering from the sidebar, just show the disabled control (not as a link).
        if (this.props.filter.isDisabled) {
            return (
                <div key={disabledComponentKey} className={styles.summaryCount}>
                    {disabledIcon && <img key={disabledComponentKey} src={disabledIcon} className={styles.healthStatusIcon} alt="Health status" />}
                    {value.toLocaleString() + " disabled"}
                </div>
            );
        }

        // Else show environment-specific disabled action.
        if (this.state.isDisabledFilter) {
            return (
                <div key={disabledComponentKey} className={styles.summaryCount}>
                    {disabledIcon && <img key={disabledComponentKey} src={disabledIcon} className={styles.healthStatusIcon} alt="Health status" />}
                    {value.toLocaleString() + " disabled"}
                    <SmallCloseButton
                        onClose={() => {
                            this.setState({ isDisabledFilter: false }, () => {
                                if (this.state.expanded) {
                                    this.reloadDataAndCurrentPageIndex();
                                }
                            });
                        }}
                    />
                </div>
            );
        } else {
            return (
                <ActionButton
                    key={disabledComponentKey}
                    icon={<img key={disabledComponentKey} src={disabledIcon} className={styles.healthStatusIcon} alt="Health status" />}
                    className={styles.summaryCount}
                    type={ActionButtonType.Ternary}
                    label={value.toLocaleString() + " disabled"}
                    onClick={(e: any) => {
                        // The user may click a disabled link to open an expander (but it shouldn't ever close it).
                        if (this.state.expanded) {
                            e.preventDefault();
                            e.stopPropagation(); //prevent clicking the link toggling the panel/expander.
                        }
                        // Clear any healthStatus filters when disabled is clicked. You can't chain inline disabled and healthStatus
                        // filters together because they use different and/or logic at the API and it causes UI confusion.
                        this.setState(
                            {
                                isDisabledFilter: true,
                                healthStatusFilter: null,
                            },
                            () => {
                                if (this.state.expanded) {
                                    this.reloadDataAndCurrentPageIndex();
                                }
                            }
                        );
                    }}
                />
            );
        }
    }

    private async performHealthCheck(environment?: EnvironmentResource) {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createPerformHealthCheckTaskForEnvironment(environment);
            this.setState({ redirectToTaskId: task.Id });
        });
    }

    private async performTentacleUpgrade(environment?: EnvironmentResource): Promise<boolean> {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createUpgradeTentaclesTaskForEnvironment(environment);
            this.setState({ redirectToTaskId: task.Id });
        });
    }

    private async performCalamariUpgrade(machineIds: string[]) {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createUpdateCalamariOnTargetsTask(machineIds);
            this.setState({ redirectToTaskId: task.Id });
        });
    }
}

export default EnvironmentSummarySection;
