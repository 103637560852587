import * as React from "react";
import { WorkerPoolResource } from "client/resources";
import { FormFieldProps } from "components/form";
import { WorkerPoolChip } from "components/Chips";
import ReferenceDataItem from "client/resources/referenceDataItem";
import LookupReferenceDataItemChip from "components/LookupReferenceDataItemChip";
import { ChipIcon } from "components/Chips";
import MultiSelect from "components/MultiSelect/MultiSelect";
import { FocusableComponent } from "components/VirtualListWithKeyboard/FocusableComponent";

export const WorkerPoolTypedMultiSelect = MultiSelect<ReferenceDataItem>();

interface WorkerPoolMultiSelectProps extends FormFieldProps<string[]> {
    items: ReferenceDataItem[];
    openOnFocus?: boolean;
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    multiSelectRef?(component: FocusableComponent | null): void;
}

const WorkerPoolMultiSelect: React.StatelessComponent<WorkerPoolMultiSelectProps> = props => {
    const chipRenderer = (r: WorkerPoolResource, onRequestDelete: (event: object) => void) => {
        return (
            <LookupReferenceDataItemChip
                lookupCollection={props.items}
                lookupId={r.Id}
                type={ChipIcon.Environment}
                chipRender={item => <WorkerPoolChip workerPoolType={r.WorkerPoolType} onRequestDelete={onRequestDelete} workerPoolName={item.Name} />}
            />
        );
    };

    return <WorkerPoolTypedMultiSelect items={props.items} fieldName="worker pools" renderChip={chipRenderer} {...props} />;
};

export default WorkerPoolMultiSelect;
