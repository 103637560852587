import * as React from "react";
import { AwsElasticContainerRegistryFeedResource, FeedType } from "client/resources/feedResource";
import { Summary } from "components/form";
import Note from "components/form/Note/Note";
import Text from "components/form/Text/Text";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import feedTypeRegistry, { FeedEditProps } from "./feedTypeRegistry";
import BasicFeedCredentialsFormSection from "../BasicFeedCredentialsFormSection";
import { ObfuscatedPlaceholder, default as Sensitive } from "../../../../../components/form/Sensitive/Sensitive";

class AwsElasticContainerRegistry extends React.Component<FeedEditProps<AwsElasticContainerRegistryFeedResource>, never> {
    render() {
        return (
            <React.Fragment>
                <ExpandableFormSection key="accessKey" errorKey="accessKey" title="Access Key" summary={this.accessKeySummary()} help="The AWS access key to use when authenticating against Amazon Web Services.">
                    <Note>
                        Refer to the <ExternalLink href="AwsDocsManagingAccessKeys">AWS documentation</ExternalLink> for information on generating access and secret keys.
                    </Note>
                    <Text value={this.props.feed.AccessKey} onChange={AccessKey => this.props.onChange({ ...this.props.feed, AccessKey })} label="Access Key" error={this.props.getFieldError("AccessKey")} />
                </ExpandableFormSection>
                <ExpandableFormSection key="secretKey" errorKey="secretKey" title="Secret Key" summary={this.secretKeySummary()} help="The AWS secret key to use when authenticating against Amazon Web Services.">
                    <Sensitive value={this.props.feed.SecretKey} onChange={SecretKey => this.props.onChange({ ...this.props.feed, SecretKey })} label="Secret Key" error={this.props.getFieldError("SecretKey")} />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Region"
                    title="Region"
                    summary={this.props.feed.Region ? Summary.summary(this.props.feed.Region) : Summary.placeholder("Please enter the AWS region where this registry resides")}
                    help="The AWS region where the registry resides."
                >
                    <Text value={this.props.feed.Region} onChange={Region => this.props.onChange({ ...this.props.feed, Region })} label="Region" error={this.props.getFieldError("Region")} />
                    <Note>
                        View the <ExternalLink href="AWSRegions">AWS Regions and Endpoints</ExternalLink> documentation for a current list of the available region codes.
                    </Note>
                </ExpandableFormSection>
            </React.Fragment>
        );
    }

    private accessKeySummary = () => {
        return this.props.feed.AccessKey ? Summary.summary(this.props.feed.AccessKey) : Summary.placeholder("No access key provided");
    };

    private secretKeySummary = () => {
        return this.props.feed.SecretKey && this.props.feed.SecretKey.HasValue ? Summary.summary(ObfuscatedPlaceholder) : Summary.placeholder("No secret key provided");
    };
}

feedTypeRegistry.registerFeedType({
    type: FeedType.AwsElasticContainerRegistry,
    edit: AwsElasticContainerRegistry,
    text: "AWS Elastic Container Registry",
    hasUri: false,
});
