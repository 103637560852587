import Logger from "client/logger";
import { each } from "lodash";
import { MachineResource, MachineModelHealthStatus, CommunicationStyle, TentacleEndpointResource } from "client/resources";
import EndpointsHelper from "utils/EndpointsHelper/EndpointsHelper";

export default class MachineIconHelper {
    public static disabledIcon(): any {
        const filename = MachineIconHelper.healthStatusIconFilename("Disabled");
        let icon = null;
        try {
            icon = require("areas/infrastructure/components/MachineHealthStatusIcons/" + filename);
        } catch (error) {
            Logger.error(error);
        }
        return icon;
    }

    public static machineIcon(machine: MachineResource): any {
        const filename = MachineIconHelper.machineIconFilename(machine);
        return MachineIconHelper.machineIconFromFilename(filename);
    }

    public static machineIconFilename(machine: MachineResource): string {
        const state = machine.HealthStatus;
        let stateText = state as string;
        if (machine.IsDisabled) {
            stateText = "Disabled";
        } else if (state !== "Unknown" && state !== "Unavailable" && state !== "Unhealthy") {
            // Upgrade status shouldn't override these states
            if (EndpointsHelper.isTentacle(machine.Endpoint)) {
                const tentacleEndpoint = machine.Endpoint as TentacleEndpointResource;
                const tentacleDetails = tentacleEndpoint.TentacleVersionDetails;
                if (tentacleDetails.UpgradeSuggested && !tentacleDetails.UpgradeLocked) {
                    stateText = "NeedsUpgrade";
                }
            } else if (!machine.HasLatestCalamari) {
                stateText = "CalamariNeedsUpgrade";
            }
        }
        const filename = "Server-" + stateText + "." + machine.Endpoint.CommunicationStyle + ".svg";
        return filename;
    }

    public static machineIconFromFilename(filename: string): any {
        let icon = null;
        try {
            icon = require("areas/infrastructure/components/MachineStatusIcons/" + filename);
        } catch (error) {
            Logger.error(error);
        }
        return icon;
    }

    public static communicationStyleIcon(commStyle: CommunicationStyle): any {
        const filename = MachineIconHelper.communicationStyleIconFilename(commStyle);
        return MachineIconHelper.machineIconFromFilename(filename);
    }

    private static communicationStyleIconFilename(commStyle: CommunicationStyle): string {
        return "Server-Default." + commStyle.toString() + ".svg";
    }

    private static healthStatusIcon(status: MachineModelHealthStatus): any {
        const filename = MachineIconHelper.healthStatusIconFilename(status);
        let icon = null;
        try {
            icon = require("areas/infrastructure/components/MachineHealthStatusIcons/" + filename);
        } catch (error) {
            Logger.error(error);
        }
        return icon;
    }

    private static healthStatusIconFilename(statusText: string): string {
        return statusText + ".svg";
    }

    // You can new-up a MachineIconHelper to save repeating the same icon lookup logic.
    public healthStatusIcons: any = {};
    public communicationStyleIcons: any = {};
    constructor() {
        // Prepare dictionaries of icons to save lookups (caching).

        // HealthStatus
        const healthStatusValues = Object.keys(MachineModelHealthStatus).map((k: any) => MachineModelHealthStatus[k]);
        const healthStatusNames = healthStatusValues.filter(v => typeof v === "string") as string[];
        each(healthStatusNames, statusText => {
            const status = statusText as MachineModelHealthStatus;
            this.healthStatusIcons[statusText] = MachineIconHelper.healthStatusIcon(status);
        });
        this.healthStatusIcons["Disabled"] = MachineIconHelper.disabledIcon();

        // CommunicationStyle
        const commStyleValues = Object.keys(CommunicationStyle).map((k: any) => CommunicationStyle[k]);
        const commStyleNames = commStyleValues.filter(v => typeof v === "string") as string[];
        each(commStyleNames, commStyleText => {
            const commStyle = commStyleText as CommunicationStyle;
            this.communicationStyleIcons[commStyleText] = MachineIconHelper.communicationStyleIcon(commStyle);
        });
    }
}
