import { pageId } from "utils/pageId";

export default () => ({
    releases: pageId("Project.Releases", "Releases"),
    releaseCreate: pageId("Project.ReleaseNew", "New Release"),
    release: () => {
        return {
            root: pageId("Project.ReleaseVersion", "Release Version"),
            edit: pageId("Project.ReleaseVersionEdit", "Edit Release Version"),
            deployments: {
                specific: pageId("Project.Deployment", "Deployment"),
                retry: pageId("Project.DeploymentRetry", "Retry Deployment"),
                create: pageId("Project.DeploymentNew", "New Deployment"),
            },
        };
    },
});
