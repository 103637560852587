import BasicRepository from "./basicRepository";
import { ProjectGroupResource } from "../resources";
import Client from "../client";

class ProjectGroupRepository extends BasicRepository<ProjectGroupResource, ProjectGroupResource> {
    constructor(client: Client) {
        super("ProjectGroups", client);
    }
}

export default ProjectGroupRepository;
