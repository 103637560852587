import * as React from "react";
import LinearProgress from "material-ui/LinearProgress";
import CircularProgress from "material-ui/CircularProgress";
import { primaryLight } from "theme/colors";
const styles = require("./style.less");
import { busyIndicatorClass } from "uiTestClasses";

interface BusyIndicatorProps {
    show: boolean;
    inline?: boolean;
}

const BusyIndicator: React.StatelessComponent<BusyIndicatorProps> = props => {
    if (props.inline) {
        return (
            props.show && (
                <span className={`${busyIndicatorClass} ${styles.circularBusyIndicator}`}>
                    <CircularProgress mode="indeterminate" color={primaryLight} size={22} thickness={2} />
                </span>
            )
        );
    } else {
        return props.show ? (
            <div className={`${busyIndicatorClass} ${styles.busyIndicator}`}>
                <LinearProgress mode="indeterminate" color={primaryLight} />
            </div>
        ) : (
            <div className={styles.busyIndicatorHiddenSpacer} />
        );
    }
};

BusyIndicator.displayName = "BusyIndicator";

export default BusyIndicator;
