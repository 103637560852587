import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonDeploymentTargetOverview, CommonDeploymentTargetTopics } from "../../CommonComponents/CommonDeploymentTargetViews";

export const InfrastructureDeploymentTargetConnectivityRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.infrastructure.deploymentTarget.connection,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonDeploymentTargetOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonDeploymentTargetTopics />;
    },
};
