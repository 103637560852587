import * as React from "react";
import { commandLinePackagingInstruction } from "../../Instructions";
import { CommonCommandLineTextForOctoExe, CommonCommandLineTextForOctoExeCodeSample } from "../CommonRegistrationText";

const CommandLinePackaging: React.SFC<{}> = () => (
    <div>
        <p>
            When packaging other applications from command line, we recommend using <i>Octo.exe</i>.
        </p>
        {CommonCommandLineTextForOctoExe}
        {CommonCommandLineTextForOctoExeCodeSample}
    </div>
);

export default commandLinePackagingInstruction(() => <CommandLinePackaging />);
