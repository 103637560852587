import * as React from "react";
import { PageHelpRegistration } from "./pageHelpRegistry";

export interface RegistrationProps {
    registrations: PageHelpRegistration[];
}

export function withRegistrations(getRegistrations: () => PageHelpRegistration[]) {
    return <T extends {}>(Component: React.ComponentType<T & RegistrationProps>) => {
        class WithRegistrations extends React.Component<T & Partial<RegistrationProps>, RegistrationProps> {
            constructor(props: T & Partial<RegistrationProps>) {
                super(props);
                const registrations = getRegistrations();
                this.state = {
                    registrations,
                };
            }
            render() {
                return <Component {...this.state} {...this.props} />;
            }
        }
        return WithRegistrations;
    };
}

export default withRegistrations;
