import * as React from "react";
import { DeploymentStepResource, DeploymentActionResource, Permission, DeploymentProcessResource, StartTrigger } from "client/resources";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { OverflowMenuItems } from "components/Menu";
import SelectParentStep from "./SelectParentStep";
import SpecialVariables from "client/specialVariables";
import CloneStep, { CloneStepTarget } from "./CloneStep";
import DeploymentPartSorter from "./DeploymentPartSorter";
import pluginRegistry, { ActionScope } from "components/Actions/pluginRegistry";
import routeLinks from "routeLinks";
import { repository } from "clientInstance";
import { OverflowMenuNavLink, OverflowMenuDialogItem, OverflowMenuDisabledItem, OverflowMenuGenericItem } from "components/Menu/OverflowMenu";
import { cloneDeep } from "lodash";
import { useProjectContext } from "areas/projects/context";
import { useDeploymentProcessContext } from "./DeploymentProcessContext";

export interface DeploymentProcessEditorFilter {
    filterKeyword: string;
    environmentId?: string;
    channelId?: string;
    includeUnscoped?: boolean;
}

export interface DeploymentProcessEditorQuery {
    filterKeyword?: string;
    environmentId?: string;
    channelId?: string;
    includeUnscoped?: boolean;
}

export function superEncodeURI(value: string) {
    if (!value) {
        return null;
    }
    // encodeURIComponent doesn't encode (), which may be valid keywords.
    let encodedValue = encodeURIComponent(value);
    encodedValue = encodedValue.replace(/\(/g, "%28");
    encodedValue = encodedValue.replace(/\)/g, "%29");
    return encodedValue;
}

export function getDeploymentProcessQueryFromFilters(filter: DeploymentProcessEditorFilter): DeploymentProcessEditorQuery {
    return {
        ...filter,
        filterKeyword: filter.filterKeyword,
    };
}

export function getDeploymentProcessFilter(query: DeploymentProcessEditorQuery): DeploymentProcessEditorFilter {
    return {
        filterKeyword: query.filterKeyword || "",
        environmentId: query.environmentId || "",
        channelId: query.channelId || "",
        includeUnscoped: query.includeUnscoped,
    };
}

type OverflowMenuTypes = OverflowMenuNavLink | OverflowMenuDialogItem | OverflowMenuDisabledItem | OverflowMenuGenericItem;

interface DeploymentProcessRenderProps {
    name: string;
    index: string;
    detailsUrl: string;
    menuItems: Array<OverflowMenuNavLink | OverflowMenuDialogItem | OverflowMenuDisabledItem | OverflowMenuGenericItem>;
    isParentGroup: boolean;
    isCurrentAction: boolean;
    isChildAction: boolean;
    isPlaceholder: boolean;
    isRunInParallelWithLast: boolean;
    isDisabled: boolean;
}

export interface DeploymentPartContextMenuProps {
    scope: ActionScope;
    step: DeploymentStepResource;
    action?: DeploymentActionResource;
    stepIndex?: number;
    actionIndex?: number;
    isChildAction?: boolean;
    isParentGroup?: boolean;
    isCurrentAction?: boolean;
    keywordSearch?: string;
    render: (props: DeploymentProcessRenderProps) => React.ReactElement<any>;
}

interface ProcessOverflowMenuOptions {
    projectId: string;
    projectSlug: string;
    step: DeploymentStepResource;
    action?: DeploymentActionResource;
    scope: ActionScope;
    process: DeploymentProcessResource;
    saveChanges(deploymentProcess: DeploymentProcessResource): Promise<any>;
    refreshData(): Promise<any>;
    onDeploymentProcessUpdated(process: DeploymentProcessResource): void;
}

function getOverflowMenuItems(options: ProcessOverflowMenuOptions, isChildAction?: boolean): OverflowMenuTypes[] {
    const { action, step, projectSlug, projectId } = options;
    const menuItems = [];
    const processEditPermission = { permission: Permission.ProcessEdit, project: options.projectId, tenant: "*" };
    const canEdit = isAllowed(processEditPermission);

    menuItems.push(OverflowMenuItems.navItem(canEdit ? "Edit" : "View Details", calculateDetailsUrl(options.projectSlug, action ? action.Id : step.Id)));

    if (!isChildAction) {
        if (!canHaveChildren(options, step)) {
            menuItems.push(OverflowMenuItems.disabledItem("Add child step", "This step type does not support child steps"));
        } else {
            menuItems.push(OverflowMenuItems.navItem("Add child step", addChildStepUrl(step, projectSlug), null, processEditPermission));
        }
    }

    if (action) {
        menuItems.push(OverflowMenuItems.item(action.IsDisabled ? "Enable" : "Disable", () => (action.IsDisabled ? enable(options, action) : disable(options, action)), processEditPermission));
    }

    if (!action && canHaveChildren(options, step)) {
        menuItems.push(OverflowMenuItems.item("Enable all", () => enableAll(options, step), processEditPermission));
        menuItems.push(OverflowMenuItems.item("Disable all", () => disableAll(options, step), processEditPermission));
    }

    if (isChildAction) {
        menuItems.push(OverflowMenuItems.item("Move out", () => moveOut(options, step, action), processEditPermission));
    }

    if (action) {
        if (!canMoveIn(options, step.Id)) {
            menuItems.push(OverflowMenuItems.disabledItem("Move into...", "No steps available that can have children or this step type cannot be a child step."));
        } else {
            const stepsToMoveInto = options.process.Steps.filter(s => s.Id !== step.Id && canHaveChildren(options, s));
            const selectParentStep = (
                <SelectParentStep steps={stepsToMoveInto} actionName={action.Name} currentlyTargetedRoles={step.Properties[SpecialVariables.Action.TargetRoles] as string} onStepSelected={parentStepId => moveIn(options, step, action, parentStepId)} />
            );
            menuItems.push(OverflowMenuItems.dialogItem("Move into...", selectParentStep, processEditPermission));
        }
    }

    const cloneStepAction = <CloneStep actionName={action ? action.Name : step.Name} currentProjectId={projectId} onCloneTargetSelected={async (cloneTarget, selectedProjectId) => cloneStep(options, cloneTarget, step, action, selectedProjectId)} />;
    menuItems.push(OverflowMenuItems.dialogItem("Clone...", cloneStepAction, processEditPermission));

    if (action) {
        menuItems.push(OverflowMenuItems.deleteItemDefault("step", () => deleteStep(options, step, action), processEditPermission));
    } else {
        menuItems.push(OverflowMenuItems.deleteItemDefault("parent step", () => deleteStep(options, step), processEditPermission));
    }

    if (!action) {
        menuItems.push(
            OverflowMenuItems.dialogItem(
                "Reorder child steps",
                <DeploymentPartSorter deploymentProcessId={options.process.Id} title="Reorder child steps" stepId={step.Id} saveDone={options.refreshData} onDeploymentProcessUpdated={options.onDeploymentProcessUpdated} />,
                processEditPermission
            )
        );
    }

    return menuItems;
}

async function deleteStep(options: ProcessOverflowMenuOptions, step: DeploymentStepResource, action?: DeploymentActionResource) {
    const actionId = action ? action.Id : null;

    const deploymentProcess = cloneDeep(options.process);
    const stepClone = deploymentProcess.Steps.find(x => x.Id === step.Id);

    if (!stepClone) {
        throw Error(`The step "${step.Id}" could not be found in deployment process ${deploymentProcess.Id}`);
    }

    if (actionId) {
        stepClone.Actions = stepClone.Actions.filter(x => x.Id !== actionId);
    }

    if (stepClone.Actions.length === 0) {
        deploymentProcess.Steps = deploymentProcess.Steps.filter(x => x.Id !== step.Id);
    } else if (stepClone.Actions.length === 1) {
        stepClone.Name = stepClone.Actions[0].Name;
    }

    await options.saveChanges(deploymentProcess);
    return true;
}

function canHaveChildren({ scope }: ProcessOverflowMenuOptions, step: DeploymentStepResource) {
    return (
        step.Actions.filter(a => {
            const actionDefinition = pluginRegistry.getAction(a.ActionType, scope);
            return actionDefinition.canHaveChildren(step);
        }).length > 0
    );
}

async function disableAll(options: ProcessOverflowMenuOptions, step: DeploymentStepResource) {
    step.Actions.forEach(a => (a.IsDisabled = true));
    await options.saveChanges(options.process);
}

async function enableAll(options: ProcessOverflowMenuOptions, step: DeploymentStepResource) {
    step.Actions.forEach(a => (a.IsDisabled = false));
    await options.saveChanges(options.process);
}

function newStepFromAction(step: DeploymentStepResource, action: DeploymentActionResource): DeploymentStepResource {
    return {
        Id: null as any,
        Name: action.Name,
        PackageRequirement: step.PackageRequirement,
        Properties: step.Properties,
        Condition: step.Condition,
        StartTrigger: step.StartTrigger,
        Actions: [action],
    };
}

async function moveOut(options: ProcessOverflowMenuOptions, step: DeploymentStepResource, action: DeploymentActionResource) {
    const newStep = newStepFromAction(step, action);
    const name = newStep.Name;
    let i = 1;
    const isStep = (s: any) => s.Name === newStep.Name;

    while (true) {
        if (!options.process.Steps.some(isStep)) {
            break;
        }
        newStep.Name = name + " " + i;
        i++;
    }

    const actionIndex = step.Actions.indexOf(action);
    step.Actions.splice(actionIndex, 1);
    if (step.Actions.length === 1) {
        step.Name = step.Actions[0].Name;
    }

    const stepIndex = options.process.Steps.indexOf(step);
    options.process.Steps.splice(stepIndex + 1, 0, newStep);

    await options.saveChanges(options.process);
}

async function moveIn(options: ProcessOverflowMenuOptions, step: DeploymentStepResource, action: DeploymentActionResource, parentStepId: string) {
    // Copy the action to the new step.
    const newParent = options.process.Steps.find(s => s.Id === parentStepId);
    newParent.Actions.splice(newParent.Actions.length, 0, action);

    // Remove action from the old step.
    const actionIndex = step.Actions.indexOf(action);
    step.Actions.splice(actionIndex, 1);

    // Remove parent step if no actions left.
    if (step.Actions.length === 0) {
        const stepIndex = options.process.Steps.indexOf(step);
        options.process.Steps.splice(stepIndex, 1);
    }

    await options.saveChanges(options.process);
}

async function enable(options: ProcessOverflowMenuOptions, action: DeploymentActionResource) {
    action.IsDisabled = false;
    await options.saveChanges(options.process);
}

async function disable(props: ProcessOverflowMenuOptions, action: any) {
    action.IsDisabled = true;
    await props.saveChanges(props.process);
}

function calculateDetailsUrl(projectSlug: string, id: string): string | null {
    return routeLinks.project(projectSlug).process.step(id); //, superEncodeURI(this.props.keywordSearch));
}

function canMoveIn(options: ProcessOverflowMenuOptions, stepId: string) {
    const currentStep = options.process.Steps.find(step => step.Id === stepId);
    if (!currentStep) {
        return false;
    }

    const isChildStep = canBeChildStep(options, currentStep);
    const hasParentStep = options.process.Steps.filter(step => step.Id !== stepId && canHaveChildren(options, step)).length > 0;

    return isChildStep && hasParentStep;
}

const canBeChildStep = (options: ProcessOverflowMenuOptions, step: DeploymentStepResource) => step.Actions.every(action => pluginRegistry.getAction(action.ActionType, options.scope).canBeChild);

async function cloneStep(options: ProcessOverflowMenuOptions, cloneStepTarget: CloneStepTarget, step: DeploymentStepResource, action?: DeploymentActionResource, targetProjectId?: string) {
    const getDeploymentProcess = async (cloneTarget: CloneStepTarget, projectId: string) => {
        if (cloneTarget === CloneStepTarget.ThisProject) {
            return options.process;
        }
        const project = await repository.Projects.get(projectId);
        return repository.DeploymentProcesses.get(project.DeploymentProcessId);
    };

    const nameOfActionExists = (steps: DeploymentStepResource[], actionName: string) => {
        return steps.filter(s => s.Name === actionName || s.Actions.filter(a => a.Name === actionName).length > 0).length > 0;
    };

    const getNewActionName = (steps: DeploymentStepResource[], clonedAction: DeploymentActionResource) => {
        let suffix = "";
        let counter = 1;
        while (nameOfActionExists(steps, clonedAction.Name + suffix)) {
            suffix = " - clone (" + counter + ")";
            counter++;
        }
        return clonedAction.Name + suffix;
    };

    const stepNameExists = (steps: DeploymentStepResource[], stepName: string) => {
        return steps.filter(s => s.Name === stepName).length > 0;
    };

    const getNewStepName = (steps: DeploymentStepResource[], clonedStep: any) => {
        let suffix = "";
        let counter = 1;
        while (stepNameExists(steps, clonedStep.Name + suffix)) {
            suffix = " - clone (" + counter + ")";
            counter++;
        }
        return clonedStep.Name + suffix;
    };

    const deploymentProcess = await getDeploymentProcess(cloneStepTarget, targetProjectId);

    if (action && step.Actions.length > 1 && cloneStepTarget === CloneStepTarget.AnotherProject) {
        // It's a child step (action) being cloned to a different project.
        const newStep = newStepFromAction(step, action);
        newStep.Name = getNewStepName(deploymentProcess.Steps, newStep);
        newStep.Actions[0].Id = "";
        newStep.Actions[0].Name = getNewActionName(deploymentProcess.Steps, newStep.Actions[0]);
        newStep.Actions[0].Channels = [];
        deploymentProcess.Steps.splice(deploymentProcess.Steps.length, 0, newStep);
    } else if (action && step.Actions.length > 1) {
        // It's a child step being cloned within the same step.
        const clonedAction = JSON.parse(JSON.stringify(action)) as DeploymentActionResource;
        clonedAction.Id = "";
        clonedAction.Name = getNewActionName(deploymentProcess.Steps, clonedAction);

        const actionIndex = step.Actions.indexOf(action);
        if (actionIndex === -1) {
            step.Actions.splice(step.Actions.length, 0, clonedAction);
        } else {
            step.Actions.splice(actionIndex + 1, 0, clonedAction);
        }
    } else {
        // It's a step being cloned (either to the same project or a different project).
        const clonedStep = JSON.parse(JSON.stringify(step)) as DeploymentStepResource;
        clonedStep.Id = "";
        clonedStep.Name = getNewStepName(deploymentProcess.Steps, clonedStep);
        clonedStep.Actions.forEach((a: any, index: number) => {
            clonedStep.Actions[index].Id = "";
            clonedStep.Actions[index].Name = getNewActionName(deploymentProcess.Steps, clonedStep.Actions[index]);
            clonedStep.Actions[index].Channels = [];
        });

        const stepIndex = deploymentProcess.Steps.indexOf(step);
        if (stepIndex === -1) {
            deploymentProcess.Steps.splice(deploymentProcess.Steps.length, 0, clonedStep);
        } else {
            deploymentProcess.Steps.splice(stepIndex + 1, 0, clonedStep);
        }
    }

    await options.saveChanges(deploymentProcess);
}

function addChildStepUrl(step: DeploymentStepResource, projectSlug: string): string {
    return routeLinks.project(projectSlug).process.childStepTemplates(step.Id).root;
}

const DeploymentPartContextMenu: React.FC<DeploymentPartContextMenuProps> = props => {
    const { step, action, stepIndex, actionIndex, keywordSearch, isParentGroup, isChildAction } = props;
    const projectContext = useProjectContext();
    const processContext = useDeploymentProcessContext();

    const name = props.isParentGroup ? step && step.Name : action && action.Name;
    const index = props.isParentGroup ? stepIndex + "." : stepIndex ? `${stepIndex}.${actionIndex}.` : `${actionIndex}.`;
    const isPlaceholder = props.isParentGroup ? !step || !step.Id : !action || !action.Id;
    const isRunInParallelWithLast = isParentGroup ? step.StartTrigger === StartTrigger.StartWithPrevious && !keywordSearch : step.StartTrigger === StartTrigger.StartWithPrevious && !isChildAction && !keywordSearch;
    const isDisabled = isParentGroup ? step.Actions && step.Actions.every(x => !!x.IsDisabled) : action.IsDisabled;

    const options: ProcessOverflowMenuOptions = {
        action,
        step,
        process: processContext.state.process,
        projectId: projectContext.state.model.Id,
        projectSlug: projectContext.state.model.Slug,
        saveChanges: processContext.actions.saveDeploymentProcess,
        onDeploymentProcessUpdated: processContext.actions.onDeploymentProcessChange,
        refreshData: processContext.actions.refreshDeploymentProcess,
        scope: props.scope,
    };

    const menuItems = getOverflowMenuItems(options, isChildAction);

    return props.render({
        name,
        index,
        detailsUrl: calculateDetailsUrl(options.projectSlug, props.action ? props.action.Id : props.step.Id),
        menuItems,
        isParentGroup: props.isParentGroup,
        isCurrentAction: props.isCurrentAction,
        isChildAction: props.isChildAction,
        isPlaceholder,
        isRunInParallelWithLast,
        isDisabled,
    });
};

export default React.memo(DeploymentPartContextMenu);
