import { primaryText, secondaryText } from "theme/colors";
import * as React from "react";
import { UserResource } from "client/resources";
import { FormFieldProps } from "components/form";
import LookupResourceChipComponent from "components/LookupResourceChip";
import { ChipIcon } from "components/Chips";
import MultiSelect from "./MultiSelect";
import { UserChip, userDisplayName } from "components/Chips/UserChip";

interface UserMultiSelectProps extends FormFieldProps<string[]> {
    items: UserResource[];
    label?: string | JSX.Element;
    error?: string;
}
const UserTypedMultiSelect = MultiSelect<UserResource>();
const LookupUserChip = LookupResourceChipComponent<UserResource>();

const UserMultiSelect: React.StatelessComponent<UserMultiSelectProps> = props => {
    const chipRenderer = (r: UserResource, onRequestDelete: (event: object) => void) => {
        return <LookupUserChip lookupCollection={props.items} lookupId={r.Id} type={ChipIcon.Environment} chipRender={item => <UserChip onRequestDelete={onRequestDelete} user={item} />} />;
    };

    const { items, ...rest } = props;
    return (
        <UserTypedMultiSelect
            items={items.map(i => {
                const user = { ...i, ...{ Name: `${userDisplayName(i)} ${i.EmailAddress || ""}` } };
                return user;
            })}
            fieldName="users"
            renderChip={chipRenderer}
            renderItem={i => ({ primaryText: userDisplayName(i), secondaryText: i.EmailAddress })}
            {...rest}
        />
    );
};

export default UserMultiSelect;
