import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonProjectVariableTopics } from "../../CommonComponents/CommonProjectVariableViews";
import { OverviewContainer, OverviewHighlightText, TermsText } from "../../CommonComponents/CommonViews";
import { Note } from "components/form";

export const ProjectVariablePreviewRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().variables.preview,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return (
            <OverviewContainer>
                <OverviewHighlightText>
                    <p>Variable preview gives you an insight in to the final value a variable will have when it is involved in a deployment. It evaluates variable substitution syntax and variable scoping.</p>
                </OverviewHighlightText>
                <TermsText>
                    <Note heading={"Limitations"}>There are some limitations as some aspects of variable evaluation require an actual deployment, for example output variables.</Note>
                    <Note>Note in particular that your results will be limited by the Octopus Permissions you have.</Note>
                </TermsText>
            </OverviewContainer>
        );
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectVariableTopics />;
    },
};
