import * as React from "react";
import AreaTitle from "components/AreaTitle";
import routeLinks from "../../../routeLinks";
import DrawerWrapperLayout from "components/Drawer/DrawerWrapperLayout";

const DashboardLayout: React.StatelessComponent<{}> = props => {
    return (
        <main id="maincontent">
            <AreaTitle link={routeLinks.root} title="Dashboard" />
            <DrawerWrapperLayout>{props.children}</DrawerWrapperLayout>
        </main>
    );
};

export default DashboardLayout;
