import * as React from "react";
import { History } from "history";
type LocationDescriptor = History.LocationDescriptor;
import InternalLink from "components/Navigation/InternalLink/InternalLink";

const styles = require("./style.less");

interface BreadcrumbProps {
    title?: string; // If specified, this text will display above the title.
    path?: LocationDescriptor; // If specified, this will link your breadcrumb title.
    isAreaLevelBreadcrumb?: boolean;
}

const Breadcrumb: React.StatelessComponent<BreadcrumbProps> = props => {
    return props.title ? (
        <div className={styles.breadcrumb}>
            {props.path ? (
                <InternalLink className={props.isAreaLevelBreadcrumb ? styles.breadcrumbLink : null} to={props.path}>
                    {props.title}
                </InternalLink>
            ) : (
                <div>{props.title}</div>
            )}
        </div>
    ) : null;
};

export default Breadcrumb;
