import * as React from "react";
import { BaseComponent } from "../BaseComponent/BaseComponent";
import ConfirmUpgradeDialog from "components/Dialog/ConfirmUpgradeDialog";
import { DoBusyTask } from "../DataBaseComponent/DataBaseComponent";

interface OpenConfirmUpgradeDialogMenuItemProps {
    dialogTitle: string;
    label?: string;
    disabled: boolean;
    confirmButtonLabel?: string;
    confirmButtonBusyLabel?: string;
    confirmButtonDisabled: boolean;
    acceptOnClick?: (onClick: () => void) => void;
    onConfirmUpgradeClick(): Promise<boolean>;
    renderContent(doBusyTask: DoBusyTask): React.ReactNode;
}

interface OpenConfirmUpgradeDialogMenuItemState {
    open: boolean;
}

export default class OpenConfirmUpgradeDialogMenuItem extends BaseComponent<OpenConfirmUpgradeDialogMenuItemProps, OpenConfirmUpgradeDialogMenuItemState> {
    constructor(props: OpenConfirmUpgradeDialogMenuItemProps) {
        super(props);
        this.state = { open: false };
    }

    onClick = () => {
        this.setState({ open: true });
    };

    render() {
        if (this.props.acceptOnClick) {
            this.props.acceptOnClick(this.onClick);
        }

        return (
            <ConfirmUpgradeDialog
                title={this.props.dialogTitle}
                open={this.state.open}
                confirmButtonLabel={this.props.confirmButtonLabel}
                confirmButtonBusyLabel={this.props.confirmButtonBusyLabel}
                confirmButtonDisabled={this.props.confirmButtonDisabled}
                onClose={() => this.setState({ open: false })}
                onConfirmUpgradeClick={() => this.props.onConfirmUpgradeClick()}
                renderContent={this.props.renderContent}
            />
        );
    }
}
