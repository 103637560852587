import * as React from "react";
import { repository } from "clientInstance";
import { LicenseResource, UpgradeConfigurationResource } from "client/resources";
import { Section } from "components/Section/Section";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import { UpgradeNotificationMode } from "client/resources/upgradeConfigurationResource";
import { UpdateNotifications } from "areas/configuration/components/License/UpdateNotifications";
import { Callout, CalloutType } from "components/Callout/Callout";
import CodeEditor, { TextFormat } from "components/CodeEditor/CodeEditor";
import { OptionalFormBaseComponentState, FormBaseComponent } from "components/FormBaseComponent";
import { cloneDeep } from "lodash";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import SidebarLayout from "components/SidebarLayout/SidebarLayout";
const styles = require("./style.less");
import Permission from "client/resources/permission";
import { DataTableRowColumn } from "components/DataTable/DataTableRowColumn";
import { DataTableRow } from "components/DataTable/DataTableRow";
import { DataTableBody } from "components/DataTable/DataTableBody";
import { DataTable } from "components/DataTable/DataTable";
import { LicenseStatusResource, LicenseLimitStatus, LicenseMessage, LicenseMessageDisposition } from "client/resources/licenseStatusResource";
import ToolTip from "components/ToolTip/index";
import { alert, info, danger } from "theme/colors";
import { ActionButtonType } from "components/Button";

interface LicenseState extends OptionalFormBaseComponentState<LicenseResource> {
    licenseStatus?: LicenseStatusResource;
    upgradeConfiguration?: UpgradeConfigurationResource;
}

export class License extends FormBaseComponent<{}, LicenseState, LicenseResource> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const getLicence = repository.Licenses.getCurrent();
            const getLicenceStatus = repository.Licenses.getCurrentStatus();
            const getUpgradeConfiguration = repository.UpgradeConfiguration.get();

            const license = await getLicence;
            this.setState({
                upgradeConfiguration: await getUpgradeConfiguration,
                licenseStatus: await getLicenceStatus,
                model: license,
                cleanModel: cloneDeep(license),
            });
        });
    }

    async save() {
        return this.doBusyTask(async () => {
            const licence = await repository.Licenses.getCurrent();
            licence.LicenseText = this.state.model.LicenseText;
            const updated = await repository.Licenses.modify(licence);
            const licenseStatus = await repository.Licenses.getCurrentStatus();
            this.setState({ model: updated, licenseStatus, cleanModel: cloneDeep(updated) });
        });
    }

    render() {
        const license = this.state.model;
        const licenseStatus = this.state.licenseStatus;
        const config = this.state.upgradeConfiguration;

        const sidebarLimits = licenseStatus && licenseStatus.Limits && licenseStatus.Limits.length > 0 && (
            <div key="licenseLimits">
                <h4>License Limits</h4>
                <DataTable>
                    <DataTableBody>
                        {licenseStatus.Limits.map((l: LicenseLimitStatus, index) => (
                            <DataTableRow key={index}>
                                <DataTableRowColumn>{l.Name}</DataTableRowColumn>
                                <DataTableRowColumn>
                                    {l.CurrentUsage}/{l.EffectiveLimitDescription}
                                </DataTableRowColumn>
                                <DataTableRowColumn>
                                    {l.Disposition === LicenseMessageDisposition.Information && (
                                        <ToolTip content={l.Message}>
                                            <em className={"fa fa-info-circle"} style={{ color: info }} />
                                        </ToolTip>
                                    )}
                                    {l.Disposition === LicenseMessageDisposition.Warning && (
                                        <ToolTip content={l.Message}>
                                            <em className={"fa fa-warning"} style={{ color: alert }} />
                                        </ToolTip>
                                    )}
                                    {l.Disposition === LicenseMessageDisposition.Error && (
                                        <ToolTip content={l.Message}>
                                            <em className={"fa fa-exclamation-triangle"} style={{ color: danger }} />
                                        </ToolTip>
                                    )}
                                </DataTableRowColumn>
                            </DataTableRow>
                        ))}
                    </DataTableBody>
                </DataTable>
            </div>
        );

        const sidebarConfig = config && (
            <div key="upgradeNotifications">
                <h4>Upgrade Notifications</h4>
                <p>
                    The update notification that is shown in the top menu will <strong>{this.getUpgradeOption()}</strong>
                </p>
                <OpenDialogButton label="Change">
                    <UpdateNotifications onSaveDone={upgradeConfiguration => this.setState({ upgradeConfiguration })} />
                </OpenDialogButton>
            </div>
        );

        const body = licenseStatus && (
            <div key="licenseBody">
                {!licenseStatus.IsCompliant && (
                    <Section>
                        <Callout type={CalloutType.Danger} title="Your Octopus Server is not compliant with your license">
                            <p>{licenseStatus.ComplianceSummary}</p>
                            <p>
                                Get more information or upgrade your license at <ExternalLink href="Purchase">octopus.com</ExternalLink>.
                            </p>
                        </Callout>
                    </Section>
                )}
                {licenseStatus.DaysToEffectiveExpiryDate < 30 && (
                    <Section>
                        <Callout type={licenseStatus.DaysToEffectiveExpiryDate > 0 ? CalloutType.Warning : CalloutType.Danger} title={this.getLicenceExpiryTitle(licenseStatus.DaysToEffectiveExpiryDate)}>
                            <p>Renewing your license guarantees access to:</p>

                            {licenseStatus.DoesExpiryBlockKeyActivities ? (
                                <ul className={styles.list}>
                                    <li>Ongoing ability to create new releases and deployments</li>
                                    <li>All new features, enhancements, bug fixes, and patches</li>
                                    <li>Continued help and support</li>
                                </ul>
                            ) : (
                                <ul className={styles.list}>
                                    <li>All major features and enhancements</li>
                                    <li>Bug fixes and patches</li>
                                    <li>Continued help and support</li>
                                </ul>
                            )}
                            <p>
                                Renewing your license is quick and easy. Visit our <ExternalLink href={`Renew?licenseKey=${license.SerialNumber}`}>renewal and upgrade page</ExternalLink> for more information.
                            </p>
                        </Callout>
                    </Section>
                )}
                <Section>
                    <div className={styles.licenceBox}>
                        <CodeEditor value={license.LicenseText} allowFullScreen={false} language={TextFormat.XML} onChange={v => this.setState({ model: { ...license, LicenseText: v } })} />
                    </div>
                </Section>
                {licenseStatus.Messages && licenseStatus.Messages.length > 0 && (
                    <Section>
                        <DataTable>
                            <DataTableBody>
                                {licenseStatus.Messages.map((m: LicenseMessage, index) => (
                                    <DataTableRow key={index}>
                                        <DataTableRowColumn>
                                            {m.Disposition === LicenseMessageDisposition.Information && <em className={"fa fa-info-circle"} style={{ color: info }} />}
                                            {m.Disposition === LicenseMessageDisposition.Warning && <em className={"fa fa-warning"} style={{ color: alert }} />}
                                            {m.Disposition === LicenseMessageDisposition.Error && <em className={"fa fa-exclamation-triangle"} style={{ color: danger }} />}
                                        </DataTableRowColumn>
                                        <DataTableRowColumn>{m.Message}</DataTableRowColumn>
                                    </DataTableRow>
                                ))}
                            </DataTableBody>
                        </DataTable>
                    </Section>
                )}
            </div>
        );

        return (
            <FormPaperLayout title="License" busy={this.state.busy} errors={this.state.errors} cleanModel={this.state.cleanModel} model={this.state.model} savePermission={{ permission: Permission.AdministerSystem }} onSaveClick={() => this.save()}>
                <SidebarLayout sideBar={[sidebarLimits, sidebarConfig]}>{body}</SidebarLayout>
            </FormPaperLayout>
        );
    }

    getUpgradeOption() {
        switch (this.state.upgradeConfiguration.NotificationMode) {
            case UpgradeNotificationMode.AlwaysShow:
                return "show when any update is available";
            case UpgradeNotificationMode.ShowOnlyMajorMinor:
                return "show only when major or minor updates are available";
            default:
                return "never show";
        }
    }

    getLicenceExpiryTitle(expiresIn: number) {
        if (expiresIn < 0) {
            return `Your license expired ${-expiresIn} days ago`;
        }
        if (expiresIn === 0) {
            return "Your license expires today";
        }
        return `Your license expires in ${expiresIn} days`;
    }
}

export default License;
