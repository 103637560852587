import * as React from "react";
import TentacleDownloadButton from "areas/infrastructure/components/TentacleDownloadButton";
import { Note } from "components/form";
import ExternalLink from "components/Navigation/ExternalLink";
import { TentacleCalloutHeading } from "./TentacleCalloutHeading";

const styles = require("./styles.less");

export const LinuxPollingTentacleCallout: React.SFC<{}> = () => (
    <div>
        <h3>Installation Instructions</h3>
        <TentacleCalloutHeading />
        <p>
            For getting started instructions please visit the <ExternalLink href="LinuxListeningTentacle">Linux Tentacle documentation</ExternalLink>.
        </p>
    </div>
);

export default LinuxPollingTentacleCallout;
