import { repository } from "../../../../../clientInstance";
import * as _ from "lodash";
export interface DeploymentEnvironmentAndTenantInfo {
    EnvironmentId: string;
    TenantId?: string;
}
export async function loadPendingInterruptions(projectId: string, deployments: DeploymentEnvironmentAndTenantInfo[]) {
    const allTasks = await Promise.all(
        deployments.map(async deployment => {
            const options = {
                environment: deployment.EnvironmentId,
                project: projectId,
                active: true,
                hasPendingInterruptions: true,
                tenant: deployment.TenantId,
            };

            return repository.Tasks.list(options);
        })
    );
    return _.flatten(allTasks.map(s => s.Items));
}
