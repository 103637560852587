import * as React from "react";
import LegacyMuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { MuiThemeProvider } from "@material-ui/core/styles";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import { getTheme, ThemePaletteType, legacyTheme } from "theme";
import { getCurrentTheme } from "./reducers/selectors";
import { connect } from "react-redux";

interface GlobalConnectedProps {
    theme: ThemePaletteType;
}

const Theme: React.StatelessComponent<GlobalConnectedProps> = ({ theme, children }) => {
    return (
        <LegacyMuiThemeProvider muiTheme={getMuiTheme(legacyTheme)}>
            <MuiThemeProvider theme={getTheme(theme)}>{children}</MuiThemeProvider>
        </LegacyMuiThemeProvider>
    );
};

const mapGlobalStateToProps = (state: GlobalState) => ({
    theme: getCurrentTheme(state),
});

const ConnectedTheme = connect(mapGlobalStateToProps)(Theme);
export default ConnectedTheme;

export { Theme, ConnectedTheme };
