import * as React from "react";
import { Callout, CalloutType } from "components/Callout/Callout";
import { Section } from "components/Section/Section";

const DeprecatedEndpoint: React.SFC = () => {
    return (
        <Section>
            <Callout type={CalloutType.Danger} title="Deprecated">
                This endpoint has been deprecated and is no longer supported by the Octopus UI.
            </Callout>
        </Section>
    );
};

export default DeprecatedEndpoint;
