import * as React from "react";
import { ExpandableFormSection, SummaryNode, Summary, FormSectionHeading } from "components/form/";
import Text from "components/form/Text/Text";
import { AccountType, SshKeyPairAccountResource } from "client/resources/accountResource";
import AccountEditBase, { AccountEditModel } from "./AccountEditBase";
import { SensitiveValue } from "client/resources/propertyValueResource";
import Sensitive, { ObfuscatedPlaceholder } from "components/form/Sensitive/Sensitive";
import SensitiveFileUpload from "components/form/SensitiveFileUpload/SensitiveFileUpload";
import { required } from "components/form/Validators";
import AccountUsage from "areas/infrastructure/components/AccountEdit/AccountUsage";

interface SshKeyPairModel extends AccountEditModel {
    username: string;
    passphrase: SensitiveValue;
    privateKeyFile?: SensitiveValue;
}

class SshKeyPairAccountEdit extends AccountEditBase<SshKeyPairAccountResource, SshKeyPairModel> {
    getPartialResource(): Partial<SshKeyPairAccountResource> & { AccountType: AccountType } {
        return {
            AccountType: AccountType.SshKeyPair,
            Username: this.state.model.username,
            PrivateKeyPassphrase: this.state.model.passphrase,
            PrivateKeyFile: this.state.model.privateKeyFile,
        };
    }

    getPartialModel(account?: SshKeyPairAccountResource): Partial<SshKeyPairModel> {
        if (!account) {
            return {
                username: null,
                passphrase: null,
                privateKeyFile: null,
            };
        }
        return {
            username: account.Username,
            passphrase: account.PrivateKeyPassphrase,
            privateKeyFile: account.PrivateKeyFile,
        };
    }

    usernameSummary(): SummaryNode {
        return this.state.model.username ? Summary.summary(this.state.model.username) : Summary.placeholder("No username provided");
    }

    passphraseSummary(): SummaryNode {
        return this.state.model.passphrase && this.state.model.passphrase.HasValue ? Summary.summary(ObfuscatedPlaceholder) : Summary.placeholder("No passphrase provided");
    }

    privateKeySummary(): SummaryNode {
        if (this.state.model.privateKeyFile && this.state.model.privateKeyFile.HasValue) {
            return Summary.summary("A private key has been provided");
        }
        return Summary.placeholder("No private key provided");
    }

    customSecondaryAction(): JSX.Element {
        return null;
    }

    customExpandableFormSections(): JSX.Element[] {
        const baseElements: JSX.Element[] = [];

        baseElements.push(
            <FormSectionHeading title="Ssh Key Pair Settings" key={"header"} />,
            <ExpandableFormSection key="username" errorKey="username" title="Username" focusOnExpandAll summary={this.usernameSummary()} help="The username to use when authenticating against the remote host.">
                <Text value={this.state.model.username} onChange={username => this.setModelState({ username })} label="Username" validate={required("Please enter an username")} error={this.getFieldError("username")} />
            </ExpandableFormSection>,
            <ExpandableFormSection key="privatekey" errorKey="privateKeyFile" title="Private Key File" summary={this.privateKeySummary()} help="Upload the private key file portion of the key pair.">
                <SensitiveFileUpload label="Private key file" value={this.state.model.privateKeyFile} onChange={privateKeyFile => this.setModelState({ privateKeyFile })} error={this.getFieldError("privateKeyFile")} />
            </ExpandableFormSection>,
            <ExpandableFormSection key="passphrase" errorKey="passphrase" title="Passphrase" summary={this.passphraseSummary()} help="The passphrase for the private key, if required.">
                <Sensitive value={this.state.model.passphrase} onChange={passphrase => this.setModelState({ passphrase })} label="Passphrase" />
            </ExpandableFormSection>
        );

        return baseElements;
    }
}

export default SshKeyPairAccountEdit;
