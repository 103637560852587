import * as React from "react";
import { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import pageIds from "pageIds";
import { CommonLibraryCertificateOverview, CommonLibraryCertificateTopics } from "../../CommonComponents/CommonLibraryCertificateViews";

export const LibraryCertificateRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.library.certificate,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryCertificateOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryCertificateTopics />;
    },
};
